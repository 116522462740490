import React from 'react';

import { CustomerCard } from '../CustomerCard';
import { IndustryCard } from '../IndustryCard';
import { useCustomer } from '@contexts/CustomerContext';
import { useNavigation } from '@react-navigation/native';
import { useAuth } from '@contexts/Auth';
import { VStack } from 'native-base';

export function RetailterCard() {
  const { customer } = useCustomer();
  const navigation = useNavigation()
  const { user } = useAuth();

  return (
    <VStack space={2}>
      <IndustryCard
        name={user.getNameIndustryStore()}
        onPress={() => navigation.navigate("ChooseIndustry")}
      />
      <CustomerCard
        code={customer.code}
        businessName={customer.businessName || ""}
        onPress={() => navigation.navigate("ChooseCustomer")}
      />
    </VStack>
  );
}