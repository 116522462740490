import React, { memo } from "react";

import { Actionsheet, Modal, View } from "native-base";
import { CouponsType } from "@types/CouponsType";
import { Coupon } from "../Coupon";

export type CouponActionsheetProps = {
  onClose: () => void
  visible: boolean
  coupon: CouponsType
}

export const CouponActionsheet: React.FC<CouponActionsheetProps> = ({ onClose, visible, coupon }) => (
  <Actionsheet
    isOpen={visible}
    onClose={() => onClose()}
  >
    <Actionsheet.Content
      flex={1}
      w={'full'}
      bgColor='white'
      _dark={{
        bgColor: 'muted.800'
      }}
      borderWidth={0}
    >
      <Modal.CloseButton
        id="coupon-actionsheet:close"
        _icon={{
          color: 'primary.600'
        }}
      />
      <View
        flex={1}
        w={'full'}
        mb={3}
        pl={2}
      >
        {coupon && <Coupon _header={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }} item={coupon} />}
      </View>
    </Actionsheet.Content>
  </Actionsheet>
)