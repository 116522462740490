import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { THEME } from "../../theme";
import { width, height } from "../Dimension";

export const styles = StyleSheet.create({
  containerContent: {
    flex: 1,
    position: "relative",
    width: width * 0.9,
    alignSelf: "center",
  },
  containerFilters: {
    alignSelf: "flex-start",
    paddingTop: width * 0.007,
    width: width * 0.24,
    alignItems: "flex-start",
  },
  containerProducts: {
    width: width * 0.66,
    alignSelf: "flex-end",
    paddingTop: width * 0.007,
  },
  tabBarContainer: {
    minHeight: width * 0.036,
    borderRadius: 2,
    marginBottom: width * 0.01,
  },
  tabBarIndicator: {
    backgroundColor: THEME.colors.green[300],
    height: height * 0.006,
  },
  tabBarNewsContainer: {
    minHeight: "52%",
    maxWidth: 72,
    alignItems: "flex-start",
  },
  tabBarTitle: {
    width: "120%",
  },
});
