export const RoutesScreen = {
  screens: {
    ChooseIndustry: "industry",
    Industry: {
      path: "industry/:industry",
      screens: {
        Home: {
          screens: {
            Main: {
              path: "home",
            },
            Brands: {
              path: "brands",
            },
            Brand: {
              path: "brand",
            },
            Products: {
              path: "products",
            },
            Combos: {
              path: "combos",
            },
            Combo: {
              path: "combo",
            },
          }
        },
        Searches: {
          path: 'search',
          screens: {
            Searches: {
              path: "",
            },
            Brands: {
              path: "brands",
            },
            Brand: {
              path: "brand",
            },
            Products: {
              path: "products",
            },
            Combos: {
              path: "combos",
            },
            Combo: {
              path: "combo",
            },
          }
        },
        More: {
          path: 'More',
        },
        Orders: {
          path: 'orders',
          screens: {
            Orders: {
              path: "",
            },
            Order: {
              path: "/:orderId",
            },
          }
        },
      },
    },
    SignIn: "signIn",
    SignInSso: "signInSso",
    Callback: "callback",
    PreferredWholesaler: "industry/:industry/wholesalers",
    ProductList: "products",
    SummaryOrder: ":industry/cart/summary",
    OrderDetail: "order/:orderId",
    ManageCustomers: "manage-customers",
    ValidateCustomers: "validate-customers",
    WholesalerOrder: ":industry/cart/wholesaler",
    FinishOrder: ":industry/cart/finish",
    ListOrder: "orders",
    ChooseCustomer: "choose-customer/:industry",
    Coupons: {
      path: "coupons",
      screens: {
        Coupons: {
          path: "",
        },
        CouponsApply: {
          path: "apply",
        },
      }
    },
    PrivacyPolicy: "privacy-policy",
    TimeBlock: "time-block",
  },
};
