import React from 'react';
import { HStack, Modal, Text } from 'native-base';

import { CartIcon } from '@assets/images/svgs/CartIcon';
import { useScheduleModal } from '@hooks/useScheduleModal';


type WebProps = {
  children: React.ReactNode,
}

export function Web({ children }: WebProps) {
  const scheduleModal = useScheduleModal();

  return (
    <Modal isOpen={scheduleModal.isOpen} onClose={scheduleModal.onClose} testID='schedule-web'>
      <Modal.Content maxWidth="504px" px={6} pb={6}>
        <Modal.CloseButton _icon={{
          size: 4,
          color: 'primary.600'
        }} />

        <Modal.Header pl={0}>
          <HStack
            w='100%'
            alignItems='center'
          >
            <CartIcon size={6} />
            <Text
              ml={4}
              flex={1}
              fontSize='md'
              fontFamily='subheading'
              color='success.700'
            >
              Data de envio do pedido
            </Text>
          </HStack>
        </Modal.Header>
        <Modal.Body px={0} pb={0}>
          {children}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}