import React, { useState } from "react";
import { View, Text } from 'native-base';
import { RFValue } from "react-native-responsive-fontsize";
import { DataTable } from "react-native-paper";
import { styles } from "./styles";
import { GenerateKey } from "../../helpers/GenerateKey";

export const CommonDataTable = (
  header: any[] = [],
  rows: any[] = [],
  fontSize: number = 6
) => {
  return (
    <DataTable key={GenerateKey()}>
      {header.length > 0 && (
        <DataTable.Header style={styles.headerColor}>
          {header.map((column) => {
            return (
              <DataTable.Title key={column} style={[{ maxWidth: column[1] + "%" }]}>
                <Text style={[styles.headerText]}>{column[0]}</Text>
              </DataTable.Title>
            );
          })}
        </DataTable.Header>
      )}

      {rows.length > 0 &&
        rows.map((row) => {
          return (
            <DataTable.Row key={GenerateKey()}>
              {Object.entries(row).map((entry: any) => {
                const [key, value] = entry;
                return (
                  <View
                    key={GenerateKey()}
                    style={[{ width: value[1] + "%" }]}
                  >
                    <Text style={[styles.dataText, { fontSize: RFValue(fontSize) }]}> {value[0]} </Text>
                  </View>
                );
              })}
            </DataTable.Row>
          );
        })}
    </DataTable>
  );
};

