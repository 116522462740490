import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';
import { AdditionalCustomersType } from '@types/AdditionalCustomersType';

type ItemListValidateCustomersProps = {
  type: 'WEB' | 'MOBILE';
  item: AdditionalCustomersType;
  index?: number
}

export function ItemListValidateCustomers({
  type,
  item,
  index = 0
}: ItemListValidateCustomersProps) {
  return (
    type === 'WEB' ? (
      <Web item={item} index={index} />
    ) : (
      <Mobile item={item} />
    )
  );
}