import React from 'react';
import { Link, ILinkProps } from 'native-base';
import { User, useAuth } from '@contexts/Auth';
import { industryVar, useIndustry } from '@hooks/useIndustry';
import { useReactiveVar } from '@apollo/client';

type LinkTradeToolsProps = ILinkProps & {
  user: User,
}

export const LinkTradeTools = ({ user, ...restProps }: LinkTradeToolsProps) => {

  useIndustry({
    industry: user.getNameIndustryStore(),
    skip: false
  });
  const industry = useReactiveVar(industryVar);
  let linkTradeTools = industry.transferOrderUrl ?? '';
  const showLinkTradeTools = linkTradeTools && user.isIndustryEmployee();
  const { user:auth } = useAuth();

  if (!showLinkTradeTools) {
    return null;
  }

  linkTradeTools = `${linkTradeTools}?r=default/sso/LoginWithToken&token=${auth.getToken()}`;

  return (
    <Link
      href={linkTradeTools}
      _hover={{
        opacity: 0.8,
      }}
      isExternal
      p={2}
      mb={3}
      _text={{
        color: 'primary.600',
        fontWeight: '700',
        fontSize: ['md', 'lg'],
        textDecorationLine: 'none',
      }}
      _dark={{
        opacity: 0.7,
        textDecorationLine: 'none'
      }}

      {...restProps}
    >
      Acessar Transfer Order
    </Link>
  )
};
