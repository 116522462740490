
import React from "react";
import { View } from "native-base";
import Img from '@assets/images/a-z_product.png';
import ImgBrand from '@assets/images/offer_brand.png';
import ImgCombo from '@assets/images/combos.png';
import { Card } from "@components/Search/Card";

export const Search = ({ navigation }: any) => {
  return (
    <View
      flex="1"
      alignItems='center'
      justifyContent='flex-start'
      px={3}
      mt={6}
    >
      <Card
        text="Produtos A-Z"
        alt="Produtos A-Z"

        onPress={() => navigation.navigate('Products')}
        sourceImg={Img}
        mb={4}
      />
      <Card
        text="Ofertas por marcas"
        alt="Ofertas por marcas"
        onPress={() => navigation.navigate('Brands')}
        sourceImg={ImgBrand}
        mb={4}
      />
      <Card
        text="Combos"
        alt="Combos"
        onPress={() => navigation.navigate('Combos')}
        sourceImg={ImgCombo}
      />
    </View>
  );
};
