import { useState } from "react";
import { useCustomer } from "../contexts/CustomerContext";

export interface CustomerFilter {
  companyName: string;
  businessName: string;
  code: string;
  group: string;
  search: string;
}

export const useFilterCustomer = () => {
  const [businessName, setbusinessName] = useState('');
  const [code, setCode] = useState('');
  const [group, setGroup] = useState('');
  const [filtered, setFiltered] = useState(false);
  const [search, setSearch] = useState('');

  const { companyName, setCompanyName } = useCustomer();

  const handleCompanyName = (companyName: string) => {
    setFiltered(true);
    setCompanyName(companyName);
  };

  const handlebusinessName = (businessName: string) => {
    setFiltered(true);
    setbusinessName(businessName);
  };

  const handleCode = (code: string) => {
    setFiltered(true);
    setCode(code);
  };

  const handleGroup = (group: string) => {
    setFiltered(true);
    setGroup(group);
  };

  const handleSearchCustomer = (filter: string) => {
    setFiltered(true);
    setSearch(filter);
  };

  const handleCustomer = {
    handleCompanyName,
    handlebusinessName,
    handleCode,
    handleGroup,
    handleSearchCustomer
  }

  const resetStates = () => {
    setSearch('');
    setCompanyName('');
    setbusinessName('');
    setCode('');
    setGroup('');
  }

  const customerFilter: CustomerFilter = { companyName, businessName, code, group, search };
  return { customerFilter, handleCustomer, setFiltered, filtered, resetStates };
};
