import React from "react";
import { TouchableOpacity } from "react-native";
import { View, Text, Button } from 'native-base';
import { CouponsType } from "@types/CouponsType";
import { useCoupon } from '@hooks/useCoupon';

import { styles } from './styles';

type ItemCouponProps = {
  coupons?: (CouponsType)[];
  onClickRules: (item: CouponsType) => void;
  activeRedeemCoupon?: boolean;
}

export const ItemCoupon: React.FC<ItemCouponProps> = ({ coupons, onClickRules, activeRedeemCoupon = true }) => {

  const { applyCoupon } = useCoupon();

  const onApplyCoupon = async (coupon: CouponsType) => {
    await applyCoupon(coupon);
  }

  if (!coupons) return <></>

  const couponsNotApplied = coupons
    .filter((item: CouponsType) => !item.applied);

  return (
    <>
      {couponsNotApplied
        .map((item: CouponsType) => {
          const isCouponActive = item?.active ?? false;

          return (
            <View style={styles.container} key={item.id}>
              <Text style={[!isCouponActive && styles.opacity]}
                fontSize={['md', 'xs']}
                fontWeight='700'
                color='muted.800'
                _dark={{
                  color: 'muted.300'
                }}
              >
                {item.coupon.name}
              </Text>
              {item.coupon.description.length > 0 &&
                <Text style={[!isCouponActive && styles.opacity]}
                  fontSize={['sm', 'xs']}
                  fontWeight='400'
                  color='muted.800'
                  _dark={{
                    color: 'muted.300'
                  }}
                  mt={1}
                >
                  {item.coupon.description}
                </Text>
              }
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                marginTop={["2", "3"]}
              >
                {activeRedeemCoupon &&
                  <Button
                    style={[!isCouponActive && styles.buttonCouponDisabled]}
                    onPress={() => onApplyCoupon(item)}
                    disabled={!isCouponActive}
                    _text={{
                      fontWeight: '400',
                      fontSize: ['sm', 'xs']
                    }}
                    _hover={{
                      bg: 'primary.500',
                    }}
                    _pressed={{
                      bg: 'primary.400',
                    }}
                    _dark={{
                      opacity: 0.8,
                    }}
                    bg='primary.600'
                    rounded='sm'
                  >
                    Resgatar cupom
                  </Button>

                }
                <TouchableOpacity onPress={() => onClickRules(item)} style={styles.buttonRules}>
                  <Text
                    fontSize={['sm', 'xs']}
                    fontWeight='400'
                    color='primary.600'
                    _dark={{
                      color: 'primary.400'
                    }}
                  >
                    Ver regras
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          );
        })
      }
    </>
  );
};