import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "../Dimension";

export const styles = StyleSheet.create({
    container: {
        width: 200,
        flex: 1,
        position: 'absolute',
        paddingRight: 20,
        paddingLeft: 20,
        borderRadius: 4,
        shadowColor: "#00000029",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowRadius: 11.41,
        elevation: 2,
        top: width * 0.05,
        backgroundColor: '#FFFFFF',
        right: width * 0.074,
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: 20
    },
    gif: {
        width: 70,
        height: 70,
        margin: 'auto',
    },
    title: {
        
        fontWeight: "500",
        fontSize: RFValue(7),
        color: "#1C1C1C",
        alignSelf: "flex-start",
        marginBottom: 15,
        marginTop: 5
    },
    btnText: {
        fontSize: RFValue(6.5),
        fontWeight: "400",
        color: "#FFFFFF",
        
    },
    btn: {
        width: "90%",
        height: 42,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#74BF48",
        backgroundColor: "#74BF48",
        margin: 'auto',
    },
    iconTop: {
        width: 0,
        height: 0,
        borderBottomColor: "#fff",
        borderBottomWidth: 15,
        borderRightColor: "#fff",
        borderRightWidth: 15,
        position: 'absolute',
        top: -8,
        left: 92,
        shadowColor: "#00000029",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowRadius: 11.41,
        elevation: 2,
        transform: [{ rotate: '45deg'}],
    }
});