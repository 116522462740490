import { getUrlParam } from "@helpers/GerUrlParam";
import { getSessionStorage, setSessionStorage } from "@helpers/SessionStorage";
import { IndustryDataType } from "../../types/IndustryDataType";
import { dynamicTab } from "../../types/DynamicTab";
import { PromotionalActionType } from "../../types/PromotionalActionType";

  const promo: PromotionalActionType = {
    name: '',
    origin: '',
    redirect: false,
    nameIndustry: '',
    active: false,
    error: false
  };

  export const setPromotionalAction = async (signed: boolean, url:any,) => {
    const queryString = new URLSearchParams(url.search);

    const hasQueryStringCampaign = queryString.get("promotionalActionName")

    if(hasQueryStringCampaign) {
      promo.name = queryString.get("promotionalActionName") || "";
      promo.origin = queryString.get("promotionalActionOrigin") || "";
      promo.nameIndustry  = getUrlParam(0);
      promo.redirect = !signed;
      promo.active = true;

      await setSessionStorage('promotionalAction', promo);
    }
  }

  export const filterIndustryPromotionalAction = async (industries: IndustryDataType[]) => {

    if(industries.length === 0) {
      return;
    }

    const promo = await getSessionStorage('promotionalAction');

    const industryList:IndustryDataType[] = industries.filter((industry) => industry.nameIndustry === promo.nameIndustry);

    if(industryList.length === 0) {
      return;
    }

    return industryList[0];
  }

  export const redirectPromotionalAction = async () => {
    const promo = await getSessionStorage('promotionalAction');

   const redirect = (nameIndustry: string) => {
      const url = new URL(document.URL);
      window.location.href = `${url.origin}/industry/${nameIndustry}/wholesalers`;
    }

    if(promo?.redirect){
      promo.redirect = false;
      await setSessionStorage('promotionalAction', promo);
      await redirect(promo.nameIndustry);
    }
  }

  export const finishPromotionalActionSession = async (timer: boolean = false) => {
    const promo = await getSessionStorage('promotionalAction');

    if(!promo) return ;

    promo.active = false;

    if(timer) {
      setTimeout(async() => {
        await setSessionStorage('promotionalAction', promo);
      },400)
    } else {
      await setSessionStorage('promotionalAction', promo);
    }
  }

  export const getTabPromotional =  (): dynamicTab[]  => {

    return [
      {
        key: 'promotionalAction',
        title: 'Ação Promocional',
        active: true,
        category: "promotional",
      }
    ]

  }
