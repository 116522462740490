import React from "react";
import { CartTargetCard } from "@components/CartTargetCard";
import { FilterIcon } from "@components/FilterIcon";
import { Text, View, Pressable, VStack } from "native-base";
import { makeVar, useReactiveVar } from "@apollo/client";
import { FastAccessCard } from "@components/FastAccessCard";

export const fastAccessModalVar = makeVar(false);

export const FilterIconMobile = () => {
  const fastAccessModal = useReactiveVar(fastAccessModalVar);
  const closeModal = React.useCallback(
    () => fastAccessModalVar(false),
    [fastAccessModal]
  );

  return (
    <VStack bg={"white"}>
      <View>
        <CartTargetCard bgColor={"white"} testID="cart-target-card" />
      </View>
      <View ml={2} w={"full"}>
        <Pressable
          onPress={() => fastAccessModalVar(true)}
          _pressed={{
            opacity: 30,
          }}
        >
          <View flexDirection={"row"} mb={1}>
            <FilterIcon width={20} height={"20"} />
            <Text fontSize={"md"} fontWeight={"500"} color={"primary.600"}>
              Filtrar
            </Text>
          </View>
        </Pressable>
      </View>

      <FastAccessCard.Actionsheet
        visible={fastAccessModal}
        closeModal={closeModal}
      />
    </VStack>
  );
};
