import { StyleSheet } from "react-native";
import { width, height } from "@components/Dimension";

export const styles = StyleSheet.create({
  container: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: height * 0.05,
  },
  pagination: {
    borderRadius: 2,
    width: width * 0.024,
    height: height * 0.008,
    marginTop: width * 0.060,
    marginLeft: 0,
    marginRight: width * 0.006,
  },
});