import React, { useEffect, useState } from "react";
import { Box, CheckCircleIcon, HStack, IBoxProps, Input, Skeleton, Text, View, WarningOutlineIcon, useToast } from "native-base";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { Loading } from "@components/Loading";
import { render } from "@testing-library/react-native";

export const CustomOrderCode = () => {

  const { loading: loadingCart, setCustomOrderCode, cart } = useShoppingCart();

  const [customCode, setCustomCode] = useState<string | null>('');
  const [previousCode, setPreviousCode] = useState<string | null>('');

  useEffect(() => {
    if(!loadingCart && cart?.customCode) {
      setCustomCode(cart?.customCode);
    }
  }, [cart])

  const handleCodeMutation = () => {
    setPreviousCode(customCode);
    if(customCode != previousCode) {
      setCustomOrderCode(customCode);  
    }
  }

  const codeChangeValidation = (code: string) => {
    code = code.replace(/[^0-9a-zA-Z]+/ig, "")
    const newCode = code === '' ? null : code; 
    setCustomCode(newCode);
  }


  return (
    !loadingCart ? <Box
      flexDirection='row'
      justifyContent='space-between'
      marginBottom={5}
    >
      <Text
        fontSize={16}
        fontWeight={500}
        lineHeight={"md"}
        color={'muted.800'}
        _dark={{
          color:'muted.400'
        }}
      >
        {'Código Personalizado'}
      </Text>
      <View flexDirection={'column'}>
        <Input
        borderColor={'green.700'}
        value={customCode || ''}
        onChange={(e) => {
          codeChangeValidation(e.target.value);
        }}
        onBlur={(e) => {
          handleCodeMutation()
        }}
        />
        <Text
        color={'muted.800'}>
          Opcional
        </Text>
      </View>
    </Box> : <Loading></Loading>
  );
};
