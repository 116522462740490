import React, { useContext } from "react";
import { Button, IStackProps, VStack } from "native-base";

import { FastAcessContext } from "./Context";

export type FastAccessCardButtonsProps = IStackProps & {}

export const FastAccessCardButtons: React.FC<FastAccessCardButtonsProps> = (props) => {
  const { clear, filter } = useContext(FastAcessContext);  
  return (
    <VStack  {...props} space={5} justifyContent={"space-around"}>
      <Button width={"auto"} onPress={filter}>Aplicar filtros</Button>
      <Button variant={"outline"} onPress={clear}>Limpar filtros</Button>      
    </VStack>
  )
}
