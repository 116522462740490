import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "../../Dimension";

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 3,
    padding: 15,
    marginBottom: width * 0.008
  },
  buttonCouponDisabled: {
    opacity: 0.5,
    backgroundColor: "#686868",
    borderColor: "#686868",
  },
  buttonRules: {
    alignItems: "center",
    justifyContent: "center",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: "#74BF48"
  },
  opacity: {
    opacity: 0.5
  },
  containerProgress: {
    marginTop: width * 0.005
  },
});