
import React, { useCallback, useMemo } from "react";
import { useAuth } from "@contexts/Auth";
import { Button } from "../../Button";
import { ProfileProps } from "../ProfileProps";

export const IndustryEmployee: React.FC<ProfileProps> = ({ navigation, ...restPros }) => {
  const { user } = useAuth()

  const onPress = useCallback((): void => {
    navigation.navigate('ChooseCustomer')
  }, [user])


  const text = useMemo((): string => {
    return 'Alterar Cliente'
  }, [user])

  return (<Button onPress={onPress} text={text} {...restPros} />);
};
