import React from "react";
import { HStack, Text, View } from "native-base";
import { MaskedText } from "react-native-mask-text";
import { WholesalerBranchesType } from "@types/WholesalerBranchesType";
import { PaymentTypeEnum } from "@enums/PaymentTypeEnum";


type WholesalerItemOrderProps = {
  item: WholesalerBranchesType
}

export const WholesalerItemOrder = ({ item }: WholesalerItemOrderProps) => {

  let payment = (item.wholesaler.paymentTerms?.find(payment => payment.selected))?.description;

  if (item.wholesaler?.paymentType == PaymentTypeEnum.CLIENT) {
    payment = 'Prazo cliente';
  }

  return (
    <View
      p={2}
      mb={4}
      w={"full"}
      flexDirection={["column"]}
      backgroundColor={'white'}
      _dark={{
        bgColor: 'muted.800',
        borderWidth: 0,
      }}
      borderRadius={6}
      borderWidth={1}
      borderColor='gray.300'
      justifyContent="center"
      alignItems="center"
    >

      <View h={"65%"} ml={2} justifyContent={"center"} alignItems={["normal"]} w={"full"} flexDirection={["column"]}
      >
        <View flexDirection={"row"} justifyContent={"space-between"} w={"90%"} mt={"2px"}>
          <HStack space={2}>
            <View
              bgColor={'muted.200'}
              borderBottomRadius={2}
            >
              <Text
                px={"2px"}
                p={"1px"}
                fontSize={["xs", '10']}
                _dark={{
                  color: 'muted.800'
                }}
              >
                {item.priority}º distribuidor
              </Text>
            </View>
            <View
              bgColor={'orange.100'}
              _dark={{
                bgColor: 'orange.200'
              }}
              justifyContent={'center'}
              borderBottomRadius={2}>
              {payment && <Text
                px={"2px"}
                fontSize={["xs", '10']}
                _dark={{
                  color: 'muted.800'
                }}
              >
                {payment}
              </Text>}
            </View>
          </HStack>
        </View>
        <View>
          <Text
            fontSize={["xs", 'xs', 'xs', "sm"]}
            color={'muted.800'}
            selectable
            fontWeight={"500"}
            _dark={{
              color: 'muted.400'
            }}
          >
            {item.description}
          </Text>
        </View>
        <View>
          <HStack alignItems={["center"]}>
            <Text
              fontSize={["base", "xs"]}
              selectable
              color={'muted.800'}
              _dark={{
                color: 'muted.400'
              }} >
              <MaskedText mask="99.999.999/9999-99">{item.code}</MaskedText>
            </Text>
          </HStack>
        </View>
      </View>
    </View>
  );
};