import React from "react";
import { View, Text, Box, HStack, VStack, Hidden, Pressable } from 'native-base';
import { TextHelper } from '@helpers/TextHelper';
import { styles } from "@components/DragCard/styles";
import { WholesalerBranchesType } from "../../../types/WholesalerBranchesType";
import { PaymentTermType } from "../../../types/PaymentTermType";
import { ScreenDimensions } from '@screens/ChooseWholesaler/ScreenDimensions';

interface Props {
  item: WholesalerBranchesType
  showBillingOptions: boolean
  setWholesaler?: () => void,
  isPreferredWholesalerScreen: boolean
}

const paymentText = 'Prazo Cliente';

export function CardWholesaler({ item, showBillingOptions = false, setWholesaler, isPreferredWholesalerScreen }: Props) {
  const largeDevices = ["xs", "sm", "md", "lg", "xl", "2xl"];
  let selectedPaymentText = paymentText;

  if (item.wholesaler.paymentTerms?.length == 1 && showBillingOptions) {
    item.wholesaler.paymentTerms[0].selected = true;
  }

  let selectedPayment: PaymentTermType[] | undefined;

  selectedPayment = item.wholesaler.paymentTerms?.filter(
    (paymentTerm: PaymentTermType) => paymentTerm.selected === true
  );

  if (selectedPayment?.length) {
    selectedPaymentText = selectedPayment[0]?.description;
  }

  return (
    <Box width={['100%', ScreenDimensions()]} minH={16} _dark={{ bg: styles.cardsBackgroundDark.color }}>
      <VStack
        w='full'
        h='100%'
        justifyContent='center'
        p={3}
      >
        <Hidden only={largeDevices}>
          <HStack
            width='fit-content'
            px={2}
            rounded='md'
            bg='orange.100'
          >
            {!isPreferredWholesalerScreen && (
              <Text fontSize='xs' _dark={{ color: styles.darkSecondary.color }}>
                {selectedPaymentText}
              </Text>
            )}
          </HStack>
        </Hidden>

        <Text
          fontSize={['xs', 'sm']}
          fontWeight='500'
          color={styles.fontColorDefault.color}
          _dark={{ color: styles.darkPrimary.color }}
        >
          {item.description}
        </Text>

        <HStack alignItems='center'>
          <Text
            flex={1}
            fontSize='xs'
            color={styles.fontColorDefault.color}
            _dark={{ color: styles.darkPrimary.color }}
          >
            {new TextHelper(item.code || "").formatCNPJ()}
          </Text>

          <Hidden only={largeDevices}>
            <Pressable onPress={setWholesaler}>
              <Text
                fontSize='xs'
                fontFamily='heading'
                color={styles.linkColor.color}
              >
                selecionar
              </Text>
            </Pressable>
          </Hidden>
        </HStack>
      </VStack>
    </Box>
  )
}