import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useAuth } from "../contexts/Auth";
import { AssortmentsByUserFilterType } from "../types/AssortmentsByUserFilterType";
import { AssortmentType } from "../types/AssortmentType";


const LOAD_USER_DATA = gql`
query (
  $activeOnly: Boolean
  $filter: Retail_AssortmentsByUserIdFilter
) {
    Retail_assortmentsByUser(activeOnly:$activeOnly filter: $filter first:1000) {
      id
      name
    }
}`;

const useAssortmentsByUser = (filter: AssortmentsByUserFilterType, activeOnly: boolean = true) => {
  return useQuery(LOAD_USER_DATA, {
    variables: {
      activeOnly,
      filter
    },
    fetchPolicy: 'no-cache',
  });
};

export const useAssortments = () => {
  const { user } = useAuth();
  const { data, error, loading } = useAssortmentsByUser({
    industry: {
      name: user.getNameIndustryStore()
    }
  });
  const [assortments, setAssortments] = useState<AssortmentType[]>([]);
  const [first, setFirst] = useState<boolean>(true);

  if (data && first) {
    const result = data.Retail_assortmentsByUser?.map((assortment: AssortmentType) => {

      return {
        id: assortment.id,
        name: assortment.name,
      } as AssortmentType
    })

    setAssortments(result ?? []);
    setFirst(false)
  }

  return { assortments, loading, error }
};
