import { useAuth } from "../contexts/Auth";
import { IntegrationEnum } from "../enums/IntegrationEnum";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useCustomer } from "../contexts/CustomerContext";
import { IndustryInputType } from "../types/IndustryInputType";
import { BrandType } from "../types/BrandType";
import Cdn from "../helpers/Cdn";
import { AvailabilityConsolidateEnum } from "../enums/AvailabilityConsolidateEnum";
import { CommercialPolicyInputType } from "@types/CommercialPolicyInputType";

const ALL_COMMERCIAL_POLICIES = gql`
  query AllCommercialPolicies(
    $customerCode: String!
    $integrations: IntegrationEnum!
    $prevailing: Boolean
    $industry: IndustryInputType
    $aggregator: Boolean
    $availability: AvailabilityConsolidateEnum
  ) {
    allCommercialPolicies(
      customerCode: $customerCode
      integrations: [$integrations]
      prevailing: $prevailing
      industry: $industry
      aggregator: $aggregator
      availability: $availability
    ) {
      id
      industry {
        industryCode
        name
      }
    }
  }
`;

const ALL_COMMERCIAL_POLICIES_WITH_DISCOUNT_BRANDS = gql`
  query AllCommercialPolicies(
    $customerCode: String!
    $integrations: IntegrationEnum!
    $prevailing: Boolean
    $industry: IndustryInputType
    $aggregator: Boolean,
    $filter: CommercialPolicyInputType,
    $discountFilter: DiscountsBrandArgsType
    $availability: AvailabilityConsolidateEnum
  ) {
    allCommercialPolicies(
      customerCode: $customerCode
      integrations: [$integrations]
      prevailing: $prevailing
      industry: $industry
      aggregator: $aggregator      
      filter: $filter
      availability: $availability
    ) {
      id
      endValidityDate
      industry {
        industryCode
        name
      }
      discountsBrand(filter: $discountFilter) {
        id
        amount
        value
        brand {
          id
          description
        }
      }
    }
  }
`;

export const useAllCommercialPolicies = (
  prevailing: boolean = true,
  industry: IndustryInputType = {
    isEnabled: true,
  },
  aggregator: boolean = true
) => {
  const { user } = useAuth();
  const { customerCode, impersonate } = useCustomer();
  const integrations = IntegrationEnum.POINT_OF_SALES;
  const availability: AvailabilityConsolidateEnum = impersonate && user.isUserAllowedToImpersonate()
    ? AvailabilityConsolidateEnum.CUSTOMER 
    : user.getAvailability();

  return useQuery(ALL_COMMERCIAL_POLICIES, {
    variables: {
      customerCode: customerCode !== "" ? customerCode : user.getUserCode(),
      prevailing,
      integrations,
      industry,
      aggregator,
      availability,
    },
    fetchPolicy: "no-cache",
  });
};

export const useAllCommercialPoliciesWithBrand = (
  prevailing: boolean = true,
  industry: IndustryInputType = {
    isEnabled: true,
  },
  aggregator: boolean = true,
  filter: CommercialPolicyInputType
) => {
  const { user } = useAuth();
  const { customerCode, impersonate } = useCustomer();
  const integrations = IntegrationEnum.POINT_OF_SALES;
  const assortmentsIds: number[] = user
    .getAssortments()
    .map((assortment) => Number(assortment.id));
  const discountFilter = {
    assortmentsIds,
  };
  const availability: AvailabilityConsolidateEnum = impersonate && user.isUserAllowedToImpersonate()
  ? AvailabilityConsolidateEnum.CUSTOMER 
  : user.getAvailability();


  return useQuery(ALL_COMMERCIAL_POLICIES_WITH_DISCOUNT_BRANDS, {
    variables: {
      customerCode: customerCode,
      prevailing,
      integrations,
      industry,
      aggregator,
      filter,
      discountFilter,
      availability
    },
    fetchPolicy: "no-cache",
  });
};

export const useAllCommercialPolicieslazy = (
  prevailing: boolean = true,
  industry: IndustryInputType = {
    isEnabled: true,
  }
) => {
  const { customerCode, impersonate } = useCustomer();
  const { user } = useAuth();
  const integrations = IntegrationEnum.POINT_OF_SALES;
  const availability: AvailabilityConsolidateEnum = impersonate && user.isUserAllowedToImpersonate()
  ? AvailabilityConsolidateEnum.CUSTOMER 
  : user.getAvailability();

  return useLazyQuery(ALL_COMMERCIAL_POLICIES, {
    variables: {
      customerCode: customerCode,
      prevailing,
      integrations,
      availability,
      industry,
    },
    fetchPolicy: "no-cache",
  });
};

export const refactorResult = (
  data: any[],
): BrandType[] => {
  const { user } = useAuth();
  const brands: BrandType[] = [];
  for (const commercialPolicy of data) {
    for (const discountBrand of commercialPolicy.discountsBrand) {
      const brandFind = brands.find(
        (brand) =>
          brand.id === discountBrand.brand.id &&
          brand.idCommercialPolicy === commercialPolicy.id
      );
      if (!brandFind) {
        brands.push({
          id: discountBrand.brand.id,
          idCommercialPolicy: commercialPolicy.id,
          name: discountBrand.brand.description,
          endValidityDate: commercialPolicy.endValidityDate,
          image: Cdn.getUrl(
            user?.getNameIndustryStore(),
            "brand",
            `${discountBrand.brand.id}.png`
          ),
          discounts: [
            {
              amount: discountBrand.amount,
              value: discountBrand.value,
            },
          ],
        } as BrandType);
      } else {
        brandFind.discounts.push({
          amount: discountBrand.amount,
          value: discountBrand.value,
        });
      }
    }
  }

  return brands;
};
