import React from "react";
import { ScrollView, View } from 'native-base';
import { ComboTab } from "@components/ComboTab";

export const Combos = () => {
  return (
    <ScrollView px={3} flex={1} showsVerticalScrollIndicator={false} >
      <ComboTab />
    </ScrollView>
  );
};
