import React from "react";
import { Box, IBoxProps } from "native-base";

type SeparatorProps = IBoxProps

export const Separator = (props: SeparatorProps) => {
  return <Box
    height={'0.5'}
    bgColor={"muted.500"}
    width={'full'}
    alignSelf={"center"}
    my={4}
    {...props}
  />
};
