import React from "react";
import { View, FlatList } from "native-base";
import { cloneDeep } from "@apollo/client/utilities";

import { Accordion } from "@components/Accordion";
import { width } from "@components/Dimension";
import { ProductCart } from "@components/SuggestedProducts/SuggestionProductCart/ProductCart";

import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { CartType, ItemType } from "@types/CartType";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { ListType } from "@types/ListType";

interface Props {
  cart: CartType | undefined;
}

interface SectionedListInterface {
  [key: string]: CommercialPolicyProductsType;
}

interface ConvertedListInterface {
  listId: string;
  value: CommercialPolicyProductsType;
}

export const SuggestionProductCart = (Props: Props) => {
  const { cart } = Props;
  const sectionedList: SectionedListInterface[] = [];

  let suggestedProducts = cart?.items.filter((item: any) => {
    return (item?.suggestions?.list?.length && item?.suggestions?.list?.length > 0)
  });

  const createSectionedList = (index: number, value: ItemType) => {
    if (sectionedList.hasOwnProperty(index)) {
      const itemInList = value?.suggestions?.list?.find((list: ListType) => {
        return list.code == index;
      });

      const newValue = cloneDeep(value);
      newValue.amount = itemInList?.amount;
      sectionedList[index].push(newValue);
      return;
    }

    const itemInList = value?.suggestions?.list?.find((list: ListType) => {
      return list.code == index;
    });

    const newValue = cloneDeep(value);
    newValue.amount = itemInList?.amount;

    sectionedList[index] = [newValue];
  };

  for (let product of suggestedProducts) {
    product?.suggestions?.list?.map((list: ListType) => {
      if (list?.code) {
        createSectionedList(list?.code, product);
      }
    });
  }

  let i = 0;
  const convertedList: ConvertedListInterface[] = [];

  Object.keys(sectionedList).map((listas: string) => {
    convertedList.push({
      listId: Object.keys(sectionedList)[i],
      value: Object.values(sectionedList)[i],
    });
    i++;
  });

  const { clearList, clearByType } = useShoppingCart();

  return Object.keys(sectionedList).length > 0 ? (
    <View flexDirection={"column"} >
    <Accordion
      title="Listas sugeridas"
      callbackText={"Limpar listas sugeridas"}
      callback={() => {
        clearByType([ItemTypeEnum.SUGGESTED_LIST]);
      }}
    >
      {convertedList?.map((item: ConvertedListInterface) => {
        return (
          <Accordion
            key={item.listId}
            title={item.listId || "Lista"}
            callbackText={"Limpar lista"}
            callback={() => clearList(item.listId)}
          >
            <FlatList
              mb={5}
              data={item.value}
              keyExtractor={(item) => item.product.code}
              renderItem={({
                item,
              }: {
                item: CommercialPolicyProductsType;
              }) => <ProductCart product={item} />}
              numColumns={1}
              ItemSeparatorComponent={() => (
                <View style={{ height: width * 0.01 }} />
              )}
              style={{
                width: width * 0.26,
              }}
            />
          </Accordion>
        );
      })}
    </Accordion>
    </View>
  ) : (
    <></>
  );
};
