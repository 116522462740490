import React, { useState } from "react";
import { View, Text, ScrollView, Button, Hidden } from 'native-base';
import { ChooseWholesaler } from "@screens/ChooseWholesaler";
import { Config } from "@configs/Config";
import { UnexpectedError } from "../../components/UnexpectedError";
import { Loading } from "../../components/Loading";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useAuth } from "../../contexts/Auth";
import { useSaveCustomerWholesalerBranches } from "@hooks/useSaveCustomerWholesalerBranches";
import { useFilterWholesalers } from "@hooks/useFilterWholesaler";
import { useSearchWholesalers } from "@hooks/useSearchWholesalers";
import { WholesalerBranchesInputType } from "../../types/WholesalerBranchesInputType";
import { WholesalerBranchesType } from "../../types/WholesalerBranchesType";
import { btnSaveStyleProps, btnDisabled } from "@screens/ChooseWholesaler/styleProps";
import { useNavigation } from '@react-navigation/native';
import { styles } from "./styles";
import { useCustomer } from "../../contexts/CustomerContext";
import { useWholesalersStorage } from "@hooks/useWholesalersStorage";
import { setContext } from "@apollo/client/link/context";

export const PreferredWholesaler = () => {
  const navigation = useNavigation();

  const [wholesalerState, setWholesalerState] = useState<WholesalerBranchesType[]>([])
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const { loading: loadingCart, changeShippingOrder } = useShoppingCart()
  const { customerCode } = useCustomer();
  const [saveCustomerWholesalerBranches] = useSaveCustomerWholesalerBranches();

  const wholesalersStorage = useWholesalersStorage();
  const { searchText, handleSearchWholesalers } = useFilterWholesalers();
  const { wholesalerSearchList, loading, error } = useSearchWholesalers({ text: searchText });

  const handleActionButton = async (newPriority: WholesalerBranchesType[], wholesalersBranch: WholesalerBranchesInputType[]) => {
    wholesalersBranch = wholesalersBranch.map(wholesaler => ({ code: wholesaler.code, priority: wholesaler.priority }));
    const industryName = user?.getNameIndustryStore();

    wholesalersStorage.update(newPriority, Config.PREFERRED_WHOLESALER_STORAGE_EXPIRATION);
    setWholesalerState(newPriority);

    saveCustomerWholesalerBranches({
      variables: { customerCode, industryName, wholesalersBranch },
    });// TODO: dando erro possivelmente na master tb
    await changeShippingOrder(newPriority);
    navigation.navigate('Industry', { industry: industry })
  };

  const handleButton = (onPress: () => void, disabled: boolean) => {
    const btnStyleProps = disabled ? { ...btnDisabled } : { ...btnSaveStyleProps }

    return (
      <Button  {...btnStyleProps}
        isDisabled={disabled}
        onPress={onPress}>
        Salvar lista de Distribuidores
      </Button>
    )
  };

  if (loading || loadingCart || wholesalersStorage.loading) {
    return <Loading />;
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  return (
    <View style={styles.container}>
      <Hidden only={['base', 'xs']}>
        <>
          <Text style={styles.title}>
            Selecione a sua ordem preferencial de distribuidores
          </Text>
          <Text style={styles.titleSubtitle}>
            Os preços exibidos no portal serão relacionados ao 1º distribuidor selecionado.
          </Text>
        </>
      </Hidden>
      {loading ? (
        <Loading />
      ) : (
        <ScrollView flex={1}>
          <ChooseWholesaler
            searchWholesalers={handleSearchWholesalers}
            data={wholesalerSearchList}
            storageValue={wholesalersStorage.wholesalers}
            actionButton={handleActionButton}
            handleButton={handleButton}
            clearEnabled={false}
            context={wholesalerState}
            contextFunction={setWholesalerState}
            orderWholesalers={false}
            isPreferredWholesalerScreen={true}
          />
        </ScrollView>
      )}
    </View>
  );
};