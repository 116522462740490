import React from 'react';
import { create } from 'zustand';

interface AlertCouponModalStore {
  isOpen: boolean;
  isVisible: boolean
  countCoupon: number
  onOpen: () => void;
  onClose: () => void;
  onVisible: () => void;
  onInvisible: () => void;
  setCountCoupon: (countCoupon: number) => void
}

export const useAlertCouponModal = create<AlertCouponModalStore>((set) => ({
  isOpen: false,
  isVisible: false,
  countCoupon: 0,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  onVisible: () => set({ isVisible: true }),
  onInvisible: () => set({ isVisible: false }),
  setCountCoupon: (countCoupon: number) => set({ countCoupon }),
}));
