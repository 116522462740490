import React, { createContext } from "react";
import { FilterInterface } from "./FilterInterface";

interface ContextData {
    valuesCheckBox: FilterInterface;
    filter: () => void
    clear: () => void
}

export const FastAcessContext = createContext<ContextData>(
    {} as ContextData
);

