import React from 'react';
import { View, Text } from 'native-base';
import Ionicons from 'react-native-vector-icons/Ionicons';

export const DialogContentMinAmount = () => {
  const icon = <Ionicons name='alert-circle' size={20} color='red' />;
  return (
    <View>
      <Text
        borderBottomWidth={1}
        borderBottomColor='white'
        numberOfLines={5}
      >
        Você não atingiu as regras mínimas para concluir o pedido. Verifique
        as informações presentes no alerta do produto para finalizar o
        pedido. ({icon})
      </Text>
    </View>
  );
};
