import React, { useEffect } from "react";
import { useColorMode } from "native-base";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";

import AuthRoutes from "./Auth.routes";
import AppRoutes from "./App.routes";
import { RoutesScreen } from "./RoutesScreen";

import { Loading } from "@components/Loading";
import { useAuth } from "@contexts/Auth";
import { CouponContextProvider } from "@contexts/CouponContext";
import { useCustomer } from "@contexts/CustomerContext";
import { ShoppingCartApiProvider } from "@contexts/ShoppingCartContext2";
import { AlertAddToCart } from "@components/AlertAddToCart";
import { AlertOverwriteToCart } from "@components/AlertOverwriteToCart";
import { AlertExistsInList } from "@components/AlertExistsInList";
import { AlertListItemExist } from "@components/AlertListItemExist";
import { FractionationAlert } from "@components/FractionationAlert";
import { setPromotionalAction } from "@components/PromotionalAction";

const config = RoutesScreen;

const linking = {
  config,
  prefixes: [],
};

const Routes = () => {
  const { signed, loading } = useAuth();
  const { loading: loadingCustomer } = useCustomer();
  const { colorMode } = useColorMode();

  useEffect(() => {
      const url = new URL(document.URL);
      setPromotionalAction(signed, url);
  }, [signed])

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: colorMode === "dark" ? "#282929" : "#FAFBFB",
    },
    dark: colorMode === "dark",
  };

  if (loading || loadingCustomer) {
    return <Loading />;
  }

  return (
    <NavigationContainer linking={linking} theme={MyTheme} independent={true}>
      <ShoppingCartApiProvider>
        <CouponContextProvider>
          <>
            {signed ? <AppRoutes /> : <AuthRoutes />}
            <AlertAddToCart />
            {/* TODO: provisorio até ter um root layout */}
            <AlertOverwriteToCart />
            <AlertExistsInList />
            <AlertListItemExist />
            <FractionationAlert />
          </>
        </CouponContextProvider>
      </ShoppingCartApiProvider>
    </NavigationContainer>
  );
};

export default Routes;
