import React, { useCallback } from 'react';
import { useColorMode, useTheme } from 'native-base';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { MaterialCommunityIcons, AntDesign, Feather, SimpleLineIcons } from '@expo/vector-icons';

import { HeaderBar } from '@components/HeaderBar';
import { Brand } from '@screens/Brand';
import { Brands } from '@screens/Brands';
import { OrderListMobile } from '@screens/OrderListMobile';
import { Product } from '@screens/Product';
import { Search } from '@screens/Search';
import { More } from '@screens/More';
import { OrderMobile } from '@screens/OrderMobile';
import { Combos } from "@screens/Combos";
import { Combo } from "@screens/Combo";

const Bottom = createBottomTabNavigator()
const Stack = createNativeStackNavigator()

function ProductStackScreen() {
  return (
    <Stack.Navigator initialRouteName="Main">
      <Stack.Screen name="Main" component={Product} initialParams={{ name: "Início" }}
        options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader isLeftLogoHomeHeader {...props} /> }}
      />
      <Stack.Screen name="Products" component={Product} initialParams={{ name: "Produtos" }}
        options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name="Brands" component={Brands} initialParams={{ name: "Ofertas por marcas" }}
        options={{ headerShown: true, title: 'Ofertas por marcas', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name="Brand" component={Brand} initialParams={{ name: "Ofertas por marcas" }}
        options={{ headerShown: true, title: 'Ofertas por marcas', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name="Combos" component={Product} initialParams={{ name: "Combos" }}
        options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
    </Stack.Navigator>
  );
}


function SearchScreen() {
  return (
    <Stack.Navigator initialRouteName="Searches">
      <Stack.Screen name="Searches" component={Search} initialParams={{ name: "Categorias" }}
        options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name='Products' component={Product} initialParams={{ name: 'Produtos' }}
        options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name='Brands' component={Brands} initialParams={{ name: 'Ofertas por marcas' }}
        options={{ headerShown: true, title: 'Ofertas por marcas', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name='Brand' component={Brand} initialParams={{ name: 'Ofertas por marcas' }}
        options={{ headerShown: true, title: 'Ofertas por marcas', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name="Combos" component={Combos} initialParams={{ name: "Combos" }}
        options={{ headerShown: true, title: 'Combos', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
      <Stack.Screen name="Combo" component={Combo} initialParams={{ name: "Combo" }}
        options={{ headerShown: true, title: 'Combos', header: (props) => <HeaderBar isStackHeader {...props} /> }}
      />
    </Stack.Navigator>
  );
}

function Orders() {
  return (
    <Stack.Navigator initialRouteName="Orders">
      <Stack.Screen name='Orders'
        component={OrderListMobile}
        initialParams={{ name: "Lista de pedidos" }}
        options={{
          title: 'Lista de pedidos',
          headerShown: true,
          header: (props) => <HeaderBar isStackHeader {...props} />
        }}
      />
      <Stack.Screen
        name='Order'
        component={OrderMobile}
        initialParams={{ name: 'Detalhes do pedido' }}
        options={{
          headerShown: true,
          title: 'Detalhes do pedido',
          header: (props) => <HeaderBar isStackHeader hasRightIcon={false} {...props}
          />
        }}
      />
    </Stack.Navigator>
  );
}

export function HomeBottomScreen() {
  const { colors } = useTheme()
  const { colorMode } = useColorMode()

  const color = useCallback(() => {
    if (colorMode == 'dark') {
      return colors.muted[400];
    }
    return colors.muted[700];
  }, [colors, colorMode])

  return (
    <Bottom.Navigator
      initialRouteName='Home'
      screenOptions={{
        unmountOnBlur: true,
        headerShown: false,
        lazy: true,
        tabBarActiveTintColor: colors.primary[600],
        tabBarInactiveTintColor: color(),
        tabBarStyle: {
          backgroundColor: colorMode == 'dark' ? colors.muted[800] : colors.muted[100],
          borderTopWidth: 0
        },
      }}
    >
      <Bottom.Screen
        name='Home'
        component={ProductStackScreen}
        options={{
          title: 'Início',
          tabBarIcon: (props) => (
            <SimpleLineIcons
              name='home'
              size={26}
              style={{
                flexDirection: 'row'
              }}
              color={props.focused ? colors.primary[600] : color()}
            />
          )
        }}
      />
      <Bottom.Screen
        name="Searches"
        component={SearchScreen}
        initialParams={{ name: "Categorias" }}
        options={{
          title: 'Buscas',
          tabBarIcon: (props) => (
            <AntDesign name='search1'
              size={26}
              color={props.focused ? colors.primary[600] : color()}
            />
          )
        }}
      />
      <Bottom.Screen
        name='Orders'
        component={Orders}
        initialParams={{ name: "Lista de pedidos" }}
        options={{
          title: 'Lista de pedidos',
          tabBarIcon: (props) => (
            <MaterialCommunityIcons
              name='purse-outline'
              size={28}
              color={props.focused ? colors.primary[600] : color()}
            />
          ),
        }}
      />
      <Bottom.Screen
        name="More"
        component={More}
        options={{
          title: 'Mais',
          tabBarIcon: (props) => (
            <Feather name='more-horizontal'
              size={28}
              color={props.focused ? colors.primary[600] : color()}
            />
          )
        }}
      />
    </Bottom.Navigator>
  );
}
