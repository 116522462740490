import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import Storage from "../components/StorageTTL";
import { CustomerByUserType } from "../types/CustomerByUserType";
import { useAuth } from "./Auth";

interface CustomerContextData {
  customerCode: string;
  hasCustomer: boolean;
  loading: boolean;
  customerLoading: boolean;
  saveCustomer(customerInput: CustomerByUserType): Promise<void>;
  customer: CustomerByUserType;
  cardSelected: string;
  setCardSelected(code: string): void;
  customerModalOpen: boolean;
  setCustomerModalOpen(code: boolean): void;
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  customerListData: never[];
  setCustomerListData: Dispatch<SetStateAction<never[]>>;
  progressiveFilter: boolean;
  setProgressiveFilter: Dispatch<SetStateAction<boolean>>;
  impersonate: boolean;
  setImpersonate: Dispatch<SetStateAction<boolean>>;
  setImpersonateStorage(value: boolean): void;
}

export const CustomerContext = createContext<CustomerContextData>(
  {} as CustomerContextData
);

export const CustomerProvider: React.FC = ({ children }) => {
  const key = `Customer`;
  const [loading, setLoading] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(true);
  const [cardSelected, setCardSelected] = useState("");
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [customerListData, setCustomerListData] = useState([]);
  const [impersonate, setImpersonate] = useState(false);
  const { user, setAssortments } = useAuth();

  const [customerCode, setCustomerCode] = useState<string>("");

  const [customer, setCustomer] = useState<CustomerByUserType>(() => {
    return {} as CustomerByUserType;
  });

  const [progressiveFilter, setProgressiveFilter] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [customer]);

  const loadImpersonateData = useCallback(async () => {
    const storageImpersonate = await Storage.getItem('impersonate');
    if (storageImpersonate && user.getUuid()) {
      setImpersonate(storageImpersonate);
      setAssortments([], user);
    }

  }, [user])

  useEffect(() => {
    async function loadStorageData() {
      const storagedCustomer = await Storage.getItem(key);
      if (storagedCustomer) {
        setCustomer(storagedCustomer);
      }
      setCustomerLoading(false);
    }

    loadStorageData();
    loadImpersonateData();
  }, [loadImpersonateData]);

  async function setImpersonateStorage(value: boolean) {
    await Storage.setItem('impersonate', value);
    const assortments = value && user.isUserAllowedToImpersonate() ? [] : user.getAssortments();
    setAssortments(assortments, user);
    setImpersonate(value);
  }

  async function saveCustomer(customerInput: CustomerByUserType) {
    setCustomer(customerInput);
    setCustomerCode(customerInput.code);
    let expires_in = new Date().getTime();
    await Storage.setItem(key, JSON.stringify(customerInput), expires_in);
  }

  return (
    <CustomerContext.Provider
      value={{
        customerCode: customer?.code ?? "",
        hasCustomer: customer?.code ? customer?.code.length > 0 : false,
        loading: loading,
        customerLoading: customerLoading,
        saveCustomer,
        customer,
        setCardSelected,
        cardSelected,
        companyName,
        setCompanyName,
        customerListData,
        setCustomerListData,
        progressiveFilter,
        setProgressiveFilter,
        customerModalOpen,
        setCustomerModalOpen,
        impersonate,
        setImpersonate,
        setImpersonateStorage,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  return context;
};
