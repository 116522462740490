import React, { useState, memo } from "react";
import { View, Dimensions } from "react-native";
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";
import { RFValue } from "react-native-responsive-fontsize";
import { PaginationType } from "../types/PaginationType";

const { width } = Dimensions.get("window");

export const Pagination = memo(
  ({
    pageCount,
    stylePaginationButton,
    stylePaginationButtonActive,
    setOffset,
    total,
    isDefault = true
  }: {
    pageCount: number;
    stylePaginationButton: any;
    stylePaginationButtonActive: any;
    itemList: any;
    setOffset: any;
    total: number;
    isDefault?: boolean;
  }) => {
    const [paginationList, setPaginationList] = useState<PaginationType[]>([]);
    const [paginationFocus, setPaginationFocus] = useState(0);

    let objectPagination: PaginationType[] = [];

    if (total > 0) {
      const paginationCount = Math.ceil(total / pageCount);
      const arrayPagination = Array.from(Array(paginationCount).keys());

      objectPagination = arrayPagination.map((item) => {
        return {
          index: item,
          focus: item === 0,
        };
      });
    }

    if (
      paginationList.length === 0 ||
      paginationList.length !== objectPagination.length
    ) {
      setPaginationFocus(0);
      setPaginationList(objectPagination);
    }

    function handlePressPagination(item: PaginationType) {
      const oldPaginationList = paginationList;
      oldPaginationList[paginationFocus].focus = false;
      oldPaginationList[item.index].focus = true;

      setPaginationFocus(item.index);
      setPaginationList(oldPaginationList);

      const offset = isDefault ? (item.index * pageCount) : item.index;
      setOffset(offset);
    }

    const FlatListItemSeparator = () => {
      return (
        <View
          style={{
            height: 1,
            width: width * 0.007, // 10,
            backgroundColor: "rgba(229, 229, 229, 0.1)",
          }}
        />
      );
    };

    const HeaderComponent = () => {
      return (
        <IconButton
          icon={"arrow-left"}
          color="#74BF48"
          size={RFValue(9)}
          onPress={before}
          rippleColor="rgba(229, 229, 229, 0.5)"
          disabled={paginationFocus === 0}
        />
      );
    };

    const before = () => {
      if (paginationFocus !== 0) {
        const backFocus = {
          index: paginationFocus - 1,
          focus: true,
        };

        handlePressPagination(backFocus);
      }
    };

    const FooterComponent = () => {
      return (
        <IconButton
          icon={"arrow-right"}
          color="#74BF48"
          size={RFValue(9)}
          onPress={next}
          rippleColor="rgba(229, 229, 229, 0.5)"
          disabled={paginationFocus === paginationList.length - 1}
        />
      );
    };

    const next = () => {
      if (paginationFocus !== paginationList.length - 1) {
        const nextFocus = {
          index: paginationFocus + 1,
          focus: true,
        };

        handlePressPagination(nextFocus);
      }
    };

    return (
      <FlatList
        contentContainerStyle={{
          alignItems: "center",
          marginTop: paginationList.length === 1 ? 10 : 0,
        }}
        data={paginationList}
        horizontal={true}
        keyExtractor={(item) => `key-${item.index}`}
        ItemSeparatorComponent={FlatListItemSeparator}
        ListFooterComponent={FooterComponent}
        ListHeaderComponent={HeaderComponent}
        renderItem={({ item }) => (
          <TouchableOpacity
            key={item.index}
            onPress={() => handlePressPagination(item)}
          >
            <View
              style={[
                stylePaginationButton,
                item.focus ? stylePaginationButtonActive : {},
              ]}
            />
          </TouchableOpacity>
        )}
      />
    );
  }
);
