
import React, { useEffect } from "react";
import { View, ScrollView } from "native-base";

export const PrivacyPolicy = () => {
  useEffect(() => {
    OneTrust.NoticeApi
      .Initialized
      .then(function () {
        OneTrust
          .NoticeApi
          .LoadNotices(['https://privacyportal-cdn.onetrust.com/4200e0f4-9141-46dc-863a-4c957253a99a/privacy-notices/13f5fef0-59ad-4ddb-9a7c-11932a53fed2.json']);
      });
  }, [])

  return (
    <ScrollView flex={1}>
      <View id="otnotice-13f5fef0-59ad-4ddb-9a7c-11932a53fed2"
        nativeID="otnotice-13f5fef0-59ad-4ddb-9a7c-11932a53fed2"
      />
    </ScrollView>
  );
};
