import React from "react";
import { Spinner, View, ISpinnerProps, Center } from "native-base";

type LoaderProps = {
  visible?: boolean
  _spinner?: ISpinnerProps
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const { visible = true, _spinner } = props

  if (!visible) {
    return <></>
  }

  return (
    <>
      <Center flex={1}
      >
        <Spinner
          size="lg"
          color={'primary.600'}
          {..._spinner} />
      </Center>
      <View
        zIndex={2}
        flex={1}
        left={0}
        top={0}
        right={0}
        bottom={0}
        opacity={0.3}
        bgColor={'muted.200'}
        justifyContent={'center'}
        justifyItems={'center'}
        position={'absolute'}
      />
    </>
  );
};
