import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Actionsheet, CloseIcon, Factory, HStack, Icon, Text, VStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

import { useAuth } from '@contexts/Auth';
import {
  useManageCustomersResponsiveOverlayError
} from '@hooks/useManageCustomersResponsiveOverlayError';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Mobile() {
  const navigation = useNavigation();
  const { isOpen, onClose } = useManageCustomersResponsiveOverlayError();
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();

  function handleUpdateOrder() {
    onClose();
    navigation.navigate('SummaryOrder', { industry: industry });
  }

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} hideDragIndicator>
      <Actionsheet.Content
        p={4}
        borderTopRadius='xl'
        _dark={{
          bg: 'muted.800'
        }}
      >
        <TouchableOpacityFactory alignSelf='end' onPress={onClose}>
          <CloseIcon size={4} color='primary.600' />
        </TouchableOpacityFactory>

        <HStack
          w='100%'
          alignItems='center'
          pb={4}
          borderBottomWidth={1}
          borderBottomColor='muted.300'
        >
          <Icon
            as={AntDesign}
            name='warning'
            color='red.500'
            size={6}
            mr={4}
          />

          <Text fontSize='sm' fontFamily='subheading' color='red.500'>
            Remova o(s) cupom(ns)
          </Text>
        </HStack>

        <VStack mt={4}>
          <Text fontSize='sm' color='muted.800' mb={12}>
            Para prosseguir com a operação de gerenciamento{"\n"}
            de clientes, é necessário remover o(s) cupom(ns){"\n"}
            aplicados no pedido.
          </Text>

          <TouchableOpacityFactory
            testID='manage-customers-error-mobile_update-order'
            bg='primary.600'
            rounded='sm'
            py={4}
            px={8}
            mb={3}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.8,
            }}
            onPress={handleUpdateOrder}
          >
            <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
              Alterar o pedido
            </Text>
          </TouchableOpacityFactory>

          <TouchableOpacityFactory
            testID='manage-customers-error-mobile_cancel'
            rounded='sm'
            py={4}
            px={8}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.7,
            }}
            onPress={onClose}
          >
            <Text fontSize='sm' fontFamily='subheading' color='primary.600'>
              Cancelar
            </Text>
          </TouchableOpacityFactory>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
}