import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View, Text } from 'native-base';
import { width } from "../components/Dimension";
import { CheckBox } from "react-native-elements";
import Ionicons from "react-native-vector-icons/Ionicons";
import { RFValue } from "react-native-responsive-fontsize";
import { OrderType } from "../types/OrderType";
import { OrderStatus } from "../components/OrderStatus";
import { LinkButton } from "../components/LinkButton";



export const HeaderOrderGrid = () => {
  return (
    <View style={styles.containerRow}>
      <View style={styles.containerBox}></View>
      <View style={styles.containerInfo}>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoHeaderText}>ID</Text>
        </View>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoHeaderText}>ID Personalizado</Text>
        </View>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoHeaderText}>Status</Text>
        </View>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoHeaderText}>Data da criação</Text>
        </View>
      </View>
      <View style={styles.containerBoxAction}></View>
    </View>
  );
};

export const OrderGridItem = ({ item }: { item: OrderType }) => {
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const codes = item.customerOrderCode.split('-');
  const defaultCode = codes[0];
  const customCode = codes[1] || '';

  return (
    <View style={styles.containerRow}>
      <View style={styles.containerBox}>
        <CheckBox
          disabled={false}
          checked={toggleCheckBox}
          onPress={() => setToggleCheckBox(!toggleCheckBox)}
          checkedColor="#74BF48"
          size={RFValue(10)}
        />
      </View>
      <View style={styles.containerInfo}>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoRowText}>{defaultCode}</Text>
        </View>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoRowText}>{customCode}</Text>
        </View>
        <View style={styles.containerInfoItem}>
          <OrderStatus status={item.status} />
        </View>
        <View style={styles.containerInfoItem}>
          <Text style={styles.infoRowText}>{item.createdAt}</Text>
        </View>
      </View>
      <View style={styles.containerBoxAction}>
        <LinkButton
          to={{
            screen: "OrderDetail",
            params: { orderId: item.id },
          }}
          style={{}}
        >
          <Ionicons name="search" size={RFValue(10)} color="#686868" />
        </LinkButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    margin: 0,
    padding: 0,
    width: width * 0.852, // 1163
  },

  containerBox: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    flexDirection: "row",
    margin: 0,
    padding: 0,
    width: width * 0.035, // 50,
    justifyContent: "center",
    alignItems: "center",
  },

  containerBoxAction: {
    borderWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    flexDirection: "row",
    margin: 0,
    padding: 0,
    width: width * 0.035, // 50,
    justifyContent: "center",
    alignItems: "center",
  },

  containerInfo: {
    flexDirection: "row",
    padding: width * 0.007, // 10,
    width: width * 0.782, // 1070
  },

  containerInfoItem: {
    width: width * 0.200, // 262,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: width * 0.014, // 10,
  },

  infoHeaderText: {

    fontWeight: "500",
    fontSize: RFValue(9),
    color: "#484848",
  },

  infoRowText: {

    fontWeight: "400",
    fontSize: RFValue(7),
    color: "#484848",
  },
});
