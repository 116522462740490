import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import { ArrowUpIcon, ArrowDownIcon, useMediaQuery } from "native-base";
import { DraxView } from "react-native-drax";

import { WholesalerBranchesType } from "@types/WholesalerBranchesType";
import { PaymentTermType } from "@types/PaymentTermType";
import { CardWholesalerBranch } from "@components/CardWholesalerBranch";

import { styles } from "./styles";
import { cloneDeep } from "@apollo/client/utilities";


export const SelectedDragCard = ({
  card,
  selectedCards,
  setSelectedCards,
  availableCards,
  setAvailableCards,
  setContext,
  showBillingOptions = false,
  editWholesaler,
  isPreferredWholesalerScreen
}: {
  card: WholesalerBranchesType;
  selectedCards: WholesalerBranchesType[];
  setSelectedCards: any;
  availableCards: WholesalerBranchesType[];
  setAvailableCards: React.Dispatch<React.SetStateAction<WholesalerBranchesType[]>>;
  setContext: any;
  showBillingOptions: boolean;
  editWholesaler: () => void,
  isPreferredWholesalerScreen: boolean
}) => {
  const [item, setItem] = useState<WholesalerBranchesType>(card);
  const [draggable, setDraggable] = useState(card.code !== "");
  const [isSmallScreen] = useMediaQuery({ maxWidth: 480 });
  const cardIndex = item.priority - 1;
  setContext(selectedCards);

  function reorderSelectedList(newSelectedCards: WholesalerBranchesType[]) {
    const selectedCards: WholesalerBranchesType[] = [];
    let prioritySelected: number = 1
    let priority: number = 0
    let tempSelectedCards = [];

    for (let newSelectedCard of newSelectedCards) {
      let emptyItem = {
        code: "",
        description: "",
        priority: 0,
        wholesaler: {
          id: 0,
          paymentType: null
        }
      };

      if (newSelectedCard?.code) {
        newSelectedCard.priority = prioritySelected
        selectedCards.push(cloneDeep(newSelectedCard))
        prioritySelected++
      }

      emptyItem.priority = ++priority
      tempSelectedCards.push(emptyItem)
    }

    for (let i = 0; i < selectedCards.length; i++) {
      tempSelectedCards[i] = selectedCards[i]
    }

    return tempSelectedCards
  }

  function removeCard() {
    const emptyItem = {
      code: "",
      description: "",
      priority: item.priority,
      wholesaler: {
        id: 0,
        paymentType: null
      }
    };

    const newSelectedCards = [...selectedCards];
    const newAvailableCards = [...availableCards];

    const index = newSelectedCards.findIndex((newSelectedCard) => {
      return newSelectedCard.code === item.code;
    });
    newSelectedCards[index] = emptyItem;

    const newSelectedCardsReordered = newSelectedCards;
    const newAvailableCardsFiltered = newAvailableCards.filter((availableCard) => {
      return availableCard.code != item.code
    })

    item.priority = 0;
    newAvailableCardsFiltered.push(item);

    handleAvailableCards(newAvailableCardsFiltered);
    setSelectedCards(reorderSelectedList(newSelectedCardsReordered));
    setContext(newSelectedCardsReordered);
    setItem(emptyItem);
    setDraggable(false);
  }

  function selected(paymentSelected: PaymentTermType) {
    const wholesalers = selectedCards;
    const wholesalerIndex = wholesalers.findIndex(
      (w: WholesalerBranchesType) => w.code === item.code
    );

    wholesalers[wholesalerIndex]?.wholesaler?.paymentTerms?.map(
      (payment: PaymentTermType) => {
        payment.selected = payment.code == paymentSelected.code;
      }
    );
    setContext(wholesalers)
  }

  function handleAvailableCards(newAvailableCards: WholesalerBranchesType[]) {
    newAvailableCards.sort((a, b) =>
      a.description > b.description ? 1 : b.description > a.description ? -1 : 0
    );
    setAvailableCards(newAvailableCards);
  }

  function handleReceiveDragDrop(payload: WholesalerBranchesType) {
    const newSelectedCards = [...selectedCards];

    if (payload.priority === 0) {
      payload.priority = item.priority;

      const newAvailableCards = [...availableCards];
      const index = newAvailableCards.findIndex((object) => {
        return object.code === payload.code;
      });

      newAvailableCards.splice(index, 1);

      if (item.description !== "") {
        item.priority = 0;
        newAvailableCards.push(item);
      }

      newSelectedCards[payload.priority - 1] = payload;

      handleAvailableCards(newAvailableCards);
      setItem(payload);
      setSelectedCards(reorderSelectedList(newSelectedCards));
      setContext(newSelectedCards);
      setDraggable(true);

      return;
    }

    const priorityIncoming = payload.priority;
    const priorityCard = item.priority;

    payload.priority = priorityCard;
    item.priority = priorityIncoming;

    newSelectedCards[priorityIncoming - 1] = item;
    newSelectedCards[priorityCard - 1] = payload;

    setItem(payload);
    setSelectedCards(reorderSelectedList(newSelectedCards));
    setDraggable(true);
  }

  function handleDragDrop(payload: WholesalerBranchesType) {
    if (!payload.priority) {
      return;
    }

    if (payload.description === "") {
      setDraggable(false);
    }

    setItem(payload);
  }

  function renderArrow(startIndex: number, endIndex: number, arrowType: "UP" | "DOWN") {
    return (
      <TouchableOpacity
        onPress={() => {
          const newData = selectedCards.slice();

          newData.splice(startIndex, 0, newData.splice(endIndex, 1)[0]);

          setSelectedCards(reorderSelectedList(newData));
        }}
      >
        {arrowType === 'UP' && <ArrowUpIcon size={4} color='primary.600' />}
        {arrowType === 'DOWN' && <ArrowDownIcon size={4} color='primary.600' />}
      </TouchableOpacity>
    );
  }

  return (
    <DraxView
      style={[
        styles.squareShapeView,
        item.description === "" ? {
          alignItems: "center",
          justifyContent: "center"
        } : null
      ]}
      animateSnapback={false}
      draggingStyle={styles.dragging}
      onReceiveDragDrop={({ dragged: { payload } }) =>
        handleReceiveDragDrop(payload)
      }
      onDragDrop={({ receiver: { payload } }) => handleDragDrop(payload)}
      draggable={isSmallScreen ? false : draggable}
      payload={item}
    >
      <CardWholesalerBranch
        card={item}
        removeCard={removeCard}
        selected={selected}
        showBillingOptions={showBillingOptions}
        editWholesaler={editWholesaler}
        isPreferredWholesalerScreen={isPreferredWholesalerScreen}
        handleArrowUp={() => (
          item.priority !== 1 && (
            renderArrow(cardIndex, cardIndex - 1, "UP")
          )
        )}
        handleArrowDown={() => (
          (item.priority !== 6 && selectedCards[cardIndex + 1]?.code !== "") && (
            renderArrow(cardIndex + 1, cardIndex, "DOWN")
          )
        )}
      />
    </DraxView>
  );
};