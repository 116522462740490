import React, { useState } from "react";
import { IInputProps, Icon, Pressable } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

import { Input } from "@components/Input";

export type SearchBarProps = & Pick<IInputProps, 'placeholder' | 'borderRadius' | 'height' | 'borderColor' | 'value' | 'onChangeText' | 'onBlur' | 'id' | 'testID' | 'shadow' | 'size' | 'onKeyPress' | 'variant'> & {
  onPress?: () => void;
}

export function SearchBar(props: SearchBarProps) {
  const [textValue, setTextValue] = useState<string | undefined>('');
  const { size = 'md' } = props;

  if (props.variant == 'outline') {
    props = {
      ...props,
      shadow: 2,
      borderColor: 'muted.700',
      height: 10,
      borderRadius: 8
    }
  }

  const onPress = () => {
    if (textValue !== props.value) {
      setTextValue(props?.value);
      props.onPress?.();
    }
  };

  return (
    <Input
      testID={props.testID}
      height={8}
      bgColor="muted.100"
      shadow={2}
      size={size}
      borderRadius={10}
      placeholder={props.placeholder}
      value={props.value}
      onChangeText={props.onChangeText}
      onKeyPress={(event) => props.onKeyPress?.(event)}
      onBlur={(e) => props.onBlur?.(e)}
      _input={{
        fontSize: ['xs', 'sm']
      }}
      _dark={{
        bgColor: 'muted.800'
      }}
      InputRightElement={
        <Pressable
          disabled={textValue === props.value}
          onPress={onPress}
        >
          <Icon marginX="2" size="md" color="primary.600" as={<MaterialIcons name="search" />} />
        </Pressable>
      }
      {...props}
    />
  )
}