import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  useMediaQuery,
  Button,
  HStack,
  Factory,
  Skeleton,
  Stack,
  VStack,
} from "native-base";
import {
  useRoute,
  RouteProp,
  useNavigation,
  useIsFocused,
} from '@react-navigation/native';
import { TouchableOpacity } from "react-native";
import { useAuth } from "@contexts/Auth";
import { useCustomer as useCustomerContext } from "@contexts/CustomerContext";
import { RedirectHome } from "@helpers/RedirectHome";
import helpers from "@helpers/NumberHelper";
import { GenerateKey } from "@helpers/GenerateKey";
import { useCustomer } from "@hooks/useCustomer";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { ConfirmationOrderModal } from "@components/FinishOrder/ConfirmationOrderModal";
import { ContainerContentChevron } from "@components/ContainerContentChevron";
import { InfoCustomer } from "@components/FinishOrder/InfoCustomer";
import { WholesalerItemOrder } from "@components/FinishOrder/WholesalerItemOrder";
import { InfoOrder } from "@components/FinishOrder/InfoOrder";
import { Separator } from "@components/FinishOrder/Separator";
import { InfoCommercialPolicyProduct } from "@components/FinishOrder/InfoCommercialPolicyProduct";
import { UnexpectedError } from "@components/UnexpectedError";
import { LayoutOrder } from "@components/LayoutOrder";
import Alert, { Props as AlertProps, useRefAlert } from "@components/Alert";
import { RouteParams } from '@types/RouteParams';
import { WholesalerBranchesType } from '@types/WholesalerBranchesType';
import { CustomerType } from '@types/CustomerType';
import { TextHelper } from '@helpers/TextHelper';
import { useWholesalerBranchCart } from '@hooks/useWholesalerBranchCart';
import { AdditionalCustomersType } from '@types/AdditionalCustomersType';
import { ManageCustomersModal } from '@components/ManageCustomersModal';
import { useCustomersModal } from '@hooks/useCustomersModal';
import { useScheduleResponsiveOverlayError } from '@hooks/useScheduleResponsiveOverlayError';
import { ManageCustomersResponsiveOverlayError } from '@components/ManageCustomersResponsiveOverlayError';
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { Config } from '@configs/Config';
import { ValidateCustomersModal } from '@components/ValidateCustomerModal';
import { useValidateCustomersModal } from '@hooks/useValidateCustomersModal';
import { ResultSendOrderResponsiveOverlay } from '@components/ResultSendOrderResponsiveOverlay';
import { useResultSendOrderResponsiveOverlay } from '@hooks/useResultSendOrderResponsiveOverlay';
import { Schedule } from '@components/FinishOrder/Schedule';
import { useScheduleModal } from '@hooks/useScheduleModal';
import { ScheduleResponsiveOverlayError } from '@components/ScheduleResponsiveOverlayError';
import { useManageCustomersResponsiveOverlayError } from '@hooks/useManageCustomersResponsiveOverlayError';
import { useFractionationAlertModal } from "@hooks/useFractionationAlertModal";
import { useTimeBlock } from "@hooks/useTimeBlock";
import { CustomOrderCode } from "@components/FinishOrder/CustomOrderCode";

export const FinishOrder = () => {
  const navigation = useNavigation();
  useTimeBlock();
  const { cart, empty, loading: loadingCart } = useShoppingCart();
  const { user } = useAuth();
  const { customerCode } = useCustomerContext();
  const { wholesalers, loading: loadingWholesalers } =
    useWholesalerBranchCart();
  const isFocused = useIsFocused();
  const redirect = RedirectHome();
  const industryCode = user?.getIndustry() || "";
  const route = useRoute<RouteProp<RouteParams, "Industry">>();
  const industry = route.params.industry;
  const { data, loading, error } = useCustomer(customerCode, industryCode);
  const [swalProps, setSwalProps] = useState({});
  const [isOrderFinish, setIsOrderFinish] = useState(false);
  const [isOrderSent, setIsOrderSent] = useState(false);
  const [isLoaded, setIsLoader] = useState(false);
  const customersModal = useCustomersModal();
  const scheduleModal = useScheduleModal();
  const validateCustomersModal = useValidateCustomersModal();
  const isManagerCustomersAvailable =
    !user.isPointOfSales() && Config.FT_ADDITIONAL_CUSTOMERS;
  const resultSendOrderDialog = useResultSendOrderResponsiveOverlay();
  const fractionationAlert = useFractionationAlertModal();

  const { loading: additionalCustomersLoading, getAdditionalCustomers } =
    useAdditionalCustomers();

  const manageCustomersError = useManageCustomersResponsiveOverlayError();
  const scheduleError = useScheduleResponsiveOverlayError();

  const [onShow] = useState(() => () => {
    navigation.navigate("ListOrder");
  });

  const [additionalCustomers, setAdditionalCustomers] = useState<
    AdditionalCustomersType[]
  >([]);

  const fillAdditionalCustomers = async () => {
    try {
      const value = await getAdditionalCustomers(cart?.id);
      setAdditionalCustomers(value);
    } catch (e) {
      console.log(e);
    }
  };

  function handleManageCustomers() {
    if (!isManagerCustomersAvailable) return;

    if (cart && cart?.coupons?.length === 0) {
      goToManagerCustomers();
      return;
    }

    manageCustomersError.onOpen();
  }

  function onSchedule() {
    if (cart && additionalCustomers.length > 0) {
      scheduleError.onOpen('Não é possível agendar seu pedido, quando há mais de um cliente adicionado.');
      return;
    }

    if (cart && cart?.coupons?.length > 0) {
      scheduleError.onOpen('Não é possível agendar o seu pedido, quando há cupons adicionados.');
      return;
    }

    scheduleModal.onOpen()
  }

  useEffect(() => {
    if (!additionalCustomersLoading) {
      fillAdditionalCustomers();
    }
  }, [isFocused, additionalCustomersLoading]);

  const FactoryOpacity = Factory(TouchableOpacity);

  const [isSmallScreen] = useMediaQuery([{ maxWidth: 640 }]);
  const alertDialog = useRef<useRefAlert>();
  const alertDialogPosSendOrder = useRef<useRefAlert>();

  let totalUnits = 0;
  cart?.items.forEach((item) => {
    totalUnits += item.amount;
  });

  useEffect(() => {
    if (isOrderSent) {
      onShow();
    }

    return () => setIsOrderSent(false);
  }, [isOrderSent, onShow]);

  useEffect(() => {
    if (
      resultSendOrderDialog.isOpen &&
      resultSendOrderDialog.type == "SUCCESS"
    ) {
      setIsOrderFinish(true);
    }
  }, [resultSendOrderDialog.isOpen]);

  useEffect(() => {
    let active = true;

    const fetchData = async () => {
      setTimeout(async () => {
        if (active) {
          if (!loading || !loadingCart) {
            setIsLoader(true);
          }
        }
      }, 1000);
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [loading, loadingCart]);

  const openModal = () => {
    if (isManagerCustomersAvailable && !!additionalCustomers?.length) {
      return goToValidateCustomers();
    }

    alertDialog.current?.show();
  };

  let onNewOrder = () => {
    navigation.navigate("Industry", { industry: industry });
    setIsOrderFinish(false);
  };

  const alertParamsPosSendOrder: AlertProps = {
    type: "SUCCESS",
    headerText: "Pedido realizado!",
    bodyContent: (
      <View>
        <Text
          selectable
          fontSize="sm"
          lineHeight={20}
          fontWeight="500"
          color="muted.800"
          _dark={{
            color: "muted.400",
          }}
        >
          Enviado com sucesso!
        </Text>
        <Text
          selectable
          fontSize="sm"
          lineHeight={16}
          fontWeight="400"
          marginTop={1}
          color="muted.800"
          _dark={{
            color: "muted.400",
          }}
        >
          O que você deseja fazer agora?
        </Text>
      </View>
    ),
    btnTextConfirm: "Novo pedido",
    btnTextCancel: "Detalhes do pedido",
    onConfirmCallback: () => {
      onNewOrder();
    },
    onCloseCallback: () => {
      setIsOrderSent(true);
    },
  };

  const closeModal = async (swal: boolean = false, error: boolean = false) => {
    if (error) {
      setSwalProps({
        show: true,
        text: "Ocorreu um erro ao gerar o pedido. Tente novamente.",
        icon: "error",
        error: true,
      });
      return;
    }

    if (swal) {
      setIsOrderFinish(true);
      alertDialogPosSendOrder.current?.show();
    }
  };

  const handleConfirmSwal = async (prop: any) => {
    setSwalProps({});
  };

  if (!loading && !loadingCart && empty && !isOrderFinish && isFocused) {
    navigation.navigate(redirect.screen, redirect.params);
    return;
  }

  if (!cart?.valid && (cart?.items?.length || 0) > 0) {
    navigation.navigate("SummaryOrder", { industry: industry });
    fractionationAlert.onOpen();
    return;
  }

  if (!loading && (error || data?.customer === null)) {
    return <UnexpectedError consoleError={error?.message} />;
  }

  const customer: CustomerType = data?.customer as CustomerType;

  const goToManagerCustomers = () => {
    if (isSmallScreen) {
      return navigation.navigate("ManageCustomers");
    }
    customersModal.onOpen();
  };

  const goToValidateCustomers = () => {
    if (isSmallScreen) {
      return navigation.navigate("ValidateCustomers");
    }

    validateCustomersModal.onOpen();
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={!isSmallScreen}>
        <LayoutOrder
          title="Finalização do pedido"
          subtitle="Aqui está o resumo dos produtos que estão dentro do seu pedido"
          shopTab={3}
          isMobile={isSmallScreen}
          industryName={industryCode}
        >
          <HStack
            flexDirection={["column", "column", "row"]}
            space={["12", "12", "8", "12"]}
            px={["3", ""]}
          >
            <View w={["full", "full", "65%"]}>
              <View>
                <ContainerContentChevron
                  collapsable
                  title={"Informações do cliente"}
                  justifyContent={"flex-start"}
                  bgColor={"white"}
                  _dark={{
                    bgColor: "muted.800",
                    borderWidth: 0,
                  }}
                  borderColor="coolGray.200"
                  borderRadius={6}
                  borderWidth="1"
                  shadow="2"
                  p="2"
                  mb={3}
                  sideTitle={isManagerCustomersAvailable && (
                    <FactoryOpacity
                      onPress={() => handleManageCustomers()}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      py={2}
                      pr={2}
                    >
                      <Text color={'#69A920'} fontSize={'sm'} fontFamily={'subheading'}>
                        Gerenciar clientes
                      </Text>
                    </FactoryOpacity>
                  )}
                >
                  {isManagerCustomersAvailable && additionalCustomers?.length > 0 && (
                    <Stack flex={1}>
                      <View
                        bgColor={'success.500'}
                        mb={1}
                        borderRadius={16}
                        maxWidth={'56'}
                        alignItems={'center'}
                        height={6}
                        justifyContent={'center'}
                      >
                        <Text
                          px={0.5}
                          p={0.25}
                          fontSize={["xs", "sm"]}
                          color={'white'}
                        >
                          +{additionalCustomers.length} Clientes adicionados
                        </Text>
                      </View>
                      <View
                        bgColor={'muted.200'}
                        mt={2}
                        mb={5}
                        borderRadius={16}
                        width={24}
                        alignItems={'center'}
                        height={6}
                        justifyContent={'center'}
                      >
                        <Text px={0.5} p={0.25} fontSize={["xs", "sm"]}
                          _dark={{
                            color: 'muted.900'
                          }}
                        >
                          1º Cliente:
                        </Text>
                      </View>
                    </Stack>
                  )}
                  <Skeleton.Text lines={1} mb="3" px="1" startColor="muted.400" isLoaded={!loading}>
                    <InfoCustomer label={customer?.name} value={TextHelper.create(customer?.code ?? '').formatCNPJ()} />
                  </Skeleton.Text>

                  <Skeleton.Text lines={1} px="4" startColor="muted.400" isLoaded={!loading}>
                    <InfoCustomer enumeration label="Endereço" value={`${customer?.address.street}, ${customer?.address.cep}`} />
                  </Skeleton.Text>
                </ContainerContentChevron>
              </View>

              <HStack
                space={["12", "12", "8", "12"]}
                flexDirection={["column", "column", "column", "row-reverse"]}
                display={["block", "flex"]}
              >
                <ContainerContentChevron
                  collapsable
                  title={"Distribuidores"}
                  mb={[4, 3]}
                  sideTitle={<Button
                    onPress={() => navigation.navigate('WholesalerOrder', { industry: industry })}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    variant={'unstyled'}
                    p="0"
                  >
                    <Text
                      color={'primary.600'}
                      fontSize={'sm'}
                      fontFamily={'subheading'}
                      _dark={{
                        opacity: 0.8,
                      }}
                    >
                      Alterar a seleção
                    </Text>
                  </Button>}
                >
                  <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded && !loadingWholesalers}>
                    {wholesalers.map((item: WholesalerBranchesType) => <WholesalerItemOrder key={GenerateKey()} item={item} />)}
                  </Skeleton>
                  <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded && !loadingWholesalers} />
                  <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded && !loadingWholesalers} />
                </ContainerContentChevron>

                <ContainerContentChevron
                  collapsable
                  title={"Resumo do pedido"}
                  mb={[4, 3]}
                  sideTitle={<Button
                    onPress={() => navigation.navigate('SummaryOrder', { industry: industry })}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    variant={'unstyled'}
                    p="0"
                  >
                    <Text
                      color={'primary.600'}
                      fontSize={'sm'}
                      fontFamily={'subheading'}
                      _dark={{
                        opacity: 0.8,
                      }}
                    >
                      Alterar pedido
                    </Text>
                  </Button>}
                >
                  <View
                    bgColor={"white"}
                    _dark={{
                      bgColor: "muted.800",
                      borderWidth: 0,
                    }}
                    borderColor="coolGray.200"
                    borderWidth="1"
                    borderRadius={6}
                    shadow="2"
                    p={2}
                  >
                    <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded}>
                      {cart?.items.map((item) => <InfoCommercialPolicyProduct key={GenerateKey()} item={item} mb={4} />)}
                    </Skeleton>
                    <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded} />
                    <Skeleton px="4" mb="4" rounded="md" startColor="muted.400" isLoaded={isLoaded} />
                  </View>
                </ContainerContentChevron>
              </HStack>
            </View>

            <ContainerContentChevron
              w={["", "full", "35%"]}
              title={"Informações do pedido"}
            >

              <CustomOrderCode/>

              <InfoOrder
                isLoaded={isLoaded}
                label={"Código do pedido"}
                value={cart?.code ?? ""}
                mb={4}
              />

              <InfoOrder
                isLoaded={isLoaded}
                label={"Total de itens"}
                value={cart?.items?.length.toString() ?? "0"}
                mb={4}
              />

              <InfoOrder
                isLoaded={isLoaded}
                label={"Total de unidades"}
                value={totalUnits.toString()}
              />

              <Separator />

              <InfoOrder
                isLoaded={isLoaded}
                label={"Valor bruto"}
                value={"R$ " + helpers.currency(cart?.grossPrice ?? 0, 2)}
                mb={4}
              />

              <InfoOrder
                isLoaded={isLoaded}
                label={"Desconto total"}
                color="orange.500"
                value={"-R$ " + helpers.currency(cart?.discountValue ?? 0, 2)}
              />

              <Separator />

              <InfoOrder
                isLoaded={isLoaded}
                label={"Valor final"}
                value={"R$ " + helpers.currency(cart?.netPrice ?? 0, 2)}
              />

              <Separator mb={"16"} />

              {!isSmallScreen && <>
                <Skeleton px="4" mb="4" rounded="sm" startColor="primary.400" isLoaded={isLoaded}>
                  <Button
                    testID='finalizar-pedido:enviar-pedido'
                    _text={{
                      fontWeight: '700',
                      fontSize: ['md', 'lg']
                    }}
                    mb={3}
                    rounded='sm'
                    onPress={() => openModal()}
                  >
                    Enviar o pedido
                  </Button>
                </Skeleton>
                <Skeleton px="4" mb="4" rounded="sm" startColor="muted.300" isLoaded={isLoaded}>
                  <Button
                    variant={'ghost'}
                    testID='finalizar-pedido:alterar-distribuidores'
                    w='100%'
                    p={2}
                    mb={3}
                    onPress={onSchedule}
                    alignItems='center'
                    justifyContent='center'
                    colorScheme={'primary'}
                    _text={{
                      color: 'primary.600',
                      fontWeight: '700',
                      fontSize: ['md', 'lg']
                    }}
                    _dark={{
                      opacity: 0.7,
                      _pressed: {
                        bgColor: "muted.700"
                      }
                    }}
                  >
                    Agendar envio
                  </Button>
                </Skeleton>
              </>
              }
            </ContainerContentChevron>
          </HStack>

          <ManageCustomersResponsiveOverlayError
            type={isSmallScreen ? "MOBILE" : "WEB"}
          />
          <ScheduleResponsiveOverlayError
            type={isSmallScreen ? 'MOBILE' : 'WEB'}
          />
          <ConfirmationOrderModal onClose={closeModal} alertDialog={alertDialog} />
          <Alert ref={alertDialogPosSendOrder} {...alertParamsPosSendOrder} />
        </LayoutOrder>

        {customersModal.isOpen &&
          isManagerCustomersAvailable &&
          !isSmallScreen && (
            <ManageCustomersModal
              handleComplete={fillAdditionalCustomers}
              cartId={cart?.id || ""}
            />
          )}

        {isManagerCustomersAvailable && !isSmallScreen && (
          <ValidateCustomersModal />
        )}

        <ResultSendOrderResponsiveOverlay type={isSmallScreen ? 'MOBILE' : 'WEB'} />
        <Schedule type={isSmallScreen ? 'MOBILE' : 'WEB'} />

      </ScrollView>
      {isSmallScreen && <VStack
        backgroundColor={'white'}
        alignItems={'center'}
        borderTopRadius={10}
        borderLeftColor={'white'}
        borderRightColor={'white'}
        borderBottomColor={'white'}
        borderTopColor={'gray.200'}
        borderWidth={1}
        p={5}
        h={'48'}
        _dark={{
          bgColor: 'muted.800',
          borderWidth: 0,
        }}
      >
        <View flex={1} w={'100%'} mb={3}>
          <InfoOrder
            isLoaded={isLoaded}
            label={"Valor final"}
            value={"R$ " + helpers.currency(cart?.netPrice ?? 0, 2)}
          />
        </View>
        <Skeleton px="4" mb="4" rounded="sm" startColor="primary.400" isLoaded={isLoaded}>
          <Button
            width={80}
            height={12}
            testID='finalizar-pedido:enviar-pedido'
            _text={{
              fontWeight: '700',
              fontSize: ['md', 'lg']
            }}
            _hover={{
              bg: 'primary.500',
            }}
            _pressed={{
              bg: 'primary.400',
            }}
            _dark={{
              opacity: 0.8,
            }}
            bg='primary.600'
            mb={3}
            rounded='sm'
            onPress={() => openModal()}
          >
            Enviar o pedido
          </Button>
        </Skeleton>
        <Skeleton px="4" mb="4" rounded="sm" startColor="muted.300" isLoaded={isLoaded}>
          <Button
            variant={'unstyled'}
            testID='finalizar-pedido:alterar-distribuidores'
            w='100%'
            p={2}
            mb={3}
            onPress={onSchedule}
            alignItems='center'
            justifyContent='center'
            _hover={{
              opacity: 0.8,
            }}
            _pressed={{
              bgColor: "muted.300",
            }}
            _text={{
              color: 'primary.600',
              fontWeight: '700',
              fontSize: ['md', 'lg']
            }}
            _dark={{
              opacity: 0.7,
              _pressed: {
                bgColor: "muted.700"
              }
            }}
          >
            Agendar envio
          </Button>
        </Skeleton>
      </VStack>}
    </>
  );
};
