import React from 'react';
import { VStack, Text, IconButton, Alert, HStack, CloseIcon, ITextProps, IAlertProps, Button } from 'native-base';

export type Props = IAlertProps & {
  id: string | number,
  status?: 'info' | 'warning' | 'error' | 'success',
  variant?: 'solid' | 'lightText' | 'subtle' | 'top-accent' | 'outline',
  title?: string,
  description?: string,
  isClosable?: boolean,
  toast: any,
  _title: ITextProps,
  onPress?: () => void
}

export const ToastAlert = ({
  id,
  status = 'info',
  variant = 'solid',
  title,
  description,
  isClosable,
  toast,
  _title,
  onPress,
  ...rest
}: Props) => {

  function onAlertPress() {
    toast.close(id);
    onPress && onPress();
  }

  return (
    <Button 
      onPress={onAlertPress}
      variant='unstyled'
    >
      <Alert
        maxWidth='100%'
        alignSelf='center'
        flexDirection='row'
        status={status ? status : 'info'}
        variant={variant}
        {...rest}
      >
        <VStack space={1} flexShrink={1} w='100%'>
          <HStack flexShrink={1} alignItems='center' justifyContent='space-between'>
            <HStack space={2} flexShrink={1} alignItems='center'>
              <Alert.Icon />
              <Text fontSize='md'
                fontWeight='medium'
                flexShrink={1}
                color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}
                {..._title}
              >
                {title}
              </Text>
            </HStack>
            {isClosable ? <IconButton variant='unstyled' icon={<CloseIcon size='3' />} _icon={{
              color: variant === 'solid' ? 'lightText' : 'darkText'
            }} onPress={() => toast.close(id)} /> : null}
          </HStack>
          <Text px='6' color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}>
            {description}
          </Text>
        </VStack>
      </Alert>
    </Button>
  )
}



