import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { configurationsVar, useConfigurationsLazy } from '@hooks/useConfigurations';
import { useAuth } from "@contexts/Auth";
import { useReactiveVar } from "@apollo/client";
import { ConfigurationEnum } from "@enums/ConfigurationEnum";
import { settingsByUser, useSettingsByUser } from "./useSettingsByUser";

function isSpecificTime() {
  const now = new Date();
  const hora = now.getHours();
  const minutes = now.getMinutes();

  const validTimes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
  return validTimes.includes(hora) && minutes === 0;
}

type useTimeBlockProps = {
  block: boolean,
  time?: {
    start?: string,
    end: string
  },
  week: {
    start: string,
    end: string,
  }
}

export const useTimeBlock = (): useTimeBlockProps => {
  const [block, setBlock] = useState(false);
  const navigation = useNavigation();
  const { user, signIn, signed } = useAuth()
  const [query] = useConfigurationsLazy({ industry: user.getNameIndustryStore(), skip: false })

  const validate = async () => {
    const { data } = await query()
    const dateRestriction = data?.CMS_configurations?.dateRestriction ?? false;
    setBlock(dateRestriction)
  }

  useSettingsByUser(
    user.getNameIndustryStore(),
    false
  )

  const configurations = useReactiveVar(configurationsVar);
  const settingsUser = useReactiveVar(settingsByUser);

  const TIME_AVAILABILITY = configurations
    ?.find(config => config.name == ConfigurationEnum.HORARIO_DISPONIBILIDADE)
    ?.value ?? '';
  const [horaries, weeks = ''] = TIME_AVAILABILITY.split(',')
  const [start, end] = horaries.split('-')
  const [startWeek, endWeek] = weeks.split('-')

  useEffect(() => {

    const intervalId = setInterval(() => {
      if (isSpecificTime()) {
        validate()
      }
    }, 2000);

    if (!settingsUser.useHoursRestriction) {
      return () => {
        clearInterval(intervalId);
      };
    }
    validate()

    return () => {
      clearInterval(intervalId);
    };
  }, [user.getNameIndustryStore(), signIn, signed, settingsUser.useHoursRestriction]);

  useEffect(() => {
    if (block) {
      navigation.navigate('TimeBlock')
    }
  }, [block, navigation]);

  return {
    block,
    time: {
      start,
      end
    },
    week: {
      start: startWeek,
      end: endWeek,
    }
  }
}