import React from "react";
import { TouchableOpacity } from "react-native";
import { CheckCircleIcon, CloseIcon, Factory, Modal, VStack, Text, WarningIcon } from "native-base"
import { useNavigation } from "@react-navigation/native";

import { useAuth } from "@contexts/Auth";
import { useResultSendOrderResponsiveOverlay } from "@hooks/useResultSendOrderResponsiveOverlay";

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Web() {
  const { isOpen, onClose, type } = useResultSendOrderResponsiveOverlay();
  const navigation = useNavigation();

  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();

  function goToHome() {
    onClose();
    navigation.navigate('Industry', { industry });
  }

  return (
    <Modal
      isOpen={isOpen}
      size={'xl'}
      h={'100%'}
      overflow={"hidden"}
    >
      <Modal.Content maxWidth={'504px'} maxHeight={'362px'}>
        <Modal.Body>
          <VStack w='100%' alignItems='center'>
            <TouchableOpacityFactory alignSelf='end' onPress={goToHome}>
              <CloseIcon size={3} color='primary.600' />
            </TouchableOpacityFactory>

            {type === 'SUCCESS' ? (
              <CheckCircleIcon size={10} color={'success.500'} />
            ) : (
              <WarningIcon size={10} color='red.500' />
            )}

            <Text
              flex={1}
              fontSize='sm'
              fontFamily='subheading'
              color={type === 'SUCCESS' ? 'success.700' : 'red.500'}
              mt={2}
            >
              {type === 'SUCCESS' ? "Pedido enviado com sucesso" : "Erro ao enviar o seu pedido"}
            </Text>

            <Text
              px={4}
              py={8}
              flex={1}
              fontSize='xs'
              fontFamily='body'
              color='muted.800'
              textAlign={'center'}
              mt={2}
            >
              {type === 'SUCCESS'
                ? "Verificar os detalhes dos seus pedidos ou fazer um novo pedido?"
                : 'Não se preocupe, seu carrinho não foi perdido.\n'
                + 'Clique novamente em "Enviar Pedido" na tela de Finalização; Caso o\n'
                + 'problema persista, entre em contato com a Funcional.'
              }
            </Text>
          </VStack>

          <VStack w='full'>
            {type === 'SUCCESS' ? (
              <>
                <TouchableOpacityFactory
                  testID='result-send-order-overlay-web_send'
                  bg='primary.600'
                  rounded='sm'
                  py={4}
                  px={8}
                  mb={3}
                  alignItems='center'
                  justifyContent='center'
                  _dark={{
                    opacity: 0.8,
                  }}
                  onPress={goToHome}
                >
                  <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
                    Novo pedido
                  </Text>
                </TouchableOpacityFactory>

                <TouchableOpacityFactory
                  testID='result-send-order-overlay-web_cancel'
                  rounded='sm'
                  py={4}
                  px={8}
                  alignItems='center'
                  justifyContent='center'
                  _dark={{
                    opacity: 0.7,
                  }}
                  onPress={() => { onClose(), navigation.navigate('ListOrder') }}
                >
                  <Text fontSize='sm' fontFamily='subheading' color='primary.600'>
                    Detalhes do pedido
                  </Text>
                </TouchableOpacityFactory>
              </>
            ) : (
              <TouchableOpacityFactory
                testID='result-send-order-overlay-web_error'
                bg='primary.600'
                rounded='sm'
                py={4}
                px={8}
                mb={3}
                alignItems='center'
                justifyContent='center'
                _dark={{
                  opacity: 0.8,
                }}
                onPress={onClose}
              >
                <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
                  Voltar
                </Text>
              </TouchableOpacityFactory>
            )}
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
