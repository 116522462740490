import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { View, Text, Modal, Factory, VStack, HStack, Stack } from "native-base";
import { useCustomersModal } from "@hooks/useCustomersModal";
import { CustomerByUserTypeEdited, customersList } from "@hooks/useCustomersById";
import { useReactiveVar } from "@apollo/client";
import { SearchBar } from "@components/SearchBar";
import { ManagerCustomersList } from "@components/ManagerCustomersList";
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { AdditionalCustomersType } from "@types/AdditionalCustomersType";

export const ManageCustomersModal = ({ handleComplete, cartId }: { handleComplete: () => {}, cartId: string }) => {
  const FactoryOpacity = Factory(TouchableOpacity);
  const customersModal = useCustomersModal();
  const [searchText, setSearchText] = useState("");
  const customers: CustomerByUserTypeEdited[] = useReactiveVar(customersList);
  const {
    getAdditionalCustomers: getSelectedCustomers,
    includeAdditionalCustomers,
    removeAdditionalCustomers
  } = useAdditionalCustomers();
  const [refreshData, setRefreshData] = useState(false);

  const getAdditionalCustomers = () => {
    const data = customers
      .filter(
        (item) => {
          return item.isChecked == true
        }
      ).map(
        (item: CustomerByUserTypeEdited) => {
          return { name: item.businessName || '', code: item.code || '', status: undefined };
        });

    return data;
  }

  const getAdditionalCustomersToRemove = async () => {
    const selectedCustomers: AdditionalCustomersType[] = await getSelectedCustomers(cartId);
    const data: AdditionalCustomersType[] =
      customers.filter(
        item => selectedCustomers?.map(item => item.code).includes(item.code) && !item.isChecked
      ).map(item => { return { name: item.businessName || '', code: item.code || '', status: undefined } });

    return data;
  }

  const handlePressComplete = async () => {
    const dataToAdd = getAdditionalCustomers();
    const dataToRemove = await getAdditionalCustomersToRemove();

    await includeAdditionalCustomers(cartId || '', dataToAdd);
    await removeAdditionalCustomers(cartId || '', dataToRemove);

    handleComplete();

    customersModal.onClose();
  }

  useEffect(() => {
    setRefreshData(customersModal.isOpen);
  }, [customersModal.isOpen]);


  return (
    <Modal
      isOpen={customersModal.isOpen}
      size={'xl'}
      overflow={'hidden'}
      height={'100%'}
      onClose={customersModal.onClose}
    >
      <Modal.Content
        maxWidth={'836px'}
        overflow={'hidden'}
      >
        <Modal.CloseButton
          testID="ManageCustomersModal-Touchable-CloseModal"
          onPress={customersModal.onClose}
        />
        <Modal.Header
          color={'muted.800'}
          w={'full'}
          fontSize={'lg'}
          fontFamily={'subheading'}
        >
          Gerenciar Clientes
        </Modal.Header>
        <Modal.Body
          overflow={'hidden'}
          p='2'
          height={'96'}
        >
          <HStack
            space={4}
            px={["3", "3", 0]}
            direction={["column", "column", "column", "row"]}
            justifyContent={["center", "center", "space-between"]}
            alignItems={["center", "baseline"]}
            bgColor={["white", ""]}
            _dark={{
              bgColor: ["muted.800", ""],
            }}
            borderBottomWidth={[2, 0]}
            borderColor="coolGray.200"
            borderWidth="0"
          >
            <View width={'full'} pt={4}>
              <SearchBar
                variant={'outline'}
                size={['md', 'xs']}
                placeholder={"Pesquise pelo nome do cliente ou CNPJ"}
                value={searchText}
                onChangeText={(e: string) => setSearchText(e)}
              />
            </View>
          </HStack>
          <VStack pt={1} flex={1} maxH={"56"}>
            <ManagerCustomersList searchText={searchText} isMobile={false} refreshData={refreshData} cartId={cartId} />
          </VStack>

          <Stack
            backgroundColor={'white'}
            _dark={{
              bgColor: 'muted.800'
            }}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            p={3}
            w={'full'}
            h={"24"}
            direction={'row'}
          >
            <FactoryOpacity
              onPress={() => customersModal.onClose()}
              justifyContent={"center"}
              alignItems={'center'}
              py={2}
              pr={2}
              borderRadius={4}
              width={48}
              height={12}
              testID="ManageCustomersModal-Touchable-CancelButton"
            >
              <Text fontSize={'sm'} fontWeight={'bold'} color={'primary.600'}>
                Cancelar
              </Text>
            </FactoryOpacity>
            <FactoryOpacity
              onPress={() => handlePressComplete()}
              justifyContent={"center"}
              alignItems={'center'}
              py={2}
              pr={2}
              backgroundColor={'primary.600'}
              borderRadius={4}
              width={48}
              height={12}
              testID="ManageCustomersModal-Touchable-Conclude"
            >
              <Text fontSize={'sm'} color={'white'}>
                Concluir
              </Text>
            </FactoryOpacity>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal >
  );
};
