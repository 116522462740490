import React, { useState } from "react";
import {
  FlatList,
  Tooltip,
  useMediaQuery,
  View,
  Text,
  Button,
  AlertDialog,
  Box,
  Pressable
} from "native-base";

import { Card } from "@components/Card";
import { CustomerCard } from "@components/CustomerCard";
import { height } from "@components/Dimension";
import { Loading } from "@components/Loading";

import { useCustomer } from "@contexts/CustomerContext";
import { TextHelper } from "@helpers/TextHelper";
import { CustomerByUserType } from "@types/CustomerByUserType";
import { filtersVar } from "@hooks/useHasCommercialPolicy";
import { useReactiveVar } from "@apollo/client";

interface Props {
  customers: CustomerByUserType[];
  loadCustomers(): Promise<void>;
  loading: boolean;
  style?: any;
  listeners?: EventListener[];
  onPressEvent: any;
}

export const CustomerList = ({
  customers,
  loadCustomers,
  loading,
  style,
  onPressEvent
}: Props) => {
  const [modalCustomer, setModalCustomer] = useState<CustomerByUserType | null>(
    null
  );

  const { setCardSelected, customerModalOpen, setCustomerModalOpen, setImpersonateStorage } = useCustomer();
  const flatListRef = React.useRef();
  const cancelRef = React.useRef(null);

  const [isMediumScreen, isSmallScreen] = useMediaQuery([
    {
      maxWidth: 960,
    },
    {
      maxWidth: 640,
    }
  ]);

  const numColumns = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;
  const cardWidth = isSmallScreen ? '100%' : isMediumScreen ? '50%' : "33%";

  return (
    <>
      <FlatList
        showsVerticalScrollIndicator={true}
        height={height * 0.7}
        style={style}
        data={customers}
        key={numColumns}
        numColumns={numColumns}
        ref={flatListRef}
        onEndReached={async ({ distanceFromEnd }) => {
          if (distanceFromEnd == 0) {
            return;
          }
          await loadCustomers();
        }}
        onEndReachedThreshold={0.1}
        ListFooterComponent={() => (loading ? <Loading /> : null)}
        ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
        contentContainerStyle={[
          { paddingBottom: 100 },
          customers.length === 0 && { flex: 1 },
        ]}
        renderItem={({ item, index }) => (
          <View
            flex={1}
            maxW={cardWidth}
          >
            <Tooltip
              _dark={{
                bgColor: "muted.400",
                _text: {
                  color: "muted.800"
                }
              }}
              _text={{
                color: "muted.800"
              }}
              bgColor="white"
              key={item.code}
              label={item.companyName}
              openDelay={500}
              closeDelay={400}
              placement={"top left"}
              width={[328, 360, 280]}
            >
              <Pressable
                onPress={() => {
                  setModalCustomer(item);
                  setCardSelected(item.code);
                  filtersVar({
                    ...filtersVar(),
                    customer: { code: item.code }
                  })
                  setCustomerModalOpen(true);
                }}
                key={item.code}
                style={
                  !isSmallScreen && {
                    flex: 1,
                    marginRight: !isMediumScreen ? 20 : index % 2 !== 0 ? 0 : 20
                  }
                }
              >
                <Card.Container testID={item.code}>
                  <Card.Body style={{ flex: 1 }}>
                    <Card.Text
                      description={
                        item.businessName ? item.businessName : item.companyName
                      }
                      color="muted.800"
                      fontSize="sm"
                      fontWeight="700"
                    />

                    <Card.Text
                      description={new TextHelper(item.code).formatCNPJ()}
                      color="gray.600"
                      fontSize="sm"
                      fontWeight="400"
                    />
                  </Card.Body>
                </Card.Container>
              </Pressable>
            </Tooltip>
          </View>
        )}
      />

      <AlertDialog
        key={modalCustomer?.code}
        leastDestructiveRef={cancelRef}
        isOpen={customerModalOpen}
        closeOnOverlayClick={true}
        onClose={() => {
          setCustomerModalOpen(false);
        }}
      >
        <AlertDialog.Content
          borderWidth={1}
          borderColor="gray.300"
          _dark={{
            borderColor: "muted.700"
          }}
        >
          <Box>
            <AlertDialog.CloseButton _icon={{ color: "primary.600" }} />

            <AlertDialog.Header
              pr={10}
              _text={{
                color: 'muted.800'
              }}
              _dark={{
                _text: {
                  color: 'muted.400'
                }
              }}
            >
              {
                modalCustomer?.businessName ?
                  modalCustomer?.businessName :
                  modalCustomer?.companyName
              }
            </AlertDialog.Header>
          </Box>

          <AlertDialog.Body>
            <>
              <CustomerCard.Body customer={modalCustomer} />
              <CustomerCard.Item label="Endereço">
                <Text
                  fontSize="xs"
                  marginTop={0}
                  fontWeight="medium"
                  selectable
                  color='muted.800'
                  _dark={{
                    color: 'muted.400'
                  }}
                >
                  {modalCustomer?.address?.street}{" "}
                  {modalCustomer?.address?.neighborhood}
                  {", "}
                  {modalCustomer?.address?.city?.name}
                  {"-"}
                  {modalCustomer?.address?.city?.state?.abbreviation}
                  {", "}
                  {new TextHelper(modalCustomer?.address?.zipCode || '').formatZipCode()}
                </Text>
              </CustomerCard.Item>
            </>
          </AlertDialog.Body>

          <AlertDialog.Footer>
            <Button.Group flex={1} flexDir='column' >
              <Button
                _text={{
                  fontWeight: "700",
                  fontSize: "sm"
                }}
                _hover={{
                  opacity: 0.9,
                }}
                _pressed={{
                  bgColor: "primary.700",
                }}
                _dark={{
                  opacity: 0.8,
                }}
                key={modalCustomer?.code}
                bgColor="primary.600"
                onPress={() => {
                  onPressEvent();
                  setCustomerModalOpen(false);
                }}
              >
                Selecionar cliente
              </Button>
              
              <Button
                _text={{
                  fontWeight: "400",
                  fontSize: "sm",
                }}
                _hover={{
                  opacity: 0.9,
                }}
                _dark={{
                  opacity: 0.8,
                }}
                key={modalCustomer?.code}
                variant={"outline"}
                mt={2}
                onPress={() => {
                  onPressEvent();
                  setImpersonateStorage(true);
                  setCustomerModalOpen(false);
                }}
              >
                Comprar como cliente
              </Button>

              <Button
                _text={{
                  fontWeight: "700",
                  fontSize: "sm",
                  color: "primary.600"
                }}
                _hover={{
                  opacity: 0.8,
                }}
                _pressed={{
                  bgColor: "muted.300",
                }}
                _dark={{
                  opacity: 0.7,
                  _pressed: {
                    bgColor: "muted.700"
                  }
                }}
                key={modalCustomer?.code}
                mt={2}
                bgColor="transparent"
                onPress={() => setCustomerModalOpen(false)}
              >
                Continuar selecionando
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
};
