import React, { useState } from "react";
import { ComboCard } from "./ComboCard";

import { useAllCommercialPoliciesRules } from "@hooks/useAllComercialPoliciesRules";
import { Loading } from "@components/Loading";
import { List } from "@components/List";
import { PolicyRuleEnum } from "@enums/PolicyRuleEnum";
import { View, useMediaQuery } from "native-base";
import { useAuth } from "@contexts/Auth";
import { CommercialPolicyComboType } from "@types/CommercialPolicyComboType";


export const ComboTab = () => {
  const [comboSearch, setComboSearch] = useState<CommercialPolicyComboType[]>([]);
  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 900,
    },
  ]);
  const numColumns = isSmallScreen ? 2 : 3
  const { user } = useAuth();

  const { data, loading, error } = useAllCommercialPoliciesRules({
    prevailing: true,
    industry: {
      isEnabled: true, industryCode: user.getIndustry(),
    },
    aggregator: false,
    policyRules: {
      policyRule: [
        PolicyRuleEnum.MINIMUM_AMOUNT_BY_BRAND,
        PolicyRuleEnum.REQUIRED_BRAND,
        PolicyRuleEnum.REQUIRED_PRODUCT,
        PolicyRuleEnum.NOT_CONSIDERED_FOR_MINIMUM,
        PolicyRuleEnum.REQUIRED_BASKET,
        PolicyRuleEnum.MINIMUM_AMOUNT_BY_TABLE,
      ],
      policyRuleNot: [PolicyRuleEnum.PROGRESSIVE_BY_BRAND],
    }
  });

  const minTextSearch = 0;

  if (loading) {
    return <Loading />;
  }

  const commercialPolicyData = data.allCommercialPolicies;

  const search = async (text: string) => {
    const result = commercialPolicyData.filter(
      (combo: any) => combo.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
    );

    return text.length > minTextSearch
      ? setComboSearch(result)
      : setComboSearch(commercialPolicyData);
  };

  return (
    <List
      data={comboSearch}
      loading={loading}
      error={error}
      numColumns={numColumns}
      total={comboSearch.length}
      showPagination={!isSmallScreen}
      containerTitle={isSmallScreen ? '' : "Combos disponíveis"}
      columnWrapperStyle={{
        justifyContent: isSmallScreen
          ? "space-between"
          : "flex-start",
        marginBottom: 20
      }}
      contentContainerStyle={{
        justifyContent: "space-around",
      }}
      _searchBar={{
        variant: isSmallScreen ? 'outline' : ''
      }}
      keyExtractor={(item) => `${item.id}`}
      renderItem={({ item, index }) => (
        <View
          maxW={['46%', '46%', '46%', '33%']}
          flex='1'
          key={index}
        >
          <ComboCard commercialPolicy={item} />
        </View>
      )}
      refetch={(_, text) => {
        search(text);
      }}
    />
  );
};
