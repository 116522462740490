import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

const CART = gql`
  mutation ClearCart($cartId: ID!) {
    Cart_clearCart(cartId: $cartId) {
      id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
    }
  }
`;

export const useClearCart = () => {
  return useMutation(CART, {
    refetchQueries: [

    ],
    onCompleted(data) {
      if (data.Cart_clearCart) {
        cartVar(data.Cart_clearCart)
      }
    },
  });
};
