import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

export type SetCustomOrderCodeInput = {
  cartId: string,
  code: string,
}

const SET_CUSTOM_ORDER_CODE = gql`
  mutation SetCustomOrderCode($input: Cart_SetCustomOrderCodeInputType!) {
    Cart_setCustomOrderCode(input: $input) {
      id
      customerCode
      industryCode
      customCode
      status
      code
      userId
      grossPrice
      netPrice
      valid
      discountValue
      coupons
      shippingOrders {
        code
        paymentTerm
      }
      items {
        code
        amount
        totalDiscount
        commercialPolicyId
        type
        valid
        unitGrossPrice
        unitNetPrice
        grossPrice
        netPrice
        discountValue
        discount
        errors {
          type
          message
        }
        discountsTrace
        coupons {
          name
          percentage
        }
        product {
          code
          description
          brand {
            id
            description
          }
          assortment {
            name
          }
        }
        suggestions {
          list {
            name
            code
            amount
          }
          anchor {
            anchorAmount
          }
        }
      }
    }
  }
`;

export const useSetCustomOrderCode = () => {
  return useMutation(SET_CUSTOM_ORDER_CODE, {
    onCompleted(data) {
      if (data.Cart_setCustomOrderCode) {
        cartVar(data.Cart_setCustomOrderCode)
      }
    },
  });
};
