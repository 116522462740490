import React from "react";
import { Box, Text, View } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";
import { FontAwesome5 } from "@expo/vector-icons";

import { THEME } from "@theme/index";
import helpers from "@helpers/NumberHelper";
import { CustomHidden } from "@helpers/CustomHidden";
import { ProductFractionation } from "@components/ProductFractionation";
import { TextHelper } from "@helpers/TextHelper";
import { DottedLine } from "@components/DottedLine";
import { WholesalerProductPrice } from "@components/WholesalerProductPrice";

export interface WholesalerPriceProps {
  description: string;
  code: string;
  price: number;
  fractionation: number;
  stock?: number | null;
  outdated: boolean;
  updatedAt: Date | null;
}

export const WholesalerPriceCard = (props: WholesalerPriceProps) => {
  const wholesalerPrice = props;
  return (
    <Box
      testID={`wholesaler-price-card-${props.code}`}
      flex={1}
      rounded="lg"
      w={'72'}
      minH={'40'}
      overflow="hidden"
      borderColor="coolGray.200"
      borderWidth="1"
      shadow="2"
      _dark={{
        borderColor: "coolGray.600",
        backgroundColor: "gray.700",
      }}
      p='3'
      pb={'4'}
    >
      <View flex={1} flexDirection={"row"} marginY={"2"} marginX={"1"}>
        <FontAwesome5
          name="truck"
          size={RFValue(16)}
          color={THEME.colors.green[300]}
        />
        <View flexDirection={"column"} marginLeft={2}>
          <View>
            <Text
              color='gray.600'
              fontWeight="600"
              lineHeight='16'
              marginBottom='1'
              fontFamily={'heading'}
            >
              {wholesalerPrice.description}
            </Text>
            <View flexDirection={"row"}>
              <Text
                color='muted.700'
                fontWeight="400"
                lineHeight='16'
              >
                {TextHelper.create(wholesalerPrice.code).formatCNPJ()}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View flex='1' flexDirection={"column"} justifyContent={'end'}>
        <CustomHidden show={wholesalerPrice?.fractionation > 1}>
          <ProductFractionation
            fractionationAmount={wholesalerPrice?.fractionation}
            flexDirection={'initial'}
            _label={{
              flexDirection: 'initial',
              _text: {
                fontWeight: '400'
              }
            }}
            alignSelf="start"
            _text={{
              color: 'info.600',
            }}
            _icon={
              <FontAwesome5
                name="box-open"
                size={12}
                color={"rgb(46, 144, 250)"}
              />
            }
          />
        </CustomHidden>
        <DottedLine />
        <View marginTop={1} flexDirection={"row"} >
          <Text
            color={'muted.900'}
            fontWeight={"400"}
            lineHeight={16}
          >
            Estoque:
          </Text>
          <Text
            color={'muted.900'}
            fontWeight={"600"}
            lineHeight={16}
            fontSize={'sm'}
          >
            {wholesalerPrice.stock != null ? wholesalerPrice.stock + " un." : " N/D"}
          </Text>
        </View>
        <View marginTop={1} flexDirection={"row"} >
          <Text
            color={'muted.900'}
            fontWeight={"400"}
            lineHeight={16}
          >
            Preço do distribuidor:
          </Text>
          <WholesalerProductPrice data={wholesalerPrice} />
        </View>
      </View>
    </Box>
  );
};
