import { Dimensions } from 'react-native';

export const ScreenDimensions = ():number => {
    const { width } = Dimensions.get("window")

    if(width < 768) {
      return 200
    }

    if(width < 992) {
      return 200
    }

    if(width < 1280) {
      return 240
    }

    if(width < 1368) {
      return 180
    }    

    return 200
}