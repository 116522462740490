import { create } from 'zustand';

interface ScheduleResponsiveOverlayErrorStore {
  isOpen: boolean;
  message?: string;
  onOpen: (message?: string) => void;
  onClose: () => void;
}

export const useScheduleResponsiveOverlayError = create<ScheduleResponsiveOverlayErrorStore>((set) => ({
  isOpen: false,
  onOpen: (message?: string) => set({ isOpen: true, message }),
  onClose: () => set({ isOpen: false, message: undefined })
}));
