import React, { useEffect } from "react";
import Alert, { useRefAlert, Props } from "@components/Alert";
import { useAddItemOverwriteAlert } from "@hooks/useAddItemOverwriteAlert";

export const AlertOverwriteToCart = () => {
  const add = useAddItemOverwriteAlert()
  const dialog = React.useRef<useRefAlert>();

  useEffect(() => {
    add.setRef(dialog)
  }, [])

  const params: Props = {
    headerText: "Substituição de produtos",
    bodyContent: add.text,
    btnTextConfirm: "Substituir",
    showBtnCancel: true,
    onConfirmCallback: () => {
      add.onSuccessCallback()
      add.onClose();
    },
    onCloseCallback: () => {
      add.onClose();
    },
  };

  return (
    <Alert ref={dialog} {...params} />
  );
};