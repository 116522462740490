import { StyleSheet } from "react-native";

import { height, width } from "@components/Dimension";
import { THEME } from "@theme/index";
import { RFValue } from "react-native-responsive-fontsize";

export const styles = StyleSheet.create({
  modalContainer: {},
  modalContent: {},
  textBtn: {
    color: THEME.colors.green[400],
    fontSize: THEME.fontSizes.md,
    fontWeight: "bold",
    fontFamily: THEME.fonts["heading"]
  },
  image: {
    borderBottomStartRadius: width * 0.003, // 4,
    borderTopStartRadius: width * 0.003, // 4,
    width: 400, // 104
    height: 400, // 123
    alignSelf: "flex-start",
  },
  title: {
    fontSize: 30,
    marginTop:"2%"
  },
  ean: {
    marginTop: 5,
    fontSize: 12,    
  },
  shippingBox: {
    color:THEME.colors.gray["800"],
  },
  text: {
    color:THEME.colors.gray[600],
    fontFamily:THEME.fonts["body"],
    fontWeight:"600",
  },
  textDescription: {
    fontFamily: THEME.fonts["body"],
    fontWeight: "400",
    fontSize: RFValue(8),
    color: THEME.colors.gray["600"],
    marginBottom: width * 0.007, // 10,
  },
});
