import { ChevronDownIcon, ChevronUpIcon, HStack, View } from 'native-base';
import React, { memo, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { Pressable, Text } from 'native-base';

type AccordionItemPros = PropsWithChildren<{
  title: string;
  callbackText: string;
  callback: (id?: number | unknown) => void;
}>;

export const Accordion = memo(({ children, title, callbackText, callback }: AccordionItemPros): JSX.Element => {
  const [expanded, setExpanded] = useState(true);

  function toggleItem() {
    setExpanded(!expanded);
  }

  const body = <View>{children}</View>;

  return (
    <View>
      <Pressable
        onPress={toggleItem}
      >
        <HStack space={1} alignItems={'center'} mb={2}>
          {expanded ? (<ChevronUpIcon color={"green.300"} />) : (<ChevronDownIcon color={"green.300"} />)}
          <Text fontSize={["md"]}>{title}</Text>
        </HStack>
      </Pressable>
      <View
        position={"absolute"}
        alignSelf={"flex-end"}
      > 
      <Pressable onPress={ callback }>
          <Text textAlign={'end'} pr={3} color={"primary.600"} >{callbackText}</Text>
      </Pressable>  
      </View>
      {expanded && body}
    </View>
  )
})