import React, { useEffect, useState } from "react";
import {
  createNativeStackNavigator
} from "@react-navigation/native-stack";

import { ChooseCustomer } from "../screens/ChooseCustomer";
import { useTimeBlock } from "@hooks/useTimeBlock";

const AppStack = createNativeStackNavigator();

const IndustryEmployeeRoute = () => {
  useTimeBlock()

  return (

    <>
      <AppStack.Screen
        name="ChooseCustomer"
        component={ChooseCustomer}
        options={{ headerShown: false, title: "Tela das indústrias" }}
      />
    </>
  )
};

export default IndustryEmployeeRoute;
