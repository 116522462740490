import { useAuth } from "../contexts/Auth";
import { useQuery, gql, useLazyQuery, makeVar } from "@apollo/client";
import { WholesalerBranchesType } from "../types/WholesalerBranchesType";
import { PaymentTermType } from "../types/PaymentTermType";
import { useCustomer } from "../contexts/CustomerContext";

const GET_CUSTOMER_COMMERCIAL_POLICY_SHIPPING_ORDER = gql`
  query GetCustomerCommercialPolicyShippingOrder(
    $customerCode: String!
    $industryCode: String!
    $commercialPolicyId: Int!
    $filter: String
    $assortment: ShippingOrderAssortmentInputType
  ) {
    getCustomerCommercialPolicyShippingOrder(
      customerCode: $customerCode
      industryCode: $industryCode
      commercialPolicyId: $commercialPolicyId
      filter: $filter
      assortment: $assortment
    ) {
      code
      description
      priority
      wholesaler{
        id
        paymentType
        paymentTerms{
          code
          id
          description
        }
      }
    }
  }
`;
// TODO:Refactor Criar type para o filtro
export const useGetCustomerCommercialPolicyShippingOrder = () => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getIndustry();
  const commercialPolicyId = user?.getCommercialPolicyIdStore();
  const filter = '';
  const assortment = {
    names: user.getAssortments().map(element => element.name)
  };

  return useQuery(GET_CUSTOMER_COMMERCIAL_POLICY_SHIPPING_ORDER, {
    variables: { customerCode, industryCode, commercialPolicyId, filter, assortment },
  });
};

export const wholesalerBranchesVar = makeVar<WholesalerBranchesType[]>([])
export const useGetCustomerCommercialPolicyShippingOrderLazy = () => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getIndustry();
  const commercialPolicyId = user?.getCommercialPolicyIdStore();
  const filter = '';
  const assortment = {
    names: user.getAssortments().map(element => element.name)
  };

  return useLazyQuery(
    GET_CUSTOMER_COMMERCIAL_POLICY_SHIPPING_ORDER, {
    fetchPolicy: 'cache-first',
    variables: {
      customerCode,
      industryCode,
      commercialPolicyId,
      filter,
      assortment
    },
    onCompleted(data) {
      if (data?.getCustomerCommercialPolicyShippingOrder) {
        wholesalerBranchesVar(refactorStatus(data.getCustomerCommercialPolicyShippingOrder))
      }
    },
  });

}

export const refactorResult = (
  wholesalers: any,
): WholesalerBranchesType[] => {
  const whole = wholesalers || [];

  const product = whole.map((item: WholesalerBranchesType) => {
    return {
      code: item.code,
      description: item.description,
      priority: 0
    };
  });
  return product;
};

export const refactorStatus = (
  wholesalers: any,
  resetPriority: boolean = false
): WholesalerBranchesType[] => {
  const whole = wholesalers || [];

  const product: WholesalerBranchesType[] = whole.map((item: WholesalerBranchesType) => {
    return {
      code: item.code,
      description: item.description,
      priority: resetPriority ? 0 : item.priority,
      wholesaler: {
        id: item.wholesaler.id,
        paymentType: item.wholesaler.paymentType,
        paymentTerms: item.wholesaler.paymentTerms?.map((payment: PaymentTermType) => {
          return {
            code: payment.code,
            id: payment.id,
            description: payment.description,
            selected: false
          }
        })
      }
    };
  });

  return product;
};


