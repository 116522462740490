import { CartProductTypeEnum } from "@enums/CartProductTypeEnum"
import { ItemTypeEnum } from "@enums/ItemTypeEnum"

type Tab = CartProductTypeEnum | null | undefined
export const CastProductEnum = (tab: Tab): ItemTypeEnum => {
  if (tab === CartProductTypeEnum.COMBO) {
    return ItemTypeEnum.COMBO
  }

  if (tab === CartProductTypeEnum.BRAND_OFFER) {
    return ItemTypeEnum.OFFERS_BY_BRAND
  }

  return ItemTypeEnum.AGGREGATOR
}