import React, { memo } from "react";
import { Button, Center, Factory, Flex, HStack, Image, Text, VStack, View } from 'native-base';
import { EvilIcons, AntDesign, SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { Asset } from "expo-asset";
import funcionalLogo from "@assets/images/funcional_logo_dark.png";
import { LinkButton } from "@components/LinkButton";

const assetLogoFuncional = Asset.fromModule(funcionalLogo);
const logoFuncional = assetLogoFuncional.uri;

const LinkButtonNative = Factory(LinkButton);

export function currentYear() {
  return (new Date()).getFullYear();
}

export function Footer() {
  return (
    <Center
      bgColor='gray.800'
      height={10}
    >
      <Text
        fontSize='xs'
        fontWeight='400'
        color='muted.400'
      >
        © {currentYear()} Funcional Health Tech. Todos os direitos reservados.
      </Text>
    </Center>
  );
};

interface FooterMainProps {
  industryName: string
}

export const FooterMain: React.FC<FooterMainProps> = memo(({ industryName }) => {
  return (
    <View
      bg='muted.900'
      w='100%'
      h={['md', 'xs']}
      position={"relative"}
      pb={"4"}
      mt="16"
      justifyContent={"space-evenly"}
      _dark={{
        bg: 'gray.700'
      }}
    >
      <View
        alignSelf={"center"}
        flexDirection={["column", "row"]}
        w={"90%"}
      >

        <Flex flex={1} w={280} h={175}
          justifyContent={"space-around"}
        >
          <Image
            width="120"
            height="34"
            resizeMode="contain"
            alignItems="center"
            alt={"Logo funcional"}
            source={{
              uri: logoFuncional,
            }}
          />
          <Text
            fontSize={"xs"}
            color={"muted.400"}
          >
            Nossa proposta é de parceria. Somos um aliado nas escolhas e decisões, com resultados diretos na redução dos custos e melhor gestão dos investimentos em saúde.
          </Text>
        </Flex>
        <Flex flex={1} w={280} h={175}>

        </Flex>
        <Flex flex={1} w={280} h={175}>
          <Text fontWeight="medium" color={"muted.400"} mb={"4"}>Produtos</Text>
          <VStack space={4} alignItems="flex-start" direction={["row", "column"]}>

            {/*
             TODO: há um bug ao usar navigation.navigate nas tabs de produtos em que elas somem e ser distorcem 
            <Button
              variant={"link"}
              p={0}
              _text={{
                color: "muted.500"
              }}
              onPress={() => {
                navigation.navigate("Industry", { industry: nameIndustry, tab: 'productList' });
              }}
            >
              Lista de produtos
            </Button> */}
            <LinkButtonNative
              p={0}
              style={{}}
              to={{
                screen: "Industry",
                params: { industry: industryName, tab: 'productList' }
              }}
            >
              <Text
                color="muted.500"
                fontFamily={"body"}
                fontWeight={"400"}
                fontSize={"xs"}
              >
                Lista de produtos
              </Text>
            </LinkButtonNative>
            <LinkButtonNative
              p={0}
              style={{}}
              to={{
                screen: "Industry",
                params: { industry: industryName, tab: 'combos' }
              }}
            >
              <Text
                color="muted.500"
                fontFamily={"body"}
                fontWeight={"400"}
                fontSize={"xs"}
              >
                Lista de combos
              </Text>
            </LinkButtonNative>
            <LinkButtonNative
              p={0}
              style={{}}
              to={{
                screen: "Industry",
                params: { industry: industryName, tab: 'offersByBrands' }
              }}
            >
              <Text
                color="muted.500"
                fontFamily={"body"}
                fontWeight={"400"}
                fontSize={"xs"}
              >
                Lista de marcas
              </Text>
            </LinkButtonNative>
          </VStack>
        </Flex>
        <Flex flex={1} w={280} h={175}>
          <Text fontWeight="medium" color={"muted.400"} mb={"4"}>Institucional</Text>
          <VStack space={4} alignItems="flex-start" direction={["row", "column"]}>
            <Button
              variant={"link"}
              p={0}
              _text={{
                color: "muted.500",
                fontSize: ["xs"]
              }}
            >
              Política de Privacidade
            </Button>
          </VStack>
        </Flex>
        <Flex flex={1} w={280} h={175} >
          <Text fontWeight="medium" color={"muted.400"} mb={"4"}>Redes sociais</Text>
          <Flex justifyContent={"space-between"} flex={"1"} flexDirection={["row", "column"]}>
            <VStack space={4} alignItems="flex-start" direction={["row", "column"]}>
              <HStack space={"3"}>
                <AntDesign name="youtube" size={24} color="gray" />
                <Foundation name="social-linkedin" size={24} color="gray" />
                <EvilIcons name="sc-facebook" size={24} color="gray" />
              </HStack>
            </VStack>
            <HStack space={2} alignItems={"center"} >
              <SimpleLineIcons name="globe" size={24} color="gray" />
              <Text color="muted.500">Português</Text>
            </HStack>
          </Flex>
        </Flex>
      </View>
      <View
        justifyContent={"flex-start"}
        position={"relative"}
        borderTopWidth={1}
        borderTopColor={"muted.400"}
        alignSelf={"center"}
        w={"90%"}
      >
        <Text fontSize='xs'
          fontWeight='400'
          color='muted.400'
        >
          © {currentYear()} Funcional Health Tech. Todos os direitos reservados.
        </Text>
      </View>
    </View >
  )
})