import { TagAlert, TagAlertProps } from "@components/TagAlert";
import {
  Text
} from "native-base";
import React from "react";

export type TagAlertOutdatedPriceProps = TagAlertProps

export const TagAlertOutdatedPrice: React.FC<TagAlertOutdatedPriceProps> =
  ({ ...props }) => {
    return (
      <TagAlert {...props}>
        Os valores em<Text
          pl={1}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
          color={"warning.500"}
          lineHeight={'sm'}
          fontSize={'xs'}
          fontWeight='400'
        >
          AMARELO
        </Text> estão desatualizados; passe o cursor do mouse para saber a última data de atualização.
      </TagAlert>
    );
  };
