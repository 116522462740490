import React, { ForwardedRef, ReactNode } from 'react';
import { AlertDialog, Button, Icon } from 'native-base';
import Feather from 'react-native-vector-icons/Feather';

type AlertType = 'ALERT' | 'SUCCESS';

interface IAlert {
  getColor(): string
  getDarkColor(): string
  getIconName(): string
}

class Success implements IAlert {
  getColor() {
    return 'primary.600';
  }
  getDarkColor() {
    return 'primary.600';

  }
  getIconName() {
    return 'check-circle'
  }
}

class Default implements IAlert {
  getColor() {
    return 'muted.800';
  }
  getDarkColor() {
    return 'muted.400';
  }
  getIconName() {
    return ''
  }
}

class AlertClass implements IAlert {
  getColor() {
    return 'red.500';
  }
  getDarkColor() {
    return 'red.400';

  }
  getIconName() {
    return 'alert-triangle'
  }
}
export interface Props {
  type?: AlertType;
  headerText?: string;
  headerIcon?: ReactNode;
  bodyContent: ReactNode | (() => void);
  btnTextConfirm: string;
  btnTextCancel?: string;
  showBtnCancel?: boolean;
  onConfirmCallback?: Function;
  onCloseCallback?: Function;
}

const Alert = React.forwardRef(
  (props: Props, ref: ForwardedRef<useRefAlert | undefined>) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const cancelRef = React.useRef(null);
    const {
      type = 'ALERT',
      headerText = '',
      headerIcon,
      bodyContent,
      btnTextConfirm = "Sim",
      btnTextCancel = "Não",
      showBtnCancel,
      onConfirmCallback,
      onCloseCallback,
    } = props;


    const getType = (type: AlertType): IAlert => {
      if (type === 'ALERT') {
        return new AlertClass()
      }

      if (type === 'SUCCESS') {
        return new Success()
      }

      return new Default()
    }

    const typeClass = getType(type);

    React.useImperativeHandle(ref, () => ({
      show(): void {
        setIsOpen(true);
      },
    }));

    const onClose = (): void => {
      if (onCloseCallback) {
        onCloseCallback();
      }
      setIsOpen(false);
    };

    const executeCallback = () => {
      if (onConfirmCallback) {
        onConfirmCallback();
      }

      setIsOpen(false);
    };

    const btnCancel = (): React.ReactElement => {
      let showUpBottom = showBtnCancel;

      if (typeof showBtnCancel === typeof undefined) {
        showUpBottom = true;
      }

      if (!showUpBottom) return <></>;

      return (
        <Button
          onPress={onClose}
          ref={cancelRef}
          _text={{
            fontWeight: '700',
            fontSize: ['xs', 'sm'],
            color: 'primary.600'
          }}
          _hover={{
            opacity: 0.8,
          }}
          _pressed={{
            bgColor: 'muted.300',
          }}
          _dark={{
            opacity: 0.7,
            _pressed: {
              bgColor: 'muted.700'
            }
          }}
          flex={1}
          mr={4}
          borderWidth={2}
          borderColor='primary.600'
          bgColor='transparent'
        >
          {btnTextCancel}
        </Button>
      );
    };

    const iconHeader = (): React.ReactElement => {
      if (type === 'SUCCESS' || type === 'ALERT') {
        return (
          <Icon
            as={Feather}
            name={typeClass.getIconName()}
            size={[5, 6]}
            mr={2}
            color={typeClass.getColor()}
            _dark={{
              color: typeClass.getDarkColor()
            }}
          />
        )
      }

      if (!headerIcon) return <></>;

      return <>{headerIcon}</>;
    };

    return (
      <AlertDialog
        p={4}
        alignSelf='center'
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content
          w='100%'
          borderWidth={1}
          borderColor='gray.300'
          _dark={{
            borderColor: 'muted.700'
          }}
        >
          <AlertDialog.CloseButton px={0} _icon={{ color: 'primary.600' }} />

          <AlertDialog.Header
            flexDirection='row'
            alignItems='center'
            _text={{
              color: typeClass.getColor(),
              fontWeight: '700',
              fontSize: ['sm', 'md']
            }}
            _dark={{
              _text: {
                color: typeClass.getDarkColor()
              }
            }}
          >
            {iconHeader()}
            {headerText}
          </AlertDialog.Header>

          <AlertDialog.Body>
            {bodyContent}
          </AlertDialog.Body>

          <AlertDialog.Footer borderTopWidth={0}>
            <Button.Group flex={1} flexDir='row' justifyContent='space-between'>
              {btnCancel()}

              <Button
                onPress={executeCallback}
                _text={{
                  fontWeight: '700',
                  fontSize: ['xs', 'sm']
                }}
                _hover={{
                  opacity: 0.9,
                }}
                _pressed={{
                  bgColor: 'primary.700',
                }}
                _dark={{
                  opacity: 0.8,
                }}
                flex={1}
                bgColor='primary.600'
              >
                {btnTextConfirm}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  }
);

export default Alert;

export type useRefAlert = {
  show: () => void;
};
