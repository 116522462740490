import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Factory, HStack, Icon, Modal, Text } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { useCartSurplusItensErrorAlertModal } from '@hooks/useCartSurplusItensErrorAlertModal';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Web() {
  const alert = useCartSurplusItensErrorAlertModal();

  function handleUpdateOrder() {
    alert.onClose();
  }

  return (
    <Modal isOpen={alert.isOpen} onClose={alert.onClose} >
      <Modal.Content maxWidth="504px" px={6} pb={6} testID='cart-surplus-alert'>
        <Modal.CloseButton _icon={{
          size: 4,
          color: 'primary.600'
        }} />

        <Modal.Header pl={0}>
          <HStack alignItems='center'>
            <Icon
              as={AntDesign}
              name='warning'
              color='warning.400'
              size={8}
              mr={4}
            />

            <Text color='warning.500' fontSize='lg' fontFamily='subheading'>
              Itens faltantes
            </Text>
          </HStack>
        </Modal.Header>

        <Modal.Body px={0} pb={0} >
          <Text fontSize='md' color='muted.800' mb={12}>
            Desculpe! Não é possivel incluir itens em um carrinho de falta.{"\n"}
            Caso não queria continuar o pedido dos itens faltantes, limpe o carrinho.
          </Text>

          <TouchableOpacityFactory
            testID='manage-customers-error-web_update-order'
            bg='primary.600'
            h={12}
            rounded='sm'
            p={4}
            mb={4}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.8,
            }}
            onPress={handleUpdateOrder}
          >
            <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
              Ok
            </Text>
          </TouchableOpacityFactory>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}