import { useQuery, gql, makeVar } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { CartType, ItemType } from "@types/CartType";

export const cartVar = makeVar<CartType | undefined>(undefined)

const CART = gql`
    query Cart($id: ID!){
      Cart_cart(id:$id) {
        id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
      }
 }
`;

export const useCart = (id: string, skip: boolean = false) => {
	return useQuery(CART, {
		skip,
		nextFetchPolicy: 'cache-first',
		variables: {
			id,
		},
		onCompleted(data) {
			if (data.Cart_cart) {
				cartVar(data.Cart_cart);
			}
		},
	});
};
