import React from "react";
import { View, Text } from 'native-base';
import { Card } from "react-native-elements";
import { CommonDataTable } from "../../components/CommonDataTable";
import { SubOrderDetails } from "../SubOrderDetails";
import { format } from 'date-fns';

export const SubOrder = (subOrderRow: any) => {

  const columnsSize = [
    15,
    15,
    20,
    32,
    6,
    12,
  ]

  const header = [
    ["Nº Sub-Pedido", columnsSize[0]],
    ["Data/Hora Criação", columnsSize[1]],
    ["Status", columnsSize[2]],
    ["Distribuidor", columnsSize[3]],
    ["Prazo", columnsSize[4]],
    ["Detalhe dos Pedidos", columnsSize[5]],
  ];

  const rows = Object.values(subOrderRow);

  return (
    <View key={Math.random()}>
      <Card key={Math.random()}>
        {CommonDataTable(header, [])}
        {rows.length > 0 ? (
          rows.map((row: any) => {
            const newRow = {
              orderData: {
                id: [row.tradetoolsSubOrderId || "", columnsSize[0]],
                date: [format(new Date(row.createAt), "dd/MM/yyyy HH:mm") || "", columnsSize[1]],
                status: [row.statusDescription || "", columnsSize[2]],
                distribuidor:
                  [row.wholesalerBranch.name +
                    " - " +
                    row.wholesalerBranch.code || "", columnsSize[3]],
                billingTerm: [row?.shippingOrder?.billing.term, columnsSize[4]],
              },
              productData: {
                items: row.items
              },
            };
            return <View key={Math.random()}>{SubOrderDetails(newRow)}</View>;
          })
        ) : (
          <Card>
            <Text></Text>
          </Card>
        )}
      </Card>
    </View>
  );
};
