import React, { ReactNode } from "react";
import { Text, Center, Flex, ICenterProps, ITextProps } from "native-base";

export type Props = ICenterProps & {
  icon?: ReactNode | undefined;
  text?: string | undefined;
}

function Label({ icon, text, _text, ...rest }: Props) {
  return (
    <Center
      testID="label-component"
      position='absolute'
      alignContent='center'
      width='100%'
      zIndex={1}
      bottom={0}
      bg='purple.700'
      {...rest}
    >
      <Flex direction='row' alignItems='center'>
        {icon}
        <Text
          fontSize={['2xs', '2xs', '2xs', 'xs']}
          fontWeight='700'
          color='muted.100'
          p={1}
          {..._text}
        >
          {" "}
          {text}{" "}
        </Text>
      </Flex>
    </Center>
  );
};

export default React.memo(Label);
