import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { HeaderBar } from "@components/HeaderBar";
import { SummaryOrder } from "@screens/SummaryOrder";
import { WholesalerOrder } from "@screens/WholesalerOrder";
import { FinishOrder } from "@screens/FinishOrder";
import { ListOrder } from "@screens/ListOrder";
import { OrderDetail } from "@screens/OrderDetail";
import { ManageCustomers } from "@screens/ManageCustomers";
import { ValidateCustomers } from "@screens/ValidateCustomers";


const AppStack = createNativeStackNavigator();

const OrderRoute = () => (
  <>
    <AppStack.Screen
      name="SummaryOrder"
      component={SummaryOrder}
      initialParams={{ name: "Resumo do pedido" }}
      options={{
        title: "Resumo do carrinho",
        headerShown: true,
        header: (props) => <HeaderBar isStackHeader {...props} />
      }}
    />
    <AppStack.Screen
      name="WholesalerOrder"
      component={WholesalerOrder}
      initialParams={{ name: "Distribuidores" }}
      options={{
        title: "Resumo do carrinho - distribuidores",
        headerShown: true,
        header: (props) => <HeaderBar isStackHeader {...props} hasRightIcon={false} />
      }}
    />
    <AppStack.Screen
      name="FinishOrder"
      component={FinishOrder}
      initialParams={{ name: "Finalização do pedido" }}
      options={{
        title: "Resumo do carrinho - finalização",
        headerShown: true,
        header: (props) => <HeaderBar isStackHeader {...props} hasRightIcon={false} />
      }}
    />
    <AppStack.Screen
      name="ListOrder"
      component={ListOrder}
      options={{ title: "Lista de pedidos" }}
    />
    <AppStack.Screen
      name="ManageCustomers"
      component={ManageCustomers}
      initialParams={{ name: "Gerenciar clientes" }}
      options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader hasRightIcon={false} {...props} /> }}
    />
    <AppStack.Screen
      name="ValidateCustomers"
      component={ValidateCustomers}
      initialParams={{ name: "Validação dos clientes" }}
      options={{ headerShown: true, header: (props) => <HeaderBar isStackHeader hasRightIcon={false} {...props} /> }}
    />
    <AppStack.Screen name="OrderDetail" component={OrderDetail} />
  </>
);

export default OrderRoute;
