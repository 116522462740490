import React, { useState } from "react";
import { Text, View, FlatList, Modal, Button, Pressable } from "native-base";
import { styles } from "./style";
import { CommercialPolicyProductsType } from "../../types/CommercialPolicyProductsType";

interface ProgressiveProductDiscountProps {
  product: CommercialPolicyProductsType;
}

export const ProgressiveProductDiscount: React.FC<ProgressiveProductDiscountProps> = ({
  product,
}) => {
  const [showModal, setShowModal] = useState(false);

  if (!product.discount?.progressive?.products || product.discount?.progressive?.products?.length <= 0) {
    return null;
  }

  return (
    <>
      <Pressable
        testID="progressive-product-discount-pressable"
        onPress={() => setShowModal(true)}
        _hover={{
          opacity: 30
        }}
        _pressed={{
          opacity: 30
        }}
        _focus={{
          opacity: 30
        }}
        _focusVisible={{
          opacity: 30
        }}
      >
        <Text
          color='primary.600'
          fontSize={['2xs', 'xs']}
          fontWeight='500'
        >
          Ler regra
        </Text>
      </Pressable>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content
          w={['90%', '75%']}
          maxWidth={["full", "350"]}
        >
          <Modal.CloseButton />
          <Modal.Header style={styles.title}
            color={'muted.800'}
            w={'full'}
            fontWeight={500}
          >
            Desconto progressivo
          </Modal.Header>
          <Modal.Body>
            <Text
              color={'muted.800'}
              _dark={{
                color: 'muted.500'
              }}
              fontSize={'sm'}
              fontStyle={'normal'}
              fontWeight={"medium"}
            >
              Desconto base, inicial é de {product.discount.base}% OFF
            </Text>
            <View style={styles.textProduct2}>
              <FlatList
                data={product.discount.progressive?.products}
                renderItem={({ item }) => (
                  <Text
                    color={'muted.800'}
                    _dark={{
                      color: 'muted.500'
                    }}
                    fontSize={'sm'}
                    fontStyle={'normal'}
                    fontWeight={"normal"}
                  >
                    {`\u2022  Na compra`} <Text fontSize={'sm'} color={'orange.500'}>{item.amount}un</Text>, ganhe <Text fontSize={'sm'} color={'orange.500'}>{item.value}% OFF</Text>.
                  </Text>
                )}
                key={2}
              />
            </View>
            <Button
              bgColor={"primary.600"}
              h={"8"}
              mt={4}
              alignItems={"center"}
              justifyContent={"center"}
              onPress={() => setShowModal(false)}
            >
              <Text color={"muted.100"}>Entendi</Text>
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal >
    </>
  )
}