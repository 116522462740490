import React from 'react';
import { View, Text, Flex } from 'native-base';

export const DialogProductToListTemplate = (): React.ReactElement => {
  return (
    <View>
      <Text
        fontWeight='700'
        fontSize='sm'
        color='gray.600'
      >
        Você precisa retirar as listas que contém os produtos que você deseja adicionar ou,
        caso deseje fazer as duas compras, finalize o pedido da lista, e depois realize um novo pedido.
      </Text>
    </View>
  );
};
