import { gql, useLazyQuery } from "@apollo/client";

const VALIDATE_COMMERCIAL_POLICIES_BY_CUSTOMER = gql`
  query validateCommercialPoliciesByCustomer(
		$customer: ValidateCustomerInputType!
		$industry: SimulateIndustryInputType!
		$commercialPolicy: CommercialPolicyByCustomerInputType!
		$integrations: [IntegrationEnum!]
		$availability: String
	) {
    validateCommercialPoliciesByCustomer(
      customer: $customer
      industry: $industry
      commercialPolicy: $commercialPolicy
      integrations: $integrations
      availability: $availability
    ) {
      commercialPolicies {
        id
        valid
      }
      valid
    }
  }
`;

export const useValidateCommercialPoliciesByCustomer = () => {
	return useLazyQuery(VALIDATE_COMMERCIAL_POLICIES_BY_CUSTOMER, {
		fetchPolicy: 'cache-first',
	});
};
