import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { height, width } from "../Dimension";

export const styles = StyleSheet.create({
  modalStyle: {
    padding: 0,
    margin: 0,
    top: 0,
    right: 0,
    position: "absolute",
    overflow: 'hidden'
  },
  container: {
    margin: 0,
    width: width * 0.3,
    paddingTop: width * 0.011,
    backgroundColor: "#FFFFFF",
    shadowColor: "#000000",
    shadowOpacity: 0.2,
    shadowRadius: 10,
    height: '100vh',
    paddingBottom: '10vh',
    padding: width * 0.021,
    position: 'relative'
  },
  containerItems: {
    marginTop: 25,
    overflow: 'auto' as 'scroll',
    height: '90%'
  },
  containerTitle: {
    flexDirection: "row",
  },
  textTitle: {
    color: "#484848",
    fontSize: RFValue(12),

    fontWeight: "500",
  },
  closeButtonCircle: {
    borderRadius: Math.round(width + height) / 2,
    width: width * 0.023,
    height: width * 0.023,
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    alignSelf: "flex-end",
    position: 'absolute',
    right: 0
  }
});