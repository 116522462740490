import { Box, Center, Progress, VStack, Text, Stack } from "native-base";
import React, { useMemo } from "react";
import { THEME } from "@theme/index";
import helpers from "@helpers/NumberHelper";
import { useShoppingCart } from "@hooks/useShoppingCart";

import { useReactiveVar } from "@apollo/client";
import {
  commercialPolicyProductsVar,
  useCommercialPolicyProducts,
} from "@hooks/useCommercialPolicyProducts";
import { ItemType } from "@types/CartType";
import { RequiredTypeEnum } from "@enums/RequiredTypeEnum";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";

export type MinimumAmountProgressBarProps = {
  label: string;
  currentAmount: number;
  minimumAmount: number;
  commercialPolicyId: number;
  invoicedAmount?: number;
};

export const MinimumAmountProgressBar = (
  props: MinimumAmountProgressBarProps
) => {
  const { label, minimumAmount, commercialPolicyId } = props;
  const commercialPolicyProducts = useReactiveVar(commercialPolicyProductsVar);
  const { cart, loading, empty } = useShoppingCart();

  const productSamePolicy: ItemType[] =
    cart?.items.filter(
      (item) =>
        item.commercialPolicyId === commercialPolicyId &&
        item.type == ItemTypeEnum.COMBO
    ) ?? [];

  const productCodes: string[] =
    productSamePolicy.map((item) => item.code) ?? [];

  const countProduct: number =
    cart?.items.reduce((previous, current) => previous + current.amount, 0) ??
    0;

  const skip = loading || empty;
  //TODO estudar se é melhor usar fields adcionais
  useCommercialPolicyProducts(
    {
      commercialPolicyId,
      product: {
        codes: productCodes,
      },
      requiredType: [
        RequiredTypeEnum.NON_REQUIRED,
        RequiredTypeEnum.REQUIRED,
        RequiredTypeEnum.REQUIRED_BASKET,
      ],
      limit: 60,
      offset: 0,
    },
    skip
  );

  const currentValue = useMemo(() => {
    const sameAmount = commercialPolicyProducts
      .filter((item) => item.commercialPolicyId === commercialPolicyId)
      .filter((p) => productCodes.includes(p.product.code))
      .filter((p) => !p.hasNotConsideredMinimum)
      .map((p) => p.product.code);

    const same = productSamePolicy.filter((p) => sameAmount.includes(p.code));

    const value: number = same.reduce(
      (previous, current) => previous + current.netPrice,
      0
    );

    return value + (props.invoicedAmount || 0);
  }, [cart?.items, countProduct, commercialPolicyProducts.length]);

  const currentProgress = (currentValue / minimumAmount) * 100;
  const currentAmountCurrencyFormatted: string = `R$ ${helpers.currency(
    currentValue,
    2
  )}`;
  const minimumAmountCurrencyFormatted: string = `R$ ${helpers.currency(
    minimumAmount,
    2
  )}`;

  const reachedAmount = (): string => {
    if (currentValue >= minimumAmount) {
      return THEME.colors.primary[500];
    }

    return THEME.colors.danger[600];
  };

  return (
    <VStack space="md">
      <Stack direction="row" alignItems={"center"} mb="2.5" mt="1.5" space={3}>
        <Center minW={"15%"} maxW={"15%"}>
          <Text
            color={"muted.800"}
            _dark={{
              color: "muted.400",
            }}
            fontWeight={"500"}
            fontSize={["xs", "sm"]}
            lineHeight={"sm"}
          >
            {label}
          </Text>
        </Center>
        <Box flex={1}>
          <Progress
            rounded={"lg"}
            size={"2xl"}
            bg={"gray.100"}
            borderColor={"gray.300"}
            borderWidth={1}
            _filledTrack={{ bg: reachedAmount() }}
            value={currentProgress}
          />
          <Center
            position={"absolute"}
            justifyContent={"center"}
            right={[20, 20, 64]}
            top={1}
          >
            <Text
              fontSize={["xs", "sm"]}
              fontWeight="bold"
              color={"muted.900"}
              _dark={{
                color: "muted.400",
              }}
              lineHeight={"sm"}
            >
              {currentAmountCurrencyFormatted} /{minimumAmountCurrencyFormatted}
            </Text>
          </Center>
        </Box>
      </Stack>
    </VStack>
  );
};
