import { StyleSheet } from 'react-native';

import { styles as dragCardStyle } from "@components/SelectedDragCard/styles"
import { THEME } from '@theme/index';
import { ScreenDimensions } from '@screens/ChooseWholesaler/ScreenDimensions';

export const styles = StyleSheet.create({
  squareShapeView: {
    width: ScreenDimensions(),
    minHeight: 70,
    marginRight: 8,
    backgroundColor: THEME.colors.white,
    justifyContent: "center",
    ...dragCardStyle.cardBorder
  },

  squareShapeViewMobile: {
    width: "full",
    minHeight: 64,
    backgroundColor: THEME.colors.white,
    ...dragCardStyle.cardBorder
  },

  dragging: {
    opacity: 0.2,
  },
  cardBorder: dragCardStyle.cardBorder,

  fontColorDefault: {
    color: THEME.colors.muted[800]
  },
  
  paymentTerms: {
    backgroundColor: THEME.colors.orange[100]
  },

  linkColor: {
    color: THEME.colors.primary[600]
  },

  cardsBackgroundDark: {
    color: THEME.colors.muted[800]
  },

  darkPrimary: {
    color: THEME.colors.muted[400]
  },

  darkSecondary: {
    color: THEME.colors.muted[900]
  }

});