
import React, { useCallback, useMemo } from "react";
import { useAuth } from "@contexts/Auth";
import { Button } from "../../Button";
import { ProfileProps } from "../ProfileProps";

export const Retailer: React.FC<ProfileProps> = ({ navigation, ...restPros }) => {
  const { user } = useAuth()

  const onPress = useCallback((): void => {
    navigation.navigate('ChooseIndustry')
  }, [user])


  const text = useMemo((): string => {
    return 'Alterar Indústria'
  }, [user])

  const onPressCustomer = useCallback((): void => {
    navigation.navigate('ChooseCustomer')
  }, [user])


  const textCustomer = useMemo((): string => {
    return 'Alterar Cliente'
  }, [user])

  return (<>
    <Button onPress={onPress} text={text} {...restPros} />
    <Button onPress={onPressCustomer} text={textCustomer} {...restPros} />
  </>);
};
