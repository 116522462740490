import React, { MutableRefObject } from "react";
import { FastAccessCardButtons, FastAccessCardButtonsProps } from "./FastAccessCardButtons";
import { FastAccessCardProps, FastAccessCard as Card } from "./FastAccessCard";
import { FastAccessActionsheetProps, FastAccessActionsheet } from "./FastAccessActionsheet";

export type IFastAccessType = ((
  props: FastAccessCardProps & { ref?: MutableRefObject<any> }
) => JSX.Element) & {
  Buttons: React.MemoExoticComponent<
    (props: FastAccessCardButtonsProps & { ref?: MutableRefObject<any> }) => JSX.Element
  >
  Actionsheet: React.MemoExoticComponent<
    (props: FastAccessActionsheetProps & { ref?: MutableRefObject<any> }) => JSX.Element
  >
};

const FastAccessCardTemp: any = Card
if(FastAccessCardTemp) {
  FastAccessCardTemp.Buttons = FastAccessCardButtons;
  FastAccessCardTemp.Actionsheet = FastAccessActionsheet;  
} 

const FastAccessCard = FastAccessCardTemp as IFastAccessType;

export { FastAccessCard };