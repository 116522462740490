import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';

type DateSchedule = {
  date: Date | string,
  period: string
}

type SendOrderResponsiveOverlayProps = {
  type: 'WEB' | 'MOBILE';
  handleSendOrder: () => Promise<void>;
  cartCode?: string;
  dateSchedule?: DateSchedule
}

export function SendOrderResponsiveOverlay({
  type,
  handleSendOrder,
  cartCode,
  dateSchedule
}: SendOrderResponsiveOverlayProps) {
  return (
    type === 'WEB' ? (
      <Web handleSendOrder={handleSendOrder} cartCode={cartCode || ''} dateSchedule={dateSchedule} />
    ) : (
      <Mobile handleSendOrder={handleSendOrder} dateSchedule={dateSchedule} />
    )
  );
}