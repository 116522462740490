import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

const CLEAR_COUPONS = gql`
  mutation ClearCoupons($cartId: ID!) {
    Cart_clearCoupons(cartId: $cartId) {
      id
      customerCode
      industryCode
      status
      code
      customCode
      userId
      grossPrice
      netPrice
      valid
      discountValue
      coupons
      shippingOrders {
        code
        paymentTerm
      }
      items {
        code
        amount
        totalDiscount
        commercialPolicyId
        type
        valid
        unitGrossPrice
        unitNetPrice
        grossPrice
        netPrice
        discountValue
        discount
        errors {
          type
          message
        }
        discountsTrace
        coupons {
          name
          percentage
        }
        product {
          code
          description
          brand {
            id
            description
          }
          assortment {
            name
          }
        }
        suggestions {
          list {
            name
            code
            amount
          }
          anchor {
            anchorAmount
          }
        }
      }
    }
  }
`;

export const useClearCoupons = () => {
	return useMutation(CLEAR_COUPONS, {
		onCompleted(data) {
			if (data.Cart_clearCoupons) {
				cartVar(data.Cart_clearCoupons)
			}
		},
	});
};
