import React, { useState } from "react";

import { Web } from "./Web";
import { Mobile } from "./Mobile";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useResultSendOrderResponsiveOverlay } from "@hooks/useResultSendOrderResponsiveOverlay";
import { Loader } from "@components/Loader";
import { useSendOrderResponsiveOverlay } from "@hooks/useSendOrderResponsiveOverlay";
import {
  Box,
  CheckIcon,
  Factory,
  FormControl,
  Select,
  Text,
  VStack,
  ChevronDownIcon,
  WarningOutlineIcon,
} from "native-base";
import { TouchableOpacity } from "react-native";
import { useScheduleModal } from "@hooks/useScheduleModal";
import { MaskedTextInput } from "react-native-mask-text";
import { SendOrderResponsiveOverlay } from "@components/SendOrderResponsiveOverlay";
import { THEME } from "@theme/index";

type ScheduleProps = {
  type: "WEB" | "MOBILE";
};

const TouchableOpacityFactory = Factory(TouchableOpacity);

function validateDateSchedule(date: string, period: string) {
  const dateParts: string[] = date.split("/");

  if (dateParts.length < 3) {
    return false;
  }

  if (Number(dateParts[0]) == 0 || Number(dateParts[0]) > 31) {
    return false;
  }

  if (Number(dateParts[1]) == 0 || Number(dateParts[1]) > 12) {
    return false;
  }

  const dateConvert: Date = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );

  const today = new Date();

  dateConvert.setHours(parseInt(period.split(":")[0]));

  if (dateConvert < today) {
    return false;
  }

  return true;
}

export function Schedule({ type }: ScheduleProps) {
  const [loading, setLoading] = useState(false);
  const { schedule, cart } = useShoppingCart();
  const resultSendOrderDialog = useResultSendOrderResponsiveOverlay();
  const sendOrderDialog = useSendOrderResponsiveOverlay();
  const [inputDate, setInputDate] = React.useState(``);
  const [period, setPeriodo] = React.useState("");
  const [dateInvalid, setDateInvalid] = useState(false);

  const onCheckout = async () => {
    setLoading(true);
    sendOrderDialog.onClose();

    const isValid = validateDateSchedule(inputDate, period);

    if (!isValid) {
      setDateInvalid(true);
      setLoading(false);
      return;
    }

    const dateParts: string[] = inputDate.split("/");

    const date: Date = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );

    try {
      await schedule([
        {
          date,
          period,
        },
      ]);
      resultSendOrderDialog.onOpen("SUCCESS");
    } catch (e) {
      console.log(e);
      resultSendOrderDialog.onOpen("ERROR");
    } finally {
      setLoading(false);
    }
  };

  return type === "WEB" ? (
    <>
      <Web>
        <Content
          valueDate={inputDate}
          valuePeriodo={period}
          onChangeDate={setInputDate}
          onChangePeriodo={setPeriodo}
          hasError={dateInvalid}
          onSetHasError={setDateInvalid}
        />
      </Web>
      <Loader visible={loading} />
      <SendOrderResponsiveOverlay
        type="WEB"
        handleSendOrder={onCheckout}
        cartCode={cart?.code ?? ""}
        dateSchedule={{
          date: inputDate,
          period: period,
        }}
      />
    </>
  ) : (
    <>
      <Mobile>
        <Content
          valueDate={inputDate}
          valuePeriodo={period}
          onChangeDate={setInputDate}
          onChangePeriodo={setPeriodo}
          hasError={dateInvalid}
          onSetHasError={setDateInvalid}
        />
      </Mobile>
      <Loader visible={loading} />
      <SendOrderResponsiveOverlay
        type="MOBILE"
        handleSendOrder={onCheckout}
        cartCode={cart?.code ?? ""}
        dateSchedule={{
          date: inputDate,
          period: period,
        }}
      />
    </>
  );
}

type ContentProps = {
  valueDate: any;
  onChangeDate: (date: string) => void;
  valuePeriodo: any;
  onChangePeriodo: (period: string) => void;
  hasError: any;
  onSetHasError: (hasError: boolean) => void;
};

export function Content({
  valueDate,
  onChangeDate,
  valuePeriodo,
  onChangePeriodo,
  hasError,
  onSetHasError,
}: ContentProps) {
  const sendOrderDialog = useSendOrderResponsiveOverlay();
  const scheduleModal = useScheduleModal();

  const onDateBlur = (valueDate: any, valuePeriodo: any) => {
    const isValid = validateDateSchedule(valueDate, valuePeriodo);
    if (isValid) {
      return onSetHasError(false);
    }
    return onSetHasError(true);
  };

  const onSend = () => {
    const isValid = validateDateSchedule(valueDate, valuePeriodo);

    if (!isValid) {
      return onSetHasError(true);
    }

    scheduleModal.onClose();
    sendOrderDialog.onOpen();
  };
  return (
    <VStack mt={2}>
      <Text fontSize="sm" color="muted.800" fontWeight={"700"}>
        Dados para o agendamento do pedido.
      </Text>
      <Text fontSize="sm" color="muted.800" mb={4} fontWeight={"400"}>
        Após envio, o pedido não poderá ser alterado.
      </Text>
      <Box flex={1} mb={[12, 3]}>
        <FormControl flex={1}>
          <FormControl.Label>Período: </FormControl.Label>
          <Select
            selectedValue={valuePeriodo}
            placeholder={"Período"}
            minWidth="200"
            _item={{
              color: "muted.800",
            }}
            color={"muted.800"}
            _text={{
              color: "muted.800",
            }}
            accessibilityLabel="Período"
            _selectedItem={{
              color: "muted.800",
              endIcon: <CheckIcon size="4" />,
            }}
            mt={1}
            onValueChange={(itemValue) => {
              onChangePeriodo(itemValue);
              onDateBlur(valueDate, itemValue);
            }}

            dropdownIcon={
              <ChevronDownIcon size="3" color="primary.600" mr="2" />
            }
          >
            <Select.Item label="Manhã - 09:00 hrs" value="09:00" />
            <Select.Item label="Tarde - 17:00 hrs" value="17:00" />
          </Select>
        </FormControl>
        <FormControl isInvalid={hasError}>
          <FormControl.Label>Data: </FormControl.Label>
          <MaskedTextInput
            onChangeText={(d) => {
              if (d.length != 0) {
                onDateBlur(d, valuePeriodo);
              }
              onChangeDate(d);
            }}
            mask="99/99/9999"
            style={{
              padding: 8,
              borderWidth: 1,
              borderStyle: "solid",
              color: THEME.colors.muted["800"],
              borderColor: THEME.colors.muted["400"],
            }}
            onBlur={() => {
              onDateBlur(valueDate, valuePeriodo);
            }}
            placeholder="dd/mm/aaaa"
          />
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            Data para agendamento inválida.
          </FormControl.ErrorMessage>
        </FormControl>
      </Box>
      <TouchableOpacityFactory
        testID="send-order-overlay-mobile_send"
        bg="primary.600"
        rounded="sm"
        py={4}
        px={8}
        mb={3}
        alignItems="center"
        justifyContent="center"
        _dark={{
          opacity: 0.8,
        }}
        onPress={onSend}
      >
        <Text fontSize="sm" fontFamily="subheading" color="muted.100">
          Enviar
        </Text>
      </TouchableOpacityFactory>

      <TouchableOpacityFactory
        testID="send-order-overlay-mobile_cancel"
        rounded="sm"
        py={4}
        px={8}
        alignItems="center"
        justifyContent="center"
        _dark={{
          opacity: 0.7,
        }}
        onPress={scheduleModal.onClose}
      >
        <Text fontSize="sm" fontFamily="subheading" color="primary.600">
          Cancelar
        </Text>
      </TouchableOpacityFactory>
    </VStack>
  );
}
