export enum OrderStatusEnum {
  ORDER_PENDING = "ORDER_PENDING",
  ORDER_PROCESSING = "ORDER_PROCESSING",
  ORDER_ACCEPTED = "ORDER_ACCEPTED",
  ORDER_COMPLETE = "ORDER_COMPLETE",
  ORDER_PARTIALLY_ACCEPTED = "ORDER_PARTIALLY_ACCEPTED",
  ORDER_REJECTED = "ORDER_REJECTED",
  ORDER_WAITING_WHOLESALERS_RESPONSE = "ORDER_WAITING_WHOLESALERS_RESPONSE",
  ORDER_SCHEDULED = "ORDER_SCHEDULED",
}
