import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Factory } from 'native-base';

import { Card } from '@components/Card';
import { TextHelper } from '@helpers/TextHelper';

const FactoryTouchableOpacity = Factory(TouchableOpacity);

type Props = {
  code: string;
  businessName: string;
  onPress: () => void;
}

export function CustomerCard({ code, businessName, onPress }: Props) {
  return (
    <Card.Container
      containerStyle={{ width: '100%' }}
      testID={`${code}-card`}
    >
      <Card.Body style={{ paddingVertical: 12 }}>
        <Card.Text
          description={"Bem vindo(a) ao"}
          color="muted.800"
          fontSize="xs"
          fontWeight="500"
        />
        <Card.Text
          description={businessName}
          color="muted.800"
          fontSize="sm"
          fontWeight="700"
        />
        <Card.Text
          description={new TextHelper(code ?? '').formatCNPJ()}
          color="gray.600"
          fontSize="xs"
          fontWeight="400"
        />
        <FactoryTouchableOpacity
          testID='change-customer-button'
          mt={4}
          w='fit-content'
          onPress={onPress}
        >
          <Card.Text
            color='primary.600'
            fontSize='sm'
            fontFamily='heading'
            textDecorationLine='underline'
            _dark={{
              color: 'primary.400'
            }}
          >
            Alterar o cliente
          </Card.Text>
        </FactoryTouchableOpacity>
      </Card.Body>
    </Card.Container>
  );
}