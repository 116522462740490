import React, { useCallback, useEffect, useState, memo } from "react";
import {
  Text,
  Heading,
  Checkbox,
  Box,
  IBoxProps,
  ScrollView,
  VStack,
  Button,
} from "native-base";
import { FastAcessContext } from "@components/FastAccessCard/Context";
import {
  DynamicFilter,
  filter,
} from "@components/FastAccessCard/DynamicFilter";
import { CustomHidden } from "@helpers/CustomHidden";
import { FilterInterface } from "@components/FastAccessCard/FilterInterface";
import { FastAccessFilterEnum } from "@enums/FastAccessFilterEnum";
import { DynamicFilterEnum } from "@enums/DynamicFilterEnum";
import { ContainerContentChevron } from "@components/ContainerContentChevron";
import { makeVar, useReactiveVar } from "@apollo/client";
import { loadingFilterVar } from "@components/ProductList";
import { useFilterProducts } from "@hooks/useFilterProducts";
import { useAuth } from "@contexts/Auth";
import { TablesFilterEnum } from "@enums/TablesFilterEnum";
import { useFilter } from "@contexts/FilterContext";

export const searchFilterVar = makeVar({
  assortment: [],
  division_product: [],
  family: [],
  single: [],
} as FilterInterface);

export type FastAccessCardProps = IBoxProps & {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  filterIsClick?: boolean;
};

interface filtersData {
  assortments: filter[];
  division_products: filter[];
  families: filter[];
}

export const FastAccessCard: React.FC<FastAccessCardProps> = memo((props) => {
  const {
    title = "Filtro",
    subTitle = "Acesso rápido",
    filterIsClick = false,
    ...rest
  } = props;
  const fastAccessLoading = useReactiveVar(loadingFilterVar);
  const [valuesCheckBox, setValuesCheckBox] = useState<FilterInterface>(
    searchFilterVar()
  );
  const { user } = useAuth();
  const industryCode = user?.getIndustry();
  const { currentTabView } = useFilter();
  let urlTabParam: string = "";

  const tablesFilter: TablesFilterEnum[] = [
    TablesFilterEnum.ASSORTMENT,
    TablesFilterEnum.FAMILY,
    TablesFilterEnum.PRODUCT_DIVISION,
  ];
  const { data } = useFilterProducts({ industryCode, tablesFilter });

  const getUrlParam = () => {
    const url = new URL(document.URL);
    const queryString = new URLSearchParams(url.search);
    urlTabParam = queryString.get("tab") || "";
  };

  getUrlParam();

  const { assortments, division_products, families }: filtersData = data;

  const filter = useCallback(() => {
    searchFilterVar(valuesCheckBox);
  }, [valuesCheckBox]);

  const clear = useCallback(() => {
    const filterCleared = {
      assortment: [],
      division_product: [],
      family: [],
      single: [],
    };

    setValuesCheckBox(filterCleared);
    searchFilterVar(filterCleared);
  }, [valuesCheckBox]);

  useEffect(() => {
    if (!filterIsClick) {
      searchFilterVar(valuesCheckBox);
    }
  }, [valuesCheckBox]);

  function showFastAccessFilters(): boolean {
    const tabViewsToShow = [
      "offersByBrandProducts",
      "comboProducts",
      "productList",
    ];
    const tabView: string = currentTabView || "";

    if (!urlTabParam && tabViewsToShow.includes(tabView)) {
      return true;
    }

    if (
      (urlTabParam && tabView == "offersByBrandProducts") ||
      (urlTabParam && tabView == "comboProducts")
    ) {
      return true;
    }

    if (urlTabParam == "productList" && tabView == "productList") {
      return true;
    }

    return false;
  }

  return showFastAccessFilters() ? (
    <ScrollView w={"full"}>
      <Box
        borderColor="coolGray.200"
        w={["full", "full", "full", 200]}
        bgColor="white"
        _dark={{
          bgColor: "muted.800",
          borderColor: "muted.700",
        }}
        p={[10, 10, 2]}
        borderWidth="1"
        borderRadius={8}
        shadow="3"
        {...rest}
      >
        <Heading
          fontSize={"md"}
          mb={2}
          color={"muted.800"}
          _dark={{ color: "muted.400" }}
          fontWeight={"600"}
        >
          {title}
        </Heading>
        <Text
          color={"muted.800"}
          _dark={{ color: "muted.400" }}
          fontWeight={"400"}
          mb={2}
        >
          {subTitle}
        </Text>

        <CustomHidden breakpoint={768}>
          <VStack space={2} justifyContent={"space-around"} pb={4}>
            <Button variant={"outline"} h={6} onPress={clear}>
              <Text fontSize={["xs", "sm"]} color={"primary.600"}>
                Limpar filtros
              </Text>
            </Button>
          </VStack>
        </CustomHidden>

        <CustomHidden show={Boolean(currentTabView === "productList")}>
          <Checkbox.Group
            pointerEvents={fastAccessLoading ? "none" : "auto"}
            colorScheme="green"
            color={"muted.800"}
            accessibilityLabel="Selecione para filtrar"
            onChange={(values) => {
              setValuesCheckBox({
                ...valuesCheckBox,
                single: values,
              });
            }}
            defaultValue={valuesCheckBox?.single || []}
            value={valuesCheckBox?.single || []}
          >
            <Checkbox
              my={2}
              value={FastAccessFilterEnum.CAMPAIGN}
              color={"muted.800"}
            >
              <Text
                color={"muted.800"}
                _dark={{ color: "muted.400" }}
                fontWeight={"400"}
              >
                Campanha
              </Text>
            </Checkbox>
            <Checkbox
              my={2}
              value={FastAccessFilterEnum.HAS_PROGRESSIVE}
              color={"muted.800"}
            >
              <Text
                color={"muted.800"}
                _dark={{ color: "muted.400" }}
                fontWeight={"400"}
              >
                Desconto progressivo
              </Text>
            </Checkbox>
          </Checkbox.Group>
        </CustomHidden>

        <CustomHidden show={Boolean(assortments.length)}>
          <ContainerContentChevron
            collapsable={true}
            startOpened={false}
            title={"Sortimentos"}
            mb={[4, 3]}
            flexGrow={1}
            _title={{
              color: "primary.600",
              fontSize: ["sm", "md"],
              fontWeight: "normal",
            }}
          >
            <DynamicFilter
              group={DynamicFilterEnum.assortment}
              fastAccessLoading={fastAccessLoading}
              setValuesCheckBox={setValuesCheckBox}
              valuesCheckBox={valuesCheckBox}
              filters={assortments}
            />
          </ContainerContentChevron>
        </CustomHidden>

        <CustomHidden show={Boolean(division_products.length)}>
          <ContainerContentChevron
            collapsable={true}
            startOpened={false}
            title={"Divisão do produto"}
            mb={[4, 3]}
            flexGrow={1}
            _title={{
              color: "primary.600",
              fontSize: ["sm", "md"],
              fontWeight: "normal",
            }}
          >
            <DynamicFilter
              group={DynamicFilterEnum.division_product}
              fastAccessLoading={fastAccessLoading}
              setValuesCheckBox={setValuesCheckBox}
              valuesCheckBox={valuesCheckBox}
              filters={division_products}
            />
          </ContainerContentChevron>
        </CustomHidden>

        <CustomHidden
          show={
            Boolean(families.length) &&
            Boolean(currentTabView !== "offersByBrandProducts")
          }
        >
          <ContainerContentChevron
            collapsable={true}
            startOpened={false}
            title={"Família"}
            mb={[4, 3]}
            flexGrow={1}
            _title={{
              color: "primary.600",
              fontSize: ["sm", "md"],
              fontWeight: "normal",
            }}
          >
            <DynamicFilter
              group={DynamicFilterEnum.family}
              fastAccessLoading={fastAccessLoading}
              setValuesCheckBox={setValuesCheckBox}
              valuesCheckBox={valuesCheckBox}
              filters={families}
            />
          </ContainerContentChevron>
        </CustomHidden>

        <FastAcessContext.Provider value={{ valuesCheckBox, filter, clear }}>
          {props?.children}
        </FastAcessContext.Provider>
      </Box>
    </ScrollView>
  ) : (
    <></>
  );
});
