import React, { useCallback } from "react";
import { useNavigation } from "@react-navigation/native"

import { Card } from "@components/Card";
import { useCustomer } from "@contexts/CustomerContext"
import { TextHelper } from "@helpers/TextHelper";
import { Button } from "../Button";
import { User } from "@contexts/Auth";
import { Box, IBoxProps } from "native-base";

type CustomerCardProps = IBoxProps & {
  user: User
}

export const CustomerCard: React.FC<CustomerCardProps> = ({ user, ...restProps }) => {
  const { customer } = useCustomer()
  const navigation = useNavigation()

  const onRedirectTo = useCallback((): void => {
    navigation.navigate('ChooseCustomer')
  }, [user])

  return <Box {...restProps}>
    <Card.Text
      description={customer.businessName}
      color="muted.800"
      fontSize="sm"
      fontWeight="700"
    />
    <Card.Text
      description={new TextHelper(customer.code ?? '').formatCNPJ()}
      color="gray.600"
      fontSize="xs"
      fontWeight="400"
    />
    <Button onPress={onRedirectTo} text={'Alterar o cliente'} />
  </Box>
}