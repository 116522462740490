import React from 'react';
import { View, Text } from 'native-base';
import { ProductsRuleProps } from '@types/CouponsType';

type ProductRuleItemProps = {
  productRule: ProductsRuleProps;
}

export function ProductRuleItem({ productRule }: ProductRuleItemProps) {
  return (
    <View
      mt={4}
      flexDirection='row'
    >
      <Text
        lineHeight={24}
        fontSize={['sm', 'xs']}
        fontWeight='500'
        color='muted.800'
        _dark={{
          color: 'muted.300'
        }}
      >
        • {productRule.ean}:{`\n`}
        Qtd. mínima de {productRule.minimumAmount}un
        {productRule.discountProductPercentage ? `, com ${productRule.discountProductPercentage}% de desconto.` : '.'}
      </Text>

      {productRule.isEnforced ? (
        <Text
          lineHeight={24}
          fontSize={['sm', 'xs']}
          fontWeight='700'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}
          ml='1'
          alignSelf={'flex-end'}
        >
          - obrigatório
        </Text>
      ) : null}
    </View>
  );
}