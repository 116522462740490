export const getUrlParam = (position: number, urlRef: string = ""): string => {
  const url = new URL(urlRef || document.URL);
  const stringUrl = url.pathname.substring(1, url.pathname.length);

  if (!stringUrl) {
    return "";
  }

  const params = stringUrl.split("/");

  return params[position];
};
