
import React from "react";
import { Pressable, Text, Image, IPressableProps } from "native-base";
import { ImageSourcePropType } from "react-native";

type CardProps = IPressableProps & {
  sourceImg: ImageSourcePropType
  text: string,
  onPress: () => void
  alt: string
}

export const Card: React.FC<CardProps> = ({ onPress, text, alt, sourceImg, ...restPros }) => {
  return (
    <Pressable
      w={'full'}
      rounded="lg"
      borderColor="coolGray.200"
      borderWidth="1"
      shadow="4"
      _dark={{
        borderColor: "coolGray.600",
        backgroundColor: "gray.700"
      }}
      _web={{
        shadow: 2,
        borderWidth: 0
      }}
      onPress={onPress}
      px={3}
      py={3}
      justifyContent={"flex-start"}
      alignItems={"center"}
      justifyItems={"flex-start"}
      flexDir={'row'}
      _pressed={{
        bgColor: "muted.200"
      }}
      _hover={{
        bgColor: "muted.200"
      }}
      {...restPros}
    >
      <Image
        source={sourceImg}
        alt={alt}
        resizeMode='contain'
        resizeMethod='scale'
        size="xs"
      />
      <Text
        ml={'5'}
        fontWeight={'medium'}
        color={'muted.800'}
        fontSize={'md'}
        _dark={{
          color: "muted.400"
        }}
      >
        {text}
      </Text>
    </Pressable>
  );
};
