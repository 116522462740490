import React, { useEffect, useState } from "react";
import { Stack, useSafeArea, useToast, View } from "native-base";
import { useIsFocused } from "@react-navigation/native";
import { FastAccessCard } from "@components/FastAccessCard";
import { LayoutStore } from "@components/LayoutStore";
import { ModalCoupon } from "@components/ModalCoupon";

import {
  ProductsTabView,
  TabNavigationProvider,
} from "@components/ProductsTabView";
import { ShoppingCartModal } from "../ShoppingCart";
import { useAuth } from "@contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";
import { useFilter } from "@contexts/FilterContext";
import { useAlertCouponModal } from "@hooks/useAlertCouponModal";
import { useSideMenu } from "@hooks/useSideMenu";

import { styles } from "./styles";
import { IndustryCard } from "./IndustryCard";
import { CustomerCard } from "./CustomerCard";
import { RetailterCard } from "./RetailterCard";
import { SideMenu } from "@components/SideMenu";
import { useTimeBlock } from "@hooks/useTimeBlock";
import { getSessionStorage, setSessionStorage } from "@helpers/SessionStorage";
import { getTabPromotional } from "@components/PromotionalAction";
import { ToastAlert } from "@components/ToastAlert";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { useCartSurplusItensErrorAlertModal } from "@hooks/useCartSurplusItensErrorAlertModal";

interface TabInterface {
  key: string;
  title: string;
  active: boolean;
  category: string;
}

export const Industry: React.FC = ({ route, navigation }: any) => {
  const [hasPromotionalActionActive, setHasPromotionalActionActive] = useState(false);
  const toast = useToast();

  const isFocused = useIsFocused();
  useTimeBlock();
  const { addFilters, currentTabView } = useFilter();
  const alertCouponModal = useAlertCouponModal();

  const [dynamicTabs, setDynamicTabs] = useState<TabInterface[]>([])

  const sideMenu = useSideMenu();

  const { user } = useAuth();
  let { customer } = useCustomer();
  let isIndustryEmployee = user.isIndustryEmployee();
  const isPointOfSales = user.isPointOfSales()
  let isTradeManager = user.isTradeManager();
  let urlTabParam: string = "";
  const industry = route.params.industry;
  const { cart, loading } = useShoppingCart();
  const cartSurplusItensErrorAlert = useCartSurplusItensErrorAlertModal()
  const isCartSurplusItens = !!cart?.items.find(item => item.type == ItemTypeEnum.SURPLUS_ITENS);


  const getUrlParam = () => {
    const url = new URL(document.URL);
    const queryString = new URLSearchParams(url.search);
    urlTabParam = queryString.get("tab") || "";
  };

  getUrlParam();

  function showToastAlert(error: boolean) {
    if (error) {
      toast.show(
        {
          placement: 'top-right',
          rounded: 'lg',
          render: ({
            id,
          }) => (
            <ToastAlert
              id={id}
              toast={toast}
              title={'Ação promocional inválida'}
              variant='subtle'
              status="warning"
              isClosable
              _title={{
                color: 'warning.800'
              }}
              rounded='lg'
              right={[1, 24]}
              top={[1, 1, 6]}
            />
          )
        }
      )
    }
  }

  useEffect(() => {
    addFilters(route.params);
    alertCouponModal.onVisible();

    (async function () {
      if (isPointOfSales) {
        try {
          const promo = await getSessionStorage('promotionalAction')
          setHasPromotionalActionActive(promo.active);

          if (promo.error) {
            showToastAlert(promo.error)
            promo.error = false;
            setSessionStorage('promotionalAction', promo);
          }

        } catch (error) {
          setHasPromotionalActionActive(false)
        }
      }
    })()

  }, []);

  useEffect(() => {
    addFilters(route.params);

  }, [route.params, currentTabView, urlTabParam]);


  useEffect(() => {
    if (isCartSurplusItens) {
      navigation.navigate("SummaryOrder", { industry: industry });
      cartSurplusItensErrorAlert.onOpen();
      return;
    }
  }, [isCartSurplusItens]);

  useEffect(() => {
    if (hasPromotionalActionActive) {
      const promotionalTab = getTabPromotional();
      setDynamicTabs(promotionalTab);
    }
  }, [hasPromotionalActionActive])


  if (!isFocused) {
    return <></>;
  }

  return (
    <>
      <LayoutStore>
        {/* BANNER DE INDÚSTRIA REMOVIDO TEMPORARIAMENTE */}
        {/* {!isIndustryEmployee && <IndustryInfo />} */}

        <View testID="industry-screen" style={styles.containerContent}>
          <Stack direction={["column", "column", "row"]} flex={1}>
            <Stack space={4} w={['90%', "container"]} mr={6} m={2} alignSelf={["center", "flex-start"]}>
              {customer && isIndustryEmployee && (
                <CustomerCard
                  code={customer.code}
                  businessName={customer.businessName || ""}
                  onPress={() => navigation.navigate("ChooseCustomer")}
                />
              )}
              {user.isPointOfSales() && <IndustryCard
                name={route.params?.industry || ''}
                onPress={() => navigation.navigate("ChooseIndustry")}
              />}

              {(user.isRetailer() || isTradeManager) && <RetailterCard />}
              <FastAccessCard />
            </Stack>
            <View
              testID="products-tab-view"
              style={styles.containerProducts}
              alignSelf={"auto"}
              flex={1}
              alignItems={"normal"}
            >
              <TabNavigationProvider>
                <ProductsTabView dynamicTabs={dynamicTabs} />
              </TabNavigationProvider>
            </View>
          </Stack >
        </View >
      </LayoutStore >

      <ShoppingCartModal />
      <ModalCoupon activeRedeemCoupon={false} />
      <SideMenu open={sideMenu.visible} onClose={() => sideMenu.close()} />
    </>
  );
};
