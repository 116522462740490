import React from "react";
import { Box, IBoxProps, Text, View } from "native-base";
import helpers from "@helpers/NumberHelper";
import { ItemType } from "@types/CartType";

type InfoCommercialPolicyProductProps = IBoxProps & {
  item: ItemType
}

export const InfoCommercialPolicyProduct = ({ item, ...rest }: InfoCommercialPolicyProductProps) => {
  return (
    <Box {...rest}>
      <View
        flexDirection='row'
        justifyContent='space-between'
      >
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={"md"}
          color={'muted.800'}
          selectable
          _dark={{
            color: 'muted.400'
          }}
        >
          {item.product.description}
        </Text>
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={"md"}
          color={'muted.800'}
          _dark={{
            color: 'muted.400'
          }}
        >
          {item.amount} un.
        </Text>
      </View>
      <View
        flexDirection='row'
        justifyContent='space-between'
      >
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={"md"}
          color={'muted.700'}
          _dark={{
            color: 'muted.300'
          }}
          selectable
        >
          {item.product.code}
        </Text>
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={"md"}
          color={'muted.700'}
          _dark={{
            color: 'muted.300'
          }}
        >
          {"R$ " + helpers.currency(item.grossPrice, 2)}
        </Text>
      </View>
    </Box>
  );
};