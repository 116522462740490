import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "@components/Dimension";
import { THEME } from "@theme/index";

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 6,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  header: {
    padding: 15,
    paddingLeft: 20,
    border: "1px " + THEME.colors.green[400],
    borderTopStartRadius: 4,
    borderTopEndRadius: 4,
    color: THEME.colors.white,
    fontSize: RFValue(7),
    lineHeight: 4,
    fontWeight: "600",
    width: width * 0.2,    
    height:'auto'
  },
  body: {
    padding: 16,
    justifyContent: 'space-between',
  },
  button: {
    marginTop: 25,
    paddingHorizontal: 10,
  },
});
