import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';

type ManageCustomersResponsiveOverlayErrorProps = {
  type: 'WEB' | 'MOBILE'
}

export function ManageCustomersResponsiveOverlayError({
  type
}: ManageCustomersResponsiveOverlayErrorProps) {
  return (
    type === 'WEB' ? (
      <Web />
    ) : (
      <Mobile />
    )
  );
}