import React, { useState } from "react";
import { Button, View, Text, Popover } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";

import { width } from "@components/Dimension";
import { useCustomer } from "@contexts/CustomerContext";
import { CustomerByUserType } from "@types/CustomerByUserType";

import { styles } from "./styles";
import { THEME } from "../../theme";
import { TextHelper } from "@helpers/TextHelper";
import { filtersVar } from "@hooks/useHasCommercialPolicy";
import { useReactiveVar } from "@apollo/client";

export interface CustomerCardBodyProps {
  customer: CustomerByUserType | null;
}

function CustomerCardBody({ customer }: CustomerCardBodyProps) {
  return (
    <View>
      <CustomerCardItem
        label="Razão Social"
        text={customer?.companyName}
      />
      <CustomerCardItem
        label="CNPJ"
        text={new TextHelper(customer?.code || "").formatCNPJ()}
      />
      <CustomerCardItem
        label="Grupo de Clientes"
        text={customer?.group?.description}
      />
    </View>
  );
}
CustomerCardBody.displayName = "CustomerCard.Body";

export interface CustomerCardItemProps {
  label?: string;
  text?: string;
  children?: React.ReactNode;
  height?: number;
}

function CustomerCardItem(props: CustomerCardItemProps) {
  return (
    <>
      <Text
        fontSize="xs"
        marginTop={2}
        fontWeight={700}
        selectable
        color='muted.800'
        _dark={{
          color: 'muted.400'
        }}
      >
        {props.label || ""}
      </Text>
      {props?.text ? (
        <Text
          fontSize="xs"
          marginTop={0}
          fontWeight="medium"
          minHeight={props.height}
          selectable
          color='muted.800'
          _dark={{
            color: 'muted.400'
          }}
        >
          {props.text}
        </Text>
      ) : (
        props?.children
      )}
    </>
  );
}
CustomerCardItem.displayName = "CustomerCard.Item";

export interface CustomerPopoverItemProps {
  children?: React.ReactNode;
  code: string;
}
function CustomerPopoverItem(props: CustomerPopoverItemProps) {
  const { children, code } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { setCardSelected } = useCustomer();
  return (
    <Popover
      isOpen={isOpen}
      trigger={(trigger) => (
        <View
          alignItems={"flex-end"}
          {...trigger}
          onMouseLeave={() => {
            if (isOpen) {
              setIsOpen(false);
            }
          }}
        >
          <AntDesign
            onPress={() => {
              setIsOpen(!isOpen);
              setCardSelected(code);
              filtersVar({
                ...filtersVar(),
                customer: { code }
              })
            }}
            style={{
              fontSize: 10,
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: isOpen
                ? THEME.colors.green[400]
                : THEME.colors.gray[500],
              width: 20,
              height: 20,
              borderRadius: 50,
              marginBottom: 10,
              padding: 5,
            }}
            name={!isOpen ? "down" : "up"}
            size={20}
            color={THEME.colors.white}
          />
        </View>
      )}
      onClose={() => setIsOpen(false)}
      _overlay={{
        style: {
          height: 0,
          width: 0,
          backgroundColor: "rgba(0,0,0,0.15)",
        },
      }}
    >
      <Popover.Content
        w={width * 0.2}
        borderTopStyle={"none"}
        borderLeftRadius={"none"}
        borderRightRadius={"none"}
        borderBottomStyle={"solid"}
        shadow={5}
      >
        <Popover.Body
          overflow={"auto" as "scroll"}
          backgroundColor={THEME.colors.white}
        >
          {children}
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
}
CustomerPopoverItem.displayName = "CustomerCard.Popover";

export interface CustomerCardButtonsProps {
  children?: React.ReactNode;
}

function CustomerCardButtons({ children }: CustomerCardButtonsProps) {
  const navigation = useNavigation();

  return (
    <View style={styles.btnContainer}>
      <Button
        style={styles.btnFinish}
        onPress={() => {
          navigation.reset(
            {
              index: 0,
              routes: [{ name: 'ChooseCustomer' }],
            }
          );
        }}
      >
        <Text style={styles.btnFinishText} selectable>Trocar o Cliente</Text>
      </Button>
    </View>
  );
}
CustomerCardButtons.displayName = "CustomerCard.Buttons";

export const CustomerCard = {
  Body: CustomerCardBody,
  Buttons: CustomerCardButtons,
  Item: CustomerCardItem,
  Popover: CustomerPopoverItem,
};
