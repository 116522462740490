import React, { useEffect, useState } from "react";
import { View, Text, Center, Flex, IconButton, useMediaQuery } from 'native-base';
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import { useRoute } from "@react-navigation/native";

import { useProductTabNavigation } from "@components/ProductsTabView";
import * as TabNavigationConfig from "@configs/TabNavigationConfig";
import {
  refactorResult,
  useCommercialPolicyProductsLazy,
} from "@hooks/useCommercialPolicyProducts";
import { useCommercialPolicy } from "@hooks/useCommercialPolicy";
import { useAuth } from "@contexts/Auth";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { AssortmentInputType } from "@types/AssortmentInputType";
import { ProductInputType } from "@types/ProductInputType";
import { BrandInputType } from "@types/BrandInputType";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import { Config } from "@configs/Config";
import { List } from "@components/List";
import { ProductCard } from "@components/ProductCard";
import { DiscountRateBanner } from "../DiscountRateBanner";
import { DiscountRateSmallBanner } from "../DiscountRateSmallBanner";
import { useReactiveVar } from "@apollo/client";
import { searchFilterVar } from "@components/FastAccessCard/FastAccessCard";
import { styles } from "./styles";
import { useFilter } from "@contexts/FilterContext";
import { FilterIconMobile, fastAccessModalVar } from "@components/FastAccessCard/FilterIconMobile";
import { TagAlertOutdatedPrice } from "@components/TagAlertOutdatedPrice";

let totalOffersPerPage = 18;
const totalOffersPerPageMobile = 4;


if (Config.TOTAL_PRODUCTS_EXHIBITED) {
  totalOffersPerPage = parseInt(Config.TOTAL_PRODUCTS_EXHIBITED);
}

export type RouteParams = {
  commercialPolicyId: number
  brandId: number
};

export const OffersByBrandProducts = () => {
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<CommercialPolicyProductsType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  const [isReloading, setIsReloading] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [textFilter, setTextFilter] = useState("");
  const fastAccessFilter = useReactiveVar(searchFilterVar);
  const { user } = useAuth();
  const route = useRoute();
  const { setCurrentTabView } = useFilter();

  let { tabParams, tabNavigation, setTabNavigation } = useProductTabNavigation();

  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 768,
    },
  ]);

  if (isSmallScreen) {
    totalOffersPerPage = totalOffersPerPageMobile;
  }

  const numColumns = isSmallScreen ? 2 : 3

  const nameIndustry = user?.getNameIndustryStore();
  const params = route.params as RouteParams;
  if (params?.commercialPolicyId) {
    tabParams = params;
  }
  const brandId = tabParams?.brand?.id ?? params?.brandId
  const commercialPolicyId = tabParams?.commercialPolicyId ?? 0
  const [query, { loading, error, refetch }] = useCommercialPolicyProductsLazy({
    limit: totalOffersPerPage,
    offset: 0,
    product: {
      brand: {
        ids: [brandId || 0],
      },
    },
    commercialPolicyId: commercialPolicyId,
    fastAccessFilter
  });

  fastAccessModalVar(false)

  useEffect(() => {
    updateData(0, textFilter);
  }, [fastAccessFilter])

  useEffect(() => {
    (async () => {
      setFirst(false);
      const { data } = await query();
      setTotal(data.commercialPolicyProducts?.pageInfo.totalCount);
      result = refactorResult(
        data.commercialPolicyProducts,
        nameIndustry,
        tabParams?.commercialPolicyId
      );
      setProducts(result);
      setIsListEnd(!data.commercialPolicyProducts?.pageInfo.hasNextPage)
    })();

  }, [fastAccessFilter, first])

  const {
    data: commercialPolicyData,
    loading: loadingBanner
  } = useCommercialPolicy({
    id: commercialPolicyId,
    brandId: Number(brandId) || 0
  });

  const isDiscountRateBannerActive = commercialPolicyData?.commercialPolicy?.discountsBrand?.length
  let result: CommercialPolicyProductsType[] = [];

  async function updateData(newOffset: number, searchText: string) {
    setIsReloading(true)

    let assortment: AssortmentInputType = {
      ids: fastAccessFilter?.assortment
    }

    if (assortment.ids?.length === 0) {
      assortment = {
        ids: user.getAssortments().map((assortment) => Number(assortment.id)),
      };
    }

    const brand: BrandInputType = {
      ids: [brandId || 0],
    };

    let productDivision: AssortmentInputType = {
      ids: fastAccessFilter?.division_product
    };

    const filter: ProductInputType = {
      filter: searchText,
      assortment,
      brand,
      productDivision
    };

    const { data: updatedProductsData } = await refetch({
      product: filter,
      offset: newOffset,
      limit: totalOffersPerPage,
    });

    const resultRefactor = refactorResult(
      updatedProductsData.commercialPolicyProducts,
      nameIndustry
    );

    setIsListEnd(!updatedProductsData.commercialPolicyProducts.pageInfo.hasNextPage)
    if (isSmallScreen && newOffset != 0) {
      setProducts(prev => [...prev, ...resultRefactor])
    } else {
      setProducts(resultRefactor);
    }
    setIsReloading(false)
    return resultRefactor;
  }

  function setTabViewInFocus(tabView: string) {
    setCurrentTabView(tabView)
  }

  setTabViewInFocus('offersByBrandProducts');

  const renderBanner = () => {
    if (!isDiscountRateBannerActive) {
      return null;
    }

    if (isSmallScreen) {
      return (
        <View height={'auto'}>
          <DiscountRateSmallBanner
            discountsRange={
              commercialPolicyData?.commercialPolicy?.discountsBrand || []
            }
            endValidityDate={
              commercialPolicyData?.commercialPolicy?.endValidityDate || ''
            }
          />
          <Text
            py={2}
            pl={2}
            color={'muted.800'}
            _dark={{
              color: "muted.400"
            }}
            fontWeight={'500'}
            fontSize={'md'}
          >
            {commercialPolicyData?.commercialPolicy?.discountsBrand[0]?.brand?.description}
          </Text>
          <View h={40} w={'full'}><FilterIconMobile /></View>
        </View>
      )
    }

    return (
      <DiscountRateBanner
        discountsRange={
          commercialPolicyData?.commercialPolicy?.discountsBrand || []
        }
        endValidityDate={
          commercialPolicyData?.commercialPolicy?.endValidityDate || ''
        }
      />
    )
  }

  return (
    <View flex={1} mt={6}>
      <List
        infinitePagination={isSmallScreen}
        showPagination={!isSmallScreen}
        data={products}
        loading={loading && first}
        error={error}
        isListEnd={isListEnd}
        numColumns={numColumns}
        total={total}
        loadingRefetch={isReloading}
        pageCount={totalOffersPerPage}
        showsHorizontalScrollIndicator={false}
        isDefault={false}
        _searchBar={{
          variant: isSmallScreen ? 'outline' : ''
        }}
        containerTitle={
          isSmallScreen ? <></> : <Flex direction={"column"}>
            <Flex direction={"row"}>
              <TouchableOpacity>
                <IconButton
                  style={{ padding: 0, marginRight: 10 }}
                  icon={
                    <Center
                      borderRadius={100}
                      size={8}
                      bgColor={'gray.300'}
                    >
                      <FontAwesome name="angle-left" size={24} color="black" />
                    </Center>
                  }
                  onPress={() => {
                    const index = tabNavigation.index;
                    const routes = TabNavigationConfig.activeRoutes;
                    setTabViewInFocus('offersByBrands');
                    setTabNavigation({ routes, index });
                  }}
                ></IconButton>
              </TouchableOpacity>
              <Text style={styles.title}>Produtos disponíveis:</Text>
            </Flex>
            <Text style={styles.subTitle}>
              Ofertas por marcas: {
                commercialPolicyData?.commercialPolicy?.discountsBrand[0]?.brand?.description
              }{" "}
            </Text>
          </Flex>
        }
        banner={<>{renderBanner()}</>}
        info={<TagAlertOutdatedPrice />}
        loadingBanner={loadingBanner}
        keyExtractor={(item) => item.product.code}
        columnWrapperStyle={{
          justifyContent: isSmallScreen ? "space-between" : "flex-start",
          marginBottom: 20
        }}
        contentContainerStyle={{
          justifyContent: "space-around",
        }}
        renderItem={({ item }) => (
          <View
            flex={1}
            maxW={['46%', '46%', '46%', '33%']}
            alignItems={{ base: "center", xl: "baseline" }}
          >
            <ProductCard
              product={item}
              tab={CartProductTypeEnum.BRAND_OFFER}
            />
          </View>
        )}
        refetch={(offset, text) => {
          setTextFilter(text)
          return updateData(offset, text);
        }}
      />
    </View>
  );
};
