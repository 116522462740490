import { gql, makeVar, useQuery } from "@apollo/client";

const PROMOTIONAL_ACTION = gql`
  query (
    $name: String!
 ) {
    CMS_promotionalActions(
      filters: {
        name: {
          eq: $name
        }
      }
    ) {
      data {
        attributes {
          productEans
          origins
        }
      }
    }
  }
`;

interface Filter {
  name: string
  skip: boolean
}

export interface PromotionalFilterEansInterface {
  eans: string[] | undefined,
  origins: string[] | undefined
}

const initialState: PromotionalFilterEansInterface = {
  eans: [],
  origins: []
}

export const promotionalActionEansVar = makeVar<PromotionalFilterEansInterface>(initialState);

export const usePromotionalAction = ({ name, skip }: Filter) => {
  return useQuery(PROMOTIONAL_ACTION, {
    skip,
    fetchPolicy: "cache-first",
    variables: {
      name: name,
    },
    onCompleted(data) {
      if (data?.CMS_promotionalActions?.data) {
        const promo = data.CMS_promotionalActions.data
        let eans: string[] = promo[0]?.attributes.productEans.split(',') ?? [];
        let origins: string[] = promo[0]?.attributes.origins || [];
        eans = eans.map((ean) => ean.trim())
        promotionalActionEansVar({
          'eans': eans,
          'origins': origins
        })
      }
    },
  });
}

