import { gql, makeVar, useLazyQuery, useQuery } from "@apollo/client";
import { format } from "date-fns";

import {
  CouponProps,
  CouponsType,
  ProductsRuleProps,
  ProductType,
  RulesProps
} from "../types/CouponsType";
import { useAuth } from "../contexts/Auth";
import helpers from "../helpers/NumberHelper";
import { useCustomer } from "../contexts/CustomerContext";
import { Config } from "@configs/Config";

const USE_CUSTOMER_COUPONS = gql`
  query ($filter: Kupono_CustomerCouponFilter) {
    Kupono_customerCoupons(customerCouponsFilter: $filter) {
      id
      coupon {
        name
        discountPercentage
        maxValue
        isAcumulative
        description
        demandTargetPeriod
				minCheckoutVolume
        utilizationLimit
				endDate
        isProductDiscount
        products {
          ean
          minimumAmount
          limitAmount
          enforced
          discountProductPercentage
        }
      }
    }
  }
`;

export const useCustomerCouponsLazy = () => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getNameIndustryStore();

  return useLazyQuery(USE_CUSTOMER_COUPONS, {
    variables: {
      filter: {
        customerCode,
        onlyAvailable: true,
        industry: industryCode
      },
    },
  });
};


export const couponsVar = makeVar<CouponsType[]>([])
export const useCustomerCoupons = () => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getNameIndustryStore();

  return useQuery(USE_CUSTOMER_COUPONS, {
    fetchPolicy: 'cache-first',
    skip: !Config.FT_KUPONO_ENABLED,
    variables: {
      filter: {
        customerCode,
        onlyAvailable: true,
        industry: industryCode
      },
    },
    onCompleted(data) {
      if (data?.Kupono_customerCoupons) {
        couponsVar(refactorResult(data?.Kupono_customerCoupons))
      }
    },
  });
};

export const refactorResult = (coupons: CouponsType[]) => {
  const filteredCoupons = coupons.filter(item => !item.coupon.demandTargetPeriod);

  return filteredCoupons.map((item: CouponsType) => ({
    ...item,
    rules: setCouponRules(item.coupon),
    applied: false
  }));
};

const setCouponRules = (coupon: CouponProps) => {
  const formattedEndDate = format(new Date(coupon.endDate), "dd/MM/yyyy");

  let rules: RulesProps = {
    endDate: `Expira em ${formattedEndDate}`,
    products: [],
  };

  rules.products = coupon.products.length > 0 ? setProductRules(coupon.products) : [];

  if (coupon.maxValue && coupon.maxValue > 0) {
    rules.maxValue = `Desconto máximo de até R$  ${helpers.currency(coupon.maxValue, 2)}`;
  }

  if (coupon.minCheckoutVolume && +coupon.minCheckoutVolume > 0) {
    rules.minCheckoutVolume = `Escolha no mínimo ${coupon.minCheckoutVolume} itens para habilitar este cupom`
  }

  if (!coupon.isProductDiscount) {
    rules.discountPercentage = `Até ${coupon.discountPercentage}% de desconto`;
  }

  return rules;
}

const setProductRules = (products: ProductType[]) => {
  let rules: ProductsRuleProps[] = [];

  const sortedProducts = [...products].sort(
    (a: ProductType, b: ProductType) => +b.enforced - +a.enforced
  );

  sortedProducts.forEach((product: ProductType) => {
    rules.push({
      ean: product.ean,
      minimumAmount: product.minimumAmount,
      isEnforced: product.enforced,
      discountProductPercentage: product.discountProductPercentage,
    });
  });

  return rules;
}