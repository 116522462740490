import { useEffect, useState } from "react";
import { WholesalerBranchesType } from "../types/WholesalerBranchesType";
import { 
  useGetCustomerCommercialPolicyShippingOrder, 
  refactorStatus,
  useGetCustomerCommercialPolicyShippingOrderLazy
} from "./useGetCustomerCommercialPolicyShippingOrder";

interface SearchWholesalers {
  text: string
}

export const useSearchWholesalers = (searchWholesalers: SearchWholesalers) => {
  const { text } = searchWholesalers;
  const { data, error, loading } = useGetCustomerCommercialPolicyShippingOrder();
  const [wholesalerList, setWholesalerList] = useState<WholesalerBranchesType[]>([]);
  const [wholesalerSearchList, setWholesalerSearchList] = useState<WholesalerBranchesType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  
  if (data && first) {
    const result = refactorStatus(data.getCustomerCommercialPolicyShippingOrder);
    setWholesalerList(result);
    setWholesalerSearchList(result);
    setFirst(false);
  }

  const search = async () => {
    const result = wholesalerList.filter(wholesaler => {
      return wholesaler.description.toLowerCase().indexOf(text.toLowerCase()) >= 0
      || !isNaN(parseInt(text)) && wholesaler.code.indexOf(text.repeat(1).replace(/[^\d]/g, '')) >= 0
    });

    return setWholesalerSearchList(refactorStatus(result, true));
  }

  useEffect(() => {
    search();
  }, [text]);

  return { wholesalerSearchList, error, loading }
};

export const useSearchWholesalersLazy = (searchWholesalers: SearchWholesalers) => {
  const { text } = searchWholesalers;  
  const [queryWholesaler, { data, error, loading}] = useGetCustomerCommercialPolicyShippingOrderLazy();  
  const [wholesalerList, setWholesalerList] = useState<WholesalerBranchesType[]>([]);
  const [wholesalerSearchList, setWholesalerSearchList] = useState<WholesalerBranchesType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  
  if (data && first) {
    const result = refactorStatus(data.getCustomerCommercialPolicyShippingOrder);
    setWholesalerList(result);
    setWholesalerSearchList(result);
    setFirst(false)
  }

  const search = async () => {
    const result = wholesalerList.filter(
      wholesaler => wholesaler.description.toLowerCase().indexOf(text.toLowerCase()) >= 0
    );

    if (text.length > 0) {
      return setWholesalerSearchList(refactorStatus(result, true));
    }
    return setWholesalerSearchList(refactorStatus(result, true));
  }

  useEffect(() => {
    search();
  }, [text]);

  return {queryWholesaler, wholesalerSearchList, error, loading }
};