import { gql, useLazyQuery } from "@apollo/client";
import { BannerType } from '@types/BannerType';
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";

const BANNERS = gql`
  query (
    $industry: String!
    $userProfile: String!
  ) {
    CMS_banners(
      filters: {
        user_profile: {
          eq: $userProfile
        }
        industry: {
          code: {
            eq: $industry
          }
        }
      }
      sort: ["publishedAt:desc"]
      pagination: {
        page: 0
        pageSize: 5
      }
    ) {
      data {
        attributes {
          description
          publishedAt
          link
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

interface Filters {
  industry: string
  userProfile: 'POINT_OF_SALES_BUYER' | 'INDUSTRY_EMPLOYEE'
}

interface Error {
  message: string | undefined
}

const useBannersQuery = ({ industry, userProfile }: Filters) => {

  return useLazyQuery(BANNERS, {
    variables: {
      industry,
      userProfile
    },
  });
};

export const useBanners = () => {
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const { impersonate } = useCustomer();

  let userProfileFilter: 'POINT_OF_SALES_BUYER' | 'INDUSTRY_EMPLOYEE' = user.isPointOfSales() ? "POINT_OF_SALES_BUYER" : 'INDUSTRY_EMPLOYEE';
  if (impersonate && user.isUserAllowedToImpersonate()) {
    userProfileFilter = 'POINT_OF_SALES_BUYER';
  }

  const [query] = useBannersQuery({
    industry,
    userProfile: userProfileFilter
  });
  const [banners, setBanners] = useState<BannerType[]>([]);

  const loadData = async () => {
    const { data, loading, error } = await query();

    if (error) {
      setError(error);
    }

    if (!data) {
      setLoading(false);
    }

    if (data) {

      const bannersRefetch: BannerType[] = data.CMS_banners.data.map((banner: any) => {
        return {
          description: banner.attributes.description,
          url: banner.attributes.image?.data?.attributes?.url,
          link: banner.attributes?.link
        }
      }).filter((banner: BannerType) => !!banner.url);

      setLoading(loading);
      setBanners(bannersRefetch);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return { banners, error, loading }
};

