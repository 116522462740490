import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { height, width } from "../../components/Dimension";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#E5E5E5",
    padding: width * 0.034,
  },
  containerBox: {
    width: width * 0.9,
    backgroundColor: "#FFFFFF",
    padding: width * 0.023, // 30,
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: width * 0.014,
  },
  title: {
    
    fontWeight: "500",
    fontSize: RFValue(16),
    color: "#1C1C1C",
  },
  subtitle: {

    fontWeight: "400",
    fontSize: RFValue(8),
    color: "#686868",
    lineHeight: width * 0.014,
  },
  paginationButton: {
    borderRadius: Math.round(width + height) / 2,
    backgroundColor: "#DADADA",
    width: width * 0.01,
    height: width * 0.01,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationButtonActive: {
    backgroundColor: "#74BF48",
  },
  textDescription: {

    fontWeight: "400",
    fontSize: RFValue(8),
    color: "#686868",
    marginBottom: width * 0.007, // 10,
  },
});