import React, { useEffect } from 'react';
import Alert, { useRefAlert, Props } from '@components/Alert';
import { useAddListItemExistsAlert } from '@hooks/useAddListItemExistsAlert';

export const AlertListItemExist = () => {
  const add = useAddListItemExistsAlert();
  const dialog = React.useRef<useRefAlert>();

  useEffect(() => {
    add.setRef(dialog);
  }, []);

  const params: Props = {
    headerText: "Item da lista já adicionado",
    bodyContent: add.text,
    btnTextConfirm: "Substituir",
    showBtnCancel: true,
    onConfirmCallback: () => {
      add.onSuccessCallback();
      add.onClose();
    },
    onCloseCallback: () => {
      add.onClose();
    },
  };

  return <Alert ref={dialog} {...params} />;
};
