import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { styles as ListStyles } from "@components/List/styles"
import { THEME } from "../../theme";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  title: {
    fontWeight: ListStyles.title.fontWeight,  
    alignSelf: ListStyles.title.alignSelf,
    fontSize: ListStyles.title.fontSize
  },
  iconListTitle: {
    backgroundColor: THEME.colors.gray[300],
    borderRadius: 100,
    size: 8
  },
  subTitle: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(7),
    fontWeight: "600",
    color: THEME.colors.gray[800],
    marginBottom: width * 0.01
  },
  summaryTotal: {
    fontWeight: "normal",
    color: THEME.colors.orange["200"]
  },
  btn: { 
    color: "#FFF",
    backgroundColor: "primary.600"
  },
  btn_hover: { 
    backgroundColor: "primary.700"
  },
  font: {
    fontSize: RFValue(5),
    color: "muted.800"
  },
  fontDark: {
    color: 'muted.300'    
  },
  listContent: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(8),
    fontWeight: "600",
    color: THEME.colors.gray["600"],
    marginTop: 8,
  },
  ListTopLine: {
    borderTopWidth: 3,
    borderTopColor: THEME.colors.green[300],    
    width: "100%"
  },
  listCenter: {
    alignItems: "flex-start",    
  }
});
