import React from "react";
import { IInputProps, Input as NativeBaseInput } from "native-base";

export function Input({ ...rest }: IInputProps) {
  return (
    <NativeBaseInput
      w={"full"}
      borderWidth={0.25}
      borderRadius={1}
      size="md"
      keyboardType="default"
      fontStyle={"normal"}
      _light={{
        color: "muted.800"
      }}
      _dark={{
        color: 'muted.400',
        textDecorationColor: 'muted.400',
        colorScheme: 'muted.400'
      }}
      placeholderTextColor={"muted.600"}
      borderColor={"muted.400"}
      bgColor={"muted.200"}
      {...rest}
    />
  )
}