import { gql, makeVar, useQuery } from "@apollo/client";
import { Config } from "@configs/Config";
import { IndustryDataType } from "@types/IndustryDataType";

const QUERY = gql`
  query (
    $code: String!
  ) {
    industries(
      name: $code
    ) {
      name
      industryCode
      isEnabled
    }
  }
`;


export const industryVar = makeVar<IndustryDataType | undefined>(undefined);

interface Input {
  industry: string
  skip?: boolean
}

export const useIndustryCpm = ({ industry, skip = false }: Input) => {
  return useQuery(QUERY, {
    skip,
    fetchPolicy: "cache-first",
    variables: {
      code: industry,
    },
    onCompleted(data) {
      if (data?.industries.length > 0) {
        const industry = data.industries[0]
        industryVar(refactor(industry))
      }
    },
  });
};


const refactor = (item: any) => {

  let url = `#`;
  url = `${Config.URL_CDN_BASE
    }/${item.name.toUpperCase()}/logo.jpg`;

  const industry = item.industryCode;
  const nameIndustry = item.name;

  return {
    url: url,
    id: 0,
    industry: industry,
    nameIndustry: nameIndustry,
  };
}