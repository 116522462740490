import React from "react";
import { ITextProps, Text } from "native-base";

export type TitleProps = ITextProps & {
  children?: React.ReactNode
}

export const Title = ({ children, ...rest }: TitleProps) => {
  return (
    <Text
      fontSize={16}
      fontWeight={600}
      lineHeight={18}
      color={'muted.800'}
      _dark={{
        color: 'muted.400'
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};