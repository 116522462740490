import React from "react";
import { Popover as BasePopover, View } from "native-base";
import { styles } from "@components/CardWholesalerBranch/styles";

export const DropDownPaymentTerms = ({
  isOpen,
  setIsOpen,
  children,
  trigger,
  header,
  width
}: {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  children: React.ReactNode;
  header?: JSX.Element | undefined;
  trigger: (
    _props: any,
    state: {
      open: boolean;
    }
  ) => JSX.Element;
  width: number
}) => {
  const hasHeader:Boolean =  header ? true : false

  return (
    <View>
      <BasePopover
        isOpen={isOpen}
        trigger={trigger}
        onClose={() => setIsOpen(false)}
      >
        <BasePopover.Content w={width} >
          {hasHeader && (
            <BasePopover.Header style={styles.DropDownPaymentTerms}>
              {header}
            </BasePopover.Header>
          )}

          <BasePopover.Body zIndex={1}>
            {children}
          </BasePopover.Body>
        </BasePopover.Content>
      </BasePopover>
    </View>
  );
};
