import React from "react";
import {
  createNativeStackNavigator
} from "@react-navigation/native-stack";
import { ChooseIndustry } from "../screens/ChooseIndustry";
import { PreferredWholesaler } from "../screens/PreferredWholesaler";

import { HeaderBar } from "@components/HeaderBar";

const AppStack = createNativeStackNavigator();

const PointOfSalesRoute = () => (
  <>
    <AppStack.Screen
      name="ChooseIndustry"
      component={ChooseIndustry}
      options={{ headerShown: false, title: "Tela das indústrias" }}
    />
    <AppStack.Screen
      name="PreferredWholesaler"
      component={PreferredWholesaler}
      options={{
        headerShown: true,
        title: "Distribuidores preferenciais",
        header: (props) => <HeaderBar isDefaultHeader {...props} />
      }}
    />
  </>
);

export default PointOfSalesRoute;
