import React from "react";
import { Image } from "react-native";
import { View, Text, Center } from "native-base";
import helpers from "@helpers/NumberHelper";
import { CommercialPolicyProductsType } from "../../../../types/CommercialPolicyProductsType";
import { styles } from "./styles";
import { ItemType } from "@types/CartType";

type Props = {
  product: ItemType;
};

export function ProductCart({ product }: Props) {

  return (
    <View style={styles.card}>
      <View>
        <Image
          style={styles.image}
          source={{ uri: product.product.url }}
          defaultSource={require("../../../../../assets/images/notfound.jpg")}
        />
        {(product?.totalDiscount || 0) > 0 && (
          <Center
            borderRadius={4}
            bg="orange.500"
            _dark={{
              bg: "orange.500",
            }}
            position="absolute"
            top={2}
            right={2}
            p={1}
          >
            <Text fontSize={["2xs", "xs"]} fontWeight="700" color="muted.100">
              {product?.totalDiscount || 0}% OFF
            </Text>
          </Center>
        )}
      </View>

      <View style={styles.containerSide}>
        <View style={styles.containerTextProduct}>
          <Text style={styles.textProduct}>{product.product.description}</Text>
          <Text
            lineHeight={"md"}
            fontSize={["xs", "xs", "xs", "sm"]}
            color={"muted.800"}
            _dark={{
              color: "muted.400",
            }}
            fontStyle={"normal"}
            fontWeight={"normal"}
            selectable
          >
            {product.product.code}
          </Text>
        </View>
        <View style={styles.containerSideBottom}>
          <View style={styles.containerPriceProduct}>
            <View style={styles.priceRow}>
              {product?.totalDiscount > 0 ? (
                <>
                  <Text
                    style={styles.textNetPriceProduct}
                    textDecorationLine="line-through"
                  >
                    R$ {helpers.currency(product.unitGrossPrice, 2)}
                  </Text>
                  <Text style={styles.textNetPriceProduct}>
                    R$ {helpers.currency(product.unitNetPrice, 2)}
                  </Text>
                </>
              ) : (
                <>
                  <Text style={styles.textNetPriceProduct}>
                    R$ {helpers.currency(product.unitNetPrice, 2)}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View>
            <View alignSelf={"end"}>
              <Text
                color={"muted.700"}
                _dark={{ color: "muted.400" }}
                fontSize={"xs"}
                fontWeight="400"
              >
                {" "}
                Quantidade{" "}
              </Text>
              <Text
                fontWeight={700}
                color={"muted.900"}
                _dark={{
                  color: "muted.300",
                }}
                fontSize={"sm"}
              >
                {product?.amount} unidades
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
