import { gql, makeVar, useQuery } from "@apollo/client";


export type SettingsByUserType = {
  useHoursRestriction: any
}

const LOAD_USER_DATA = gql`
  query (
    $industryCode: String
  ) {
    Retail_settingsByUser(
      filter: { industry: { code: $industryCode } }
    ) {
      useHoursRestriction
    }
  }
`;

const defaultSetting = { useHoursRestriction: false }

export const settingsByUser = makeVar<SettingsByUserType>(defaultSetting);

export const useSettingsByUser = (
  industryCode: string,
  skip: boolean = false,
) => {
  return useQuery(LOAD_USER_DATA, {
    variables: {
      industryCode
    },
    fetchPolicy: "network-only",
    skip: skip,
    onCompleted: async (data) => {
      const value = data.Retail_settingsByUser ?? defaultSetting;
      settingsByUser(value);
    }
  });
};
