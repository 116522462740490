import { useColorMode, useTheme } from "native-base";
import React from "react";
import { ViewStyle } from "react-native";
import Modal from "react-native-modal";

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  side?: string;
  animationInTiming?: number;
  animationOutTiming?: number;
  coverScreen?: boolean;
  close: () => void;
  style?: ViewStyle
}

export const SideModal = (props: Props) => {
  const {
    children,
    isVisible,
    side,
    animationInTiming,
    animationOutTiming,
    coverScreen,
    close,
    style = {}
  } = props;

  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  let defaultStyle = {
    padding: 0,
    margin: 0,
    overflow: "hidden",
    alignSelf: side === "right" ? "flex-end" : "flex-start",
    backgroundColor: colorMode == "dark" ? colors.gray[700] : colors.white,
  } as ViewStyle

  const styleComponent = { ...defaultStyle, ...style };

  return (
    <Modal
      animationIn={side == "right" ? "slideInRight" : "slideInLeft"}
      animationOut={side == "right" ? "slideOutRight" : "slideOutLeft"}
      onBackdropPress={close}
      backdropOpacity={0.4}
      coverScreen={coverScreen ? true : false}
      isVisible={isVisible}
      animationInTiming={animationInTiming ? animationInTiming : 300}
      animationOutTiming={animationOutTiming ? animationOutTiming : 300}
      style={styleComponent}
    >
      {children}
    </Modal>
  );
};
