import { StyleSheet } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';

import { width } from '@components/Dimension';
import { THEME } from "@theme/index";

export const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    marginHorizontal: width * 0.1,
  },
  btnWholesaler: {
    width: '100%',
    marginTop: 16,
    padding: width * 0.007, // 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: THEME.colors.green[300],
    backgroundColor: THEME.colors.green[300],
    color: THEME.colors.white,
  },
  btnWholesalerText: {
    fontSize: RFValue(7),
    color: THEME.colors.white,
    fontFamily: THEME.fonts["heading"],
  },
  disabledButton: {
    backgroundColor: THEME.colors.gray[500],
    borderColor: THEME.colors.gray[500],
  },
  customSearchBar: {
    marginBottom: 15
  },
  mb1: {
    marginTop: 16,
    marginBottom: 8,
  },
  mr1: {
    marginRight: 5
  }
});
