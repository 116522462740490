import React from "react";
import { Box, IBoxProps } from "native-base";
import { useAuth } from "@contexts/Auth";
import { IndustryCard } from "./IndustryCard";
import { CustomerCard } from "./CustomerCard";
import { RetailCard } from "./RetailCard";

type CartTargetCardProps = IBoxProps & {};

export const CartTargetCard: React.FC<CartTargetCardProps> = ({
  ...restPros
}) => {
  const { user } = useAuth();

  return (
    <Box
      h={"auto"}
      width={"full"}
      _dark={{
        borderColor: "coolGray.600",
        backgroundColor: "gray.700",
      }}
      py={3}
      alignItems={"center"}
      flexDir={'row'}
      maxH={"32"}
      {...restPros}
    >
      {user.isPointOfSales() && <IndustryCard user={user} pl={2} />}
      {user.isIndustryEmployee() && <CustomerCard user={user} pl={2} />}
      {user.isRetailer() && <RetailCard user={user} />}
    </Box>
  );
};
