import { useAuth } from "../contexts/Auth";
import { gql, makeVar, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useCustomer } from "../contexts/CustomerContext";
import { CartType } from "@types/CartType";
import { ProductExtraDiscountType } from "@types/ProductExtraDiscountType";
import { CouponsType } from "@types/CouponsType";
import { couponsVar } from "./useCustomerCoupons";
import { CouponDiscountsType } from "@types/CouponDiscountsType";
import { Config } from "@configs/Config";

export const USE_COUPONS_DISCOUNTS = gql`
  query CouponsDiscounts(
    $customerCode: String
    $coupons: [ID]
    $products: [Kupono_productDetailsInput]
    $industry: String
  ) {
    Kupono_couponsDiscounts(
      customerCode: $customerCode
      coupons: $coupons
      industry: $industry
      products: $products
    ) {
      ean
      couponsDiscounts {
        couponName
        value
        discount
      }
    }
  }
`;

export const couponsDiscountsVar = makeVar<ProductExtraDiscountType[]>([])
export const couponsActiveVar = makeVar<string[]>([])

export const useCouponsDiscounts = (
  cart: CartType | undefined,
  skip: boolean = false,
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getNameIndustryStore();
  let coupons = useReactiveVar(couponsVar);
  const couponsAppliedName = cart?.coupons ?? []

  const products = cart?.items.map(
    (item) => {
      return {
        ean: item.code,
        unitPrice: item.unitGrossPrice,
        amount: item.amount,
        discount: item.discount,
      };
    }
  )
  const couponNotApplied = coupons.filter(coupon => !coupon.applied).map(coupon => coupon.coupon.name)
  return useQuery(USE_COUPONS_DISCOUNTS, {
    skip: skip || !Config.FT_KUPONO_ENABLED || couponNotApplied.length === 0,
    nextFetchPolicy: 'cache-first',
    variables: {
      customerCode,
      industry: industryCode,
      coupons: couponNotApplied,
      products
    },
    onCompleted(data) {
      if (data?.Kupono_couponsDiscounts) {
        couponsDiscountsVar(data?.Kupono_couponsDiscounts)
        const couponsNameValid: string[] = [];
        data?.Kupono_couponsDiscounts.map((productDiscount: any) => {
          productDiscount.couponsDiscounts.map((couponDiscount: CouponDiscountsType) => {
            let name: string = couponDiscount.couponName;
            if (couponDiscount.discount > 0) {
              couponsNameValid.push(name)

            }
          });
        });

        coupons = coupons.map((coupon: CouponsType) => {
          coupon.active = false;
          if (couponsNameValid.includes(coupon.coupon.name)) {
            coupon.active = true;
          }
          return coupon
        });

        const couponMarkApplied = coupons.map(coupon => {
          if (couponsAppliedName.includes(coupon.coupon.name)) {
            coupon.applied = true;
          } else {
            coupon.applied = false;

          }
          return coupon
        })

        couponsVar(couponMarkApplied)
      }
    },
  },
  );
};

export const useCouponsDiscountsLazy = (
  cart: CartType | undefined,
  skip: boolean = false,
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryCode = user?.getNameIndustryStore();
  let coupons = useReactiveVar(couponsVar);
  const couponsAppliedName = cart?.coupons ?? []

  const products = cart?.items.map(
    (item) => {
      return {
        ean: item.code,
        unitPrice: item.unitGrossPrice,
        amount: item.amount,
        discount: item.discount,
      };
    }
  )
  const couponNotApplied = coupons.filter(coupon => !coupon.applied).map(coupon => coupon.coupon.name)
  return useLazyQuery(USE_COUPONS_DISCOUNTS, {
    nextFetchPolicy: 'cache-first',
    variables: {
      customerCode,
      industry: industryCode,
      coupons: couponNotApplied,
      products
    },
    onCompleted(data) {
      if (data?.Kupono_couponsDiscounts) {
        couponsDiscountsVar(data?.Kupono_couponsDiscounts)
        const couponsNameValid: string[] = [];
        data?.Kupono_couponsDiscounts.map((productDiscount: any) => {
          productDiscount.couponsDiscounts.map((couponDiscount: CouponDiscountsType) => {
            let name: string = couponDiscount.couponName;
            if (couponDiscount.discount > 0) {
              couponsNameValid.push(name)

            }
          });
        });

        couponsActiveVar(couponsNameValid);
      }
    },
  },
  );
};
