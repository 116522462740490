import React from "react";
import { View } from "native-base";
import { IconButton } from "react-native-paper";

interface PropInterface {
  close: Function;
  size: number;
  color: string;
}

export const CloseButtonCircle = (props: PropInterface) => {
  const { close, size, color } = props;

  const h = (size + 8) / 4;
  const w = (size + 8) / 4;
  
  return (
    <View
      borderRadius={50}
      backgroundColor={"muted.200"}
      w={w}
      h={h}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <IconButton
        testID="menuProfile:BtnClose"
        icon={"close"}
        color={color}
        centered={true}
        size={size}
        onPress={() => {
          close();
        }}
      />
    </View>
  );
};
