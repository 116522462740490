import React, { createContext, useState, useContext, Dispatch, SetStateAction, useMemo } from "react";

interface FilterContextData {  
  getFilters<S = undefined>(): S | undefined;
  addFilters(newFilters: any): void;  
  currentTabView?: string,
  setCurrentTabView?: any;
}

export const FilterContext = createContext<FilterContextData>(
  {} as FilterContextData
);

export const FilterProvider: React.FC = ({ children }) => {    
  const [currentTabView, setCurrentTabView] = useState('productList');

  const [filters, setFilters] = useState<any>({});

  function getFilters() {
    return filters;
  }

  function addFilters(newFilters: any) {
    setFilters(
      {
        ...filters,
        ...newFilters,
      }
    )
  }

  const value = useMemo(() => ({
    currentTabView,
    setCurrentTabView,
    getFilters,
    addFilters
  }), [filters, currentTabView]);

  return (
    <FilterContext.Provider
      value={value}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  return context;
};
