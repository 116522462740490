import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { height, width } from "../../Dimension";

export const styles = StyleSheet.create({
  modalStyle: {
    alignItems: 'center'
  },
  container: {
    width: width * 0.35,
    backgroundColor: "#FFFFFF",
    shadowColor: "#000000",
    shadowOpacity: 0.2,
    shadowRadius: 10,
    padding: width * 0.011,
    top: 0,
    maxHeight: width * 0.35,
  },
  containerHeader: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  containerCoupons: {
    position: 'relative'
  },
  title: {
    color: "#484848",
    fontSize: RFValue(10),

    fontWeight: "500",
    marginLeft: width * 0.003
  },
  couponName: {
    color: "#000000",
    fontSize: RFValue(6),
    
    fontWeight: "600",
    textAlign: 'center',
    marginTop: width * 0.011
  },
  couponDescription: {
    color: "#484848",
    fontSize: RFValue(5),

    fontWeight: "500",
    marginTop: 13,
    textAlign: 'center'
  },
  closeButtonCircle: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: Math.round(width + height) / 2,
    width: width * 0.015,
    height: width * 0.015,
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  border: {
    borderBottomWidth: 1,
    borderColor: "#C4C4C4",
    opacity: 0.3,
    marginVertical: width * 0.013
  },
  descriptionRules: {
    color: "#484848",
    fontSize: RFValue(5),

    fontWeight: "500",
    lineHeight: 24,
    marginBottom: width * 0.002
  },
  productRulesContainer: {
    marginLeft: 16,
    maxHeight: width * 0.18,
    overflow: 'auto' as 'scroll',
  },
  productRule: {
    marginTop: 16,
    flexDirection: 'row',
  },
  productEnforcedText: {
    fontWeight: "700",
    alignSelf: "flex-end",
    marginLeft: 4,
  },
  opacity: {
    opacity: 0.5
  },
});