import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  card: {
    flex: 1,
    borderRadius: width * 0.003, // 4,
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: width * 0.09, // 123
    width: width * 0.221, // 304,
  },
  image: {
    borderBottomStartRadius: width * 0.003, // 4,
    borderTopStartRadius: width * 0.003, // 4,
    width: width * 0.067, // 90
    height: width * 0.1, // 136
    alignSelf: "flex-start",
  },
  containerSide: {
    flexDirection: "column",
    flex: 1,
    padding: width * 0.0035, // 5
  },
  containerSideBottom: {
    flexDirection: "row",
    paddingTop: width * 0.007, // 10,
  },
  containerTextProduct: {
    paddingLeft: width * 0.0035, // 5,
  },
  textProduct: {
    
    fontWeight: "400",
    fontSize: RFValue(6),
    color: "#484848",
    lineHeight: width * 0.014, // 20,
  },
  containerPriceProduct: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: width * 0.003, // 4
  },
  textPriceProduct: {
    
    fontWeight: "400",
    fontSize: RFValue(6),
    color: "#A7A7A7",
    lineHeight: width * 0.014, // 16,
    textDecorationLine: "line-through",
  },
  textPriceProductDiscount: {
    
    fontWeight: "400",
    fontSize: RFValue(7),
    color: "#1C1C1C",
    lineHeight: width * 0.015, // 28,
  },
  containerActionProduct: {
    flex: 2,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
  },
  iconDelete: {
    padding: 0,
    margin: 0,
  },
  containerActionProductCount: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  containerActionProductCountSquare: {
    borderRadius: width * 0.003, // 4,
    width: width * 0.02, // 26
    height: width * 0.02, // 26
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    cursor: "pointer",
  },
  iconCountSquare: {
    alignSelf: "center",
    marginLeft: 1,
    marginTop: 1,
  },
  inputActionProductCount: {
    width: width * 0.02,
    textAlign: "center",
    
    fontWeight: "400",
    fontSize: RFValue(6),
    color: "#686868",
  },
  priceRow: {
    flexDirection: "column",
  },
  textNetPriceProduct: {
    flexDirection: "row",
    
    fontWeight: "300",
    marginLeft: 0,
    fontSize: RFValue(6),
    color: "#000000",
    lineHeight: width * 0.0135, // 16,
  },
});
