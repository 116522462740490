import { StyleSheet } from 'react-native';
import { THEME } from "@theme/index";
import { Dimensions } from 'react-native';
import { ScreenDimensions } from '@screens/ChooseWholesaler/ScreenDimensions';
const { width } = Dimensions.get("window");
let isDarkMode = false

const colorModeStorage: string = localStorage.getItem("@color-mode") || ""
if(colorModeStorage){
  let colorMode = JSON.parse(colorModeStorage)
  if(colorMode.value === 'dark'){
    isDarkMode = true
  }
}

export const cardBorder = {
  borderRadius: 6,
  borderWidth: 1,  
  borderColor: isDarkMode ? THEME.colors.muted[800] : THEME.colors.gray[300]
}

export const styles = StyleSheet.create({
  squareShapeView: {
    width: width < 480 ? "100%" : ScreenDimensions(),
    minHeight: 85,
    marginRight: width < 480 ? 0 : 8,
    marginBottom: width < 480 ? 8 : 0,
    backgroundColor: THEME.colors.white,
    ...cardBorder
  },
  dragging: {
    opacity: 0.2
  },
  cardBorder
});