import React from 'react';
import { AddIcon, Button, HStack, Input, IInputProps, MinusIcon, Pressable, IStackProps } from 'native-base';

import { PopoverMessageValidation } from '@components/PopoverMessageValidation';

type Props = IStackProps & {
  isLoading: boolean;
  isMessageErrorShown: boolean;
  messageError?: string;
  onMessageErrorClose: () => void;
  _pressable: {
    onAddClick: () => void;
    isAddDisabled?: boolean;
    onRemoveClick: () => void;
    isRemoveDisabled?: boolean;
  };
  _input: IInputProps;
}

export function ProductAmountCounter({
  isLoading,
  isMessageErrorShown,
  messageError,
  onMessageErrorClose,
  _pressable,
  _input,
  ...rest
}: Props) {
  const isMinusButtonDisabled = isLoading || !_input.value || _input.value === '0';

  const {
    onAddClick,
    onRemoveClick,
    isAddDisabled = false,
    isRemoveDisabled = false
  } = _pressable;

  return (
    <HStack
      h={[8, 8, 8, 9]}
      borderWidth={1}
      borderColor='muted.800'
      borderRadius={4}
      alignItems="center"
      justifyContent="space-evenly"
      _dark={{
        borderColor: 'muted.400'
      }}
      {...rest}
    >
      {isLoading ? (
        <Button
          testID='product-amount-counter-loading'
          isLoading
          w='full'
          variant='unstyled'
        />
      ) : (
        <>
          <Pressable
            testID='product-amount-counter-decrement'
            disabled={isMinusButtonDisabled}
            onPress={onRemoveClick}
            isDisabled={isRemoveDisabled}
            _pressed={{
              opacity: 0.2
            }}
            _hover={{
              opacity: 0.2
            }}
          >
            <MinusIcon color="#E22849" />
          </Pressable>

          <PopoverMessageValidation
            accessibilityLabel="Mensagem de erro ao alterar quantidade"
            isOpen={isMessageErrorShown}
            onClose={onMessageErrorClose}
            trigger={(triggerProps) => {
              return (
                <Input
                  testID='product-amount-counter-input'
                  isDisabled={isLoading}
                  variant='unstyled'
                  keyboardType='number-pad'
                  color='muted.800'
                  _dark={{
                    color: 'muted.400'
                  }}
                  textAlign='center'
                  width={12}
                  fontWeight='400'
                  fontSize={['xs', 'sm']}
                  {...triggerProps}
                  {..._input}
                />
              );
            }}
            message={messageError}
          />

          <Pressable
            testID='product-amount-counter-increment'
            disabled={isLoading}
            onPress={onAddClick}
            isDisabled={isAddDisabled}
            _pressed={{
              opacity: 0.2
            }}
            _hover={{
              opacity: 0.2
            }}
          >
            <AddIcon color="primary.600" />
          </Pressable>
        </>
      )}
    </HStack>
  );
}