import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Platform } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Header, Icon } from "react-native-elements";
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Spinner from 'react-native-loading-spinner-overlay';
import {
  ArrowBackIcon,
  Center,
  HStack,
  Pressable,
  Text,
  useColorMode,
  Icon as NativeBaseIcon,
  useTheme,
  useMediaQuery,
  View,
} from "native-base";

import { useAuth } from '@contexts/Auth';
import { useCoupon } from '@hooks/useCoupon';
import { useShoppingCart } from '@hooks/useShoppingCart';


import Alert, { useRefAlert, Props as AlertProps } from "@components/Alert";
import { Config } from "@configs/Config";
import { height, width } from "@components/Dimension";
import { LinkButton } from "@components/LinkButton";
import { PlatformImplementation } from "@components/PlatformImplementation";

import { RedirectHome } from "@helpers/RedirectHome";
import {
  HeaderBarStyle,
  MobileSearchBarStyle,
} from "../../styles/HeaderStyle";

import { ProfileIcon } from "@components/More/ProfileIcon";
import { useSideMenu } from "@hooks/useSideMenu";
import { FuncionalLogo } from "@components/FuncionalLogo";
import { useCouponModal } from '@hooks/useCouponModal';
import { useAlertCouponModal } from '@hooks/useAlertCouponModal';
import { useCartModal } from '@hooks/useCartModal';
import { CustomHidden } from '@helpers/CustomHidden';

Feather.loadFont();

const imageStyleMobile = {
  w: ['120px'],
  h: ['34px'],
  alignItems: 'center'
};

const imageStyleWeb = {
  w: ['100px'],
  h: [8, 12],
  alignItems: 'center',
  ml: [0, 0, 8, 12, 16]
};

type Props = {
  navigation?: any;
  isDefaultHeader?: boolean;
  isLeftLogoHomeHeader?: boolean;
  isStackHeader?: boolean;
  hasRightIcon?: boolean;
  goBackScreen?: string;
};

type RouteParams = {
  name: string;
};
// TODO: quem será o escolhido ou o corajoso para refatorar esse componente
export const HeaderBar = ({
  navigation,
  isDefaultHeader,
  isLeftLogoHomeHeader,
  isStackHeader,
  goBackScreen,
  hasRightIcon = true,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const { clearCart, cart } = useShoppingCart();
  const countProductsCart = cart?.items.length ?? 0
  const cartModal = useCartModal()
  const { coupons, loadDiscounts } = useCoupon();
  const couponModal = useCouponModal()
  const alertCouponModal = useAlertCouponModal()
  const sideMenu = useSideMenu();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();
  const { signOut, user } = useAuth();
  const route = useRoute();
  const params = route.params as RouteParams;

  const [isSmallScreen] = useMediaQuery([{ maxWidth: 480 }]);

  const alertDialog = useRef<useRefAlert>();

  const alertParams: AlertProps = {
    type: "ALERT",
    headerText: "Limpar o resumo do pedido",
    bodyContent: (
      <Text
        selectable
        fontSize="sm"
        fontWeight="400"
        marginTop={0}
        color="muted.800"
        _dark={{
          color: "muted.400",
        }}
      >
        Você deseja remover todos os produtos do seu resumo?
      </Text>
    ),
    btnTextConfirm: "Sim",
    btnTextCancel: "Cancelar",
    onConfirmCallback: () => setLoading(true),
  };

  async function handleClearCart() {
    setTimeout(async () => {
      await clearCart()
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    if (loading) {
      handleClearCart();
    }
  }, [loading]);

  const renderLeftComponent = () => {
    return PlatformImplementation(
      () => {
        return renderLogoFuncional(imageStyleWeb);
      },
      {
        default: () => {
          return (
            <TouchableOpacity>
              <Icon
                name="menu"
                color="#686868"
                size={30}
                style={MobileSearchBarStyle.menu}
                tvParallaxProperties={undefined}
                onPress={() => navigation.openDrawer()}
              />
            </TouchableOpacity>
          );
        },
      }
    );
  };

  const renderCenterComponent = () => {
    return PlatformImplementation(renderCenterComponentWeb, {
      default: () => {
        return renderLogoFuncional(imageStyleMobile);
      },
    });
  };

  const renderLeftName = () => {
    const goBackFunction = goBackScreen
      ? () => navigation.navigate(goBackScreen)
      : navigation.goBack;

    if (isLeftLogoHomeHeader) {
      return (
        <FuncionalLogo style={imageStyleMobile} />
      );
    }

    return (
      <HStack space={4} flex={1}>
        <Pressable
          onPress={() => goBackFunction()}
          _hover={{
            opacity: 0.8,
          }}
          _focus={{
            bgColor: "muted.300",
          }}
          _pressed={{
            bgColor: "muted.300",
          }}
        >
          <ArrowBackIcon
            color="primary.600"
            size={6}
            rippleColor="rgba(229, 229, 229, 0.5)"
          />
        </Pressable>

        {params?.name && (
          <Text
            flex={1}
            fontSize={"md"}
            fontWeight={"700"}
            color={"muted.800"}
            _dark={{
              color: "muted.400",
            }}
          >
            {params?.name}
          </Text>
        )}
      </HStack>
    );
  };

  const renderRightComponent = () => {
    return PlatformImplementation(renderWebIcons, {
      default: renderMobileIcons,
    });
  };

  const renderContainerStyle = () => {
    return PlatformImplementation(
      () => {
        return HeaderBarStyle.containerStyleWeb;
      },
      {
        default: () => {
          return HeaderBarStyle.containerStyleMobile;
        },
      }
    );
  };

  const renderLogoFuncional = (style: any) => {
    const { user } = useAuth();
    const industryCode = user?.getNameIndustryStore();

    return (
      <View
        flex={1}
        alignItems={"center"}
      >
        {isDefaultHeader ? (
          <FuncionalLogo style={style} />
        ) : (
          <LinkButton
            to={{
              screen: "Industry",
              params: { industry: industryCode },
            }}
            style={{}}
          >
            <FuncionalLogo style={style} />
          </LinkButton>
        )}
      </View>
    );
  };

  function renderClearCartButton() {
    return (
      <>
        <Pressable onPress={() => alertDialog.current?.show()}>
          <Text color="primary.600" fontSize="sm" fontWeight="500">
            Limpar
          </Text>
        </Pressable>

        <Alert ref={alertDialog} {...alertParams} />
      </>
    );
  }

  const renderCenterComponentWeb = () => {
    const redirect = RedirectHome();

    return (
      <View
        flex={1}
        pl={[0, 30]}
        flexDirection={'row'}
        alignItems={'center'}
      >
        <LinkButton to={redirect}>
          <Text
            fontSize={["md"]}
            mr={7}
            color={"primary.600"}
            _dark={{
              color: "muted.500",
            }}
          >
            Lista de produtos
          </Text>
        </LinkButton>

        <LinkButton
          to={{
            screen: "ListOrder",
          }}
          style={{}}
        >
          <Text
            fontSize={["md"]}
            mr={7}
            color={"primary.600"}
            _dark={{
              color: "muted.500",
            }}
          >
            Lista de pedidos
          </Text>
        </LinkButton>
      </View>
    );
  };

  const renderMobileIconsRight = () => {
    const navigation = useNavigation();

    return (
      <HStack justifyContent={"flex-end"} space={3}>
        <CustomHidden show={Config.FT_KUPONO_ENABLED && coupons.length >= 1} >
          <View>
            <Pressable
              onPress={() => {
                navigation.navigate("Coupons");
              }}
              _hover={{
                opacity: 0.8,
              }}
              _focus={{
                bgColor: "muted.300",
              }}
              _pressed={{
                bgColor: "muted.300",
              }}
            >
              <NativeBaseIcon
                as={MaterialCommunityIcons}
                size={7}
                name="ticket-percent-outline"
                color={"muted.800"}
                _dark={{
                  color: "muted.400",
                }}
              />
              <Center
                borderRadius={"full"}
                bg="primary.500"
                position="absolute"
                top="-8"
                size={"5"}
                right={-9}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text color={'muted.100'} lineHeight={'0.5'} fontSize={'11'}> {coupons.length}</Text>
              </Center >
            </Pressable >

          </View >
        </CustomHidden>

        <Pressable
          onPress={() => {
            navigation.navigate("SummaryOrder", {
              industry: user.getNameIndustryStore(),
            });
          }}
          _hover={{
            opacity: 0.8,
          }}
          _focus={{
            bgColor: "muted.300",
          }}
          _pressed={{
            bgColor: "muted.300",
          }}
        >
          <NativeBaseIcon
            as={MaterialCommunityIcons}
            size={7}
            name="cart-outline"
            color={"muted.800"}
            _dark={{
              color: "muted.400",
            }}
          />
          <Center
            borderRadius={"full"}
            bg="primary.500"
            _text={{
              fontWeight: "400",
              fontSize: "xs",
            }}
            position="absolute"
            top="-8"
            size={"5"}
            right={-9}
          >
            <Text color={"muted.100"} lineHeight={"0.5"} fontSize={["11"]}>
              {countProductsCart}
            </Text>
          </Center>
        </Pressable>
      </HStack >
    )
  }

  const renderWebIcons = () => {
    return (
      <>
        <View style={HeaderBarStyle.viewStyle}>
          <View style={HeaderBarStyle.viewIconStyleWeb}>
            {route.name === "Industry" && (
              <HStack justifyContent={"flex-end"} space={3}>
                <CustomHidden show={Config.FT_KUPONO_ENABLED && coupons.length >= 1} >
                  <View justifyContent={"center"}>
                    <Pressable
                      onPress={() => {
                        couponModal.onOpen()
                        alertCouponModal.onClose()
                        alertCouponModal.onInvisible()
                        cartModal.onClose()
                        loadDiscounts()
                      }}
                      _hover={{
                        opacity: 0.8,
                      }}
                      _focus={{
                        bgColor: "muted.300",
                      }}
                      _pressed={{
                        bgColor: "muted.300",
                      }}
                    >
                      <NativeBaseIcon
                        as={MaterialCommunityIcons}
                        size={7}
                        name="ticket-percent-outline"
                        color={"muted.800"}
                        _dark={{
                          color: "muted.400",
                        }}
                      />
                      <Center
                        borderRadius={"full"}
                        bg="primary.500"
                        position="absolute"
                        top="-8"
                        size={"5"}
                        right={-9}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text color={'muted.100'} lineHeight={'0.5'} fontSize={'11'}> {coupons.length}</Text>
                      </Center >
                    </Pressable >

                  </View >
                </CustomHidden>
                <View justifyContent={"center"}>
                  <Pressable
                    onPress={() => {
                      cartModal.onOpen()
                      couponModal.onClose()
                    }}
                    _hover={{
                      opacity: 0.8,
                    }}
                    _focus={{
                      bgColor: "muted.300",
                    }}
                    _pressed={{
                      bgColor: "muted.300",
                    }}
                  >
                    <NativeBaseIcon
                      as={MaterialCommunityIcons}
                      size={7}
                      name="cart-outline"
                      color={"muted.800"}
                      _dark={{
                        color: "muted.400",
                      }}
                    />
                    <Center
                      borderRadius={"full"}
                      bg="primary.500"
                      _text={{
                        fontWeight: "400",
                        fontSize: "xs",
                      }}
                      position="absolute"
                      top="-8"
                      size={"5"}
                      right={-9}
                    >
                      <Text color={"muted.100"} lineHeight={"0.5"} fontSize={["11"]}>
                        {countProductsCart}
                      </Text>
                    </Center>
                  </Pressable>
                </View >
                <View>
                  <Pressable
                    onPress={() => {
                      sideMenu.open();
                    }}
                    _hover={{
                      opacity: 0.8,
                    }}
                  >
                    <ProfileIcon
                      w={9}
                      h={9}
                      fontSize={["md", "lg"]}
                      rounded={["lg"]}
                      innerProps={{ w: "95%" }}
                    />
                  </Pressable>
                </View>
              </HStack>
            )}
          </View>
        </View>
      </>
    );
  };

  const renderMobileIcons = () => {
    return (
      <SafeAreaView>
        <TouchableOpacity>
          <View style={HeaderBarStyle.iconStyleMobile}>
            <Ionicons name="cart-outline" size={30} color="#686868" />
          </View>
        </TouchableOpacity>
      </SafeAreaView>
    );
  };

  function renderMobileRightComponent() {
    if (params?.name === "Resumo do pedido") {
      return renderClearCartButton();
    }

    if (hasRightIcon) {
      return renderMobileIconsRight();
    }

    return undefined;
  }

  if (isStackHeader && (isSmallScreen || Platform.OS !== "web")) {
    return (
      <>
        <Header
          backgroundColor={
            colorMode == "dark" ? colors.muted[800] : colors.white
          }
          leftComponent={renderLeftName()}
          rightComponent={renderMobileRightComponent()}
          rightContainerStyle={{ flex: 0 }}
          centerContainerStyle={{ flex: 0 }}
          containerStyle={{
            ...renderContainerStyle(),
            borderBottomWidth: 2,
            borderBottomColor:
              colorMode == "dark" ? colors.muted[700] : colors.muted[400],
          }}
        />

        <Spinner visible={loading} color={colors.primary[600]} />
      </>
    );
  }

  return (
    <>
      <Header
        backgroundColor={colorMode == "dark" ? colors.gray[700] : colors.white}
        leftComponent={isDefaultHeader ? null : renderLeftComponent()}
        centerComponent={
          isDefaultHeader
            ? renderLogoFuncional(imageStyleMobile)
            : renderCenterComponent()
        }
        rightComponent={isDefaultHeader ? null : renderRightComponent()}
        containerStyle={{
          ...renderContainerStyle(),
          borderBottomWidth: colorMode == "dark" ? 0 : 2,
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  numberOrderCircleSelected: {
    borderRadius: Math.round(width + height) / 2,
    width: width * 0.013, // 15,
    height: width * 0.012,
    backgroundColor: "#74BF48",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 30,
    top: 5,
  },

  numberOrderTextSelected: {
    fontSize: RFValue(5),
    color: "#FFFFFF",
  },
});
