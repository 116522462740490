import React, { useState } from 'react';
import { FlatList } from 'native-base';
import { useReactiveVar } from '@apollo/client';

import { UnexpectedError } from '@components/UnexpectedError';
import { OrderCard } from '@components/OrderCard';
import { Loading } from '@components/Loading';

import { orderItemsVar, useRetailOrders } from '@hooks/useRetailOrders';
import { OrderType } from '@types/OrderType';
import { useNavigation } from '@react-navigation/native';

const MOBILE_PAGE_SIZE = 6;

export function OrderListMobile() {
  const [offset, setOffset] = useState(1)
  const navigation = useNavigation()

  const { data: queryData, loading, error, fetchMore } = useRetailOrders(
    MOBILE_PAGE_SIZE,
    offset
  );

  const orders: OrderType[] = useReactiveVar(orderItemsVar);

  const handleLoadMoreData = () => {
    if (!loading && queryData?.Retail_retailOrders?.has_more_pages) {
      fetchMore({
        variables: {
          page: offset + 1,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          return {
            data: [...prevResult.data, ...fetchMoreResult.data]
          }
        }
      });

      setOffset(offset + 1);
    }
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  return (
    <FlatList
      testID='order-list'
      data={orders}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <OrderCard
          status={item.status}
          id={item.customerOrderCode}
          customerCode={item.customerCode}
          createdAt={item.createdAt}
          onPress={() => {
            navigation.navigate('Order', {
              orderId: item.id
            })
          }}
        />
      )}
      onEndReached={handleLoadMoreData}
      onEndReachedThreshold={0.5}
      showsVerticalScrollIndicator={false}
      ListFooterComponent={loading ? <Loading /> : null}
      p={6}
    />
  );
}