import React from 'react';
import { FlatList, View } from 'native-base';
import { UnexpectedError } from '@components/UnexpectedError';
import { OrderCard } from '@components/OrderCard';
import { Loading } from '@components/Loading';

import { OrderType } from '@types/OrderType';
import { useRetailOrder } from '@hooks/useRetailOrder';
import { useRoute } from '@react-navigation/native';
import { OrderProductCard } from '@components/OrderProductCard';

export const OrderMobile = () => {
  const route = useRoute();

  const { data, loading, error } = useRetailOrder(
    route.params?.orderId
  );

  let order: OrderType | null = null

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  if (data?.Retail_retailOrder) {
    order = data?.Retail_retailOrder;
  }

  if (!order) {
    return <UnexpectedError consoleError={'Pedido não encontrado!'} />;
  }

  return (
    <View p={6} flex={1}>
      <View mb={3} borderBottomWidth={1} borderBottomColor={'muted.300'}>
        <OrderCard
          status={order.status}
          id={order.customerOrderCode}
          customerCode={order.customerCode}
          createdAt={order.createdAt}
        />
      </View>
      <FlatList
        data={order.products}
        keyExtractor={(item) => item.ean}
        renderItem={({ item }) => <OrderProductCard product={item} />}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={loading ? <Loading /> : null}
      />
    </View>
  );
}