import React, { useEffect, useState } from "react";
import { Text, View } from "native-base";

import { List } from "@components/List";
import { ProductCardAnchor } from "@components/ProductCardAnchor";
import { useAuth } from "@contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";
import { useShoppingCart } from "@hooks/useShoppingCart";
import {
  useSuggestionAnchorProductsLazy,
  refactorResult,
} from "@hooks/useSuggestedAnchorProducts";
import { ItemType } from "@types/CartType";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { EanType } from "@types/EanType";
import { useWholesalers } from "@hooks/useWholesalers";

export interface AnchorProps {
  wholesalerCode: string
}

export const AnchorProducts = (props: AnchorProps) => {
  const {wholesalerCode} = props;
  const [anchorProductsPerPage, setAnchorProductsPerPage] = useState<
    CommercialPolicyProductsType[]
  >([]);
  const [anchorProducts, setAnchorProducts] = useState<
    CommercialPolicyProductsType[]
  >([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [first, setFirst] = useState(true);
  const { user } = useAuth();
  const nameIndustry = user?.getNameIndustryStore();
  const { cart } = useShoppingCart();

  useEffect(() => {
    refreshAnchorProductsList();
  }, [anchorProducts]);

  useEffect(() => {
    if(!cart?.items) return;
    if(!first) return;
    setFirst(false);
    getData();
  });

  const wholesalers = useWholesalers();
  const { customerCode } = useCustomer();

  function getCartEans() {
    const eans: EanType[] = [];

    cart?.items?.map((item: ItemType) => {
      eans.push({
        ean: item.code,
        amount: item.amount,
      });
    });

    return eans;
  }

  const [query] = useSuggestionAnchorProductsLazy({
    anchorEans: getCartEans(),
    limit: 10,
    offset: 0,
    commercialPolicyId: 0,
    wholesalerBranchCode: wholesalerCode,
    customerCode: customerCode,
  });

  const totalAnchorProductsPerPage = 2;

  const refreshAnchorProductsList = (offset: number = 0) => {
    const start = offset;
    const end = offset + 1;

    const anchorListProductsSliced = anchorProducts.slice(start, end + 1);
    setAnchorProductsPerPage(anchorListProductsSliced);
  };

  async function getData() {
    const { data } = await query();
    const suggestionAnchorProducts = data?.Msbit_suggestedAnchorProducts;
    const productsInCommercialCondition = suggestionAnchorProducts.filter(
      (p: any) => {
        return p.commercialPolicyProduct != null;
      }
    );

    if (productsInCommercialCondition.length == 0) {
      setTotal(0);
      setAnchorProducts([]);
    }
    const result = refactorResult(productsInCommercialCondition, nameIndustry);
    setTotal(productsInCommercialCondition.length);
    setAnchorProducts(result);
    setTotal(result.length);
    setIsLoading(false);
  }

  return anchorProducts.length > 0 ? (
    <View borderRadius={4} mt={[0, 5]} shadow={2} paddingX={[2, 4, 2, 3, 10]}>
      <List
        data={anchorProductsPerPage}
        containerTitle={
          <View w={"full"} h={20} justifyContent={"space-evenly"}>
            <Text px={6} fontSize={["sm", "lg", "xl"]} color={"muted.800"}>
              Itens sugeridos. recomendamos para você
            </Text>
          </View>
        }
        numColumns={4}
        total={anchorProducts.length}
        pageCount={totalAnchorProductsPerPage}
        showSearch={false}
        keyExtractor={(item) => item.product.code}
        renderItem={({ item }) => (
          <View flex={1} maxW={"50%"}>
            <ProductCardAnchor product={item} tab={item.product.tab} />
          </View>
        )}
        refetch={(offset, text) => {
          return refreshAnchorProductsList(offset);
        }}
      />
    </View>
  ) : (
    <></>
  );
};
