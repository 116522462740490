import { useState, useEffect, useCallback } from "react";
import { useToast, useMediaQuery } from "native-base";
import { useNavigation, useFocusEffect, RouteProp, useRoute } from "@react-navigation/native";
import { useReactiveVar } from "@apollo/client";

import { Config } from "@configs/Config";
import { width } from "@components/Dimension";
import { useAuth } from "@contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";
import { CustomerByUserType } from "@types/CustomerByUserType";
import { IndustryDataType } from "@types/IndustryDataType";
import { RouteParams } from "@types/RouteParams";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";
import { useSearchWholesalersLazy } from "@hooks/useSearchWholesalers";
import { useAsyncStorage } from "@hooks/useAsyncStorage";
import { refactorStatus } from "@hooks/useGetCustomerCommercialPolicyShippingOrder";
import {
  filtersVar,
  useHasCommercialPolicy,
} from "@hooks/useHasCommercialPolicy";
import { useAssortments } from "@hooks/useAssortments";
import { useCustomersById } from "@hooks/useCustomersById";
import { useFilterCustomer } from "@hooks/useFilterCustomer";
import { industryVar, useIndustryCpm } from "@hooks/useIndustryCpm";

export const useChooseCustomer = () => {
  const [start, setStart] = useState(true);
  const [offset, setOffset] = useState(0);
  const [canScroll, setCanScroll] = useState(false);
  const [listEnd, setListEnd] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [customerListData, setCustomerListData] = useState<
    CustomerByUserType[]
  >([]);
  const [newCustomerListData, setNewCustomerListData] = useState<
    CustomerByUserType[]
  >([]);

  const route = useRoute<RouteProp<RouteParams, "Industry">>();
  const navigation = useNavigation();
  const { user, setDataStore, setAssortments } = useAuth();
  const { customerFilter, handleCustomer, resetStates } = useFilterCustomer();
  const { cardSelected, saveCustomer, setCardSelected, setImpersonateStorage } = useCustomer();
  const { assortments, loading } = useAssortments();
  const { useCustomersByUser } = useCustomersById();
  const [
    queryHasCommercialPolicy,
    { data, loading: loadingHasCommercialPolicy },
  ] = useHasCommercialPolicy();
  let nameIndustry: string = route.params?.industry ?? user.getNameIndustryStore();
  nameIndustry = user?.getSsoRoleIdentities("industry_employee")[0] ?? nameIndustry;

  const industry = useReactiveVar(industryVar);
  const commercialPolicyId = user?.getCommercialPolicyIdStore();
  const filter = "";
  const assortment = {
    names: user.getAssortments().map((element) => element.name),
  };

  useIndustryCpm({
    industry: nameIndustry,
  });

  const toast = useToast();

  const {
    data: customersData,
    refetch: customersRefetch,
    loading: customersLoading,
  } = useCustomersByUser(true, {
    industry: {
      name: nameIndustry,
    },
  });

  const {
    queryWholesaler,
  } = useSearchWholesalersLazy({ text: "" });

  const { updateStorage } = useAsyncStorage(`${nameIndustry}:wholesalers`, []);

  const [isSmallScreen, isMediumScreen, isLargeScreen] = useMediaQuery([
    {
      maxWidth: 480,
    },
    {
      minWidth: 481,
      maxWidth: 992,
    },
    {
      minWidth: 993,
    },
  ]);

  const handleToast = () => {
    toast.show({
      description: "Não há tabelas liberadas para esse cliente",
      _description: {
        color: "white",
        fontFamily: "body",
        fontSize: "xs",
        fontWeight: "medium",
        padding: 3,
      },
      placement: "top-right",
      backgroundColor: "gray.300",
      borderStyle: "solid",
      borderWidth: "1",
      borderColor: "gray.300",
      duration: 3000,
      top: width * 0.01,
      right: width * 0.034,
      rounded: 4,
    });
  };

  const formatCustomer = (customers: {
    Retail_customersByUser: CustomerByUserType[];
  }) => {
    if (!customers) {
      return [];
    }

    return customers?.Retail_customersByUser;
  };

  async function updateData(
    newOffset: number = 0,
    isFilter: boolean = false,
    enabled: boolean = canScroll,
    endReached: boolean = listEnd
  ) {
    setCardSelected("");
    setImpersonateStorage(false);
    filtersVar({
      ...filtersVar(),
      customer: { code: "" },
    });
    if (!enabled || endReached) {
      return;
    }
    setOffset(offset + 20);

    const { data: newPage } = await customersRefetch({
      activeOnly: true,
      filter: {
        ...customerFilter,
        industry: {
          name: nameIndustry,
        },
      },
      offset: isFilter ? newOffset : offset,
    });

    if (newPage?.Retail_customersByUser?.length == 0) {
      setListEnd(true);
    }

    const formattedNewPage = formatCustomer(newPage);

    if (isFilter) {
      setCustomerListData(formattedNewPage);
      return;
    }
    setScrollLoading(true);
    setNewCustomerListData(formattedNewPage);
  }

  const setPreferedWholesalers = async (industry: IndustryDataType) => {
    const res = await queryWholesaler({
      variables: {
        customerCode: cardSelected,
        industryCode: industry.industry,
        commercialPolicyId,
        filter,
        assortment,
      },
    });
    const result = refactorStatus(
      res?.data?.getCustomerCommercialPolicyShippingOrder.slice(0, 6)
    );
    const preferedWholesalers = result[0];
    if (preferedWholesalers) {
      await updateStorage(result);
    }
  };

  const setIndustry = async (industry: IndustryDataType) => {
    await setDataStore(industry, user);
  };

  async function handleSelectCustomer() {
    let filteredCustomer = customerListData.filter(
      (customer: CustomerByUserType) => {
        return customer.code == cardSelected;
      }
    );
    setIsBlocked(true);
    await saveCustomer(filteredCustomer[0]);
    const availabilities = user.isRetailer() ? [AvailabilityConsolidateEnum.CUSTOMER] : [AvailabilityConsolidateEnum.INDUSTRY]

    const { data } = await queryHasCommercialPolicy({
      variables: {
        customer: { code: cardSelected },
        industry: { code: industry?.nameIndustry ?? '' },
        availability: availabilities
      }
    });

    if (data?.hasCommercialPolicy && industry) {
      await setIndustry(industry);
      setIsClicked(false);
      await setPreferedWholesalers(industry);

      if (user.isRetailer()) {
        return navigation.reset({
          index: 0,
          routes: [
            {
              name: "PreferredWholesaler" as never,
              params: { industry: nameIndustry },
            },
          ],
        });
      }
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "Industry",
            params: { industry: nameIndustry },
          },
        ],
      });
    }
    setIsClicked(true);
  }

  function searchByFilters(searchFilter?: string) {
    const isCustomerListEmptyAfterSearch =
      !customersLoading && customerListData.length === 0;
    const isCustomerListNotEmptyAfterSearch =
      !start &&
      offset !== 20 &&
      !customersLoading &&
      customerListData.length !== 0;

    if (
      searchFilter?.trim() !== "" ||
      isCustomerListEmptyAfterSearch ||
      isCustomerListNotEmptyAfterSearch
    ) {
      fetchData();
      return;
    }

    if (start) {
      resetSearchStates();
      fetchData();
      return;
    }
  }

  function fetchData() {
    resetInitialStates();
    updateData(0, true, true, false);
  }

  function resetInitialStates() {
    setStart(true);
    setCanScroll(true);
    setOffset(0);
    setListEnd(false);
  }

  function resetSearchStates() {
    resetStates()
  }

  useEffect(() => {
    if (!start || !canScroll) {
      return;
    }
    setStart(false);
    setCanScroll(false);
    setOffset(20);
    setCustomerListData(formatCustomer(customersData));
  }, [customersData]);

  useEffect(() => {
    setCustomerListData([...customerListData, ...newCustomerListData]);
    setScrollLoading(false);
  }, [newCustomerListData]);

  useEffect(() => {
    setCanScroll(true);
  }, [customerListData]);

  useEffect(() => {
    if (isClicked && !data?.hasCommercialPolicy) {
      handleToast();
      setIsClicked(false);
      setIsBlocked(false);
    }
  }, [data, isClicked]);

  useEffect(() => {
    let url = `#`;
    url = `${Config.URL_CDN_BASE}/${nameIndustry.toUpperCase()}/logo.jpg`;

    setDataStore(
      {
        nameIndustry,
        industry: user.getIndustry(),
        id: 0,
        url,
      },
      user
    );
    const availabilities = user.isRetailer() ? [AvailabilityConsolidateEnum.CUSTOMER] : [AvailabilityConsolidateEnum.INDUSTRY]

    filtersVar({
      ...filtersVar(),
      industry: { code: nameIndustry },
      availability: availabilities,
    });
  }, []);

  useEffect(() => {
    if (!customersLoading && customerListData.length === 0) {
      resetInitialStates();
    }
  }, [
    customerFilter.search, customerFilter.companyName, customerFilter.businessName, customerFilter.code, customerFilter.group]);

  useEffect(() => {
    if (!loading) {
      setAssortments(assortments, user);
    }
  }, [loading]);

  useFocusEffect(
    useCallback(() => {
      resetSearchStates();
      setCustomerListData([]);
      setNewCustomerListData([]);
      setImpersonateStorage(false);
      searchByFilters();
      setImpersonateStorage(false);
    }, [])
  );

  return {
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
    loadingHasCommercialPolicy,
    searchByFilters,
    customersLoading,
    customerListData,
    isBlocked,
    updateData,
    handleSelectCustomer,
    scrollLoading,
    handleCustomer,
    customerFilter,
  }
};
