import React from 'react';
import { Button, Center, CloseIcon, Heading, Text } from 'native-base';

import { useAuth } from '@contexts/Auth';

export function UnavailableIndustries() {
  const { signOut } = useAuth();

  return (
    <Center flex={1} testID='unavailable-industries'>
      <Center
        w={[16, 20]}
        h={[16, 20]}
        bg="danger.300"
        rounded={["2xl", "3xl"]}
        mb={[6, 8]}
      >
        <CloseIcon size={[8, 10]} color="red.500" />
      </Center>
      
      <Heading
        fontSize={["md", "xl"]}
        fontFamily="heading"
        color="muted.800"
        lineHeight="xl"
        _dark={{
          color: "muted.400"
        }}
      >
        Não há indústrias liberadas.
      </Heading>
      
      <Text
        fontSize={["sm", "md"]}
        fontFamily="body"
        color="muted.800"
        mb={24}
        _dark={{
          color: "muted.400"
        }}
      >
        Em breve teremos novidades!
      </Text>

      <Button
        testID='logout-button'
        bg="primary.600"
        w={["full", 64]}
        h={[9, 12]}
        alignItems="center"
        justifyContent="center"
        _hover={{
          bg: "primary.500"
        }}
        onPress={signOut}
      >
        <Text fontSize={["md", "lg"]} color="muted.200">
          Voltar para a tela de login
        </Text>
      </Button>
    </Center>
  );
};
