import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const { width } = Dimensions.get("window");
import { THEME } from "../../../theme";

export const styles = StyleSheet.create({
  content: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(8),
    fontWeight: "600",
    color: THEME.colors.gray["600"],
    marginTop: 8,
  },
  brandText: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(7),
    color: THEME.colors.gray["600"],
  },
  brandSubText: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(7),
    color: THEME.colors.gray["400"],
  },
  labelsContainer: {
    paddingTop: 10,
    width: "50%",
    fontFamily: THEME.fonts["body"],
    fontWeight: "600",
    color: THEME.colors.gray["500"],
  },
  expirationLabelsContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  expirationDescription: {
    color: THEME.colors.gray["500"],
    fontWeight: "600",
    fontSize: RFValue(6.5),
    marginTop: 1,
  },
  discountLabelContainer: {
    paddingTop: 10,
    width: "50%",
    alignItems: "flex-end",
    marginBottom: 8,
  },
  center: {
    alignItems: "flex-start",
    width: "90%",
  },
  discountLabel: {
    backgroundColor: THEME.colors.orange[500],
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 6,
    marginTop: 2,
    color: THEME.colors.white,
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(6),
    fontWeight: "600",
  },
});
