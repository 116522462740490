import React, { useCallback } from "react";
import { TouchableOpacity } from "react-native";
import { Flex, Icon, View, Text, Hidden, Center } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";
import { cloneDeep } from "@apollo/client/utilities";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import DefaultSourceImg from '@assets/images/brand_not_found.png';
import { useProductTabNavigation } from "@components/ProductsTabView";
import { Card } from "@components/Card";
import { width } from "@components/Dimension";
import { BrandType } from "@types/BrandType";
import { DateConvert } from "@helpers/Date";

type OffersByBrandCardProps = {
  brand: BrandType;
}

export function OffersByBrandCard({ brand }: OffersByBrandCardProps) {
  const {
    tabNavigation,
    setTabParams,
    setTabNavigation,
    getNavigationChildren,
    setTabNavigationLoading,
  } = useProductTabNavigation();
  const navigation = useNavigation()

  const onNavigate = useCallback(() => {
    if (!setTabParams) {
      navigation.navigate('Brand', {
        brandId: brand.id,
        commercialPolicyId: brand.idCommercialPolicy,
      })
      return;
    }
    setTabParams({
      name: brand.name,
      brand: brand,
      commercialPolicyId: brand.idCommercialPolicy,
    });
    const newTabNavigation = cloneDeep(tabNavigation);
    const index = newTabNavigation.index;
    const route = getNavigationChildren("offersByBrandProducts", index);
    if (route?.length) {
      setTabNavigationLoading(true);
      newTabNavigation.routes[index] = route[0];
      setTabNavigation({ ...newTabNavigation, index });
    }
  }, [tabNavigation, getNavigationChildren])

  const discountsSize = brand.discounts.length;
  return (
    <TouchableOpacity
      onPress={onNavigate}
    >
      <Card.Container centerStyle={{
        alignItems: "flex-start", width: "90%",
      }} >
        <Hidden only={['lg', 'xl', 'md', '2xl', '3xl', '4xl']}>
          <Center
            bg='orange.500'
            borderRadius={4}
            zIndex={2}
            position='absolute'
            bottom={"40"}
            right={"3"}
            px={[2, 3]}
            py={1.5}
          >
            <Text
              color='muted.100'
              fontSize={['2xs', '2xs', '2xs', 'xs']}
              fontWeight='700'
            >
              Até {brand.discounts[discountsSize - 1]?.value}%
            </Text>
          </Center>
        </Hidden>
        <Card.Image
          source={brand.image}
          alt={"Imagem " + brand.name}
          defaultSourceImg={DefaultSourceImg}
          h={[26, 48]}
          p={[0, 2]}
        />
        <Card.Body p={[2, 4]} justifyContent='space-between'>
          <Text
            fontWeight="600"
            marginTop={[0, 8]}
            lineHeight={"md"}
            fontSize={["sm", "md"]}
            color={"muted.800"}
            _dark={{
              color: "muted.400"
            }}
            fontStyle={'normal'}
            selectable
          >
            {brand.name}
          </Text>
          <Hidden only={['base', 'sm']}>
            <>
              <View marginTop={4} maxWidth={width * 0.17}>
                <Text
                  fontSize={'sm'}
                  color={'gray.600'}
                  _dark={{
                    color: 'gray.400'
                  }}
                >
                  Desconto progressivo por volume
                </Text>
                <Text
                  fontSize={'sm'}
                  color={'gray.400'}
                  _dark={{
                    color: 'gray.300'
                  }}
                >
                  Desconto inicial de {brand.discounts[0]?.value}%
                </Text>
              </View>
              <Flex direction="row">
                <View
                  paddingTop={3}
                  width={"50%"}
                  fontWeight={"600"}
                  color={'gray.500'}
                >
                  <View
                    flex={1}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                  >

                    <Icon
                      as={MaterialCommunityIcons}
                      name="clock-outline"
                      size={RFValue(2)}
                      style={{
                        marginTop: 3,
                        marginRight: 5,
                      }}
                    />
                    <Text
                      mt='1'
                      color={'gray.500'}
                      fontWeight={600}
                      fontSize={'xs'}
                      _dark={{
                        color: 'gray.300'
                      }}
                    >
                      {new DateConvert(brand.endValidityDate).getExpirationDate()}
                    </Text>
                  </View>
                </View>
                <View
                  paddingTop={3}
                  width={"50%"}
                  alignItems={"flex-end"}
                  justifyContent={'center'}
                  marginBottom={1}

                >
                  <View
                    backgroundColor={'orange.500'}
                    py={2}
                    px={2}
                    borderRadius={6}
                  >
                    <Text
                      color={'white'}
                      fontSize={'xs'}
                      fontWeight={"600"}
                      noOfLines={1}
                    >
                      Até {brand.discounts[discountsSize - 1]?.value}% OFF.
                    </Text>
                  </View>
                </View>
              </Flex>
            </>
          </Hidden>
          <Hidden only={['lg', 'xl', 'md', '2xl', '3xl', '4xl']}>
            <View mt={4}>
              <Text
                fontSize={'sm'}
                color={'muted.700'}
                lineHeight={'sm'}
                _dark={{
                  color: "muted.400"
                }}
              >
                Até {brand.discounts[discountsSize - 1]?.value}% OFF.
              </Text>
              <View justifyContent={"flex-start"} flexDir={'row'} alignItems={"center"}>
                <Icon
                  as={MaterialCommunityIcons}
                  name="clock-outline"
                  size={3}
                  color={'muted.700'}
                />
                <Text
                  fontSize={'sm'}
                  ml={1}
                  color={'muted.700'}
                  lineHeight={'sm'}
                  _dark={{
                    color: "muted.400"
                  }}
                >
                  até {new DateConvert(brand.endValidityDate).getExpirationDate()}
                </Text>
              </View>
            </View>
          </Hidden>
        </Card.Body>
      </Card.Container>
    </TouchableOpacity >
  );
}
