import { gql, makeVar, useQuery } from "@apollo/client";

const INDUSTRY = gql`
  query (
    $code: String!
  ) {
    CMS_industries(
      filters: {
        code: {
          eq: $code
        }
      }
    ) {
      data {
        attributes {
          name
          code
          transferOrderUrl
        }
      }
    }
  }
`;

interface Filters {
  industry: string
  skip: boolean
}

interface Industry {
  name: string,
  code: string,
  transferOrderUrl: string,
}

const defaultIndustry = {
  code: '',
  name: '',
  transferOrderUrl: '',
}
export const industryVar = makeVar<Industry>(defaultIndustry);

export const useIndustry = ({ industry, skip }: Filters) => {

  return useQuery(INDUSTRY, {
    skip,
    fetchPolicy: "cache-first",
    variables: {
      code: industry,
    },
    onCompleted(data) {

      if (data?.CMS_industries?.data) {
        const industry = data.CMS_industries.data
        industryVar(industry[0]?.attributes ?? defaultIndustry)
      }
    },
  });
};

