import { gql, useMutation } from "@apollo/client";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { cartVar } from "./useCart";


export type AddItemInput = {
	cartId: string,
	product: {
		code: string,
		type: ItemTypeEnum,
		commercialPolicyId: number,
		amount: number,
	},
	overwrite: boolean
}

const CART = gql`
  mutation CartAddItem($input: Cart_AddItemInput!) {
    Cart_addItem(input: $input) {
      id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          			product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
    }
  }
`;

export const useAddItem = () => {
	return useMutation(CART, {
		refetchQueries: [

		],
		onCompleted(data) {
			if (data.Cart_addItem) {
				cartVar(data.Cart_addItem)
			}
		},
	});
};
