import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from 'native-base';

export const FilterIcon = ({ width, height, title }: any) => {
  const { colors } = useTheme();
  return (
    <Svg
      height={height}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <Path
        fillRule='evenodd'
        d="M4.19806 4.59824C4.43518 3.67973 5.26998 3 6.26198 3C7.25399 3 8.08879 3.67973 8.32591 4.59824H17.4496C17.7437 4.59824 17.9823 4.83691 17.9823 5.13099C17.9823 5.42507 17.7437 5.66374 17.4496 5.66374H8.32591C8.08879 6.58226 7.25399 7.26198 6.26198 7.26198C5.26998 7.26198 4.43518 6.58226 4.19806 5.66374H2.53275C2.23866 5.66374 2 5.42507 2 5.13099C2 4.83691 2.23866 4.59824 2.53275 4.59824H4.19806Z"
        fill={colors.primary[600]} />
      <Path
        fillRule='evenodd'
        d="M4.21563 15.2525H2.55033C2.25624 15.2525 2.01758 15.4912 2.01758 15.7853C2.01758 16.0794 2.25624 16.318 2.55033 16.318H4.21563C4.45276 17.2366 5.28755 17.9163 6.27956 17.9163C7.27157 17.9163 8.10637 17.2366 8.34349 16.318H17.4672C17.7613 16.318 17.9999 16.0794 17.9999 15.7853C17.9999 15.4912 17.7613 15.2525 17.4672 15.2525H8.34349C8.10637 14.334 7.27157 13.6543 6.27956 13.6543C5.28755 13.6543 4.45275 14.334 4.21563 15.2525Z"
        fill={colors.primary[600]} />
      <Path
        fillRule='evenodd'
        d="M10.5693 9.92637H2.53275C2.23866 9.92637 2 10.165 2 10.4591C2 10.7532 2.23866 10.9919 2.53275 10.9919H10.5693C10.8059 11.9104 11.6406 12.5901 12.6333 12.5901C13.6253 12.5901 14.4601 11.9104 14.6966 10.9919H17.4498C17.7439 10.9919 17.9826 10.7532 17.9826 10.4591C17.9826 10.165 17.7439 9.92637 17.4498 9.92637H14.6966C14.46 9.00785 13.6253 8.32812 12.6333 8.32812C11.6408 8.32812 10.806 9.00785 10.5693 9.92637Z"
        fill={colors.primary[600]} />
    </Svg>
  );
};

