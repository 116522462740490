import React, { useState } from "react";

import { OffersByBrandCard } from "./OffersByBrandCard";
import {
  useAllCommercialPoliciesWithBrand,
  refactorResult,
} from "@hooks/useAllCommercialPolicies";
import { BrandType } from "../../types/BrandType";
import { useAuth } from "../../contexts/Auth";
import { List } from "../List";
import { PolicyRuleEnum } from "@enums/PolicyRuleEnum";
import { View, useMediaQuery } from "native-base";

export const OffersByBrandTab = () => {
  const { user } = useAuth();
  const [brands, setBrands] = useState<BrandType[]>([]);
  const [brandsSearch, setBrandsSearch] = useState<BrandType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 900,
    },
  ]);

  const numColumns = isSmallScreen ? 2 : 3
  const { data, loading, error } = useAllCommercialPoliciesWithBrand(
    true,
    {
      isEnabled: true,
      industryCode: user.getIndustry(),
    },
    false,
    {
      policyRule: [PolicyRuleEnum.PROGRESSIVE_BY_BRAND],
      policyRuleNot: [
        PolicyRuleEnum.REQUIRED_PRODUCT
      ]
    }
  );

  if (data && first) {
    const brandsRefactor = refactorResult(data.allCommercialPolicies);
    setFirst(false);
    setBrands(brandsRefactor);
    setBrandsSearch(brandsRefactor);
  }

  const search = async (text: string) => {
    const result = brands.filter(
      (brand) => brand.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
    );

    if (text.length > 0) {
      return setBrandsSearch(result);
    }
    return setBrandsSearch(brands);
  };

  return (
    <List
      data={brandsSearch}
      loading={loading}
      error={error}
      numColumns={numColumns}
      total={brandsSearch.length}
      showPagination={!isSmallScreen}
      containerTitle={isSmallScreen ? '' : "Famílias disponíveis"}
      columnWrapperStyle={{
        justifyContent: isSmallScreen
          ? "space-between"
          : "flex-start",
        marginBottom: 20
      }}
      contentContainerStyle={{
        justifyContent: "space-around",
      }}
      _searchBar={{
        variant: isSmallScreen ? 'outline' : ''
      }}
      keyExtractor={(item) => `${item.id + item.idCommercialPolicy}`}
      renderItem={({ item, index }) => (
        <View
          maxW={['46%', '46%', '46%', '33%']}
          flex={1}
          key={index}
        >
          <OffersByBrandCard
            brand={item} />
        </View>
      )}
      refetch={(_, text) => {
        search(text);
      }}
    />
  );
};
