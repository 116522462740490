import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

export enum ItemTypeEnum {
  AGGREGATOR = 'AGGREGATOR',
  COMBO = 'COMBO',
  OFFERS_BY_BRAND = 'OFFERS_BY_BRAND',
}

type ShippingOrderInputType = {
  code: string,
  paymentTerm?: string | null,
}

export type ChangeShippingOrderInput = {
  cartId: string,
  shippingOrder: ShippingOrderInputType[],
}

const CHANGE_SHIPPING_ORDER = gql`
  mutation ChangeShippingOrder($input: Cart_ChangeShippingOrderInputType!) {
    Cart_changeShippingOrder(input: $input) {
      id
      customerCode
      industryCode
      status
      code
      customCode
      userId
      grossPrice
      netPrice
      valid
      discountValue
      coupons
      shippingOrders {
        code
        paymentTerm
      }
      items {
        code
        amount
        totalDiscount
        commercialPolicyId
        type
        valid
        unitGrossPrice
        unitNetPrice
        grossPrice
        netPrice
        discountValue
        discount
        errors {
          type
          message
        }
        discountsTrace
        coupons {
          name
          percentage
        }
        product {
          code
          description
          brand {
            id
            description
          }
          assortment {
            name
          }
        }
        suggestions {
          list {
            name
            code
            amount
          }
          anchor {
            anchorAmount
          }
        }
      }
    }
  }
`;

export const useChangeShippingOrder = () => {
  return useMutation(CHANGE_SHIPPING_ORDER, {
    onCompleted(data) {
      if (data.Cart_changeShippingOrder) {
        cartVar(data.Cart_changeShippingOrder)
      }
    },
  });
};
