
import React from "react";
import { Box, Button, Link, Text, View } from "native-base";
import packageInfo from "../../../package.json";
import { FuncionalCircleIcon } from "@components/FuncionalCircleIcon";
import { ProfileCard } from "@components/More/ProfileCard";
import { Logout } from "@components/More/Logout";
import { useAuth } from "@contexts/Auth";
import { TargetSwitcher } from "@components/More/TargetSwitcher";
import { LinkTradeTools } from "@components/LinkTradeTools";

export const More = ({ navigation }: any) => {
  const { user } = useAuth()
  const onToPrivacyPolicy = () => {
    navigation.navigate("PrivacyPolicy");
  };

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      px={3}
      mt={6}
      mb={6}
    >
      <ProfileCard mb={"4"} />
      <TargetSwitcher navigation={navigation} profile={user.getProfile()} />
      <LinkTradeTools
        user={user}
        alignSelf={"flex-start"}
      />
      <Button
        variant={"unstyled"}
        testID="more:term"
        w="100%"
        p={2}
        mb={3}
        onPress={onToPrivacyPolicy}
        alignItems="center"
        justifyContent="flex-start"
        _hover={{
          opacity: 0.8,
        }}
        _pressed={{
          bgColor: "muted.300",
        }}
        _text={{
          color: "primary.600",
          fontWeight: "700",
          fontSize: ["md", "lg"],
        }}
        _dark={{
          opacity: 0.7,
          _pressed: {
            bgColor: "muted.700",
          },
        }}
      >
        Termos de uso e Política de privacidade
      </Button>
      <Logout />
      <View justifyContent={"end"} flex={1}>
        <Box alignItems="center" justifyContent="space-between" height={52}>
          <FuncionalCircleIcon width={25} height={25} />
          <Text color={"muted.600"} fontSize="sm">
            Versão {packageInfo.version}
          </Text>
        </Box>
      </View>
    </View>
  );
};
