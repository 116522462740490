import React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

import { useTheme } from 'native-base';

export const FuncionalCircleIcon = ({ color, width, height, title, htmlColor }: any) => {
    const theme = useTheme();
    return (
        <Svg
            height={height}
            viewBox="0 0 48 48"
            width={width}
            xmlns="http://www.w3.org/2000/svg">
            {title && <title>{title}</title>}
            <Circle cx="24" cy="24" r="24" fill="#13C051" />
            <Path
                d="M28.9418 10C27.8116 10 25.8589 10.172 25.0588 10.5176C24.2573 10.8617 23.6133 11.315 23.1241 11.8774C22.6348 12.4384 22.2823 13.0907 22.0652 13.8297C21.8481 14.5702 21.7395 15.3241 21.7395 16.0886V19.0985H18.7028V22.4659H21.738L21.7351 30.7072L21.7395 31.9099C21.7395 32.803 21.512 33.4478 21.054 33.8427C20.6331 34.2077 20.0085 34.3962 19.198 34.4246H17V38H19.2783V37.997C20.4264 37.9761 22.1916 37.8055 22.9426 37.4824C23.7427 37.1383 24.3881 36.6835 24.8774 36.1226C25.3652 35.5601 25.7191 34.9093 25.9363 34.1688C26.1534 33.4283 26.2619 32.6744 26.2619 31.9099V29.114H26.2575L26.259 22.4659H29.4549V19.1H26.259V16.0901C26.259 15.197 26.488 14.5522 26.9446 14.1573C27.4011 13.7609 28.0882 13.5634 29.0028 13.5634V13.5604H31V10.003H28.9418V10Z"
                fill="white"
            />
        </Svg>
    );
};

