import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { View, Text, FlatList, VStack } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";
import { height, width } from "../components/Dimension";
import { ProductCart } from "../components/ProductCart";
import { IconButton } from "react-native-paper";
import { LinkButton } from "../components/LinkButton";
import { useRoute, RouteProp, useNavigation } from "@react-navigation/native";
import { RouteParams } from "../types/RouteParams";
import { useAuth } from "../contexts/Auth";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { Loader } from "@components/Loader";
import { useCartModal } from "@hooks/useCartModal";
import { CloseButtonCircle } from "@components/CloseButtonCircle";
import { SideModal } from "@components/SideModal";
import { THEME } from "@theme/index";
import { SuggestionProductCart } from "@components/SuggestedProducts/SuggestionProductCart";
import { ItemType } from "@types/CartType";
import { ShoppingCartProductList } from "./ShoppingCartProductList";

export const ShoppingCartModal = () => {
  const route = useRoute<RouteProp<RouteParams, "Industry">>();
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore() || route?.params?.industry;
  const navigation = useNavigation();
  const { cart, clearCart, loading } = useShoppingCart();
  const cartModal = useCartModal();

  function closeModal() {
    cartModal.onClose();
  }

  const itemRemoves = async () => {
    await clearCart();
  };

  return (
    <SideModal isVisible={cartModal.isOpen} close={closeModal} side="right">
      <View style={styles.container} height={"100%"}>
        {loading && (
          <Loader
            _spinner={{
              accessibilityLabel: "Carregando carrinho",
              testID: "loading-modal-cart",
            }}
          />
        )}
        <View height={70} padding={"30px"} flexDirection={"column"}>
          <View flex={1} flexDirection={"row"}>
            <View flex={3} alignSelf={"center"}>
              <Text color={"gray.600"} fontWeight={500} fontSize={["lg", "xl"]}>
                Carrinho de compras
              </Text>
            </View>
            <View flex={1} alignSelf={"center"} alignItems={"flex-end"}>
              <CloseButtonCircle
                size={24}
                close={() => closeModal()}
                color={THEME.colors.green[300]}
              />
            </View>
          </View>
          <View mt={5}>
            {cart?.items.length !== 0 && (
              <TouchableOpacity activeOpacity={0.5} onPress={itemRemoves}>
                <Text style={styles.btnClearCart}>Limpar Carrinho</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>

        {cart?.items.length !== 0 ? (
          <View height={"80%"}>
            <View style={styles.containerProducts}>
              <View>
                <ShoppingCartProductList cart={cart}/>
              </View>
              <View>
                <SuggestionProductCart cart={cart}/>
              </View>
            </View>
            <View style={styles.containerAction}>
              <LinkButton
                onPressLink={() => {
                  navigation.navigate("SummaryOrder", {
                    industry: industry,
                  } as never);
                  closeModal();
                }}
                style={[styles.btnFinish, styles.btn]}
              >
                <Text style={[styles.btnFinishText, styles.btnText]}>
                  Ir para o carrinho
                </Text>
              </LinkButton>
            </View>
          </View>
        ) : (
          <View style={styles.container} height={"80%"} width={width* 0.26}>
            <View style={styles.containerNoProducts} height={"80%"}>
              <View style={styles.containerIcon}>
                <IconButton
                  icon={"cart-outline"}
                  color="#686868"
                  size={RFValue(35)}
                  rippleColor="#686868"
                />
              </View>
              <Text style={styles.titleNoProducts}>
                Ah não! {"\n"} Seu carrinho está vázio!
              </Text>
              <Text style={styles.textNoProducts}>
                Aproveite nossas ofertas.
              </Text>
            </View>

            <View style={styles.containerAction}>
              <LinkButton
                to={{
                  screen: "Industry",
                  params: { industry: industry },
                }}
                style={[styles.btnFinish, styles.btn]}
              >
                <Text style={[styles.btnFinishText, styles.btnText]}>
                  Continuar comprando
                </Text>
              </LinkButton>
            </View>
          </View>
        )}
      </View>
    </SideModal>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 0,
    backgroundColor: "#FFFFFF",
  },

  backgroundGradient: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: width * 0.035, // 35,
  },

  textTitle: {
    color: "#484848",
    fontSize: RFValue(11),

    fontWeight: "500",
  },
  containerIcon: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "auto",
  },
  closeButtonCircle: {
    borderRadius: Math.round(width + height) / 2,
    width: width * 0.023, // 30,
    height: width * 0.023, // 30,
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    alignSelf: "flex-end",
    marginLeft: width * 0.05, // 61,
  },

  containerProducts: {
    marginTop: width * 0.007, // 10,
    maxHeight: width * 0.44,
    paddingLeft: width * 0.021, // 30
    paddingBottom: width * 0.007, // 10,
    overflow: "scroll",
    height: height * 0.7
  },

  containerNoProducts: {
    marginTop: width * 0.007, // 10,
    maxHeight: width * 0.44,
    paddingBottom: width * 0.007, // 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  containerAction: {
    justifyContent: "center",
    alignItems: "flex-end",
    paddingLeft: width * 0.021, // 30
    paddingRight: width * 0.021, // 30
  },

  btn: {
    width: "100%", // 300,
    height: width * 0.04, // 55,
    marginBottom: width * 0.007, // 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#74BF48",
  },

  btnFinish: {
    backgroundColor: "#74BF48",
    color: "#FFFFFF",
  },

  btnFinishText: {
    color: "#FFFFFF",
  },

  btnClearCart: {
    color: "#74BF48",
    fontSize: RFValue(6),
  },

  btnText: {
    fontSize: RFValue(8),
  },
  titleNoProducts: {
    fontWeight: "500",
    fontSize: RFValue(11),
    color: "#1C1C1C",
    textAlign: "center",
    flexDirection: "row",
    width: "100%",
    marginBottom: 20,
  },
  textNoProducts: {
    fontWeight: "500",
    fontSize: RFValue(8),
    color: "#484848",
    lineHeight: width * 0.015, // 20,
    textAlign: "center",
    flexDirection: "row",
    width: "100%",
  },
});
