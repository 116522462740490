import React from "react";
import { ScrollView, View } from 'native-base';
import { OffersByBrandTab } from "@components/OffersByBrandTab";

export const Brands = () => {
  return (
    <ScrollView flex={1} showsVerticalScrollIndicator={false} >
      <View px={3}>
        <OffersByBrandTab />
      </View>
    </ScrollView>
  );
};
