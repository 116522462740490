import React, { Suspense, useCallback, useState } from "react";
import { View, Text, Icon, Tooltip, Pressable, Flex, Box } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { CouponApplied } from "@components/CouponApplied";
import { ProductAmountCounter } from "@components/ProductAmountCounter";
import { useShoppingCart } from "@hooks/useShoppingCart";
import helpers from "@helpers/NumberHelper";
import { ErrorType } from "@types/ErrorType";
import { ItemType } from "@types/CartType";
import { ProductSummaryImage } from "@components/ProductSummaryImage";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { TagAlert } from "@components/TagAlert";
import { ProductType } from "@types/ProductType";
import { PRODUCT, refactorResult } from '@hooks/useProduct';
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "@contexts/Auth";
import { Loading } from "@components/Loading";
import SingleProductModal from "@components/SingleProductModal";


type Props = {
  product: ItemType;
  showShopping?: boolean;
};

export function ProductSummary({ product, showShopping = true }: Props) {
  const [countProduct, setCountProduct] = useState(product.amount.toString());
  const [isMessageErrorShown, setIsMessageErrorShown] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(
    undefined
  );
  const { addItem, removeItem } = useShoppingCart();
  const errors: string[] = [];

  product?.errors?.map((e: ErrorType) => {
    errors.push(e.message);
  });
  const hasErrors = errors.length > 0;
  const isSuggestedProduct = product.suggestions?.list?.length > 0;
  const isSurplusItens = product.type === ItemTypeEnum.SURPLUS_ITENS;

  const [singleProduct, setSingleProduct] = useState<ProductType>();
  const [isSingleProductModalOpen, setIsSingleProductModalOpen] =
    useState(false);
  const [queryToGetProducts] = useLazyQuery(PRODUCT);
  const { user } = useAuth();

  async function remove(ean: string) {
    await removeItem(ean);
  }

  function handleInputCount(item: any) {
    if (!isNaN(item)) {
      let newCount = item;
      if (newCount === "") {
        newCount = "0";
      }

      setCountProduct(`${parseInt(newCount)}`);
    }
  }

  async function addToCart(
    item: ItemType,
    quantity: string,
    actualQuantity: string,
    isInput: boolean = false
  ) {
    item = { ...item };
    item.amount = parseInt(quantity);
    if (
      quantity === "0" ||
      parseInt(actualQuantity) + parseInt(quantity) <= 0
    ) {
      try {
        await remove(item.code);
      } catch (error: any) {
        setMessageError(error.message);
        setIsMessageErrorShown(true);
      }

      return;
    }

    try {
      await addItem(item, isInput);
    } catch (error: any) {
      setMessageError(error.message);
      setIsMessageErrorShown(true);
    }
  }

  function handleInputBlur() {
    if (countProduct === "0") {
      remove(product.product.code);
    }
    addToCart(product, countProduct, "0", true);
  }

  function handleOpenSingleProductModal(item: ProductType | undefined) {
    setSingleProduct(refactorResult(item, user?.getNameIndustryStore()));
    setIsSingleProductModalOpen(true);
  }

  const handleCloseSingleProductModal = useCallback(() => {
    setSingleProduct(undefined);
    setIsSingleProductModalOpen(false);
  }, [isSingleProductModalOpen]);

  async function handleModalOpenClick() {
    const variables = {
      variables: {
        code: product.product.code,
      },
    };

    const productData = await queryToGetProducts(variables);

    handleOpenSingleProductModal(
      refactorResult(
        await productData.data.product,
        user?.getNameIndustryStore()
      )
    );
  }

  return (
    <Flex
      direction="row"
      bg="muted.100"
      borderWidth={hasErrors ? 2 : 0}
      borderColor={hasErrors ? "red.600" : "transparent"}
      borderRadius={4}
      shadow={4}
      _dark={{
        bg: "muted.800",
      }}
    >
      <Box w={[24, 26]}>
        <Pressable
          onPress={() => handleModalOpenClick()}
        >
          <ProductSummaryImage
            item={product}
            _image={{
              h: [35, 43],
            }}
          />
        </Pressable>

      </Box>

      <Box testID={`${product.code}:card`} flex={1} p={[2, 4]}>
        <Flex direction="row" justifyContent="space-between">
          <Box w="85%">
            <Text
              fontSize={["xs", "sm"]}
              fontWeight="500"
              color="muted.800"
              numberOfLines={2}
              _dark={{
                color: "muted.400",
              }}
            >
              {product.product.description}
            </Text>

            <Text
              fontSize="xs"
              fontWeight="400"
              color="muted.800"
              _dark={{
                color: "muted.400",
              }}
            >
              {product.code}
            </Text>
            {isSurplusItens && (
              <TagAlert pl={0}>
                Item de falta
              </TagAlert>
            )}
          </Box>

          {!isSuggestedProduct && product.type !== ItemTypeEnum.SURPLUS_ITENS && (
            <Pressable
              testID={`${product.product.code}:remove`}
              onPress={() => {
                remove(product.product.code);
              }}
            >
              <Icon
                as={MaterialCommunityIcons}
                size={[4, 5]}
                name="delete"
                color="muted.800"
                _dark={{
                  color: "muted.300",
                }}
              />
            </Pressable>
          )}
        </Flex>

        <Flex direction="row" justifyContent="space-between" mt={1} mb={3}>
          {product?.coupons?.length > 0 && (
            <CouponApplied testID={`${product.product.code}:extra-discounts`} />
          )}
        </Flex>

        <Box flex={1} justifyContent="end">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {product.totalDiscount > 0 ? (
                <>
                  <Text
                    fontSize={["xs", "sm"]}
                    fontWeight="500"
                    color="muted.700"
                    textDecorationLine="line-through"
                    _dark={{
                      color: "muted.300",
                    }}
                  >
                    R$ {helpers.currency(product.unitGrossPrice, 2)}
                  </Text>

                  <Text
                    fontSize={["sm", "md"]}
                    fontWeight="700"
                    color="muted.900"
                    _dark={{
                      color: "muted.500",
                    }}
                  >
                    R$ {helpers.currency(product.unitNetPrice, 2)}
                  </Text>
                </>
              ) : (
                <Text
                  fontSize={["sm", "md"]}
                  fontWeight="700"
                  color="muted.900"
                  _dark={{
                    color: "muted.500",
                  }}
                >
                  R$ {helpers.currency(product.unitNetPrice, 2)}
                </Text>
              )}
            </Box>

            <Flex direction="row" alignItems="center">
              {hasErrors && (
                <View testID={`${product.product.code}:tooltip-error`}>
                  <Tooltip
                    label={errors?.map((error: string) => {
                      return error;
                    })}
                    bgColor={"gray.450"}
                  >
                    <View>
                      <Icon
                        as={MaterialCommunityIcons}
                        size={[4, 5]}
                        name="alert-circle"
                        color="red.600"
                        mr={2}
                      />
                    </View>
                  </Tooltip>
                </View>
              )}

              {!isSuggestedProduct && product.type !== ItemTypeEnum.SURPLUS_ITENS ? (
                <ProductAmountCounter
                  w={[24, 24, 24, 40]}
                  h={[7, 9]}
                  isLoading={false}
                  isMessageErrorShown={isMessageErrorShown}
                  messageError={messageError}
                  onMessageErrorClose={() => {
                    setIsMessageErrorShown(false);
                    setMessageError(undefined);
                  }}
                  _pressable={{
                    onAddClick: () => {
                      addToCart(product, "1", product.amount.toString());
                    },
                    onRemoveClick: () => {
                      addToCart(product, "-1", product.amount.toString());
                    },
                  }}
                  _input={{
                    value: countProduct,
                    onChangeText: handleInputCount,
                    onBlur: handleInputBlur,
                  }}
                />
              ) : (
                <Box>
                  <View>
                    <Text
                      color={"muted.700"}
                      _dark={{ color: "muted.400" }}
                      fontSize={"xs"}
                      fontWeight="400"
                    >
                      {" "}
                      Quantidade{" "}
                    </Text>
                    <Text
                      fontWeight={700}
                      color={"muted.900"}
                      _dark={{
                        color: "muted.300",
                      }}
                      fontSize={"sm"}
                    >
                      {product?.amount} unidades
                    </Text>
                  </View>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
        {isSingleProductModalOpen && (
          <Suspense fallback={<Loading />}>
            <SingleProductModal
              isOpen={isSingleProductModalOpen}
              item={singleProduct}
              onClose={handleCloseSingleProductModal}
            />
          </Suspense>
        )}
      </Box>
    </Flex>
  );
}
