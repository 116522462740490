import { gql, useMutation } from "@apollo/client";

const SAVE_CUSTOMER_WHOLESALER_BRANCHES = gql`
  mutation SaveCustomerWholesalerBranches(
    $customerCode: String!
    $industryName: String!
    $wholesalersBranch: [Retail_WholesalerBranchesInputType!]
  ) {
    Retail_saveCustomerWholesalerBranches(
      customerCode: $customerCode
      industryName: $industryName
      wholesalersBranch: $wholesalersBranch
    ) {
      industry {
        name
      }
      customerCode
      wholesalersBranch {
        code
        priority
      }
    }
  }
`;

export const useSaveCustomerWholesalerBranches = () => {
  return useMutation(SAVE_CUSTOMER_WHOLESALER_BRANCHES);
};
