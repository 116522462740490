
import { View, useMediaQuery } from 'native-base';
import React from 'react';

interface Props {
  children: React.ReactNode,
  breakpoint?: number,
  show?: boolean
}

export const CustomHidden = ({children, breakpoint, show }: Props): JSX.Element => {
  
  if(typeof show === 'boolean') {
    return show ? <>{children}</> : <View marginTop={4}></View>
  }

  if(typeof breakpoint !== typeof undefined) {
    const [hide] = useMediaQuery([
      { maxWidth: breakpoint }
    ]);
  
    return hide ? <View marginTop={4}></View> : <>{children}</>
  }

  return <>{children}</>
}