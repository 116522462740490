import { create } from 'zustand';

import { CouponsType } from '@types/CouponsType';

interface CouponRulesModalStore {
  isOpen: boolean;
  coupon?: CouponsType
  onOpen: () => void;
  onClose: () => void;
  setCoupon: (coupon: CouponsType) => void;
}

export const useCouponRulesModal = create<CouponRulesModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false, coupon: undefined }),
  setCoupon: (coupon: CouponsType) => set({ coupon }),
}));
