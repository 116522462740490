

import { Center, CloseIcon, Heading } from "native-base";
import React from "react";

export const EmptyCustomerList = () => {
  return (
    <Center flex={1}>
      <Center
        w={[16, 20]}
        h={[16, 20]}
        bg="danger.300"
        rounded={["2xl", "3xl"]}
        mb={[6, 8]}
      >
        <CloseIcon size={[8, 10]} color="red.500" />
      </Center>
      <Heading
        fontSize={["md", "xl"]}
        fontFamily="heading"
        color="muted.800"
        lineHeight="xl"
        _dark={{
          color: "muted.400"
        }}
      >
        Ooops!{"\n"}Sem clientes disponíveis no momento.
      </Heading>
    </Center>
  );
}