import React, { useEffect } from "react";
import { View, Text } from 'native-base';
import { IconButton } from "react-native-paper";
import { RFValue } from "react-native-responsive-fontsize";
import Modal from "react-native-modal";

import { AlertCoupon } from "./AlertCoupon";
import { CouponsType } from "../../types/CouponsType";
import { ItemCoupon } from "./ItemCoupon";
import { ModalRules } from "./ModalRules";
import { useCoupon } from '@hooks/useCoupon';
import { useCouponModal } from "@hooks/useCouponModal";
import { useCouponRulesModal } from "@hooks/useCouponRulesModal";
import { useAlertCouponModal } from "@hooks/useAlertCouponModal";
import { Loading } from "../Loading";

import { styles } from "./styles";
import { Config } from "@configs/Config";

type ModalCouponProps = {
  activeRedeemCoupon?: boolean;
}

export const ModalCoupon: React.FC<ModalCouponProps> = ({ activeRedeemCoupon = true }) => {

  const { loading, coupons, loadDiscounts } = useCoupon();
  const couponModal = useCouponModal()
  const couponRulesModal = useCouponRulesModal()
  const alertCouponModal = useAlertCouponModal()

  function closeModal() {
    couponModal.onClose()
  }

  const onPressModal = async () => {
    couponModal.onOpen()
    await loadDiscounts()
    alertCouponModal.onClose()
    alertCouponModal.onInvisible()
  }

  const onPressModalClose = () => {
    alertCouponModal.onClose()
    alertCouponModal.onInvisible()
  }

  const onPressCloseModalRules = () => {
    couponRulesModal.onClose();
  }

  useEffect(() => {
    if (coupons.length >= 1 && alertCouponModal.isVisible) {
      alertCouponModal.onOpen()
    } else {
      alertCouponModal.onClose()
    }
    alertCouponModal.setCountCoupon(coupons.length)
  }, [coupons])

  const onClickRules = (item: CouponsType) => {
    couponRulesModal.setCoupon(item);
    couponRulesModal.onOpen();
  }

  if (!Config.FT_KUPONO_ENABLED) {
    return null;
  }

  return (
    <>
      <Modal
        animationIn="slideInRight"
        animationOut="slideOutRight"
        onBackdropPress={closeModal}
        backdropOpacity={0.4}
        coverScreen={true}
        isVisible={couponModal.isOpen}
        style={styles.modalStyle}
      >
        <View style={styles.container}>
          <View style={styles.containerTitle}>
            <Text style={styles.textTitle}>Cupons disponíveis</Text>
            <View style={styles.closeButtonCircle}>
              <IconButton
                icon={"close"}
                color="#74BF48"
                centered={true}
                size={RFValue(9)}
                onPress={() => {
                  closeModal();
                }}
              />
            </View>
          </View>
          <View style={styles.containerItems}>
            {loading ? <Loading /> : <ItemCoupon coupons={coupons} onClickRules={onClickRules} activeRedeemCoupon={activeRedeemCoupon} />}
          </View>
        </View>
      </Modal>

      {alertCouponModal.isVisible &&
        <AlertCoupon
          onPressModal={onPressModal}
          onPressModalClose={onPressModalClose}
          activeAlertModal={alertCouponModal.isOpen}
          quantityCoupons={alertCouponModal.countCoupon}
        />
      }
      <ModalRules
        open={couponRulesModal.isOpen}
        item={couponRulesModal.coupon}
        onClose={onPressCloseModalRules}
      />
    </>
  );
};