import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';
import { CustomerByUserTypeEdited } from '@hooks/useCustomersById';

type ItemListManageCustomersProps = {
  type: 'WEB' | 'MOBILE';
  item: CustomerByUserTypeEdited;
  press: (item:CustomerByUserTypeEdited)=> void;
  index?: number
}

export function ItemListManageCustomers({
  type,
  item,
  press,
  index = 0
}: ItemListManageCustomersProps) {
  return (
    type === 'WEB' ? (
      <Web item={item} press={press} index={index}/>
    ) : (
      <Mobile item={item} press={press}/>
    )
  );
}