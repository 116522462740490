import { styles } from "./styles";

export const containerDealersStyleProps = {
  maxW: 1400,
  borderWidth: [0, 1],
  borderColor: styles.border.color,
  borderRadius: 5
}

const btn = {
  _hover: {
    opacity: 1.0,
  },
  _dark: {
    opacity: 0.8,
  },      
  mb: 2,          
  fontWeight: '700',
  fontSize: "md" 
}

export const btnSaveStyleProps = {
  _pressed: {
    bgColor:  styles.btnEnabledPressed.color
  },  
  bgColor: styles.btnEnabled.color,
  ...btn
}

export const btnCancelProps = {  
  _pressed: {
    bgColor:  styles.btnTransparentPressed.color,
  },
  bgColor: styles.btnTransparent.color,
  ...btn
}

export const btnDisabled = {
  cursor: styles.btnDisabled.cursor, 
   bgColor: styles.btnDisabled.color,
  ...btn  
}