import React from 'react';
import { Popover, Text, Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface props {
  trigger: (_props: any, state: {
    open: boolean;
  }) => JSX.Element;
  onClose:() => void,
  message: string | undefined,
  isOpen: boolean,
  accessibilityLabel?: string,
}

export const PopoverMessageValidation = (props: props) => {
  const { trigger, onClose, message, isOpen, accessibilityLabel } = props;

  return  ( 
    <Popover isOpen={isOpen} trigger={trigger} isKeyboardDismissable onClose={onClose}>
      <Popover.Content accessibilityLabel={accessibilityLabel} w="56">
        <Popover.Arrow />
        <Popover.CloseButton onPress={onClose} />
        <Popover.Header alignItems="center">
          <Icon
            as={MaterialCommunityIcons}
            size={[4, 5]}
            name='alert-outline'
            color='red.600'
          />
        </Popover.Header>
        <Popover.Body>
          <Text color="red.600" textAlign="center">
            {message}
          </Text>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  )
};