import React, { ReactElement, ReactNode, useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import {
  Pressable,
  ScrollView,
  View,
  Text,
  Box,
  VStack,
  HStack,
  Center,
  Hidden,
  useDisclose,
  Actionsheet,
  CloseIcon
} from 'native-base';

import { DropDownPaymentTerms } from '@components/CardWholesalerBranch/DropDownPaymentTerms';
import { ScreenDimensions } from '@screens/ChooseWholesaler/ScreenDimensions';
import { PaymentTypeEnum } from '@enums/PaymentTypeEnum';
import { PaymentTermType } from '@types/PaymentTermType';
import { WholesalerBranchesType } from '@types/WholesalerBranchesType';

import { GenerateKey } from '@helpers/GenerateKey';
import { TextHelper } from '@helpers/TextHelper';
import { THEME } from '@theme/index'
import { styles } from './styles';

interface Props {
  card: WholesalerBranchesType;
  showBillingOptions: boolean;
  removeCard: () => void;
  selected: (payment: PaymentTermType) => void;
  editWholesaler: () => void;
  isPreferredWholesalerScreen: boolean;
  handleArrowUp: () => ReactNode;
  handleArrowDown: () => ReactNode;
}

export const CardWholesalerBranch = ({
  card,
  showBillingOptions = false,
  removeCard,
  selected,
  editWholesaler,
  isPreferredWholesalerScreen,
  handleArrowUp,
  handleArrowDown
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclose();

  const largeDevices = ["xs", "sm", "md", "lg", "xl", "2xl"];
  const smallDevices = ["base"];
  const [isDropPaymentTermOpen, setIsDropPaymentTermOpen] = useState(false);
  const unselectedText = 'Prazo Cliente';
  const hasBillingConditions = showBillingOptions && card?.wholesaler?.paymentType == PaymentTypeEnum.BILLING_CODE;
  let selectedPaymentText = unselectedText;

  if (card.description === "") {
    return (
      <View w={10} h={10} rounded='full' style={styles.numberOrderCircle}>
        <Text style={styles.numberOrderText} fontSize={["2xl"]}>
          {card.priority}
        </Text>
      </View>
    );
  }

  if (card?.wholesaler?.paymentTerms?.length == 1 && showBillingOptions) {
    card.wholesaler.paymentTerms[0].selected = true;
  }

  const selectedPayment = card?.wholesaler?.paymentTerms?.filter(
    (x: PaymentTermType) => x.selected === true
  );

  if (selectedPayment?.length) {
    selectedPaymentText = selectedPayment[0]?.description;
  }

  if (
    card?.wholesaler?.paymentTerms
    && card?.wholesaler?.paymentTerms?.length >= 1
    && !selectedPayment?.length
  ) {
    card.wholesaler.paymentTerms[0].selected = true;
  }

  const paymentTerms = (props: any): JSX.Element[] => {
    const limit: number = card?.wholesaler?.paymentTerms?.length || 0;
    const paymentTerms = card?.wholesaler?.paymentTerms || [];
    let payments = [];
    let reIndexedPaymentTerms = [];
    let fromIndex = 1;

    for (let element of paymentTerms) {
      reIndexedPaymentTerms[fromIndex++] = element;
    }

    for (let i = 1; i <= limit; i++) {
      let paymentTerm: PaymentTermType = reIndexedPaymentTerms[i];

      payments.push(
        <Pressable w={"100%"}
          key={paymentTerm.id}
          onPress={() => {
            selected(paymentTerm)
            setIsDropPaymentTermOpen(false)
          }}
        >
          <Text
            {...props.text}
            _dark={{
              color: paymentTerm.selected ? styles.linkPaymentTerm.color : styles.fontColorDefaultDark.color
            }}
            _light={{
              color: paymentTerm.selected ? styles.linkPaymentTerm.color : styles.fontColorDefault.color
            }}
            textAlign={"left"}
          >
            ({i}) {paymentTerm.description}
          </Text>
        </Pressable>
      )
    }

    return payments;
  }

  const menuMobilePaymentTerms = (): ReactElement => {
    const limit: number = card?.wholesaler?.paymentTerms?.length || 0;
    const paymentTerms = card?.wholesaler?.paymentTerms || [];
    let payments = [];
    let reIndexedPaymentTerms = [];
    let fromIndex = 1;

    for (let element of paymentTerms) {
      reIndexedPaymentTerms[fromIndex++] = element;
    }

    for (let i = 1; i <= limit; i++) {
      let paymentTerm: PaymentTermType = reIndexedPaymentTerms[i];

      payments.push(
        <Pressable w={"100%"}
          key={paymentTerm.id}
        >
          <Actionsheet.Item
            w={"100%"}
            h={"auto"}
            py={2}
            onPress={() => {
              selected(paymentTerm)
              onClose()
            }}
          >
            <Text
              textAlign={"left"}
              _dark={{ color: paymentTerm.selected ? styles.linkPaymentTerm.color : styles.fontColorDefaultDark.color }}
              color={paymentTerm.selected ? styles.linkPaymentTerm.color : styles.fontColorDefault.color}
              fontSize='sm'
            >
              ({i}) {paymentTerm.description}
            </Text>
          </Actionsheet.Item>
        </Pressable>
      )
    }

    return (
      <View key={card.code}>
        <Pressable onPress={onOpen}>
          <Text
            color="primary.600"
            fontSize="xs"
            fontFamily='heading'
            mr={2}
          >
            Alterar prazo
          </Text>
        </Pressable>

        <Center>
          <Actionsheet isOpen={isOpen} onClose={onClose}>
            <Actionsheet.Content _dragIndicatorWrapperOffSet={{py: '10'}}>
              <Box w="90%" justifyContent="center">
                <Text fontSize="sm" color={styles.fontColorDefault.color} fontWeight={"bold"}>
                  {card.priority}º Distribuidor
                </Text>
                
                <Text fontSize="xs" _dark={{color: styles.fontColorDefaultDark.color}}>
                  Selecione o prazo para faturamento:
                </Text>
              </Box>

              {payments}
            </Actionsheet.Content>
          </Actionsheet>
        </Center>
      </View>  
    )
  }
  
  const cardDimension = {
    width: ScreenDimensions(),
    height: 100
  }

  const menuTermStyleProps = {
    mt: -1,
    w: "full",
    borderWidth: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: "gray.300",
    bgColor: "muted.200",
    _dark:{
      bg: THEME.colors.muted[900],
      borderColor: THEME.colors.muted[800]
    }
  }

  const cardPriorityStyleProps = {
    w: "15%",
    alignItems: "center",
    h: "100%",
    borderRightWidth: 1,
    borderRightColor: "gray.300",
    _dark:{
      bg: THEME.colors.muted[900],
      borderRightColor: THEME.colors.black      
    }    
  }

  const menuStyleProps = {
    web: {
      text: {
        width: "80%",
        my: "5px"
      }
    },
    mobile: {
      text:{}
    }
  }

  const textDarkProps = {
    primary: {_dark: {color: THEME.colors.muted[400]}},
    second: {_dark: {color: THEME.colors.muted[900]}}
  }
  
  return (
    <Box
      width={["full", cardDimension.width]}
      h='100%'
      _dark={{ bg: styles.cardsBackgroundDark.color }}
    >
      <HStack h='100%'>
        <Box
          w={["10%", "0%"]}
          height={"100%"}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Hidden only={largeDevices}>
            <VStack space={4}>
              {handleArrowUp()}
              {handleArrowDown()}
            </VStack>
          </Hidden>
        </Box>

        <Box w={['90%', 'full']} h='100%'>
          <VStack h='100%' justifyContent='center'>
            <VStack
              h={"75%"}
              w={"full"}
              px={2}
              justifyContent='center'
            >
              <Hidden only={largeDevices}>
                <HStack flex={1} alignItems='center' justifyContent='space-between'>
                  <HStack>
                    <Box bg='muted.200' mr={2} borderBottomRadius={2}>
                      <Text
                        px={0.5}
                        p={0.25}
                        fontSize={["xs", "sm"]}
                        {...textDarkProps.second}
                      >
                        {card.priority}º distribuidor
                      </Text>
                    </Box>

                    <Box bg='orange.100' borderBottomRadius={2}>
                      {!isPreferredWholesalerScreen && (
                        <Text px={0.5} fontSize={['xs', 'sm']} {...textDarkProps.second}>
                          {selectedPaymentText}
                        </Text>
                      )}
                    </Box>
                  </HStack>

                  <Pressable onPress={removeCard}>
                    <CloseIcon
                      testID="delete-item-slot"
                      color={styles.deleteIconColor.color}
                      size={[3, 4]}
                    />
                  </Pressable>
                </HStack>
              </Hidden>

              <HStack>
                <Text
                  flex={1}
                  fontSize={["xs", "sm"]}
                  color={styles.fontColorDefault.color}
                  {...textDarkProps.primary}
                  fontWeight={"500"}
                >
                  {card.description}
                </Text>

                <Hidden only={smallDevices}>
                  <Pressable onPress={removeCard}>
                    <CloseIcon
                      testID="delete-item-slot"
                      color={styles.deleteIconColor.color}
                      size={[3, 4]}
                    />
                  </Pressable>
                </Hidden>
              </HStack>

              <Text fontSize='xs' color={styles.fontColorDefault.color} {...textDarkProps.primary}>
                {new TextHelper(card?.code || "").formatCNPJ()}
              </Text>
            </VStack>

            <View h={"25%"} ml={-0.5} flexDirection={"row"}>
              <Hidden only={["base"]}>
                <View {...menuTermStyleProps} flexDirection={"row"}>
                  <View {...cardPriorityStyleProps}>
                    <Text fontSize={["sm", "md"]} fontWeight="500" color="primary.600">
                      {card.priority}
                    </Text>
                  </View>

                  <DropDownPaymentTerms
                    isOpen={isDropPaymentTermOpen}
                    setIsOpen={setIsDropPaymentTermOpen}
                    width={cardDimension.width}
                    header={
                      <Text
                        fontSize={["sm"]}
                        _dark={{
                          color: styles.fontColorDefaultDark.color
                        }}
                        _light={{
                          color: styles.fontColorDefault.color
                        }}
                      >
                        Selecione o prazo para faturamento:
                      </Text>
                    }
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          {...triggerProps}
                          disabled={!hasBillingConditions}
                          title={selectedPaymentText}
                          testID="drop-down-payment-terms"
                          onPress={() => {
                            setIsDropPaymentTermOpen(true)
                          }}
                        >
                          <View w={(cardDimension.width * 84 / 100)}
                            h={"100%"}
                            color={"primary.600"}
                            alignItems={"center"}
                            flexDirection={"row"}
                            bgColor={isDropPaymentTermOpen ? "primary.700" : "muted.200"}
                            _dark={{
                              bg: THEME.colors.muted[900]
                            }}
                            marginRight={(-cardDimension.width * 15 / 100)}
                            borderBottomRightRadius={5}
                          >
                            <View w={"90%"}>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                alignSelf="center"
                                color={isDropPaymentTermOpen ? "white" : "primary.600"}
                              >
                                {selectedPaymentText}
                              </Text>
                            </View>

                            {hasBillingConditions && (
                              <View w={"10%"}>
                                <AntDesign
                                  name={isDropPaymentTermOpen ? "up" : "down"}
                                  color={isDropPaymentTermOpen ? "white" : styles.iconPopover.color}
                                />
                              </View>
                            )}
                          </View>
                        </Pressable>
                      )
                    }}
                  >
                    <Box
                      safeAreaTop
                      minH={"auto"}
                      maxH={300}
                      w="100%"
                    >
                      <ScrollView showsVerticalScrollIndicator={true}>
                        <View
                          flex={1}
                          width={"100%"}
                          h={10}
                          alignItems={"start"}
                          key={GenerateKey()}
                        >
                          {paymentTerms(menuStyleProps.web)}
                        </View>
                      </ScrollView>
                    </Box>
                  </DropDownPaymentTerms>
                </View>
              </Hidden>

              <Hidden only={largeDevices}>
                <HStack flex={1} justifyContent='end'>
                  <Pressable onPress={editWholesaler}>
                    <Text
                      color='primary.600'
                      fontSize='xs'
                      fontFamily='heading'
                      mr={2}
                    >
                      Editar
                    </Text>
                  </Pressable>

                  {hasBillingConditions && (menuMobilePaymentTerms())}
                </HStack>
              </Hidden>
            </View>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};