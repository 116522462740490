
import React from "react";
import { IButtonProps, Button as ButtonNative } from "native-base";

type ButtonAlterProps = IButtonProps & {
  text: string
}

export const Button: React.FC<ButtonAlterProps> = ({ onPress, text, ...restPros }) => {
  return (
    <ButtonNative
      variant={'unstyled'}
      testID='more:alter'
      w='100%'
      p={2}
      mb={3}
      onPress={onPress}
      alignItems='center'
      justifyContent='flex-start'
      _hover={{
        opacity: 0.8,
      }}
      _pressed={{
        bgColor: "muted.300",
      }}
      _text={{
        color: 'primary.600',
        fontWeight: '700',
        fontSize: ['md', 'lg']
      }}
      _dark={{
        opacity: 0.7,
        _pressed: {
          bgColor: "muted.700"
        }
      }}
      {...restPros}
    >
      {text}
    </ButtonNative>
  );
};
