import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export class DateConvert {
  constructor(private date: string | Date) {}

  getExpirationDate() {
    const date = typeof this.date === "string" ? parseISO(this.date) : this.date;
    const day = format(date, "dd");
    const month = format(date, "LLL", { locale: ptBR });
    const year = format(date, "yy");

    return `${day}/${month}/${year}`;
  }
  
  formatDate() {
    const date = typeof this.date === "string" ? parseISO(this.date) : this.date;
    const day = format(date, "dd");
    const month = format(date, "LL", { locale: ptBR });
    const year = format(date, "yyyy");

    return `${day}/${month}/${year}`;
  }

  getHour() {
    const date = typeof this.date === "string" ? parseISO(this.date) : this.date;
    const hours = format(date, "HH");
    const minutes = format(date, "mm");

    return `${hours}:${minutes}`
  }
}
