import { gql, makeVar, useLazyQuery, useReactiveVar } from "@apollo/client";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";

const QUERY = gql`
  query (
    $customer: CustomerInputType!
    $industry: SimulateIndustryInputType!
    $availability: [AvailabilityConsolidateEnum!]
  ) {
    hasCommercialPolicy(
		customer: $customer
		industry: $industry
    availability: $availability
	)
  }
`;

interface Filters {
  industry?: IndustryInput
  customer?: CustomerInput
  availability?: AvailabilityConsolidateEnum[]
}

interface IndustryInput {
  code: string
}

interface CustomerInput {
  code: string
}

export const filtersVar = makeVar<Filters>({});

export const useHasCommercialPolicy = () => {
  const filter = useReactiveVar(filtersVar)
  return useLazyQuery(QUERY, {
    fetchPolicy: "cache-first",
    variables: {
      ...filter
    }
  });
};

