import { useQuery, gql } from "@apollo/client";

const CUSTOMER = gql`
  query Customer($customerCode: String!, $industryCode: String!) {
    customer(code: $customerCode, industryCode: $industryCode) {
      name
      code
      phone
      contact
      address {
        street
        complement
        cep
        city
        state
      }
    }
  }
`;

export const useCustomer = (customerCode: string, industryCode: string) => {
  return useQuery(CUSTOMER, {
    variables: {
      customerCode,
      industryCode,
    },
  });
};
