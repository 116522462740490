import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Factory, HStack, Icon, Modal, Text } from 'native-base';
import { AntDesign } from '@expo/vector-icons';

import { useScheduleResponsiveOverlayError } from '@hooks/useScheduleResponsiveOverlayError';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Web() {
  const { isOpen, onClose, message } = useScheduleResponsiveOverlayError();

  function handleUpdateOrder() {
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="504px" px={6} pb={6}>
        <Modal.CloseButton _icon={{
          size: 4,
          color: 'primary.600'
        }} />

        <Modal.Header pl={0}>
          <HStack alignItems='center'>
            <Icon
              as={AntDesign}
              name='warning'
              color='red.500'
              size={8}
              mr={4}
            />

            <Text color='red.500' fontSize='lg' fontFamily='subheading'>
              Alerta de agendamento
            </Text>
          </HStack>
        </Modal.Header>

        <Modal.Body px={0} pb={0} mb={12}>
          <Text fontSize='md' color='muted.800'>
            {message}
          </Text>
        </Modal.Body>

        <TouchableOpacityFactory
          testID='manage-customers-error-web_update-order'
          bg='primary.600'
          h={12}
          rounded='sm'
          p={4}
          mb={4}
          alignItems='center'
          justifyContent='center'
          _dark={{
            opacity: 0.8,
          }}
          onPress={handleUpdateOrder}
        >
          <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
            Ok
          </Text>
        </TouchableOpacityFactory>
      </Modal.Content>
    </Modal>
  );
}