import { CastProductEnum } from "@helpers/CastProductEnum";
import { useAddItemOverwriteAlert } from "./useAddItemOverwriteAlert";
import { useShoppingCart } from "./useShoppingCart";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { ItemType } from "@types/CartType";
import { useAddItemExistsInListAlert } from "./useAddItemExistsInListAlert";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { useEffect, useState } from "react";
import { ProductValidationErrorsEnum } from "@enums/ProductValidationErrorsEnum";
import { useFractionationAlertModal } from "./useFractionationAlertModal";

export const useAddItemCart = () => {
  const { addItem: addItemInCart, cart } = useShoppingCart();
  const add = useAddItemOverwriteAlert();
  const listWarning = useAddItemExistsInListAlert();
  const frac = useFractionationAlertModal();

  const [item, setItem] = useState<any>(undefined);

  useEffect(() => {
    if (!item) {
      return;
    }

    const itemInCart = cart?.items?.find(cartItem => cartItem.code === item?.product?.code);
    const error = itemInCart?.errors.find(erro => erro.type === ProductValidationErrorsEnum.FRACTIONATION_AMOUNT_INVALID);

    if (error) {
      setItem(undefined)
      frac.onOpen()
    }
  }, [cart, item])

  const addItem = async (item: CommercialPolicyProductsType,
    onSuccess: () => void = () => { },
    onError: (error: any) => void = () => { },
    onClose: () => void = () => { },
  ) => {
    const itemInCart = cart?.items?.find(cartItem => cartItem.code === item?.product?.code);
    const type = CastProductEnum(item?.product?.tab)
    const existsInList = !!itemInCart && itemInCart?.type !== type && itemInCart?.type == ItemTypeEnum.SUGGESTED_LIST;

    if (existsInList) {
      listWarning.setWarning();
      onClose();

      add.setOnSuccessCallback(async () => {
        onClose();
      });

      add.setOnFailCallback(async () => {
        onClose()
      });

      listWarning.onOpen()
      return;
    }

    if (!!itemInCart && (itemInCart?.type !== type || itemInCart?.commercialPolicyId !== item.commercialPolicyId)) {
      add.setNewProduct({
        description: itemInCart.product.description,
        discount: itemInCart.discount
      })

      add.setOnFailCallback(async () => {
        onClose()
      })

      add.setOnSuccessCallback(async () => {
        try {
          await addItemInCart({
            amount: item.product.quantity,
            code: item.product.code,
            commercialPolicyId: item.commercialPolicyId ?? 0,
            type: type,
          } as ItemType, true)
          onSuccess()
        } catch (error) {
          onError(error)
        }
      })

      add.onOpen()
      return;
    }

    try {
      await addItemInCart({
        amount: item.product.quantity,
        code: item.product.code,
        commercialPolicyId: item.commercialPolicyId ?? 0,
        type: type,
      } as ItemType)

      const itemInCart = cart?.items?.find(cartItem => cartItem.code === item?.product?.code);
      setItem(itemInCart)
      onSuccess()
    } catch (error) {
      onError(error)
    }
  }

  return { addItem }
};
