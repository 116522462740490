import React, { useCallback, useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { View, Text, Modal, Factory, HStack, Stack, useMediaQuery, VStack, Progress } from "native-base";
import { SearchBar } from "@components/SearchBar";
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { useValidateCommercialPoliciesByCustomer } from "@hooks/useValidateCommercialPoliciesByCustomer";
import { useValidateCustomersModal } from "@hooks/useValidateCustomersModal";
import { ValidateCustomersList } from "@components/ValidateCustomersList";
import { AdditionalCustomersType } from "@types/AdditionalCustomersType";
import { useFocusEffect } from "@react-navigation/native";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useAuth } from "@contexts/Auth";
import { IntegrationEnum } from "@enums/IntegrationEnum";
import { TextHelper } from "@helpers/TextHelper";
import { useResultSendOrderResponsiveOverlay } from "@hooks/useResultSendOrderResponsiveOverlay";
import { AdditionalCustomersInputType } from "@hooks/useCheckout";
import { SendOrderResponsiveOverlay } from "@components/SendOrderResponsiveOverlay";
import { useSendOrderResponsiveOverlay } from "@hooks/useSendOrderResponsiveOverlay";
import { Loading } from "@components/Loading";

export const ValidateCustomersModal = () => {
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 640 }]);
  const FactoryOpacity = Factory(TouchableOpacity);
  const customersModal = useValidateCustomersModal();
  const [searchText, setSearchText] = useState("");
  const [customers, setCustomers] = useState<AdditionalCustomersType[]>([]);
  const [data, setData] = useState<AdditionalCustomersType[]>([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [query, { loading: loadingValidateCustomers }] = useValidateCommercialPoliciesByCustomer();
  const [successAmount, setSuccessAmount] = useState(0);
  const [failAmount, setFailAmount] = useState(0);
  const { getAdditionalCustomers, loading: loadingCart } = useAdditionalCustomers();
  const [progressValue, setProgressValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { cart, checkout } = useShoppingCart();
  const includesStatusCustomers = (customersToEdit: AdditionalCustomersType[]) => customersToEdit?.map((item: AdditionalCustomersType) => { return { ...item, status: undefined } })
  const { user } = useAuth();
  const industryCode = user.getNameIndustryStore();
  const resultSendOrderDialog = useResultSendOrderResponsiveOverlay();
  const sendOrderDialog = useSendOrderResponsiveOverlay();

  const args = {
    industry: {
      code: industryCode,
    },
    integrations: [IntegrationEnum.POINT_OF_SALES],
    availability: user.isIndustryEmployee() ? 'INDUSTRY' : 'CUSTOMER'
  };

  const fillAdditionalCustomers = async () => {
    try {
      const finalCustomers: AdditionalCustomersType[] = [];
      const asyncCustomers = await getAdditionalCustomers(cart?.id);
      setData(() => includesStatusCustomers(asyncCustomers));
      setCustomers(() => includesStatusCustomers(asyncCustomers));

      for (const element of asyncCustomers) {
        const { data } = await query({ variables: { customer: { code: element.code }, commercialPolicy: { ids: getCommercialPoliciesIds() }, ...args } });
        finalCustomers.push({ ...element, status: data?.validateCommercialPoliciesByCustomer?.valid });
        setProgressValue((value) => value + 1);
      }

      setCustomers(finalCustomers);
      setData(finalCustomers);
    } catch (e) {
      console.log(e);
    }
  }

  const getFilteredDataByFilterButton = () => {
    const filteredData = customers.filter((item: AdditionalCustomersType) => {
      if (selectedFilter == 1 && item.status == true) return true
      if (selectedFilter == 2 && item.status == false) return true
      if (selectedFilter == 0) return true
    });

    return filteredData;
  }

  const handleSearch = (value: string) => {
    const formattedQuery = value.toLowerCase();

    const filteredData = getFilteredDataByFilterButton().filter((item: AdditionalCustomersType) => {
      const formattedCNPJ = new TextHelper(item?.code).formatCNPJ();

      return (
        item?.name?.toLowerCase().includes(formattedQuery)
        || item?.code.includes(formattedQuery)
        || formattedCNPJ.includes(formattedQuery)
      )
    });

    setData(filteredData);
    setSearchText(value);
  };


  function getCommercialPoliciesIds() {
    return [...new Set(cart?.items.map(item => item.commercialPolicyId))];
  }

  useFocusEffect(
    useCallback(
      () => {
        setProgressValue(0);
        if (customersModal.isOpen) {
          fillAdditionalCustomers();
        }
      },
      [customersModal.isOpen]
    )
  )

  useEffect(
    () => {
      setSuccessAmount(getAmountStatusByFilter(1));
      setFailAmount(getAmountStatusByFilter(2));
    }, [customers]
  )

  useEffect(
    () => {
      setData(getFilteredDataByFilterButton());
    },
    [selectedFilter]
  )

  const getAmountStatusByFilter = (filter: number) => {
    const value = customers?.filter(
      (item) => {
        if (filter == 1 && item.status == true) return true
        if (filter == 2 && item.status == false) return true
        if (filter == 0) return true
      }
    )

    return value?.length
  }

  const handlePressComplete = async () => {
    if (loadingValidateCustomers) {
      return;
    }

    sendOrderDialog.onOpen();
    customersModal.onClose();
  }

  const handlePressSendOrder = async () => {
    setLoading(true);

    const additionalCustomers: AdditionalCustomersInputType[] = customers
      .filter(customer => customer.status === true)
      .map(item => {
        return {
          code: item.code
        }
      });

    sendOrderDialog.onClose();

    try {
      await checkout(additionalCustomers);
      resultSendOrderDialog.onOpen('SUCCESS');
    } catch (error) {
      resultSendOrderDialog.onOpen('ERROR');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <Modal
        isOpen={customersModal.isOpen}
        overflow='hidden'
        height='full'
        size={'xl'}
      >
        <Modal.Content maxWidth={'836px'}>
          <Modal.CloseButton
            onPress={() => customersModal.onClose()}
            testID="ValidateCustomersModal-Touchable-Close"
          />

          <Modal.Header
            color={'muted.800'}
            w={'full'}
            fontSize={'lg'}
            fontFamily={'subheading'}
          >
            Validação dos clientes
          </Modal.Header>

          <Modal.Body>
            <HStack
              space={4}
              px={["3", "3", 0]}
              direction={["column", "column", "column", "row"]}
              justifyContent={["center", "center", "space-between"]}
              alignItems={["center", "baseline"]}
              bgColor={["white", ""]}
              _dark={{
                bgColor: ["muted.800", ""],
              }}
              borderBottomWidth={[2, 0]}
              borderColor="coolGray.200"
              borderWidth="0"
            >
              <View width={'full'} pt={4}>
                <SearchBar
                  variant={'outline'}
                  size={['md', 'xs']}
                  placeholder={"Pesquise pelo nome do cliente ou CNPJ"}
                  value={searchText}
                  onChangeText={(e: string) => handleSearch(e)}
                />
              </View>
            </HStack>

            <Progress
              colorScheme={'success'}
              mt={4}
              value={progressValue}
              height={2}
              _filledTrack={{ rounded: 'none', bg: 'success.500' }}
              max={customers?.length} width={'full'}
            />

            <Text
              alignSelf={'flex-end'}
              mr={4}
              fontFamily={'body'}
              color='gray.400'
              fontSize={'xs'}
            >
              Progresso ({progressValue}/{customers?.length})
            </Text>

            <Stack direction={'row'} space={2} mt={'12px'}>
              <FactoryOpacity
                onPress={() => setSelectedFilter(0)}
                width={'84px'}
                height={'22px'}
                borderColor={'gray.500'}
                borderRadius={10}
                borderWidth={1}
                alignItems={'center'}
                justifyContent={'center'}
                backgroundColor={selectedFilter == 0 ? 'gray.500' : 'white'}
                testID="ValidateCustomersModal-Filter-Touchable-All"
              >
                <Text
                  fontFamily={'subheading'}
                  color={selectedFilter == 0 ? 'white' : 'gray.500'}
                  fontSize={'xs'}
                >
                  Todos ({customers?.length})
                </Text>
              </FactoryOpacity>

              <FactoryOpacity
                onPress={() => setSelectedFilter(1)}
                width={'96px'}
                height={'22px'}
                borderColor={selectedFilter == 1 ? 'success.500' : 'gray.500'}
                borderRadius={10}
                borderWidth={1}
                alignItems={'center'}
                justifyContent={'center'}
                disabled={loadingValidateCustomers}
                backgroundColor={selectedFilter == 1 ? 'success.500' : ''}
                testID="ValidateCustomersModal-Filter-Touchable-Success"
              >
                <Text
                  fontFamily={'subheading'}
                  color={selectedFilter == 1 ? 'white' : 'gray.500'}
                  fontSize={'xs'}
                >
                  Sucesso ({successAmount})
                </Text>
              </FactoryOpacity>

              <FactoryOpacity
                onPress={() => setSelectedFilter(2)}
                width={'73px'}
                height={'22px'}
                borderColor={selectedFilter == 2 ? 'danger.700' : 'gray.500'}
                borderRadius={10}
                borderWidth={1}
                alignItems={'center'}
                justifyContent={'center'}
                disabled={loadingValidateCustomers}
                backgroundColor={selectedFilter == 2 ? 'danger.700' : ''}
                testID='ValidateCustomersModal-Filter-Touchable-Error'
              >
                <Text
                  fontFamily={'subheading'}
                  color={selectedFilter == 2 ? 'white' : 'gray.500'}
                  fontSize={'xs'}
                >
                  Erro ({failAmount})
                </Text>
              </FactoryOpacity>
            </Stack>

            <VStack pt={1}>
              <ValidateCustomersList data={data} customers={customers} isMobile={isSmallScreen} />
            </VStack>

            <Stack
              backgroundColor={'white'}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              p={5}
              w={'full'}
              h={20}
              direction={'row'}
            >
              <FactoryOpacity
                onPress={() => customersModal.onClose()}
                justifyContent={"center"}
                alignItems={'center'}
                py={2}
                pr={2}
                borderRadius={4}
                width={48}
                height={12}
                testID="ValidateCustomersModal-Touchable-Cancel"
              >
                <Text fontSize={'sm'} fontWeight={'bold'} color={'primary.600'}>
                  Cancelar
                </Text>
              </FactoryOpacity>

              <FactoryOpacity
                onPress={() => handlePressComplete()}
                justifyContent={"center"}
                alignItems={'center'}
                py={2}
                pr={2}
                backgroundColor={'primary.600'}
                borderRadius={4}
                width={48}
                height={12}
                testID='ValidateCustomersModal-Touchable-Conclude'
              >
                <Text fontSize={'sm'} color={'white'}>
                  Enviar pedido
                </Text>
              </FactoryOpacity>
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </Modal>

      <SendOrderResponsiveOverlay type='WEB' handleSendOrder={handlePressSendOrder} cartCode={cart?.code} />
    </>
  );
};