import React, { useRef } from 'react';
import { View, Text, Pressable } from 'native-base';
import Alert, { useRefAlert, Props as AlertProps } from '@components/Alert';
import { FooterMain } from '@components/Footer';
import { ShopTabs } from '@components/ShopTabs';
import { useShoppingCart } from '@hooks/useShoppingCart';
import { Loader } from '@components/Loader';

type Props = {
  title?: string;
  subtitle?: string;
  isMobile?: boolean;
  shopTab: number;
  children: React.ReactNode;
  industryName: string;
}

export function LayoutOrder({
  title,
  subtitle,
  isMobile = false,
  shopTab,
  children,
  industryName
}: Props) {
  const { clearCart, loading } = useShoppingCart()

  const alertDialog = useRef<useRefAlert>();
  const alertParams: AlertProps = {
    type: 'ALERT',
    headerText: "Limpar o resumo do pedido",
    bodyContent: (
      <Text
        selectable
        fontSize='sm'
        fontWeight='400'
        marginTop={0}
        color='muted.800'
        _dark={{
          color: 'muted.400'
        }}
      >
        Você deseja remover todos os produtos do seu resumo?
      </Text>
    ),
    btnTextConfirm: "Sim",
    btnTextCancel: "Cancelar",
    onConfirmCallback: () => clearCart(),
  };

  function renderWebLayout() {
    return (
      <>
        <View flexDir='column'>
          <Text
            fontSize={['xl', 'xl', 'xl', '3xl']}
            fontWeight='700'
            color='muted.800'
            _dark={{
              color: 'muted.400'
            }}
          >
            {title}
          </Text>

          {shopTab === 1 ? (
            <>
              {renderClearCartButton()}
              <Alert ref={alertDialog} {...alertParams} />
            </>
          ) : (
            <Text
              fontSize={['sm', 'sm', 'sm', 'md']}
              fontWeight='500'
              color='muted.700'
              _dark={{
                color: 'muted.500'
              }}
            >
              {subtitle}
            </Text>
          )}
        </View>

        <ShopTabs active={shopTab} isMobile={false} />
      </>
    );
  }

  function renderClearCartButton() {
    return (
      <Pressable
        onPress={() => alertDialog.current?.show()}
        w='fit-content'
        _hover={{
          opacity: 0.8,
        }}
      >
        <Text
          fontSize={['sm', 'sm', 'sm', 'md']}
          fontWeight='500'
          color='primary.600'
          _dark={{
            color: 'primary.500'
          }}
        >
          {subtitle}
        </Text>
      </Pressable>
    );
  }

  return (
    <View flex={1}>
      <View
        py={[0, 0, 6]}
        px={[0, 12, 12, 24]}
        flex={1}
        shadow='none'
      >
        <View
          flexDir="row"
          justifyContent="space-between"
          marginBottom={4}
        >
          {isMobile ? <ShopTabs active={shopTab} isMobile={true} /> : renderWebLayout()}
        </View>
        <Loader
          visible={loading}
          _spinner={{
            accessibilityLabel: "Carregando carrinho",
            testID: 'loading-modal-cart'
          }}
        />
        {children}
      </View>

      {!isMobile && <FooterMain industryName={industryName} />}
    </View>
  );
};