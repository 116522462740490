import { Platform } from "react-native";

export const PlatformImplementation = (
  webImplementation: () => any,
  mobileImplementation: {
    android?: () => any;
    ios?: () => any;
    default: () => any;
  }
) => {
  if (Platform.OS === "web") {
    return webImplementation();
  }
  if (Platform.OS === "android" && mobileImplementation.android) {
    return mobileImplementation.android();
  }
  if (Platform.OS === "ios" && mobileImplementation.ios) {
    return mobileImplementation.ios();
  }
  if (!mobileImplementation.android || !mobileImplementation.ios) {
    return mobileImplementation.default();
  }
  return webImplementation();
};
