import React, { useState } from "react";
import { View } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { IconButton } from "react-native-paper";
import { CommonDataTable } from "../components/CommonDataTable";
import helpers from "../helpers/NumberHelper";
import { GenerateKey } from "../helpers/GenerateKey";

export const SubOrderDetails = (row: any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const expandButton = (
    <IconButton
      icon={"arrow-down"}
      color="#74BF48"
      size={RFValue(9)}
      rippleColor="rgba(229, 229, 229, 0.5)"
      onPress={toggleIsExpanded}
    />
  );

  const unexpandButton = (

    <IconButton
      icon={"arrow-up"}
      color="#74BF48"
      size={RFValue(9)}
      rippleColor="rgba(229, 229, 229, 0.5)"
      onPress={toggleIsExpanded}
    />
  );

  row.orderData.productIcon = [isExpanded ? unexpandButton : expandButton, 12];

  const productHeader = [
    ["EAN", 10],
    ["Descrição", 19],
    ["Qtd. Pedida", 9],
    ["Qtd. Prevista", 9],
    ["Qtd. Faturada", 9],
    ["Motivo", 10],
    ["Valor Bruto(R$)", 12],
    ["% Desconto", 10],
    ["Valor Líquido(R$)", 12],
  ];

  const products = row.productData.items.map((newRow: any) => {
    return {
      ean: [newRow.product.ean, 10],
      name: [newRow.product.name, 19],
      ordered: [newRow.orderedQuantity, 9],
      expected: [newRow.expectedQuantity, 9],
      billed: [newRow.billedQuantity, 9],
      reason: [newRow.statusDescription, 10],
      value: [helpers.currency((((newRow.unitaryNetPrice / (100 - newRow.discountPercentage)) * 100) * newRow.billedQuantity),2), 12],
      discount: [helpers.currency(newRow.discountPercentage, 2), 10],
      netPrice: [helpers.currency(newRow.unitaryNetPrice * newRow.billedQuantity,2), 12],
    };
  });

  return (
    <View key={GenerateKey()}>
      {CommonDataTable([], [row.orderData], 7)}
      {!!isExpanded && 
      <View key={GenerateKey()} style={{marginLeft: 30}}>
        {CommonDataTable(productHeader, products, 6)} 
      </View>}
    </View>
  );
};
