import React, {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  View,
  Text,
  Stack,
  Button,
  Box,
  IBoxProps,
  Pressable,
  Hidden,
  useMediaQuery,
} from "native-base";
import { useLazyQuery } from "@apollo/client";

import { HasNotConsideredMinimum } from "@components/HasNotConsideredMinimum";
import { PRODUCT, refactorResult } from "@hooks/useProduct";
import { Loading } from "@components/Loading";
import { MinimumAmount } from "@components/MinimunAmount";
import { ProductAmountCounter } from "@components/ProductAmountCounter";
import { ProductCardImage } from "@components/ProductCardImage";
import { ProductCardSkeleton } from "@components/ProductCardSkeleton";
import { ProductName } from "@components/ProductName";
import { ProgressiveProductDiscount } from "@components/ProgressiveProductDiscount";

import { useAuth } from "@contexts/Auth";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import helpers from "@helpers/NumberHelper";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { ProductType } from "@types/ProductType";
import { ProductFractionation } from "@components/ProductFractionation";

const SingleProductModal = lazy(() => import("@components/SingleProductModal"));

export type ProductCardProps = IBoxProps & {
  showShopping?: boolean;
  product: CommercialPolicyProductsType;
  tab: CartProductTypeEnum;
  isLoading?: boolean;
};

export const SuggestionProductCard: React.FC<ProductCardProps> = memo(
  ({
    product,
    showShopping = true,
    tab = CartProductTypeEnum.DEFAULT,
    isLoading = false,
    ...restProps
  }) => {
    const showElementForShopping = showShopping ? {} : { display: "none" };
    const showUnits = !showShopping ? {} : { display: "none" };
    const textAlignCardToBottom = showShopping ? {} : { alignSelf: "end" };
    const [isClicked, setIsClicked] = useState(false);
    const [isMessageErrorShown, setIsMessageErrorShown] = useState(false);
    const [singleProduct, setSingleProduct] = useState<ProductType>();
    const [isSingleProductModalOpen, setIsSingleProductModalOpen] =
      useState(false);
    const [isSmallScreen] = useMediaQuery([{ maxWidth: 480 }]);

    const { cart, loading: loadingCart } = useShoppingCart();
    const products = cart?.items ?? [];
    const { user } = useAuth();

    const cartProduct = useMemo(
      () =>
        products.find(
          (x: any) =>
            x.product.code === product.product.code && tab == x.product.tab
        ),
      [products]
    );

    const [productAmount, setProductAmount] = useState(
      !cartProduct && product.minimumAmount ? product.minimumAmount : 0
    );

    const isStoreProductLoading = useMemo(
      () => (loadingCart && isClicked) || isClicked,
      [isClicked, loadingCart]
    );

    const [queryToGetProducts] = useLazyQuery(PRODUCT);

    async function addCountProduct() {
      setProductAmount(productAmount + 1);
    }

    async function removeCountProduct() {
      setProductAmount(productAmount - 1);
    }

    function handleOpenSingleProductModal(item: ProductType | undefined) {
      setSingleProduct(refactorResult(item, user?.getNameIndustryStore()));
      setIsSingleProductModalOpen(true);
    }

    const handleCloseSingleProductModal = useCallback(() => {
      setSingleProduct(undefined);
      setIsSingleProductModalOpen(false);
    }, [isSingleProductModalOpen]);

    async function handleModalOpenClick() {
      const variables = {
        variables: {
          code: product.product.code,
        },
      };

      const productData = await queryToGetProducts(variables);

      handleOpenSingleProductModal(
        refactorResult(
          await productData.data.product,
          user?.getNameIndustryStore()
        )
      );
    }

    function handleInputCount(item: any) {
      if (!isNaN(item)) {
        let newCount = item;

        if (newCount === "" || isNaN(parseInt(newCount))) {
          newCount = "0";
        }

        setProductAmount(parseInt(newCount));
      }
    }

    function handleInputBlur() {
      return productAmount < 0
        ? setProductAmount(0)
        : setProductAmount(productAmount);
    }

    // TODO: REPENSAR NA LÓGICA DE DESABILITAR CLICK PARA NÃO AFETAR LOADING
    useEffect(() => {
      setIsClicked(false);
    }, [isClicked]);

    if (isLoading) {
      return <ProductCardSkeleton />;
    }

    return (
      <Box
        width={["90%"]}
        rounded="lg"
        height={[300, 330]}
        margin={[0.5, 0.5, 1, 1]}
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        shadow="2"
        _dark={{
          borderColor: "coolGray.600",
          backgroundColor: "gray.700",
        }}
        _web={{
          shadow: 2,
          borderWidth: 0,
        }}
        _light={{
          backgroundColor: "gray.50",
        }}
        {...restProps}
      >
        <Box>
          <Pressable
            onPress={() => handleModalOpenClick()}
            disabled={isSmallScreen}
          >
            <ProductCardImage commercialPolicyProduct={product} />
          </Pressable>
        </Box>

        <View
          background="white"
          flex={2}
          _dark={{
            backgroundColor: "muted.800",
          }}
          height={194}
          borderBottomRadius={10}
        >
          <Stack m={3} space={1} flex={1}>
            <View>
              <ProductName
                description={product.product.description}
                testID={product.product.code}
                press={() => {
                  isSmallScreen ? null : handleModalOpenClick();
                }}
              />

              <Text
                lineHeight={"md"}
                fontSize={["xs", "xs", "xs", "sm"]}
                color={"muted.800"}
                _dark={{
                  color: "muted.400",
                }}
                fontStyle={"normal"}
                fontWeight={"normal"}
                selectable
              >
                {product.product.code}
              </Text>
            </View>
            {product?.wholesaler.fractionation > 1 && <ProductFractionation
              fractionationAmount={product.wholesaler.fractionation}
            />}

            <View
              flex={1}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <View {...textAlignCardToBottom}>
                {product.discount.base > 0 ? (
                  <>
                    <Text
                      color={"muted.700"}
                      _dark={{
                        color: "muted.400",
                      }}
                      fontSize={["2xs", "2xs", "2xs", "xs"]}
                      fontWeight="400"
                      textDecorationLine="line-through"
                    >
                      R${" "}
                      {helpers.currency(product.wholesaler.price.fullPrice, 2)}
                    </Text>

                    <Text
                      fontWeight={700}
                      color={"muted.900"}
                      _dark={{
                        color: "muted.300",
                      }}
                      fontSize={["xs", "xs", "xs", "sm"]}
                    >
                      R${" "}
                      {helpers.currency(product.wholesaler.price.netPrice, 2)}
                    </Text>
                  </>
                ) : (
                  <Text
                    fontWeight={700}
                    color={"muted.900"}
                    _dark={{
                      color: "muted.300",
                    }}
                    fontSize={["xs", "xs", "xs", "sm"]}
                  >
                    R$ {helpers.currency(product.wholesaler.price.netPrice, 2)}
                  </Text>
                )}
              </View>

              <View {...textAlignCardToBottom} {...showUnits}>
                <Hidden from="base" till="lg">
                  <View>
                    <Text
                      color={"muted.700"}
                      _dark={{ color: "muted.400" }}
                      fontSize={"xs"}
                      fontWeight="400"
                    >
                      {" "}
                      Quantidade{" "}
                    </Text>
                    <Text
                      fontWeight={700}
                      color={"muted.900"}
                      _dark={{
                        color: "muted.300",
                      }}
                      fontSize={"sm"}
                    >
                      {product?.product?.quantity} unidades
                    </Text>
                  </View>
                </Hidden>
              </View>
            </View>

            <View {...showUnits}>
              <Hidden only={["lg", "xl", "2xl"]}>
                <View>
                  <Text
                    color={"muted.700"}
                    _dark={{ color: "muted.400" }}
                    fontSize={"xs"}
                    fontWeight="400"
                  >
                    {" "}
                    Quantidade{" "}
                  </Text>
                  <Text
                    fontWeight={700}
                    color={"muted.900"}
                    _dark={{
                      color: "muted.300",
                    }}
                    fontSize={"sm"}
                  >
                    {product?.product?.quantity} unidades
                  </Text>
                </View>
              </Hidden>
            </View>

            <View justifyContent={"flex-end"}>
              <ProgressiveProductDiscount product={product} />

              <MinimumAmount product={product} />

              <HasNotConsideredMinimum product={product} />
            </View>

            <Stack direction={["column", "column", "column", "row"]} w="full">
              <ProductAmountCounter
                {...showElementForShopping}
                isLoading={isStoreProductLoading}
                isMessageErrorShown={isMessageErrorShown}
                onMessageErrorClose={() => {
                  setIsMessageErrorShown(false);
                }}
                _pressable={{
                  onAddClick: addCountProduct,
                  onRemoveClick: removeCountProduct,
                }}
                _input={{
                  value: productAmount.toString(),
                  onChangeText: handleInputCount,
                  onBlur: handleInputBlur,
                }}
                w={["full", "full", "full", "50%"]}
                mr={[0, 4]}
                mb={[1, 1, 1, 0]}
              />

              <Button
                {...showElementForShopping}
                flex={1}
                bg="primary.600"
                disabled={!productAmount || productAmount == 0}
                opacity={!productAmount || productAmount == 0 ? 0.5 : 1}
                isLoading={isStoreProductLoading}
                _pressed={{
                  bg: "primary.400",
                }}
                _hover={{
                  bg: "primary.500",
                }}
                size="xs"
                alignItems={"center"}
                justifyContent={"center"}
                onPress={() => {}}
              >
                <Text fontSize={["2xs", "xs"]} fontWeight="500" color="white">
                  Comprar
                </Text>
              </Button>
            </Stack>
          </Stack>
        </View>
        {isSingleProductModalOpen && (
          <Suspense fallback={<Loading />}>
            <SingleProductModal
              isOpen={isSingleProductModalOpen}
              item={singleProduct}
              onClose={handleCloseSingleProductModal}
            />
          </Suspense>
        )}
      </Box>
    );
  }
);
