import { useAuth } from "../contexts/Auth";



type RedirectHomeOutput = {
  screen: "Industry"
  params: {
    industry: string
  }
}

export const RedirectHome = (): RedirectHomeOutput => {
  const { user } = useAuth();
  const industryCode = user?.getNameIndustryStore() || "";

  return {
    screen: "Industry",
    params: {
      industry: industryCode,
    },
  };
};
