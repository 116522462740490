import React, { useState } from "react";
import { Dimensions } from "react-native";
import { Text, Stack, Center } from "native-base";
const { width } = Dimensions.get("window");
import { List } from "@components/List";
import { SuggestedListProductCard } from "@components/SuggestedProducts/SuggestedListProductCard";
import { SuggestedProductListType } from "../../types/SuggestedProductListType";
import { useSuggestProducts } from "@hooks/useSuggestedProducts";

const offset = 0
const listsPerPage = 20;
const limit = listsPerPage;

export const SuggestedProductsTab = () => {
  const [list, setList] = useState<SuggestedProductListType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  const [total, setTotal] = useState(0);

  const getNumColumns = (): number => {
    if (width < 480) {
      return 1;
    }

    if (width < 992) {
      return 2;
    }

    if (width < 1280) {
      return 3;
    }

    return 4;
  };

  const { data, loading, error, refetch } = useSuggestProducts({
    offset: offset,
    limit: limit
  })

  const containerTitleComponent = (
    <Stack direction="column">
      <Center>
        <Text alignSelf={"flex-start"} fontWeight={500} color={"muted.800"} _dark={{ color: "muted.300" }} pt={5} fontSize={["lg", "lg", "2xl", "3xl"]}>
          Listas sugeridas
        </Text>
      </Center>
      <Center maxWidth={["80%", "70%", "90%", "100%"]} pt={[0, 2]} pb={[2, 0, 0, 0]} >
        <Text color={"muted.800"} _dark={{ color: "muted.300" }} fontSize={["xs", "sm"]}>
          Confira nossas sugestões de listas prontas que podem ser do seu
          interesse.
        </Text>
      </Center>
    </Stack>
  );

  if (data?.Msbit_suggestedLists && first) {
    setFirst(false);
    setTotal(data.Msbit_suggestedLists.pagination.total);
    setList(data.Msbit_suggestedLists.suggestionLists)
  }

  async function updateData(newOffset: number) {
    let { data: updatedListsData } = await refetch({
      pagination: {
        offset: newOffset,
        limit: listsPerPage
      }
    });

    setList(updatedListsData?.Msbit_suggestedLists?.suggestionLists ?? [])
  }

  return (
    <List
      data={list}
      loading={loading}
      error={error}
      numColumns={getNumColumns()}
      total={total}
      pageCount={listsPerPage}
      containerTitle={containerTitleComponent}
      keyExtractor={(item) => `${item.id}`}
      showSearch={false}
      renderItem={({ item }) => (
        <SuggestedListProductCard suggestedList={item} />
      )}
      refetch={(offset) => {
        updateData(offset);
      }}
    />
  );
};
