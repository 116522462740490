import { StyleSheet } from 'react-native';
import { width, height } from "../../components/Dimension";
import { THEME } from '@theme/index';

export const styles = StyleSheet.create({
  wholesalerSelectedContainer: {
    padding: 0,
    marginTop: width * 0.007, // 10,
  },
  wholesalersContainer: {
    padding: 0,
  },

  linkColorFocus: {
    color: THEME.colors.primary[600]
  },

  linkColor: {
    color: THEME.colors.primary[600]
  },

  fontColorDefault: {
    color: THEME.colors.muted[800]
  },

  border: {
    color: THEME.colors.gray[300] 
  },

  paginationBtnColor: {
    color: THEME.colors.muted[500]
  },

  paginationBtnFocus: {
    color: THEME.colors.primary[600]
  },

  btnEnabled: {
    color: THEME.colors.primary[600]
  },

  btnEnabledPressed: {
    color: THEME.colors.primary[700]
  },

  btnDisabled: {
    color: THEME.colors.muted[700],
    cursor:'default'
  },

  btnTransparent: {
    color: ''
  },

  btnTransparentPressed: {
    color: THEME.colors.gray[100]
  }

});