import React from "react";
import { StyleSheet } from "react-native";
import { Text } from 'native-base';
import Ionicons from "react-native-vector-icons/Ionicons";
import { RFValue } from "react-native-responsive-fontsize";
import { OrderStatusEnum } from "../enums/OrderStatusEnum";
import { THEME } from "@theme/index";

const statusColor = (status: OrderStatusEnum) => {
  if (
    [
      OrderStatusEnum.ORDER_PENDING,
      OrderStatusEnum.ORDER_PROCESSING,
      OrderStatusEnum.ORDER_WAITING_WHOLESALERS_RESPONSE,
    ].includes(status)
  ) {
    return "#F37022";
  }

  if (
    [OrderStatusEnum.ORDER_ACCEPTED, OrderStatusEnum.ORDER_COMPLETE].includes(
      status
    )
  ) {
    return THEME.colors.green[300];
  }

  if (status === OrderStatusEnum.ORDER_PARTIALLY_ACCEPTED) {
    return "#499E18";
  }

  if (status === OrderStatusEnum.ORDER_REJECTED) {
    return "#5A246F";
  }

  if (status === OrderStatusEnum.ORDER_SCHEDULED) {
    return THEME.colors.warning[600];
  }
};

const statusFromTo = (status: OrderStatusEnum) => {
  if (
    [OrderStatusEnum.ORDER_PENDING, OrderStatusEnum.ORDER_PROCESSING].includes(
      status
    )
  ) {
    return "Pedido Criado";
  }

  if (
    [OrderStatusEnum.ORDER_ACCEPTED, OrderStatusEnum.ORDER_COMPLETE].includes(
      status
    )
  ) {
    return "Pedido Aceito com Sucesso";
  }

  if (status === OrderStatusEnum.ORDER_PARTIALLY_ACCEPTED) {
    return "Pedido Parcialmente Aceito";
  }

  if (status === OrderStatusEnum.ORDER_REJECTED) {
    return "Erro no Pedido";
  }

  if (status === OrderStatusEnum.ORDER_WAITING_WHOLESALERS_RESPONSE) {
    return "Pedido Sem Resposta";
  }


  if (status === OrderStatusEnum.ORDER_SCHEDULED) {
    return "Pedido Agendado";
  }
};

export const OrderStatus = ({ status }: { status: string }) => {
  return (
    <Text style={styles.infoRowText}>
      <Ionicons name="ellipse" size={RFValue(7)} color={statusColor(status)} />
      {statusFromTo(status)}
    </Text>
  );
};

const styles = StyleSheet.create({
  infoRowText: {

    fontWeight: "400",
    fontSize: RFValue(7),
    color: "#484848",
  },
});
