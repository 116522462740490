import { useState } from "react";

export const useFilterWholesalers = () => {
  const [searchText, setSearchText] = useState('');

  const handleSearchWholesalers = (text: string) => {
    setSearchText(text);
  };

  return { searchText, handleSearchWholesalers };
};