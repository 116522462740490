import { create } from "zustand";

interface SideMenuInteface {
  visible: boolean;
  open(): void;
  close(): void;
}

export const useSideMenu = create<SideMenuInteface>((set: any) => ({
  visible: false,
  open: () => set({ visible: true }),
  close: () => set({ visible: false })
}));
