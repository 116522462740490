import { PromotionalActionInterface } from "@components/PromotionalAction";

export const setSessionStorage = (name: string, data: PromotionalActionInterface) => {
  return new Promise((resolve) => {
    sessionStorage.setItem(name, JSON.stringify(data));
    resolve(data);
  });
};

export const getSessionStorage = (name: string): Promise<PromotionalActionInterface> => {
  return new Promise((resolve) => {
    const response: string = sessionStorage.getItem(name) || "{}";
     resolve(JSON.parse(response));
  });
};
