import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  containerContent: {
    flex: 1,
    position: "relative",
    width: '90%',
    alignSelf: "center",
  },
  containerProducts: {
    paddingTop: width * 0.007,
  },
});
