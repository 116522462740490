import React from 'react';
import { useToast } from 'native-base';

import { ToastAlert } from '@components/ToastAlert';
import { useCartModal } from './useCartModal';

interface useAddToCartAlertProps {
  showToast: (isAdd?: boolean) => void;
}

export const useAddToCartAlert = () : useAddToCartAlertProps => {
  const toast = useToast();
  const { onOpen } = useCartModal();

  function showToast(isAdd: boolean = true) {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={isAdd ? 'Produto adicionado ao carrinho' : 'Produto removido do carrinho'}
            status="success"
            isClosable
            _title={{}}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
            onPress={onOpen}
          />
        )
      }
    )
  }

  return {
    showToast
  }
}