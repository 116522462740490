import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Actionsheet, CloseIcon, Factory, HStack, Icon, Text, VStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

import { useAuth } from '@contexts/Auth';
import {
  useManageCustomersResponsiveOverlayError
} from '@hooks/useManageCustomersResponsiveOverlayError';
import { useFractionationAlertModal } from '@hooks/useFractionationAlertModal';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Mobile() {
  const navigation = useNavigation();
  const alert = useFractionationAlertModal();
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();

  function handleUpdateOrder() {
    alert.onClose();
    navigation.navigate('SummaryOrder', { industry: industry });
  }

  return (
    <Actionsheet isOpen={alert.isOpen} onClose={alert.onClose} hideDragIndicator testID='fractionation-alert'>
      <Actionsheet.Content
        p={4}
        borderTopRadius='xl'
        _dark={{
          bg: 'muted.800'
        }}
      >
        <TouchableOpacityFactory alignSelf='end' onPress={alert.onClose}>
          <CloseIcon size={4} color='primary.600' />
        </TouchableOpacityFactory>

        <HStack alignItems='center'>
          <Icon
            as={AntDesign}
            name='warning'
            color='red.500'
            size={8}
            mr={4}
          />

          <Text color='red.500' fontSize='lg' fontFamily='subheading'>
            Quantidade inválida
          </Text>
        </HStack>

        <VStack mt={4}>
          <Text fontSize='sm' color='muted.800' mb={12}>
            Desculpe! A quantidade selecionada é inválida. Para este {"\n"} produto é
            necessário o pedido ser múltiplo do fracionamento.
          </Text>

          <TouchableOpacityFactory
            testID='manage-customers-error-web_update-order'
            bg='primary.600'
            h={12}
            rounded='sm'
            p={4}
            mb={4}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.8,
            }}
            onPress={handleUpdateOrder}
          >
            <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
              Ok, corrigir o pedido!
            </Text>
          </TouchableOpacityFactory>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
}