import React, { memo, useMemo, useState } from "react";
import { Center, Image, IImageProps, Text } from "native-base";

import { ProductRequirementLabel } from '@components/ProductCard/ProductRequirementLabel';
import DefaultSourceImg from '@assets/images/product_not_found.png';
import { CommercialPolicyProductsType } from '@types/CommercialPolicyProductsType';

type Props = {
  commercialPolicyProduct: CommercialPolicyProductsType;
  _image?: IImageProps;
};

export const ProductCardImage = memo(({ commercialPolicyProduct, _image }: Props) => {
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const isNotFound = useMemo(() => isImageNotFound, [isImageNotFound]);
  let url = commercialPolicyProduct?.product?.url;

  return (
    <>
      <ProductRequirementLabel
        commercialPolicyProduct={commercialPolicyProduct}
      />

      <Image
        key={
          isNotFound
            ? `${commercialPolicyProduct.product.code}:image-not-found`
            : commercialPolicyProduct.product.code
        }
        source={
          isNotFound
            ? DefaultSourceImg
            : { uri: url }
        }
        alt={commercialPolicyProduct.product.description}
        resizeMode="contain"
        resizeMethod="scale"
        onError={() => {
          setIsImageNotFound(true);
        }}
        testID={
          isNotFound
            ? `${commercialPolicyProduct.product.code}:image-not-found`
            : commercialPolicyProduct.product.code
        }
        h={[24, 26, 32, 40]}
        {..._image}
      />

      <Center
        borderRadius={4}
        bg="muted.300"
        _dark={{
          bg: "muted.300",
        }}
        position="absolute"
        top={2}
        left={2}
        p={1}
      >
        <Text fontSize={["2xs", "xs"]} fontWeight="700" color="muted.900">
          Estoque: {
          commercialPolicyProduct?.wholesaler?.stock != null ? 
          commercialPolicyProduct?.wholesaler?.stock + " un." : 
          " N/D"
          }
        </Text>
      </Center>

      {(commercialPolicyProduct?.discount?.total || 0) > 0 && (
        <Center
          borderRadius={4}
          bg="orange.500"
          _dark={{
            bg: "orange.500",
          }}
          position="absolute"
          top={2}
          right={2}
          p={1}
        >
          <Text fontSize={["2xs", "xs"]} fontWeight="700" color="muted.100">
            {commercialPolicyProduct?.discount?.total || 0}% OFF
          </Text>
        </Center>
      )}

      {commercialPolicyProduct?.discount?.progressive?.products?.length >
        0 && (
          <Center
            bg="orange.500"
            borderRightRadius={4}
            position="absolute"
            bottom={0}
            px={[2, 3]}
            py={1.5}
          >
            <Text
              color="muted.100"
              fontSize={["2xs", "2xs", "2xs", "xs"]}
              fontWeight="700"
            >
              Leve + Pague -
            </Text>
          </Center>
        )}
    </>
  );
}
);
