import { TextHelper } from "@helpers/TextHelper"
import { HStack, View, Text } from "native-base"
import React, { useState } from "react"
import { CustomerByUserTypeEdited } from "@hooks/useCustomersById"
import { TouchableOpacity } from "react-native"
import { CheckboxIcon } from "@assets/images/svgs/CheckboxIcon"

type ItemListManageCustomersWebProps = {
  item: CustomerByUserTypeEdited;
  press: (item:CustomerByUserTypeEdited)=> void;
  index: number
}

export const Web = ({item, press, index}: ItemListManageCustomersWebProps) => {
  const [checked, setChecked] = useState(!!item.isChecked);

  const handlePress = () => {
    item.isChecked = !item.isChecked;
    setChecked(item.isChecked);
    press(item);
  }

  return (
    <View
      width={'full'}
      height={12}
      alignSelf={'center'}
      justifyItems={'center'}
      alignItems={'center'}
      backgroundColor={index % 2 == 0 ? 'muted.200' : ''}
    >
      <HStack alignSelf={'center'} alignItems={'center'} justifyItems={'center'} h={'full'} space={5} w={'full'}>
        <View w={'10%'} alignItems={'center'}>
          <TouchableOpacity
            onPress={() => handlePress()}
            testID="ItemListManagerCustomersWeb-CheckList-Touchable"
          >
            <CheckboxIcon checked={item.isChecked} />
          </TouchableOpacity>
        </View>

        <View w={'60%'}>
          <Text
            fontSize={'xs'}
            color={item.isChecked ? 'primary.500' : 'black'}
            fontFamily={item.isChecked ? 'subheading' : 'body'}
            numberOfLines={2}
            lineHeight={18}
          >
            {item.businessName ? item.businessName : item.companyName}
          </Text>
        </View>

        <View w={'30%'}>
          <Text
            fontSize={'xs'}
            color={item.isChecked ? 'primary.500' : 'black'}
            fontFamily={item.isChecked ? 'subheading' : 'body'}
            lineHeight={18}
          >
            {new TextHelper(item.code).formatCNPJ()}
          </Text>
        </View>
      </HStack>
    </View>
  )
}