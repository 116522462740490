import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Actionsheet, CloseIcon, Factory, HStack, Icon, Text, VStack } from 'native-base';
import { AntDesign } from '@expo/vector-icons';

import { useCartSurplusItensErrorAlertModal } from '@hooks/useCartSurplusItensErrorAlertModal';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Mobile() {
  const alert = useCartSurplusItensErrorAlertModal();

  function handleUpdateOrder() {
    alert.onClose();
  }

  return (
    <Actionsheet isOpen={alert.isOpen} onClose={alert.onClose} hideDragIndicator testID='cart-surplus-alert'>
      <Actionsheet.Content
        p={4}
        borderTopRadius='xl'
        _dark={{
          bg: 'muted.800'
        }}
      >
        <TouchableOpacityFactory alignSelf='end' onPress={alert.onClose}>
          <CloseIcon size={4} color='primary.600' />
        </TouchableOpacityFactory>

        <HStack alignItems='center'>
          <Icon
            as={AntDesign}
            name='warning'
            color='red.500'
            size={8}
            mr={4}
          />

          <Text color='red.500' fontSize='lg' fontFamily='subheading'>
            Itens faltantes
          </Text>
        </HStack>

        <VStack mt={4}>
          <Text fontSize='sm' color='muted.800' mb={12}>
            Desculpe! Não é possivel incluir itens em um carrinho de falta.{"\n"}
            Caso não queria continuar o pedido dos itens faltantes, limpe o carrinho.
          </Text>

          <TouchableOpacityFactory
            testID='manage-customers-error-web_update-order'
            bg='primary.600'
            h={12}
            rounded='sm'
            p={4}
            mb={4}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.8,
            }}
            onPress={handleUpdateOrder}
          >
            <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
              Ok
            </Text>
          </TouchableOpacityFactory>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
}