import {
  IStackProps,
  ITextProps,
  Stack,
  Text,
  View,
  useMediaQuery,
} from "native-base";
import React from "react";
import { AntDesign } from "@expo/vector-icons";

export type ProductFractionationProps = IStackProps & {
  fractionationAmount: number;
  _label?: {
    _text?: {} & ITextProps
  } & IStackProps
  _text?: {} & ITextProps
  _icon?: React.ReactElement
};

export const ProductFractionation: React.FC<ProductFractionationProps> =
  ({ fractionationAmount, _label, _text, _icon, ...restProps }) => {
    const [isSmallScreen] = useMediaQuery([
      {
        maxWidth: 768,
      },
    ]);

    const iconSize = isSmallScreen ? 10 : 12;
    return (
      <Stack alignSelf={"end"} {...restProps}>
        <Stack flexDirection={"row"} {..._label}>
          <View paddingBottom={1} alignSelf={"start"}>
            {_icon ?? <AntDesign name="CodeSandbox" size={iconSize} color="black" />}
          </View>
          <View marginLeft={2}>
            <Text
              w="full"
              isTruncated
              noOfLines={2}
              fontWeight="800"
              fontSize={["2xs", "xs", "xs", "xs"]}
              color={"black"}
              _dark={{
                color: "black",
              }}
              lineHeight="sm"
              marginRight={3}
              selectable
              {..._label?._text}
            >
              Fracionamento
            </Text>
          </View>
        </Stack>
        <Text
          w="full"
          isTruncated
          noOfLines={2}
          fontWeight="500"
          fontSize={["2xs", "xs", "xs", "xs"]}
          color={"black"}
          _dark={{
            color: "black",
          }}
          lineHeight="sm"
          selectable
          {..._text}
        >
          Qtd. mín: múltiplos de {fractionationAmount}
        </Text>
      </Stack>
    );
  };
