import { cloneDeep } from '@apollo/client/utilities';
import { useFilter } from '@contexts/FilterContext';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { View, Text, useColorMode, useTheme } from 'native-base';
import {
  NavigationState,
  SceneRendererProps,
  TabBar,
  TabView,
} from 'react-native-tab-view';

import * as TabNavigationConfig from '../../configs/TabNavigationConfig';
import { BrandType } from '../../types/BrandType';
import { Loading } from '../Loading';
import { TabSuggestedProductListInterface } from '@components/SuggestedProducts/interfaces';

import { styles } from './styles';
import { dynamicTab } from '@types/DynamicTab';

interface TabBarProps extends SceneRendererProps {
  navigationState: NavigationState<RouteProps>;
}

interface RouteProps {
  key: string;
  title: string;
  active: boolean;
  category: string;
  children?: RouteChildrenProps[];
}

interface RouteChildrenProps {
  key: string;
  title: string;
  category: string;
  active: boolean;
}

interface TabNavigation {
  index: number;
  routes: RouteProps[];
}

interface TabNavigationContextData {
  tabNavigation: TabNavigation;
  setTabNavigation: Dispatch<SetStateAction<TabNavigation>>;
  tabNavigationLoading: boolean;
  setTabNavigationLoading: Dispatch<SetStateAction<boolean>>;
  currentRoute: string;
  setCurrentRoute: Dispatch<SetStateAction<string>>;
  setInitialIndex: Dispatch<SetStateAction<number>>;
  tabParams: TabParamProps | undefined;
  setTabParams: Dispatch<SetStateAction<TabParamProps | undefined>>;
  getNavigationChildren(
    key: string,
    index: number
  ): RouteChildrenProps[] | undefined;
}

interface ProviderProps {
  children: React.ReactNode;
}

interface TabParamProps extends TabSuggestedProductListInterface {
  name?: string;
  brand?: BrandType | undefined;
  commercialPolicyId?: number;
  requiredBasketAmount?: number;
  listName: string;
  listCode: string;
}

interface Params {
  tab?: string;
  productsTab?: string;
  brandId?: number;
  commercialPolicyId?: number;
}

const TabNavigationContext = createContext<TabNavigationContextData>(
  {} as TabNavigationContextData
);

export function TabNavigationProvider({ children }: ProviderProps) {
  const { getFilters } = useFilter();
  const params = getFilters<Params>();

  const index = TabNavigationConfig.activeRoutes.findIndex(
    (na) => na.key == params?.tab
  );
  const productsTab = params?.productsTab;

  const [initialIndex, setInitialIndex] = useState(index !== -1 ? index : 0);

  const [tabNavigation, setTabNavigation] = useState({
    index: initialIndex,
    routes: TabNavigationConfig.activeRoutes,
  });
  const [tabParams, setTabParams] = useState<TabParamProps | undefined>(
    undefined
  );
  const [tabNavigationLoading, setTabNavigationLoading] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("productList");

  function getNavigationChildren(key: string, index: number) {
    const children = TabNavigationConfig.activeRoutes[index].children?.filter(
      (route: RouteChildrenProps) => route.key === key
    );

    return children;
  }

  useEffect(() => {
    setTabNavigationLoading(false);
  }, [tabNavigation]);

  useEffect(() => {
    const newTabNavigation = cloneDeep(tabNavigation);

    setTabNavigation({
      ...newTabNavigation,
      index: initialIndex,
    });
  }, [initialIndex]);

  useEffect(() => {
    setInitialIndex(index !== -1 ? index : 0);
    if (productsTab) {
      setTabParams({
        brand: { id: params?.brandId } as BrandType,
        commercialPolicyId: params.commercialPolicyId,
      });

      const newTabNavigation = cloneDeep(tabNavigation);
      const route = getNavigationChildren(productsTab ?? "", index);

      if (route?.length) {
        setTabNavigationLoading(true);
        newTabNavigation.routes[index] = route[0];

        setTabNavigation({ ...newTabNavigation, index });
      }
    }
  }, [productsTab, index]);

  const value = useMemo(
    () => ({
      tabNavigation,
      setTabNavigation,
      tabParams,
      setTabParams,
      getNavigationChildren,
      tabNavigationLoading,
      setTabNavigationLoading,
      currentRoute,
      setCurrentRoute,
      setInitialIndex,
    }),
    [tabNavigation, tabParams, tabNavigationLoading, currentRoute]
  );
  return (
    <TabNavigationContext.Provider value={value}>
      {children}
    </TabNavigationContext.Provider>
  );
}

export const useProductTabNavigation = () => {
  const context = useContext(TabNavigationContext);
  return context;
};

const clearUrlQueryString = ()  => {
  const url = new URL(document.URL);
  history.pushState("","", url.origin + url.pathname );
}


interface TabProps  {
  dynamicTabs?: dynamicTab[] | undefined;
}

export const ProductsTabView = (Props: TabProps) => {
  const context = useProductTabNavigation();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();
  const { setCurrentTabView } = useFilter();
  const {
    dynamicTabs
  } = Props

  useEffect(() => {
    if(dynamicTabs?.length) {
      const initialTab = dynamicTabs[dynamicTabs.length -1]?.key;
      setCurrentTabView(initialTab)

      dynamicTabs.forEach((tab: dynamicTab) => {
        TabNavigationConfig.activeRoutes.unshift(tab)
      });
    }
  },[])

  function renderTabBar(props: TabBarProps) {
    return (
      <>
        {props.navigationState.routes.length > 1 && (
          <TabBar
            {...props}
            indicatorStyle={styles.tabBarIndicator}
            style={[
              styles.tabBarContainer,
              {
                backgroundColor:
                  colorMode == "dark" ? colors.muted[700] : colors.white,
              },
            ]}
            onTabPress={({ route }) => {
              if (context.currentRoute !== route.key) {
                context.setCurrentRoute(route.key);
                context.setTabNavigationLoading(true);
              }
            }}
            renderLabel={({ route, focused }) => (
              <>
                <Text
                  fontSize={["2xs", "xs", "sm"]}
                  _dark={{
                    color: "muted.300",
                  }}
                  color="muted.800"
                  style={[
                    styles.tabBarTitle,
                    focused ? { fontWeight: "800" } : null,
                  ]}
                >
                  {route.title}
                </Text>
              </>
            )}
          />
        )}
      </>
    );
  }

  return (
    <TabView
      lazy
      swipeEnabled={false}
      style={{ pointerEvents: context.tabNavigationLoading ? "none" : "auto" }}
      navigationState={context.tabNavigation}
      renderScene={TabNavigationConfig.sceneMap}
      renderTabBar={renderTabBar}
      renderLazyPlaceholder={() => <Loading />}
      onIndexChange={(index) => {
        setCurrentTabView(TabNavigationConfig.activeRoutes[index].key)
        clearUrlQueryString()

        context.setTabNavigation({
          index: index,
          routes: TabNavigationConfig.activeRoutes,
        });
      }}
    />
  );
};
