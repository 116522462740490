import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

const CART = gql`
  mutation ClearSuggestedList($cartId: ID!, $listCode: String!) {
    Cart_clearSuggestedList(cartId: $cartId, listCode: $listCode) {
      id
      customerCode
      industryCode
      status
      code
      customCode
      userId
      grossPrice
      netPrice
      valid
      discountValue
      coupons
      shippingOrders {
        code
        paymentTerm
      }
      items {
        code
        amount
        totalDiscount
        commercialPolicyId
        type
        valid
        unitGrossPrice
        unitNetPrice
        grossPrice
        netPrice
        discountValue
        discount
        errors {
          type
          message
        }
        discountsTrace
        coupons {
          name
          percentage
        }
        product {
          code
          description
          brand {
            id
            description
          }
          assortment {
            name
          }
        }
        suggestions {
          list {
            name
            code
            amount
          }
          anchor {
            anchorAmount
          }
        }
      }
    }
  }
`;

export const useClearSuggestedList = () => {
  return useMutation(CART, {
    onCompleted(data) {
      if (data.Cart_clearSuggestedList) {
        cartVar(data.Cart_clearSuggestedList)
      }
    },
  });
};
