import { useAuth } from "@contexts/Auth";
import { useAsyncStorage } from '@hooks/useAsyncStorage';
import { WholesalerBranchesType } from "@types/WholesalerBranchesType";

interface WholesalerBranchCart {
  loading: boolean,
  wholesalers: WholesalerBranchesType[],
  update: (newValue: WholesalerBranchesType[], expiredTime?: number | null) => Promise<void>,
}

export const useWholesalersStorage = (): WholesalerBranchCart => {
  const { user, loading } = useAuth();
  const industry = user?.getNameIndustryStore();

  const {
    storageValue: storageValueWholesalers,
    loadingStorage: loadingWholesalersStorage,
    updateStorage,
  } = useAsyncStorage<WholesalerBranchesType[]>(
    `${industry}:wholesalers`,
    []
  );


  async function update(
    newValue: WholesalerBranchesType[],
    expiredTime: number | null = null
  ) {
    updateStorage(newValue, expiredTime);
  }

  return {
    loading: loadingWholesalersStorage || loading,
    wholesalers: storageValueWholesalers,
    update,
  }
};
