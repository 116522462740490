import React from "react";
import { View, Stack, Skeleton, Box } from "native-base";

export const ProductCardSkeleton = () => {
  return (
    <Stack
      direction={"column"}
      width={"90%"}
      rounded="lg"
      height={[300, 330]}
      pr={[0, 5]}
    >
      <Box>
        <Skeleton
          alignSelf='center'
          w='100%'
          h={['40%', '50%']}
          startColor='muted.600'
        />
        <View background='white'
          _dark={{
            bgColor: 'muted.800'
          }}
          height={'full'}
          borderBottomRadius={10}
          p={[2, 3]}
        >
          <Stack flex={1}>
            <Skeleton.Text
              startColor={'muted.500'}
              mb={[2, 3]}
              size={1}
            />

            <Skeleton.Text
              startColor={'muted.500'}
              mb={[2, 3]}
            />

            <Stack direction={['column', 'column', 'column', 'row']} w='full'>
              <Skeleton
                startColor='muted.400'
                borderWidth={1}
                borderColor='muted.400'
                rounded='sm'
                w={['full', 'full', 'full', '50%']}
                mr={[0, 4]}
                mb={[1, 1, 1, 0]}
                h={[7, 7, 7, 8]}
              />

              <Skeleton
                flex={[undefined, undefined, undefined, 1]}
                startColor='primary.600'
                rounded='sm'
                h={[7, 7, 7, 8]}
              />
            </Stack>
          </Stack>
        </View >
      </Box>
    </Stack >
  );
};