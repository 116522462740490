import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Actionsheet, CloseIcon, Factory, HStack, Icon, Text, VStack } from 'native-base';
import { AntDesign } from '@expo/vector-icons';

import {
  useAddCouponResponsiveOverlayError
} from '@hooks/useAddCouponResponsiveOverlayError';

const TouchableOpacityFactory = Factory(TouchableOpacity);

export function Mobile() {
  const { isOpen, onClose } = useAddCouponResponsiveOverlayError();

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} hideDragIndicator>
      <Actionsheet.Content
        p={4}
        borderTopRadius='xl'
        _dark={{
          bg: 'muted.800'
        }}
      >
        <TouchableOpacityFactory alignSelf='end' onPress={onClose}>
          <CloseIcon size={4} color='primary.600' />
        </TouchableOpacityFactory>

        <HStack
          w='full'
          alignItems='center'
          pb={4}
          borderBottomWidth={1}
          borderBottomColor='muted.300'
        >
          <Icon
            as={AntDesign}
            name='warning'
            color='red.500'
            size={6}
            mr={4}
          />

          <Text fontSize='sm' fontFamily='subheading' color='red.500'>
            Remova os clientes para adicionar cupons
          </Text>
        </HStack>

        <VStack mt={4} w='full'>
          <Text fontSize='sm' color='muted.800' mb={10}>
            Não é possível adicionar cupons a um pedido{"\n"}
            compartilhado com vários clientes.
          </Text>

          <TouchableOpacityFactory
            testID='add-coupon-error-mobile_ok-button'
            bg='primary.600'
            rounded='sm'
            py={4}
            px={8}
            mb={6}
            alignItems='center'
            justifyContent='center'
            _dark={{
              opacity: 0.8,
            }}
            onPress={onClose}
          >
            <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
              Ok
            </Text>
          </TouchableOpacityFactory>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
}