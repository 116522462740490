import { useAuth } from "../contexts/Auth";
import { CommercialPolicyProductsType } from "../types/CommercialPolicyProductsType";
import { useQuery, gql, useLazyQuery, makeVar } from "@apollo/client";
import { Config } from "@configs/Config";
import { ProductInputType } from "../types/ProductInputType";
import { useCustomer } from "../contexts/CustomerContext";
import { AssortmentInputType } from "../types/AssortmentInputType";
import { AvailabilityConsolidateEnum } from "../enums/AvailabilityConsolidateEnum";
import { RequiredTypeEnum } from "@enums/RequiredTypeEnum";
import { CommercialPolicyProductSortType } from "@types/CommercialPolicyProductSortType";
import { SortEnum } from "@enums/SortEnum";
import { FilterInterface } from "@components/FastAccessCard/FilterInterface";
import { useWholesalers } from "./useWholesalers";
import Cdn from "@helpers/Cdn";

const COMMERCIAL_POLICY_PRODUCTS = gql`
  query commercialPolicyProducts(
    $industryCode: String!
    $commercialPolicyId: ID!
    $product: ProductInputType
    $customerCode: String!
    $wholesalerBranchCode: String!
    $limit: Int
    $offset: Int
    $hasProgressive: Boolean
    $hasCampaign: Boolean
    $availability: AvailabilityConsolidateEnum
    $requiredType: [RequiredTypeEnum!]
    $sort: CommercialPolicyProductSortType
  ) {
    commercialPolicyProducts(
      industryCode: $industryCode
      product: $product
      commercialPolicyId: $commercialPolicyId
      customerCode: $customerCode
      hasProgressive: $hasProgressive
      hasCampaign: $hasCampaign
      availability: $availability
      requiredType: $requiredType
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        pageCount
        totalCount
        hasNextPage
      }
      edges {
        node {
          commercialPolicyId
          maxAmount
          discount {
            base
            progressive {
              products {
                amount
                value
              }
            }
          }
          product {
            id
            code
            description
            monitored
            shippingBox
            brand {
              id
            }
          }
          minimumAmount
          wholesaler(
            wholesalerBranch: { code: $wholesalerBranchCode }
            customer: { code: $customerCode }
          ) {
            price
            fractionation
            stock
            outdated
            updatedAt
          }
          requiredType
          hasNotConsideredMinimum
        }
      }
    }
  }
`;

interface CommercialPolicyProductsProps {
  limit: number;
  offset: number;
  hasProgressive?: boolean;
  hasCampaign?: boolean;
  product?: ProductInputType;
  requiredType?: RequiredTypeEnum[];
  commercialPolicyId?: number | undefined;
  industryCode?: string;
  customerCode?: string;
  wholesalerBranchCode?: string;
  availability?: AvailabilityConsolidateEnum;
  sort?: CommercialPolicyProductSortType;
  fastAccessFilter?: FilterInterface
}
export const commercialPolicyProductsVar = makeVar<CommercialPolicyProductsType[]>([])
// TODO: talvez criar outro hook 
export const useCommercialPolicyProducts = (
  props: CommercialPolicyProductsProps,
  skip: boolean = false,
) => {
  const { user, loading } = useAuth();
  const { customerCode, loading: loadingCustomer, impersonate } = useCustomer();
  const wholesaler = useWholesalers();
  props.wholesalerBranchCode = props?.wholesalerBranchCode ?? wholesaler.wholesalers[0]?.code ?? "";
  props.hasProgressive = props.hasProgressive ?? false;
  props.hasCampaign = props.hasCampaign ?? false;
  props.product = props.product ?? {};
  if (!props.requiredType) {
    delete props.requiredType;
  }
  props.commercialPolicyId =
    props.commercialPolicyId ?? user?.getCommercialPolicyIdStore();
  props.industryCode = user?.getIndustry();
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.availability = impersonate && user.isIndustryEmployee() ? AvailabilityConsolidateEnum.CUSTOMER : user.getAvailability();

  let assortment: AssortmentInputType = {
    ids: props?.fastAccessFilter?.assortment || []
  }

  if (assortment.ids?.length === 0) {
    assortment = {
      ids: user.getAssortments().map((assortment) => Number(assortment.id)),
    };
  }

  let productDivision: AssortmentInputType = {
    ids: props?.fastAccessFilter?.division_product
  }

  props.product = { assortment, ...props.product, productDivision };

  props.sort = {
    product: {
      description: SortEnum.ASC,
    },
  };
  return useQuery(COMMERCIAL_POLICY_PRODUCTS, {
    skip: skip || wholesaler.loading || loading || loadingCustomer,
    variables: props,
    fetchPolicy: "cache-first",
    onCompleted(data) {
      if (data?.commercialPolicyProducts) {
        commercialPolicyProductsVar(refactorResult(data.commercialPolicyProducts, props.industryCode));
      }
    }
  });
};

export const useCommercialPolicyProductsLazy = (
  props: CommercialPolicyProductsProps
) => {
  const { user } = useAuth();
  const { customerCode, impersonate } = useCustomer();
  const industryName = user?.getNameIndustryStore();
  const { wholesalers } = useWholesalers();

  props.wholesalerBranchCode = props?.wholesalerBranchCode ?? wholesalers[0]?.code ?? "";
  props.hasProgressive = props.hasProgressive ?? false;
  props.hasCampaign = props.hasCampaign ?? false;
  props.product = props.product ?? {};
  if (!props.requiredType) {
    delete props.requiredType;
  }
  props.commercialPolicyId = props.commercialPolicyId ?? user?.getCommercialPolicyIdStore();
  props.industryCode = user?.getIndustry();
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.availability = impersonate && user.isIndustryEmployee() ? AvailabilityConsolidateEnum.CUSTOMER : user.getAvailability();

  let assortment: AssortmentInputType = {
    ids: props?.fastAccessFilter?.assortment || []
  }

  if (assortment.ids?.length === 0) {
    assortment = {
      ids: user.getAssortments().map((assortment) => Number(assortment.id)),
    };
  }

  let productDivision: AssortmentInputType = {
    ids: props?.fastAccessFilter?.division_product
  }

  const brand = {
    ids: props.product?.brand?.ids || props?.fastAccessFilter?.family
  }

  props.product = { assortment, ...props.product, brand, productDivision };

  props.sort = {
    product: {
      description: SortEnum.ASC
    }
  }
  return useLazyQuery(COMMERCIAL_POLICY_PRODUCTS, {
    variables: props,
    fetchPolicy: "no-cache",
  });
};

export const refactorResult = (
  products: any,
  nameIndustry?: string | undefined,
  commercialPolicyId?: number | undefined
): CommercialPolicyProductsType[] => {
  if (!nameIndustry) {
    const { user } = useAuth();
    nameIndustry = user?.getIndustry();
  }
  const parsedProducts = (products?.edges || []).map((edge: any) => {
    const item: CommercialPolicyProductsType = edge.node;
    const urlImage = Cdn.getProductImageUrl(item.product.code, nameIndustry || "");
    return {
      ...item,
      discount: {
        ...item.discount,
        total: item.discount.base,
      },
      product: {
        ...item.product,
        url: urlImage,
        type: null,
      },
      commercialPolicyId: commercialPolicyId || Number(item.commercialPolicyId),
      minimumAmount: item.minimumAmount,
      maxAmount: item.maxAmount,
      extraDiscounts: [],
      wholesaler: {
        price: {
          fullPrice: item.wholesaler.price,
          netPrice:
            Number(item.wholesaler.price) -
            (Number(item.wholesaler.price) * item.discount.base) / 100,
        },
        outdated: item.wholesaler?.outdated ?? false,
        updatedAt: item.wholesaler.updatedAt && new Date(item.wholesaler.updatedAt).toISOString().substr(0, 10).split('-').reverse().join('/').split('T')[0],
        fractionation: item.wholesaler.fractionation,
        stock: item.wholesaler.stock,
      },
    };
  });
  return parsedProducts;
};
