import { gql, makeVar, useLazyQuery, useQuery } from "@apollo/client";

const CONFIGURATIONS = gql`
  query ($code: String! $timeZone: String) {
    CMS_configurations(filters: { industry: { code: { eq: $code } } }) {
      dateRestriction(timeZone: $timeZone)
      data {
        attributes {
          name
          value
        }
      }
    }
  }
`;

interface Filters {
  industry: string;
  skip: boolean;
}

interface Configurations {
  name: string;
  value: string;
}

const defaultConfigurations = {
  name: "",
  value: "",
};
export const configurationsVar = makeVar<Configurations[]>([
  defaultConfigurations,
]);

export const useConfigurations = ({ industry, skip }: Filters) => {
  return useQuery(CONFIGURATIONS, {
    skip,
    fetchPolicy: "cache-first",
    variables: {
      code: industry,
    },
    onCompleted(data) {
      if (data?.CMS_configurations?.data) {
        const configurations = data.CMS_configurations.data.map((item: any) => {
          return item.attributes;
        });
        configurationsVar(configurations ?? [defaultConfigurations]);
      }
    },
  });
};


export const useConfigurationsLazy = ({ industry }: Filters) => {
  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return useLazyQuery(CONFIGURATIONS, {
    fetchPolicy: "cache-first",
    variables: {
      code: industry,
      timeZone: timezone
    },
    onCompleted(data) {
      if (data?.CMS_configurations?.data) {
        const configurations = data.CMS_configurations.data.map((item: any) => {
          return item.attributes;
        });
        configurationsVar(configurations ?? [defaultConfigurations]);
      }
    },
  });
};

