import React from 'react';
import { Flex, View, useMediaQuery } from 'native-base';
import SwiperFlatList, { Pagination, PaginationProps } from 'react-native-swiper-flatlist';

import { ComboHeadlightCard } from '@components/ComboTab/ComboHeadlightCard';
import { THEME } from '@theme/index';
import { styles } from './styles';
import { CommercialConditionRequirementsType } from '@types/CommercialConditionRequirementsType';
import { InvoicedProductsSummaryType } from '@types/InvoicedProductsSummaryType';

interface Props {
  minimumAmountBrands: CommercialConditionRequirementsType[];
  commercialPolicyId: number;
  invoicedData?: InvoicedProductsSummaryType;
}

const cardsPerPage: number = 3;

export const ComboHeadlightCardList = ({
  minimumAmountBrands,
  commercialPolicyId,
  invoicedData,
}: Props) => {
  console.log(invoicedData);
  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 900,
    },
  ]);
  const cardsPerPage = isSmallScreen ? 3 : 6;

  const mergeData = () => {
    if (!invoicedData) {
      return minimumAmountBrands;
    }

    const mergedMinimumAmountBrands = minimumAmountBrands.map(
      (data: CommercialConditionRequirementsType) => {
        if (data.isBasketRequired) {
          data.acummulativeData = invoicedData.basket?.length || 0;
          return data;
        }

        if (data.isMinimumAmountByOrder) {
          data.acummulativeData = invoicedData.total?.amount || 0;
          return data;
        }

        if (data.isMinimumAmountByOrder) {
          data.acummulativeData = invoicedData.total?.amount || 0;
          return data;
        }

        if (data.brand) {
          const invoicedBrand = invoicedData?.brands?.filter((brand: any) => {
            return brand?.id == data.brand.id;
          });

          const brand = invoicedBrand ?? [];

          data.acummulativeData = (invoicedBrand?.length || 0) > 0 ? brand[0]?.amount || 0 : 0;

          return data;
        }
      },
    );

    return mergedMinimumAmountBrands;
  };

  return (
    <Flex mb={[4, 4, 8]} style={styles.container} flex={1}>
      <SwiperFlatList
        style={{ marginBottom: 12, paddingBottom: 12, height: '100%', flex: 1 }}
        index={0}
        showPagination
        PaginationComponent={(props) => {
          return (
            <CustomPagination
              {...props}
              size={Math.ceil(minimumAmountBrands?.length / cardsPerPage)}
            />
          );
        }}
        ItemSeparatorComponent={() => {
          return <View m="2" />;
        }}
        data={mergeData()}
        renderItem={({ item }) => (
          <Flex direction="row" key={item.id} flex={1}>
            <ComboHeadlightCard key={item.id} item={item} commercialPolicyId={commercialPolicyId} />
          </Flex>
        )}
      />
    </Flex>
  );
};

export const CustomPagination = (props: PaginationProps) => {
  return (
    <Pagination
      {...props}
      paginationStyleItem={styles.pagination}
      paginationDefaultColor={THEME.colors.gray[300]}
      paginationActiveColor={THEME.colors.green[400]}
    />
  );
};
