import { Box, View } from "native-base";
import React from "react";
import { MinimumAmountProgressBar } from "./MinimumAmountProgressBar";

export type MinimumAmountProps = {
  label: string;
  currentAmount: number; // TODO avalizar se é necessário
  minimumAmount: number;
  commercialPolicyId: number;
  invoicedAmount?: number;
};

export interface ListMinimumAmountByTableProps {
  list: MinimumAmountProps[];
}

export const MinimumAmount = ({ list }: ListMinimumAmountByTableProps) => {
  return (
    <View
      w={"100%"}
      bgColor={"white"}
      _dark={{
        bgColor: "muted.800",
      }}
      mb={2}
    >
      <Box w="97%" mb={2.5} mt={2.5} ml={2}>
        {list.map((item: MinimumAmountProps) => (
          <MinimumAmountProgressBar {...item} />
        ))}
      </Box>
    </View>
  );
};
