import { TextHelper } from "@helpers/TextHelper"
import { CustomerByUserTypeEdited } from "@hooks/useCustomersById"
import { HStack, VStack, View, Text, theme } from "native-base"
import React, { useEffect, useState } from "react"
import { CheckboxIcon } from "@assets/images/svgs/CheckboxIcon"
import { TouchableOpacity } from "react-native-gesture-handler"

type ItemListManageCustomersMobileProps = {
  item: CustomerByUserTypeEdited;
  press: (item:CustomerByUserTypeEdited)=> void;
}

export const Mobile = ({item, press}: ItemListManageCustomersMobileProps) => {
  const [checked, setChecked] = useState(!!item.isChecked);

  const handlePress = () => {
    item.isChecked = !item.isChecked;
    setChecked(item.isChecked);
    press(item);
  }
  
  return (
    <View
      borderColor={'gray.300'}
      width={80}
      height={16}
      borderWidth={1}
      borderRadius={8}
      shadow={'6'}
      alignSelf={'center'}
      justifyItems={'center'}
      alignItems={'center'}
    >
      <HStack alignSelf={'center'} alignItems={'center'} justifyItems={'center'} h={'full'} space={5}>
        <TouchableOpacity
          onPress={() => handlePress()}
          testID="ItemListManagerCustomersMobile-CheckList-Touchable"
        >
          <CheckboxIcon checked={item.isChecked} />
        </TouchableOpacity>

        <VStack alignSelf={'center'} space={1} >
          <Text
            fontSize={'xs'}
            color={item.isChecked ? 'primary.500' : 'black'}
            numberOfLines={2}
            w={59}
            fontFamily={item.isChecked ? 'subheading' : 'body'}
            lineHeight={16}
          >
            {item.businessName ? item.businessName : item.companyName}
          </Text>

          <Text
            fontSize={'xs'}
            color={item.isChecked ? 'primary.500' : 'black'}
            fontFamily={item.isChecked ? 'subheading' : 'body'}
            lineHeight={14}
          >
            {new TextHelper(item.code).formatCNPJ()}
          </Text>
        </VStack>
      </HStack>
    </View>
  )
}