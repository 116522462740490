import { gql, useMutation } from "@apollo/client";

export type AdditionalCustomersInputType = {
  code: string;
}

export type CheckoutInputType = {
  cartId: string;
  additionalCustomers?: AdditionalCustomersInputType[];
}

const CART = gql`
  mutation Checkout($input: Cart_CheckoutInputType!) {
    Cart_checkout(input: $input) {
      id
      customerCode
      userId
      status
      createdAt
      updatedAt
      notifications {
        key
        message
      }
      items {
        code
        amount
        discount
        type
        coupons {
          name
          percentage
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const useCheckout = () => {
  return useMutation(CART, {
    refetchQueries: [
      'Cart'
    ]
  });
};
