import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const { width, height } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  containerTitle: {
    flexDirection: "row",
  },
  containerFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  searchBar: {
    width: width * 0.22,
    height: width * 0.03,
    fontSize: RFValue(8),
  },
  title: {

    fontWeight: "500",
    fontSize: RFValue(12),
    color: "#1C1C1C",
    alignSelf: "flex-start",
    marginBottom: width * 0.03, // 40,
  },
  containerCards: {
    flex: 1,
  },
  paginationButton: {
    borderRadius: Math.round(width + height) / 2,
    backgroundColor: "#DADADA",
    width: width * 0.01,
    height: width * 0.01,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationButtonActive: {
    backgroundColor: "#74BF48",
  },
  textDescription: {
    
    fontWeight: "400",
    fontSize: RFValue(8),
    color: "#686868",
    marginBottom: width * 0.007, // 10,
  },

});
