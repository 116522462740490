import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";
import { width } from '../../components/Dimension';

export const styles = StyleSheet.create({
    cardContainer: {
      marginTop: width * 0.015, // 50,
      flex: 1,
    },
  
    textTitleContentHeader: {
      
      fontWeight: "500",
      fontSize: RFValue(12),
      color: "#1C1C1C",
      marginLeft: width * 0.007, // 10,
    },
  
    containerContentHeader: {
      marginTop: width * 0.015, // 50,
    },
  
    textTitleContentCard: {
      
      fontWeight: "200",
      fontSize: RFValue(8),
      color: "#1C1C1C",
      marginLeft: width * 0.007, // 10,
    },
  });
  