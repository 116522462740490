import React, { useState, useEffect, useRef, memo } from "react";
import { Dimensions, FlatList, ListRenderItem, StyleProp, ViewStyle } from "react-native";
import { View, Text, HStack, Center, Flex, IBoxProps, Box, useMediaQuery } from 'native-base';
import { CommercialPolicyProductsType } from "../../types/CommercialPolicyProductsType";
import { UnexpectedError } from "../UnexpectedError";
import { Loading } from "../Loading";
import { Pagination } from "../Pagination";
import { Config } from "@configs/Config";
import { styles } from "./styles";
import { SearchBar, SearchBarProps } from "@components/SearchBar";
import { FilterIconMobile } from "@components/FastAccessCard/FilterIconMobile";
import { CustomHidden } from "@helpers/CustomHidden";
import { useFilter } from "@contexts/FilterContext";
const { width } = Dimensions.get("window");
let totalProductsPerPage = 20;

if (Config.TOTAL_PRODUCTS_EXHIBITED) {
  totalProductsPerPage = parseInt(Config.TOTAL_PRODUCTS_EXHIBITED);
}

interface Props {
  data: any[];
  loading?: boolean;
  total: number;
  numColumns?: number;
  pageCount?: number;
  containerTitle?: string | React.ReactElement;
  banner?: React.ReactElement | undefined;
  loadingBanner?: boolean;
  keyExtractor?: ((item: any, index: number) => string) | undefined;
  renderItem: ListRenderItem<any> | null | undefined;
  error?:
  | {
    message: string;
  }
  | undefined;
  refetch: (newOffset: number, searchText: string) => void;
  loadingRefetch?: boolean;
  showSearch?: boolean;
  showPagination?: boolean;
  columnWrapperStyle?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>;
  infinitePagination?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  isListEnd?: boolean
  _searchBar?: SearchBarProps
  _contentContainerSearchBar?: IBoxProps;
  isDefault?: boolean;
  info?: React.ReactElement;
}
// TODO: usar pseudo Props para alterar flatlist seachr bar

export const List = memo(
  ({
    data,
    loading,
    error,
    refetch,
    total = 0,
    renderItem,
    keyExtractor,
    numColumns = 1,
    containerTitle,
    pageCount = totalProductsPerPage,
    banner,
    loadingBanner,
    showSearch = true,
    showPagination = true,
    columnWrapperStyle,
    infinitePagination,
    showsHorizontalScrollIndicator,
    contentContainerStyle,
    loadingRefetch,
    isListEnd,
    _searchBar,
    _contentContainerSearchBar,
    isDefault = true,
    info = <></>,
  }: Props) => {
    const [searchText, setSearchText] = useState("");
    const [previousSearch, setPreviousSearch] = useState<string | undefined>(
      undefined
    );
    const [offset, setOffset] = useState<number>(0);

    const myRef = useRef<null | HTMLDivElement>(null);
    const executeScroll = () => myRef.current?.scrollIntoView();
    const [isSmallScreen] = useMediaQuery([{maxWidth: 768}]);


    useEffect(() => {
      if (offset !== undefined) {
        refetch(offset, searchText);
        setProductsList([]);
      }
    }, [offset]);

    const [focusItemList, setFocusItemList] = useState<
      CommercialPolicyProductsType[]
    >([]);
    const [productsList, setProductsList] = useState<
      CommercialPolicyProductsType[]
    >([]);

    if (error) {
      return <UnexpectedError consoleError={error.message} />;
    }

    if (data.length > 0 && productsList.length === 0) {
      result(data);
    }

    function result(data: any[]) {
      executeScroll();
      const startItemlist = data.slice(0, totalProductsPerPage);
      setFocusItemList(startItemlist);
      setProductsList(data);
    }

    if (loading || loadingBanner) {
      return (
        <Center>
          <Loading />
        </Center>
      );
    }

    const isAllowedToView = ():boolean => {
      const tabViewsToShow = [
        "offersByBrandProducts",
        "comboProducts",
        "productList",
      ];
      const { currentTabView } = useFilter();
      const tabView: string = currentTabView || "";

      if (tabViewsToShow.includes(tabView)) {
        return true;
      }

      return false;
    }

    return (
      <Flex direction="column" flex={1}>
        <View ref={myRef}></View>
        <HStack space={4}
          direction={["column", "column", "column", "row"]}
          justifyContent={["center", "center", "space-between"]}
          alignItems={["center", "baseline"]}
        >
          {typeof containerTitle == "string" ? (
            <Text
              fontWeight={"500"}
              fontSize={'xl'}
              color={"gray.800"}
              alignSelf={"flex-start"}
              marginBottom={8}
              _dark={{
                color: 'gray.300'
              }}
            >
              {containerTitle}
            </Text>
          ) : (
            containerTitle
          )}
          {showSearch && (
            <Box
              flexDirection="row"
              alignItems="center"
              w={'xs'}
              justifyContent={"flex-end"}
              {..._contentContainerSearchBar}
            >
              <SearchBar
                value={searchText}
                placeholder="O que você procura?"
                onChangeText={setSearchText}
                onBlur={(e) => {
                  if (previousSearch != searchText) {
                    refetch(0, searchText);
                    setProductsList([]);
                    setPreviousSearch(searchText);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.nativeEvent.key !== "Enter") {
                    return;
                  }

                  refetch(0, searchText);
                  setProductsList([]);
                }}
                {..._searchBar}
              />
            </Box>
          )}
        </HStack>
        {info}
        <View flex={1} mt={3}>
          {data.length > 0 ? (
            <View flex={1}>
              <FlatList
                showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
                data={data}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                numColumns={numColumns}
                key={numColumns}
                contentContainerStyle={contentContainerStyle}
                ListHeaderComponent={
                  (
                    <View flex={1} my={[4, 8]}>
                      {!loadingBanner && banner}
                    </View>
                  )
                }
                columnWrapperStyle={columnWrapperStyle}
                ItemSeparatorComponent={() => (
                  <View style={{ height: width * 0.01 }} />
                )}
                onEndReached={async ({ distanceFromEnd }) => {

                  if (!infinitePagination) {
                    return;
                  }

                  if (distanceFromEnd == 0 || loadingRefetch || isListEnd
                  ) {
                    return;
                  }
                  isDefault ? setOffset(offset + 1) : setOffset(offset + pageCount)
                }}
                onEndReachedThreshold={0.2}
                ListFooterComponent={loadingRefetch && infinitePagination ? <Loading /> : null}

              />
              {showPagination && (
                <Pagination
                  pageCount={pageCount}
                  stylePaginationButton={styles.paginationButton}
                  stylePaginationButtonActive={styles.paginationButtonActive}
                  itemList={productsList}
                  setOffset={setOffset}
                  total={total}
                />
              )}
            </View>
          ) : (
            <View>
              <Text style={styles.textDescription}>
                <View flex={1} w={'full'} my={[4, 8]}>
                  <CustomHidden show={isSmallScreen && isAllowedToView()}>
                    <FilterIconMobile />
                  </CustomHidden>
                </View>                
                Não há dados a serem exibidos
              </Text>
            </View>
          )}
        </View>
      </Flex>
    );
  }
);
