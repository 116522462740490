import React, { ReactNode } from 'react';
import { HStack, Text, useTheme, CheckCircleIcon, Icon, InfoIcon, WarningIcon } from 'native-base';
import AntDesign from 'react-native-vector-icons/AntDesign';

import { OrderStatusEnum } from '@enums/OrderStatusEnum';

type Props = {
  status: string;
}

type StatusDataPros = {
  status: string;
  description: string;
  color: string;
  icon: ReactNode;
}

export function OrderStatusTag({ status }: Props) {
  const { colors } = useTheme();
  const statusData = getStatusData(status);

  function getIcon(type: 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING') {
    const iconList = [
      {
        type: 'SUCCESS',
        icon: <CheckCircleIcon size={4} color='white' mr={3} />
      },
      {
        type: 'ERROR',
        icon: <Icon as={AntDesign} name='closecircle' size={4} mr={2} color='white' />
      },
      {
        type: 'INFO',
        icon: <InfoIcon size={4} color='white' mr={3} />
      },
      {
        type: 'WARNING',
        icon: <WarningIcon size={4} color='white' mr={3} />
      },
    ];

    const icon = iconList.find(item => item.type === type);

    return icon?.icon;
  }

  function getStatusData(status: string): StatusDataPros | undefined {
    const statusItems = [
      {
        status: OrderStatusEnum.ORDER_ACCEPTED,
        description: "Pedido aceito com sucesso",
        color: colors.success[700],
        icon: getIcon('SUCCESS')
      },
      {
        status: OrderStatusEnum.ORDER_COMPLETE,
        description: "Pedido aceito com sucesso",
        color: colors.success[700],
        icon: getIcon('SUCCESS')
      },
      {
        status: OrderStatusEnum.ORDER_PARTIALLY_ACCEPTED,
        description: "Pedido parcialmente aceito",
        color: colors.success[700],
        icon: getIcon('SUCCESS')
      },
      {
        status: OrderStatusEnum.ORDER_REJECTED,
        description: "Erro no pedido",
        color: colors.red[500],
        icon: getIcon('ERROR')
      },
      {
        status: OrderStatusEnum.ORDER_WAITING_WHOLESALERS_RESPONSE,
        description: "Pedido sem resposta",
        color: colors.muted[700],
        icon: getIcon('INFO')
      },
      {
        status: OrderStatusEnum.ORDER_PENDING,
        description: "Pedido criado",
        color: colors.warning[500],
        icon: getIcon('WARNING')
      },
      {
        status: OrderStatusEnum.ORDER_PROCESSING,
        description: "Pedido criado",
        color: colors.warning[500],
        icon: getIcon('WARNING')
      },
      {
        status: OrderStatusEnum.ORDER_SCHEDULED,
        description: "Pedido agendado",
        color: colors.warning[600],
        icon: getIcon('SUCCESS')
      },
      {
        status,
        description: status,
        color: colors.warning[500],
        icon: getIcon('WARNING')
      },
    ];

    const data = statusItems.find(item => item.status === status);

    return data;
  }

  return (
    <HStack
      testID={`order-status_${status}`}
      flex={1}
      rounded='lg'
      bg={statusData?.color}
      px={3}
      py={2}
      alignItems='center'
    >
      {statusData?.icon}

      <Text color='white' fontSize='sm' lineHeight={0}>
        {statusData?.description}
      </Text>
    </HStack>
  );
}