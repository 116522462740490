import React from "react";
import { Dimensions } from "react-native";
import { Center, Flex, Icon, View, Text } from "native-base";
import { Asset } from "expo-asset";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { Card } from "../../Card";
const { width } = Dimensions.get("window");

import { styles } from "./styles";
import { THEME } from "../../../theme";
import { height } from "../../Dimension";
import { DiscountBrandType } from "../../../types/BrandType";
import { DateConvert } from "../../../helpers/Date";

interface Props {
  discountsRange: DiscountBrandType[];
  endValidityDate: Date | string;
}

export const DiscountRateBanner = ({ discountsRange, endValidityDate }: Props) => {
  const asset = Asset.fromURI(
    require("../../../../assets/images/progressiveBannerDefault.png")
  );

  const renderDiscountTag = (discount: DiscountBrandType) => {
    return (
      <Center key={discount.value}>
        <Center
          size={width * 0.048}
          backgroundColor={THEME.colors.green[300]}
          borderRadius={"100%"}
          fontSize={RFValue(7)}
          shadow={"6"}
          style={{
            marginBottom: width * 0.004,
            marginHorizontal: width * 0.008,
          }}
        >
          <Flex direction="row">
            <Text
              style={{
                fontStyle: THEME.fonts["body"],
                fontWeight: "600",
                color: THEME.colors.white,
                fontSize: RFValue(12),
              }}
            >
              {discount?.value}
            </Text>
            <View>
              <Text style={styles.discountText}>%</Text>
              <Text style={styles.discountText}>OFF</Text>
            </View>
          </Flex>
        </Center>
        <Text
          style={{
            fontStyle: THEME.fonts["body"],
            fontWeight: "800",
            fontSize: RFValue(5.4),
            color: THEME.colors.gray[500],
            maxWidth: width * 0.05,
            textAlign: "left",
            paddingLeft: 10,
          }}
        >
          na compra de {discount.amount}un
        </Text>
      </Center>
    );
  };

  return (
    <Center
      backgroundColor={THEME.colors.gray[300]}
      height={height * 0.35}
      borderRadius={width * 0.004}
      shadow={"8"}
    >
      <Flex direction="row">
        <View
          style={{
            width: "30%",
            marginRight: width * 0.008,
            marginVertical: width * 0.01,
            backgroundColor: THEME.colors.gray["300"],
          }}
        >
          <Card.Image source={asset.uri} alt="" />
        </View>
        <View style={{ marginTop: width * 0.025 }}>
          <Flex direction="row" justifyContent="center">
            <Text style={[styles.bannerHeaderText, { fontWeight: "500" }]}>
              quanto mais você compra,
            </Text>
            <Text style={[styles.bannerHeaderText, { fontWeight: "700" }]}>
              {" "}
              mais você economiza
            </Text>
          </Flex>

          <Flex
            direction="row"
            marginTop={width * 0.01}
            marginBottom={width * 0.02}
            justifyContent="center"
          >
            {discountsRange.map((discount: DiscountBrandType) => {
              return renderDiscountTag(discount);
            })}
          </Flex>

          <Flex direction="row">
            <Text style={[styles.bannerFooterText, { fontWeight: "700" }]}>
              Desconto progressivo por volume de família:
            </Text>
            <Text style={styles.bannerFooterText}>
              {" "}
              Desconto inicial de {discountsRange[0]?.value}%,
            </Text>
            <Icon as={MaterialCommunityIcons} name="clock" size={RFValue(2)} />
            <Text style={[styles.bannerFooterText, { fontWeight: "900" }]}>
              {" "}
              até {new DateConvert(endValidityDate).getExpirationDate()}
            </Text>
          </Flex>
        </View>
      </Flex>
    </Center>
  );
};
