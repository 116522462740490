import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { ItemType } from "@types/CartType";

export const splitProducts = () => {

  const listProducts = filterListProducts();
  const aggregatorProducts = filterAggregatorProducts(); 

  const itemsByType = {
    list: listProducts,
    aggregator: aggregatorProducts,
  };

  return itemsByType;
}

export const filterAggregatorProducts = () => {
  const { cart } = useShoppingCart();

  const aggregatorProducts = cart?.items.filter((product: ItemType) => {
    return (
      product.suggestions?.list?.length == 0 || !product.suggestions?.list
    );
  });

  return aggregatorProducts;
}

export const filterListProducts = () => {
  const { cart } = useShoppingCart();
  
  const listProducts = cart?.items.filter((product: ItemType) => {
    return product.suggestions?.list?.length > 0;
  });

  return listProducts;
}