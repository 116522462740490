import { create } from 'zustand';

interface AddItemAlertStore {
  isOpen: boolean;
  isAdd: boolean;
  onOpen: (isAdd: boolean) => void;
  onClose: () => void;
}

export const useAddItemAlert = create<AddItemAlertStore>((set) => ({
  isOpen: false,
  isAdd: true,
  onOpen: (isAdd: boolean = true) => set({ isOpen: true, isAdd }),
  onClose: () => set({ isOpen: false, isAdd: false })
}));