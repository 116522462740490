import React from "react";
import {
  createNativeStackNavigator
} from "@react-navigation/native-stack";

import { SignInSso } from "@screens/SignInSso";
import { Callback } from "@screens/SignInSso/Callback";

const AuthStack = createNativeStackNavigator();

const AuthRoutes: React.FC = () => (
  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
    <AuthStack.Screen name="SignInSso" component={SignInSso} />
    <AuthStack.Screen name="Callback" component={Callback} />
  </AuthStack.Navigator>
);

export default AuthRoutes;
