import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "../../components/Dimension";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#E5E5E5",
    padding: width * 0.012, // 15,
  },
  title: {
    
    fontWeight: "500",
    fontSize: RFValue(16),
    color: "#1C1C1C",
    marginLeft: width * 0.007, // 10,
  },
  searchBarInput: {
    width: width * 0.2,
    height: width * 0.03,
    fontSize: RFValue(8),
  },
  titleSubtitle: {

    fontWeight: "500",
    fontSize: RFValue(8),
    marginLeft: width * 0.007, // 10,
    color: "#686868",
    marginBottom: width * 0.009
  },
  searchBar: {
    width: width * 0.3,
    height: width * 0.03,
    fontSize: RFValue(8),
    marginLeft: width * 0.008,
  },
});