import { useAuth } from "@contexts/Auth";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Box, Button, Stack, Text, VStack, View } from "native-base";
import React, { useEffect, useState } from "react";

export type RouteParams = {
  code: string;
};

export const Callback = () => {
  const route = useRoute();
  const params = route.params as RouteParams;
  const { getAuthToken, signIn } = useAuth();
  const [error, setError] = useState('');
  const navigation = useNavigation();

  async function logIn() {
    if (!params) {
      navigation.navigate("SignInSso")
    }

    try {
      await getAuthToken(params.code);
    } catch (error: any) {
      if (error.message === 'invalid_profile') {
        setError('Seu perfil de usuário não está disponível para esta plataforma.');
      } else {
        setError('Ooops, não foi possivel fazer seu login.')
      }
    }
  }

  const handleSignIn = () => {
    signIn()
  }

  useEffect(()=> {
    logIn();
  }, [])

  const boxError = () => {
    return (
      <Box flex={1}
        _dark={{
          bgColor: "muted.900"
        }}
        _light={{
          bgColor: "white"
        }}
        safeArea
      >
        <View alignItems={"flex-end"}>
          {/* <ColorMode /> */}
        </View>
        <View justifyContent={{ xl: "center", lg: "center", md: "center" }} flex={3}>
          <Box px={[4, 10]} pt={"5"} justifyContent={"center"} alignItems={{ xl: "center", lg: "center" }} >
            <Stack space={["24", "16"]} alignItems="center" justifyContent={"center"}>
            <VStack space={4} w={"full"} textAlign={"center"} justifyContent={"flex-center"}>
              <Stack w="100%" space={1} justifyContent={"flex-center"}>
                <Text fontFamily={"body"} fontSize={"lg"} color={"red.key"} lineHeight={"md"}
                  fontWeight={400}
                  _android={{
                    fontWeight: 600
                  }}
                >
                { error } 
                </Text>
              </Stack>
              <Stack w="100%" space={1} justifyContent={"flex-center"}>
            <Text fontFamily={"body"} fontSize={"lg"} color={"muted.600"} lineHeight={"md"}
              fontWeight={600}
              _android={{
                fontWeight: 800
              }}
            >
              Verifique seu usuário e senha.
            </Text>
            <Text fontFamily={"body"} fontSize={"lg"} color={"muted.600"} lineHeight={"md"}
              fontWeight={600}
              _android={{
                fontWeight: 800
              }}
            >
              Tente novamente clicando no botão abaixo:
            </Text>
          </Stack>
          <Stack w="100%" space={1} alignItems="center">
            <Button
              bg={"primary.600"}
              w={"50%"}
              h={"12"}
              mt={4}
              alignItems={"center"}
              justifyContent={"center"}
              _hover={{
                bg: "primary.500"
              }}
              onPress={handleSignIn}
            >
              <Text color={"white"}>Acessar conta</Text>
            </Button>
          </Stack>
            </VStack>
            </Stack>
          </Box>
        </View>
      </Box>
    )
  }

  return (
    error ? boxError() : <></>
  );
}