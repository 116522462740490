import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { AspectRatio, Center, Factory, Image } from 'native-base';

import { Card } from '@components/Card';
import { Config } from '@configs/Config';
import { TextHelper } from '@helpers/TextHelper';

const FactoryTouchableOpacity = Factory(TouchableOpacity);

type Props = {
  name: string;
  onPress: () => void;
}

export function IndustryCard({ name, onPress }: Props) {
  const [isImageFound, setIsImageFound] = useState(true);
  const path = (new TextHelper(name ?? '').upperCase());
  const imageUrl = `${Config.URL_CDN_BASE}/${path}/logo.jpg`;

  const renderIndustryImage = () => {
    return (
      <Image
        testID={`${name}-image`}
        source={{ uri: imageUrl }}
        defaultSource={{ uri: imageUrl }}
        alt={`Imagem da indústria ${name}`}
        resizeMode='contain'
          onError={() => {
          setIsImageFound(false);
        }}
      />
    );
  }

  const renderDefaultImage = () => {
    return (
      <Center
        testID={`${name}-default-image`}
        bg='primary.600'
        rounded='md'
        _text={{
          fontSize: 'sm',
          fontFamily: 'heading',
          color: 'white'
        }}
      >
        {name}
      </Center>
    );
  }

  return (
    <Card.Container
      containerStyle={{ width: '100%', height: 124 }}
      testID={`${name}-card`}
    >
      <Card.Body>
        <Card.Text
          description="Bem vindo(a) à"
          color='muted.800'
          fontSize='xs'
          fontWeight="500"
        />
        
        <AspectRatio
          w='full'
          maxH={8}
          ratio={16 / 9}
          mt={2}
        >
          {isImageFound ? renderIndustryImage() : renderDefaultImage()}
        </AspectRatio>

        <FactoryTouchableOpacity
          testID='change-industry-button'
          mt={4}
          w='fit-content'
          onPress={onPress}
        >
          <Card.Text
            color='primary.600'
            fontSize='sm'
            fontFamily='heading'
            textDecorationLine='underline'
            _dark={{
              color: 'primary.400'
            }}
          >
            Alterar a indústria
          </Card.Text>
        </FactoryTouchableOpacity>
      </Card.Body>
    </Card.Container>
  );
}