import React from 'react';
import { View } from 'native-base';
import { OffersByBrandProducts } from '@components/OffersByBrandTab/OffersByBrandProducts';

export const Brand = () => {
  return (
    <View flex={1}>
      <OffersByBrandProducts />
    </View>
  );
};
