import React, { useEffect } from 'react';
import Alert, { useRefAlert, Props } from '@components/Alert';
import { useAddItemExistsInListAlert } from '@hooks/useAddItemExistsInListAlert';

export const AlertExistsInList = () => {
  const add = useAddItemExistsInListAlert();
  const dialog = React.useRef<useRefAlert>();

  useEffect(() => {
    add.setRef(dialog);
  }, []);

  const params: Props = {
    headerText: "Item já existe em uma lista",
    bodyContent: add.text,
    btnTextConfirm: "Confirmar",
    showBtnCancel: false,
    onConfirmCallback: () => {
      add.onClose();
    },
  };

  return <Alert ref={dialog} {...params} />;
};
