import React, { memo } from 'react';
import { Flex, Text, WarningTwoIcon } from 'native-base';

import { CommercialPolicyProductsType } from '@types/CommercialPolicyProductsType';

interface Props {
  product: CommercialPolicyProductsType
}

interface PropsContent {
  minimumAmount: number
}

export const MinimumAmount = memo((props: Props) => {
  const { product } = props;

  return product.minimumAmount ? <ContentMinimumAmount minimumAmount={product.minimumAmount} /> : null
})

const ContentMinimumAmount = ({ minimumAmount }: PropsContent) => (
  <Flex
    direction='row'
    testID='content-minimum-amount'
    alignItems='center'
  >
    <WarningTwoIcon color='red.600' size={[3, 4]} mr={[1, 2]} />

    <Text 
      fontSize={['2xs', '2xs', '2xs', 'xs']}
      color='red.600'
    >
      Qtd. mín <Text bold>{minimumAmount}</Text> unid.
    </Text>
  </Flex>
)