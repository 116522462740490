import StorageTTL from "@components/StorageTTL";
import { ColorMode, StorageManager } from "native-base";

export const colorModeManager: StorageManager = {
  get: async () => {
    try {
      const colorMode = await StorageTTL.getItem('@color-mode');
      return colorMode === 'dark' ? 'dark' : 'light';
    } catch (e) {
      return 'light';
    }
  },
  set: async (value: ColorMode) => {
    try {
      await StorageTTL.setItem('@color-mode', value);
    } catch (e) {
      console.log(e);
    }
  },
};
