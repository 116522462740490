import React from "react";

import { Card } from "@components/Card";
import { User } from "@contexts/Auth";
import { HStack, VStack } from "native-base";
import { IndustryCard } from "../IndustryCard";
import { CustomerCard } from "../CustomerCard";

type RetailCardProps = {
  user: User
}

export const RetailCard: React.FC<RetailCardProps> = ({ user }) => {
  return (
    <HStack flex={1} space={2} justifyContent={'space-evenly'}>
      <VStack w={'40%'}>
        <Card.Text
          description={"Bem vindo(a) à"}
          color="muted.800"
          fontSize="sm"
          fontWeight="700"
        />
        <IndustryCard user={user} />
      </VStack>
      <CustomerCard w={'40%'} user={user} justifyContent={'space-between'} />
    </HStack>
  )
}