
import React from "react";
import { Text, View } from "native-base";
import { width } from "@components/Dimension";


type InfoCustomerProps = {
  label: string; value: string, enumeration?: boolean
}

export const InfoCustomer = ({ label, value, enumeration }: InfoCustomerProps) => {
  let labelContent = (
    <Text
      fontSize={14}
      fontWeight={500}
      lineHeight={"md"}
      color={'muted.800'}
      _dark={{
        color: 'muted.400'
      }}
      selectable
    >{label}</Text>
  )

  if (enumeration) {
    labelContent = (
      <Text
        fontSize={14}
        fontWeight={500}
        lineHeight={"md"}
        color={'muted.800'}
        _dark={{
          color: 'muted.400'
        }}
      >{label}: </Text>
    )
  }

  return (
    <View flexDirection={enumeration ? 'row' : 'column'} style={{ marginBottom: width * 0.0045 }}>
      {labelContent}
      <Text
        fontSize={14}
        fontWeight={500}
        lineHeight={"md"}
        color={'muted.700'}
        _dark={{
          color: 'muted.300'
        }}
        selectable
      >
        {value}
      </Text>
    </View>
  );
};