import React, { useMemo, useState } from "react";
import { Text, Heading, Center, View, Image, ICenterProps, ITextProps, Box, IBoxProps, IImageProps } from "native-base";
import DefaultSourceImg from '@assets/images/notfound.jpg';

import { styles } from "./styles";

export interface CardContainerProps extends Pick<ICenterProps, 'id' | 'testID'> {
  children: React.ReactNode;
  centerStyle?: Object;
  containerStyle?: Object;
}

function CardContainer({
  children,
  centerStyle,
  containerStyle,
  id,
  testID,
}: CardContainerProps) {
  return (
    <Center id={id} testID={testID} style={[{ flex: 1 }, centerStyle]}>
      <View
        borderWidth={1}
        borderColor='gray.300'
        bgColor='white'
        _dark={{
          bgColor: 'muted.800',
          borderColor: 'muted.700'
        }}
        style={[styles.container, containerStyle]}
      >
        {children}
      </View>
    </Center>
  );
}
CardContainer.displayName = "Card.Container";

export interface CardHeaderProps {
  title: string;
  isActive?: boolean;
  size?: number | null;
  color?: string | null;
}

function CardHeader({
  title,
  isActive = false,
  size = null,
  color = null,
}: CardHeaderProps) {
  if (color == null) {
    color = isActive ? "green.400" : "gray.500";
  }

  let truncatedTitle = title;

  if (size != null && title.length > size) {
    truncatedTitle = `${title.substring(0, size)}...`;
  }

  return (
    <Heading style={styles.header} bg={color}>
      <Text>{truncatedTitle}</Text>
    </Heading>
  );
}
CardHeader.displayName = "Card.Header";

export type CardBodyProps = IBoxProps & {
  children: React.ReactNode,
  style?: Object,
}

function CardBody({ children, style, ...restProps }: CardBodyProps) {
  const endStyle = Object.keys(restProps).length ? [] : [styles.body, style]
  return (
    <Box style={endStyle} {...restProps}>
      {children}
    </Box>
  );
}
CardBody.displayName = "Card.Body";

export interface CardTextProps extends ITextProps {
  description?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  children?: React.ReactNode;
}

function CardText({ description, color, fontSize, fontWeight, children, ...restProps }: CardTextProps) {
  return (
    <Text
      color={color ? color : "gray.600"}
      fontSize={fontSize ? fontSize : "md"}
      fontWeight={fontWeight ? fontWeight : "500"}
      numberOfLines={1}
      selectable
      _dark={{
        color: 'muted.400'
      }}
      {...restProps}
    >
      {description ?? children}
    </Text>
  );
}
CardText.displayName = "Card.Text";

export interface CardButtonsProps {
  children: React.ReactNode;
}

function CardButtons({ children }: CardButtonsProps) {
  return (
    <View style={styles.button}>
      {children}
    </View>
  );
}
CardButtons.displayName = "Card.Buttons";

export type CardImageProps = ICenterProps & {
  source: string;
  alt: string;
  defaultSourceImg?: any;
  _image?: IImageProps;
}

function CardImage({ source, alt, defaultSourceImg, _image, ...restProps }: CardImageProps) {
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const isNotFound = useMemo(() => isImageNotFound, [isImageNotFound]);

  return (
    <Center h={48} p={2} {...restProps}>
      <Image
        source={isNotFound ? defaultSourceImg ?? DefaultSourceImg : { uri: source }}
        onError={() => {
          setIsImageNotFound(true)
        }}
        key={isNotFound ?
          `${alt}:image-not-found` :
          alt
        }
        alt={alt}
        w='full'
        h='full'
        resizeMethod='scale'
        resizeMode='contain'
        {..._image}
      />
    </Center>
  );
}
CardImage.displayName = "Card.Image";

export const Card = {
  Container: CardContainer,
  Header: CardHeader,
  Body: CardBody,
  Buttons: CardButtons,
  Image: CardImage,
  Text: CardText
};
