import { useAuth } from "../contexts/Auth";
import { CommercialPolicyProductsType } from "../types/CommercialPolicyProductsType";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Config } from "@configs/Config";
import { useCustomer } from "../contexts/CustomerContext";
import { AssortmentInputType } from "../types/AssortmentInputType";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";
import { useWholesalers } from "./useWholesalers";

export const SUGGEDTED_LIST_PRODUCTS = gql`
  query Msbit_suggestedListProducts(
    $listId: Int!
    $wholesalerBranchCode: String!
    $customerCode: String!
    $industryCode: String!
    $limit: Int!
    $offset: Int!
    $assortmentIds: [Int!]
    $availability: AvailabilityConsolidateEnum
  ) {
    Msbit_suggestedListProducts(
      filter: { listId: $listId }
      pagination: { limit: $limit, offset: $offset }
    ) {
      pagination {
        total
        offset
        limit
      }
      suggestionListProducts {
        ean
        suggestedAmount
        commercialPolicyProduct(
          industryCode: $industryCode
          customerCode: $customerCode
          availability: $availability
          product: { assortment: { ids: $assortmentIds } }
        ) {
          commercialPolicyId
          maxAmount
          discount {
            base
            progressive {
              products {
                amount
                value
              }
            }
          }
          product {
            id
            code
            description
            monitored
            shippingBox
            brand {
              id
            }
          }
          minimumAmount
          wholesaler(
            wholesalerBranch: { code: $wholesalerBranchCode }
            customer: { code: $customerCode }
          ) {
            price
            fractionation
          }
          requiredType
          hasNotConsideredMinimum
        }
      }
    }
  }
`;

interface SuggestedListProductsProps {
  listId: number;
  limit: number;
  offset: number;
  commercialPolicyId?: number | undefined;
  industryCode?: string;
  customerCode?: string;
  wholesalerBranchCode?: string;
  assortmentIds?: number[];
  availability?: AvailabilityConsolidateEnum;
}

export const useSuggestionListProducts = (
  props: SuggestedListProductsProps
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryName = user?.getNameIndustryStore();
  const {wholesalers} = useWholesalers();

  const assortment: AssortmentInputType = {
    ids: user.getAssortments().map((assortment) => Number(assortment.id)),
  };

  props.wholesalerBranchCode = props?.wholesalerBranchCode ?? wholesalers[0]?.code ?? "";
  props.industryCode = industryName;
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.assortmentIds = assortment.ids || [];
  props.availability = user.getAvailability();

  return useQuery(SUGGEDTED_LIST_PRODUCTS, {
    variables: props,
    fetchPolicy: "no-cache",
  });
};


export const useSuggestionListProductsLazy = (
  props: SuggestedListProductsProps
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryName = user?.getNameIndustryStore();
  const wholesalers = useWholesalers();
  const assortment: AssortmentInputType = {
    ids: user.getAssortments().map((assortment) => Number(assortment.id)),
  };

  props.wholesalerBranchCode =
    props?.wholesalerBranchCode ?? wholesalers.wholesalers[0]?.code;
  props.industryCode = industryName;
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.assortmentIds = assortment.ids || [];
  props.availability = user.getAvailability();

  return useLazyQuery(SUGGEDTED_LIST_PRODUCTS, {
    variables: props,
    fetchPolicy: "no-cache",
  });
};

export const refactorResult = (
  products: any,
  listId: number,
  listName: string,
  nameIndustry?: string | undefined,
  commercialPolicyId?: number | undefined
): CommercialPolicyProductsType[] => {
  if (!nameIndustry) {
    const { user } = useAuth();
    nameIndustry = user?.getIndustry();
  }
  const parsedProducts = products.map((product: any) => {
    const item = product.commercialPolicyProduct;
    const urlImage = nameIndustry
      ? Config.URL_CDN_BASE +
      `/${nameIndustry.toUpperCase()}/${item.product.code}.jpg`
      : "";
    return {
      ...item,
      discount: {
        ...item.discount,
        total: item.discount.base,
      },
      product: {
        ...item.product,
        url: urlImage,
        type: null,
        quantity: product.suggestedAmount,
        tab: CartProductTypeEnum.SUGGESTED,
      },
      listName: listName,
      listId: listId,
      commercialPolicyId: commercialPolicyId || Number(item.commercialPolicyId),
      minimumAmount: item.minimumAmount,
      maxAmount: item.maxAmount,
      extraDiscounts: [],
      wholesaler: {
        price: {
          fullPrice: item.commercialPolicyProduct.wholesaler.price,
          netPrice:
            Number(item.commercialPolicyProduct.wholesaler.price) -
            (Number(item.commercialPolicyProduct.wholesaler.price) * item.discount.base) / 100,
        },
        fractionation: item.wholesaler.fractionation,
      },
    };
  });
  return parsedProducts;
};
