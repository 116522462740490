import React from 'react';
import { View, Text, Flex } from 'native-base';
import { Foundation } from '@expo/vector-icons';

export const DialogProductReplace = (
  description: string,
  discount: number
): React.ReactElement => {
  return (
    <View>
      <Text>
        <Text
          fontWeight='700'
          fontSize='sm'
          color='gray.600'
        >
          {description} {""}
        </Text>
        <Text 
          borderBottomWidth={1}
          borderBottomColor='white'
        >
          já foi adicionado ao seu carrinho com desconto de {discount} %. Você
          deseja fazer a troca?
        </Text>
        <Flex direction='row' marginTop={5}>
          <Foundation
            name='alert'
            size={18}
            color='gray.600'
            style={{ paddingRight: 5, marginBottom: 2 }}
          />
          <Text
            borderBottomWidth={1}
            borderBottomColor='white'
            numberOfLines={5}
          >
            Atenção! Ao aceitar a troca, os descontos de outros produtos podem
            ser impactados, por favor, verifique o carrinho novamente antes de
            enviar o pedido.
          </Text>
        </Flex>
      </Text>
    </View>
  );
};
