import React, { useState, useEffect, useRef, memo } from "react";
import { Platform } from "react-native";
import {
  View,
  Text,
  FlatList,
  Stack,
  HStack,
  useMediaQuery,
} from "native-base";

import { ProductCardSkeleton } from "@components/ProductCardSkeleton";
import { Loading } from "@components/Loading";

import { Config } from "@configs/Config";
import { useAuth } from "@contexts/Auth";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import { RequiredTypeEnum } from "@enums/RequiredTypeEnum";
import {
  useCommercialPolicyProductsLazy,
  refactorResult,
} from "@hooks/useCommercialPolicyProducts";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { UnexpectedError } from "../UnexpectedError";
import { Pagination } from "../Pagination";
import { ProductCard } from "../ProductCard";

import { styles } from "./styles";
import { useReactiveVar } from "@apollo/client";
import { useFilter } from "@contexts/FilterContext";
import { promotionalActionEansVar, usePromotionalAction } from "@hooks/usePromotionalAction";
import { getSessionStorage } from "@helpers/SessionStorage";
import { finishPromotionalActionSession } from "@components/PromotionalAction";
import { useWholesalers } from "@hooks/useWholesalers";

interface ProductsListProps {
  flex?: string | number;
  paginationInfinite?: boolean;
}

let totalProductsPerPage = Config.TOTAL_PRODUCTS_EXHIBITED
  ? parseInt(Config.TOTAL_PRODUCTS_EXHIBITED)
  : 20;

const totalOffersPerPageMobile = 4;

interface ProductsListProps {
  flex?: string | number;
  paginationInfinite?: boolean;
}

interface PromotionalActionInterface {
  name: string;
  origin: string;
  redirect: boolean;
  nameIndustry: string;
  active: boolean;
};

export const ProductPromotionalAction: React.FC<ProductsListProps> = memo((props) => {
  const { paginationInfinite = false } = props;
  const { user } = useAuth();
  const nameIndustry = user?.getNameIndustryStore();
  const { setCurrentTabView } = useFilter();
  const [promotionalActionActive, setPromotionalActionActive] = useState(false)
  const [promotionalName, setPromotionalName] = useState<string>('');
  const isPointOfSales = user.isPointOfSales();
  const [showProducts, setShowProducts] = useState(false);
  const [promoStorage, setPromoStorage] = useState<PromotionalActionInterface>({
    name: '',
    origin: '',
    redirect: false,
    nameIndustry: '',
    active: false
  })

  let { loading: loadingEans } = usePromotionalAction({
    name: promotionalName,
    skip: promotionalName ? false : true
  })
  const promotionalActionVar = useReactiveVar(promotionalActionEansVar)

  useEffect(() => {
    if (!promotionalName && isPointOfSales) {
      const promise = getSessionStorage('promotionalAction')
      promise.then((promo) => {
        setPromotionalActionActive(promo.active)
        setPromoStorage(promo);
      }
      )
    }
  }, [])


  useEffect(() => {
    if (promotionalActionActive) {
      setPromotionalName(promoStorage?.name)
      setIsFirstLoading(false);
    }
  }, [promotionalActionActive])


  const [isLargeScreen, isMediumScreen, isSmallScreen] = useMediaQuery([
    {
      minWidth: 1780,
    },
    {
      maxWidth: 960,
    },
    {
      maxWidth: 768,
    },
  ]);

  function setTabViewInFocus(tabView: string) {
    setCurrentTabView(tabView)
  }

  if (isSmallScreen) {
    setTabViewInFocus('productList');
  }

  const numColumns =
    isSmallScreen || isMediumScreen ? 2 : isLargeScreen ? 4 : 3;
  const cardWidth = isSmallScreen ? "50%" : "33%";

  if (isSmallScreen) {
    totalProductsPerPage = totalOffersPerPageMobile;
  }

  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const wholesalers = useWholesalers();

  const myRef = useRef<null | HTMLDivElement>(null);
  const executeScroll = () => myRef.current?.scrollIntoView();

  const [focusItemList, setFocusItemList] = useState<
    CommercialPolicyProductsType[]
  >([]);
  const [productsList, setProductsList] = useState<
    CommercialPolicyProductsType[]
  >([]);

  const [
    query,
    { data, loading: commercialPolicyProductsLoading, error, refetch },
  ] = useCommercialPolicyProductsLazy({
    wholesalerBranchCode: wholesalers.wholesalers[0]?.code ?? "",
    limit: totalProductsPerPage,
    offset: 0,
    requiredType: [RequiredTypeEnum.NON_REQUIRED],
    product: {
      filter: "",
      codes: promotionalActionVar.eans
    }
  });

  useEffect(() => {
    if (wholesalers.loading || promotionalActionVar.eans?.length == 0) return;

    (async () => {

      const { data } = await query();
      setTotal(data.commercialPolicyProducts.pageInfo.totalCount);
      result(data.commercialPolicyProducts);
      setIsFirstLoading(false);

      if (data.commercialPolicyProducts.pageInfo.totalCount === 0) {
        await finishPromotionalActionSession();
      }

      setShowProducts(true);
    })();

  }, [offset, wholesalers.loading, promotionalActionVar]);

  if (error) {
    return <UnexpectedError consoleError={error.message} />;
  }

  function result(products: CommercialPolicyProductsType[]) {
    if (!isFirstLoading && Platform.OS == "web") {
      executeScroll();
    }

    const result = refactorResult(products, nameIndustry);
    const startItemList = result.slice(0, totalProductsPerPage);

    if (paginationInfinite) {
      setFocusItemList((prev) => [...prev, ...startItemList]);
    } else {
      setFocusItemList(startItemList);
    }

    setProductsList(result);
  }

  function renderItem({
    item,
    index,
  }: {
    item: CommercialPolicyProductsType;
    index: number;
  }) {
    return (
      <View
        flex={1}
        maxW={cardWidth}
        key={index}
        alignItems={["center", "center", "center", "baseline"]}
      >
        <ProductCard
          product={item}
          tab={CartProductTypeEnum.DEFAULT}
          isLoading={commercialPolicyProductsLoading}
        />
      </View>
    );
  }


  return (
    <Stack direction={"column"} flex={1} mt={4}>
      <View ref={myRef}></View>
      <HStack
        space={4}
        pb={4}
        px={["3", "3", 0]}
        direction={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={["center", "baseline"]}
        bgColor={["white", ""]}
        _dark={{
          bgColor: ["muted.800", ""],
        }}
        borderBottomWidth={[2, 0]}
        borderColor="coolGray.200"
        borderWidth="0"
      >
        {!isSmallScreen && (
          <Text
            style={styles.title}
            color={"muted.800"}
            _dark={{
              color: "muted.300",
            }}
          >
            Produtos
          </Text>
        )}
      </HStack>
      <View
        alignSelf={["center", "center", "flex-start"]}
        flex={props.flex}
        w={["100%"]}
      >
        {commercialPolicyProductsLoading ? (
          <FlatList
            data={[1, 2, 3, 4, 6, 9]}
            ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
            renderItem={() => (
              <View
                flex={1}
                maxW={cardWidth}
                alignItems={["center", "center", "center", "baseline"]}
              >
                <ProductCardSkeleton />
              </View>
            )}
            key={numColumns}
            numColumns={numColumns}
          />
        ) : (
          <>
            {total > 0 ? (
              <>
                <FlatList
                  key={numColumns}
                  data={focusItemList}
                  columnWrapperStyle={{
                    justifyContent: isSmallScreen
                      ? "space-between"
                      : "flex-start",
                  }}
                  contentContainerStyle={{
                    justifyContent: "space-around",
                    paddingBottom: 100,
                  }}
                  showsHorizontalScrollIndicator={false}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.product.code}
                  numColumns={numColumns}
                  ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
                  onEndReached={async ({ distanceFromEnd }) => {
                    if (!paginationInfinite) return;
                    if (distanceFromEnd == 0 || endOfList) return;
                    if (commercialPolicyProductsLoading) return;

                    setOffset(offset + totalProductsPerPage);
                  }}
                  onEndReachedThreshold={0.2}
                  ListFooterComponent={() =>
                    commercialPolicyProductsLoading ? <Loading /> : null
                  }
                />

                {!paginationInfinite && (
                  <Pagination
                    pageCount={totalProductsPerPage}
                    stylePaginationButton={styles.paginationButton}
                    stylePaginationButtonActive={styles.paginationButtonActive}
                    itemList={productsList}
                    setOffset={setOffset}
                    total={total}
                  />
                )}
              </>
            ) : (
              <View>
                <Text style={styles.textDescription} paddingLeft={[5, 0]}>
                  A Ação Promocional expirou ou se encontra indisponível
                </Text>
              </View>
            )}
          </>
        )}
      </View>
    </Stack>
  );
});
