import * as React from 'react';
import { Text, Flex, Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
  testID: string;
}

export function CouponApplied({ testID }: Props) {
  return (
    <Flex
      testID={testID}
      direction='row'
      alignItems='center'
    >
      <Icon
        as={MaterialCommunityIcons}
        size={4}
        name='ticket-percent-outline'
        color='orange.500'
        _dark={{
          color: 'orange.400'
        }}
      />

      <Text
        fontSize='2xs'
        fontWeight='500'
        color='orange.500'
        ml={1}
        _dark={{
          color: 'orange.400'
        }}
      >
        Cupom aplicado
      </Text>
    </Flex>
  );
}
