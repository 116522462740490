
import React, { useCallback, useState } from "react";
import { Box, IBoxProps, Button, useTheme } from "native-base";
import { useAuth } from "@contexts/Auth";
import Loader from 'react-native-loading-spinner-overlay'
import { LogoutActionsheet } from "../LogoutActionsheet";

type LogoutProps = IBoxProps & {}

export const Logout: React.FC<LogoutProps> = ({ ...restPros }) => {
  const [isLoading, setIsloading] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { user, signOut } = useAuth()
  const { colors } = useTheme();

  const onLogout = (useCallback(() => {
    setIsloading(true)
    if (isClicked) {
      return;
    }
    setIsClicked(true)
    setTimeout(async () => {
      await signOut()
      setTimeout(() => {
        setIsloading(false)
        onClose()
      }, 300)
    }, 1000)

  }, [user, isClicked]))

  const onClose = useCallback(() => {
    setIsSigningOut(false)
  }, [isSigningOut])

  const onSignOut = useCallback(() => {
    setIsSigningOut(true)
  }, [isSigningOut])

  return (
    <Box
      key={user.getUuid()}
      flex={1}
      w={'full'}
      justifyContent={"flex-start"}
      alignItems={"center"}
      justifyItems={"flex-start"}
      {...restPros}
    >
      <Loader visible={isLoading} color={colors.primary[600]} />
      <LogoutActionsheet visible={isSigningOut} onClose={onClose} onLogout={onLogout} />
      <Button
        variant={'unstyled'}
        testID='more:logout'
        w='100%'
        p={2}
        mb={3}
        onPress={onSignOut}
        alignItems='center'
        justifyContent='flex-start'
        _hover={{
          opacity: 0.8,
        }}
        _pressed={{
          bgColor: "muted.300",
        }}
        _text={{
          color: 'primary.600',
          fontWeight: '700',
          fontSize: ['md', 'lg']
        }}
        _dark={{
          opacity: 0.7,
          _pressed: {
            bgColor: "muted.700"
          }
        }}
      >
        Sair
      </Button>
    </Box>
  );
};
