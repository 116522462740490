import React from "react";
import { Platform } from "react-native";
import { Text, View } from "native-base";

import { CustomerList } from "@components/CustomerList";
import { Footer } from "@components/Footer";
import { HeaderBar } from "@components/HeaderBar";
import { height } from "@components/Dimension";
import { Loading } from "@components/Loading";
import { SearchBar } from "@components/SearchBar";
import { styles } from "./styles";
import { useChooseCustomer } from "./ChooseCustomer.Model";
import { CustomerSearchBar } from "@components/CustomerSearchBar";
import { EmptyCustomerList } from "@components/EmptyCustomerList";

export const ChooseCustomerView = ({
  isLargeScreen,
  isMediumScreen,
  isSmallScreen,
  loadingHasCommercialPolicy,
  searchByFilters,
  customersLoading,
  customerListData,
  isBlocked,
  updateData,
  handleSelectCustomer,
  scrollLoading,
  customerFilter,
  handleCustomer
}: ReturnType<typeof useChooseCustomer>) => {

  const showSmallFilter = isSmallScreen ||
    Platform.OS !== "web" ||
    (isMediumScreen && height < 600);

  const emptyCustomer = !customersLoading &&
    (!customerListData || customerListData?.length === 0)

  return (
    <>
      <HeaderBar isDefaultHeader />
      <View
        style={[
          { flex: 1, marginVertical: 32 },
          isLargeScreen && { paddingHorizontal: 128 },
          isMediumScreen && { paddingHorizontal: 64 },
          isSmallScreen && {
            paddingHorizontal: 16,
            marginTop: 12,
          },
        ]}
      >
        <View flex={1}>
          <View mb={4}>
            <Text
              fontSize="md"
              fontWeight={700}
              color="muted.800"
              _dark={{
                color: "muted.400",
              }}
            >
              Escolha do cliente
            </Text>
            <Text
              fontSize="sm"
              color="muted.800"
              _dark={{
                color: "muted.400",
              }}
            >
              Selecione ou pesquise o cliente desejado.
            </Text>
          </View>
          {loadingHasCommercialPolicy && <Loading />}
          <View
            flex={1}
            width="100%"
            mb={20}
            flexDirection={isLargeScreen ? "row" : "column"}
          >
            {showSmallFilter ? (
              <View mb={6} borderColor="gray.300">
                <SearchBar
                  testID="mobile-search-bar"
                  placeholder="Razão social, nome fantasia, CNPJ"
                  value={customerFilter.search}
                  onChangeText={handleCustomer.handleSearchCustomer}
                  onKeyPress={(event) => {
                    if (event.nativeEvent.key !== "Enter") {
                      return;
                    }

                    searchByFilters(customerFilter.search);
                  }}
                  onPress={() => searchByFilters(customerFilter.search)}
                />
              </View>
            ) : (
              <View
                mb={6}
                mr={8}
                borderWidth={1}
                borderColor="gray.300"
                borderRadius={6}
                width={isLargeScreen ? 80 : "full"}
                height={96}
                justifyContent="center"
                p={4}
                bgColor="white"
                _dark={{
                  bgColor: "muted.800",
                  borderColor: "muted.700",
                }}
              >
                <Text
                  color="muted.800"
                  _dark={{
                    color: "muted.400",
                  }}
                  style={[
                    styles.mb1,
                    {
                      marginTop: 0,
                      marginBottom: 4,
                      fontWeight: "700",
                    },
                  ]}
                >
                  Filtros
                </Text>
                <CustomerSearchBar
                  testID="company-name-search-bar"
                  label="Razão social"
                  value={customerFilter.companyName}
                  onChange={handleCustomer.handleCompanyName}
                  searchByFilters={searchByFilters}
                />
                <CustomerSearchBar
                  testID="business-name-search-bar"
                  label="Nome fantasia"
                  value={customerFilter.businessName}
                  onChange={handleCustomer.handlebusinessName}
                  searchByFilters={searchByFilters}
                />
                <CustomerSearchBar
                  testID="code-search-bar"
                  label="CNPJ"
                  value={customerFilter.code}
                  onChange={handleCustomer.handleCode}
                  searchByFilters={searchByFilters}
                />
                <CustomerSearchBar
                  testID="group-search-bar"
                  label="Grupo de clientes"
                  value={customerFilter.group}
                  onChange={handleCustomer.handleGroup}
                  searchByFilters={searchByFilters}
                />
              </View>
            )}

            {emptyCustomer ? (
              <EmptyCustomerList />
            ) : (
              <View
                flex={1}
                opacity={isBlocked ? 0.5 : 1}
                pointerEvents={isBlocked ? "none" : "auto"}
              >
                <CustomerList
                  customers={customerListData}
                  loadCustomers={updateData}
                  loading={customersLoading}
                  onPressEvent={handleSelectCustomer}
                />
                {scrollLoading && <Loading />}
              </View>
            )}
          </View>
        </View>
      </View>

      {(isLargeScreen || isMediumScreen) && (
        <View justifyContent="flex-end">
          <Footer />
        </View>
      )}
    </>
  );
};
