import { useShoppingCart } from "@hooks/useShoppingCart";
import { WholesalerBranchesType } from "@types/WholesalerBranchesType";
import { useWholesalersStorage } from "./useWholesalersStorage";
import { useMemo } from "react";

interface WholesalerBranchCart {
  loading: boolean,
  wholesalers: WholesalerBranchesType[],
}

export const useWholesalerBranchCart = (): WholesalerBranchCart => {
  const { cart, loading: loadingCart } = useShoppingCart()
  const { wholesalers: storageValueWholesalers, loading: loadingWholesalers } = useWholesalersStorage()

  const shippingOrderCode = cart?.shippingOrders?.map(sO => sO.code);

  let wholesalers: WholesalerBranchesType[] = storageValueWholesalers
    .filter(wholesalerBranch => {
      return shippingOrderCode?.includes(wholesalerBranch.code)
    })

  wholesalers = wholesalers
    .map(wholesalerBranch => {
      const shippingOrder = cart?.shippingOrders?.find(so => so.code === wholesalerBranch.code)
      const priority = cart?.shippingOrders?.findIndex(so => so.code === wholesalerBranch.code)
      wholesalerBranch.priority = (priority ?? 0) + 1

      const paymentTerms = wholesalerBranch.wholesaler.paymentTerms?.map(payment => {
        payment.selected = false;
        if (payment.code === shippingOrder?.paymentTerm) {
          payment.selected = true;

        }
        return payment;
      })

      wholesalerBranch.wholesaler.paymentTerms = paymentTerms;

      return wholesalerBranch
    })
    .sort((a: WholesalerBranchesType, b: WholesalerBranchesType) => (a.priority < b.priority ? -1 : 1))
    || [];

  return useMemo(() => ({
    loading: loadingCart || loadingWholesalers,
    wholesalers
  }), [
    loadingCart, loadingWholesalers,
    cart?.shippingOrders,
    storageValueWholesalers
  ])
};
