import React, { ReactNode } from "react";
import { TouchableWithoutFeedback } from "react-native";

interface Props {
  delay?: number;
  onDoubleClick: () => void;
  children: ReactNode;
}

let lastClick: number;

export function DoubleClick({ delay = 300, onDoubleClick, children }: Props) {
  function handleDoubleClick() {
    const now = Date.now();

    if (lastClick && now - lastClick < delay) {
      onDoubleClick();
      return;
    }
    lastClick = now;
  }

  return (
    <TouchableWithoutFeedback onPress={handleDoubleClick}>
      {children}
    </TouchableWithoutFeedback>
  );
}