import { useQuery, gql } from "@apollo/client";
import { useAuth } from "@contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";
import { SuggestedListStatusEnum } from "@enums/SuggestedListStatusEnum";

const SUGGESTED_PRODUCT_LIST = gql`
  query lists(
    $filter: Msbit_suggestionFilter
    $pagination: Msbit_paginationInput!
  ) {
    Msbit_suggestedLists(filter: $filter, pagination: $pagination) {
      suggestionLists {
        id
        name
        description
        start_validity
        end_validity
        createdAt
        updatedAt
        industry
        hash
        errors
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`;

interface Pagination {
  limit: number;
  offset: number;
}

export const useSuggestProducts = ({ offset, limit }: Pagination) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryName = user?.getNameIndustryStore();

  return useQuery(SUGGESTED_PRODUCT_LIST, {
    variables: {
      filter: {
        posCode: customerCode,
        industry: industryName,
        validityStatus: SuggestedListStatusEnum[SuggestedListStatusEnum.VALID],
      },
      pagination: {
        limit: limit,
        offset: offset,
      },
    },
    fetchPolicy: "no-cache",
  });
};
