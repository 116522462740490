import React, { useEffect, useState } from "react";
import { Text, Checkbox, Box, VStack, ScrollView } from "native-base";
import { SearchBar } from "@components/SearchBar";
import { FilterInterface } from "./FilterInterface";

export type filter = {
  type: string
  label: string,
  value: string
}

interface Props {
  group: string,
  fastAccessLoading: boolean,
  setValuesCheckBox: React.Dispatch<React.SetStateAction<FilterInterface>>,
  valuesCheckBox: FilterInterface,
  filters: filter[]
}

export const DynamicFilter = (Props: Props) => {
  const [searchText, setSearchText] = useState("");
  const [searchFilter, setSearchFilter] = useState<any[]>([])
  const [load, setLoad] = useState(false)

  const {
    group,
    fastAccessLoading,
    setValuesCheckBox,
    valuesCheckBox,
    filters
  } = Props

  enum groupFilters {
    assortment = 'Sortimentos',
    division_product = 'Divisão do produto',
    family = 'Família'
  }

  const enumIndex = group as keyof typeof groupFilters;
  const indexOfCheckBox = group as keyof typeof valuesCheckBox;

  useEffect(() => {
    if (!load) {
      setSearchFilter(filters)
      setLoad(true)
    }
  }, [searchFilter])

  const doSearch = (text: string) => {
    text = text.toLocaleLowerCase()
    const noMatch = -1

    const matchedFilters = filters.filter((filter) => {
      return filter.label.toLocaleLowerCase().search(text) != noMatch
    })

    setSearchFilter(matchedFilters)
  }

  const defaultValue = valuesCheckBox[indexOfCheckBox] as string[]
  const value = valuesCheckBox[indexOfCheckBox] as string[]

  return (
    <VStack space={2}>
      <Box maxW={['full', 'full', 180, 180]}>
        <SearchBar
          size={['xs']}
          value={searchText}
          onChangeText={(text: string) => {
            setSearchText(text)
            if (text.length === 0) {
              setSearchFilter(filters)
              return
            }
            doSearch(text)
          }}
        />
      </Box>
      <Box>
        <ScrollView
          maxH="235"
          borderWidth={filters.length > 8 ? 1 : 0}
          borderColor={'muted.500'}
        >
          <Box
            paddingTop={2}
            paddingLeft={2}
          >
            <Checkbox.Group
              pointerEvents={fastAccessLoading ? "none" : "auto"}
              colorScheme="green"
              color={'muted.800'}
              accessibilityLabel="Selecione para filtrar"
              onChange={(values) => {
                setValuesCheckBox((current: FilterInterface) => {
                  const filter: FilterInterface = {}
                  filter[enumIndex] = values
                  return { ...current, ...filter };
                });
              }}
              defaultValue={defaultValue}
              value={value}
            >
              {
                searchFilter.map((filter: filter,index) => {
                    return (
                      <Checkbox key={`${enumIndex}_${index}`} testID={`${enumIndex}_${index}`} mb={2} value={filter.value} color={'muted.800'}>
                        <Text color={'muted.800'}  _dark={{ color: 'muted.400' }} fontWeight={'400'}>{filter.label}</Text>
                      </Checkbox>
                    )
                 })
              }
            </Checkbox.Group>
          </Box>
        </ScrollView>
      </Box>
    </VStack>
  );
};
