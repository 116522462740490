import React from 'react';
import { Platform } from 'react-native';
import { Box, Image, Stack, useMediaQuery } from 'native-base';
import { Asset } from 'expo-asset';

import { Footer } from '@components/Footer';
import { HeaderBar } from '@components/HeaderBar';

import imgFuncional from '@assets/images/funcional_.png';

const assetImgFuncional = Asset.fromModule(imgFuncional)
const imgSrc = assetImgFuncional.uri;

type Props = {
  children: React.ReactNode;
}

export function ContainerLayout({ children }: Props) {
  const [isSmallScreen] = useMediaQuery([{maxWidth: 620}]);
  const isLargeScreen = !isSmallScreen && Platform.OS === 'web';

  return (
    <Box flex={1} testID="container-layout">
      <HeaderBar isDefaultHeader />
      
      <Stack flex={1} direction={['column', 'row']}>
        {isLargeScreen && (
          <Image
            testID="container-layout-image"
            source={{ uri: imgSrc }}
            defaultSource={{ uri: imgSrc }}
            alt="Seleção de indústria"
            w="2/5"
            minW={75}
            h="full"
            resizeMethod="resize"
            resizeMode={isLargeScreen ? "cover" : "stretch"}
          />
        )}

        {children}
      </Stack>
      
      {isLargeScreen && <Footer />}
    </Box>
  );
};
