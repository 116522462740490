import React from "react";
import { Image } from "react-native";
import { Flex, Pressable, View, ScrollView } from "native-base";
import SwiperFlatList, {
  Pagination,
  PaginationProps,
} from "react-native-swiper-flatlist";

import { Config } from "@configs/Config";
import { Loading } from "@components/Loading";
import { useBanners } from "@hooks/useBanners";

import { THEME } from "@theme/index";
import { styles } from "./styles";
import { FooterMain } from "@components/Footer";
import { useAuth } from "@contexts/Auth";

interface Props {
  children: React.ReactNode;
}

export function LayoutStore({ children }: Props) {
  const { banners, loading: loadingBanner, error } = useBanners();
  const { user } = useAuth();
  const nameIndustry = user.getNameIndustryStore()

  return (
    <>
      {loadingBanner ? <Loading /> :
        <ScrollView
          testID='layout-store'
          style={styles.container}
          _contentContainerStyle={{
            flex: 1
          }}
        >
          {!error && banners.length > 0 &&
            <View style={styles.carouselContainer}>
              <SwiperFlatList
                index={0}
                autoplay
                autoplayDelay={Config.DELAY_BANNER_CAROUSEL || 5}
                autoplayLoop
                showPagination
                PaginationComponent={CustomPagination}
              >
                {banners.map((item) => {
                  return (
                    <Flex direction="row" key={item.url}>
                      <Pressable
                        pointerEvents={item.link ? "auto" : "none"}
                        onPress={() => window.open(item.link, '_self')}
                      >
                        <Image
                          style={styles.image}
                          source={{ uri: item.url }}
                          alt={item.description}
                        />
                      </Pressable>
                    </Flex>
                  );
                })}
              </SwiperFlatList>
            </View>
          }

          <View>
            {children}
          </View>
          
          <View justifyContent={"flex-end"}>
            <FooterMain industryName={nameIndustry} />
          </View>
        </ScrollView >
      }
    </>
  );
};

const CustomPagination = (props: PaginationProps) => {
  return (
    <Pagination
      {...props}
      paginationStyleItem={styles.pagination}
      paginationDefaultColor={THEME.colors.gray[300]}
      paginationActiveColor={THEME.colors.green[300]}
    />
  );
};