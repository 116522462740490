import React from 'react';
import { Center, Icon, Text, View } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { LinkButton } from '@components/LinkButton';

type Props = {
  industryName: string;
};

export function EmptyCart({ industryName }: Props) {
  return (
    <Center flex={1}>
      <Icon
        as={MaterialCommunityIcons}
        size={24}
        name='cart-outline'
        color={'muted.800'}
      />

      <Text
        fontSize={['xl', '2xl']}
        fontWeight='700'
        color='muted.800'
        textAlign='center'
        _dark={{
          color: 'muted.400',
        }}
      >
        Seu carrinho está vazio
      </Text>

      <Text
        mt={2}
        fontSize={['sm', 'md']}
        fontWeight='500'
        color='muted.700'
        textAlign='center'
        _dark={{
          color: 'muted.300',
        }}
      >
        Adicione produtos e aproveite nossas ofertas.
      </Text>

      <View w='100%' mt={16} px={[4, 16]}>
        <LinkButton
          to={{
            screen: "Industry",
            params: { industry: industryName },
          }}
          testID='empty-cart:go-home'
          bg='primary.600'
          py={3}
          px={5}
          borderRadius={4}
          alignItems='center'
          justifyContent='center'
          _hover={{
            bg: 'primary.500',
          }}
          _pressed={{
            bg: 'primary.400',
          }}
          _dark={{
            opacity: 0.8,
          }}
        >
          <Text fontSize={['md', 'lg']} fontWeight='500' color='white'>
            Continuar comprando
          </Text>
        </LinkButton>
      </View>
    </Center>
  );
}