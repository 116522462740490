import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ChooseIndustry } from "@screens/ChooseIndustry";
import { PreferredWholesaler } from "../screens/PreferredWholesaler";

import { HeaderBar } from "@components/HeaderBar";
import { ChooseCustomer } from "@screens/ChooseCustomer";

const AppStack = createNativeStackNavigator();

const TradeManagerRoute = () => (
  <>
    <AppStack.Screen
      name="ChooseIndustry"
      component={ChooseIndustry}
      options={{ headerShown: false, title: "Tela das indústrias" }}
    />
    <AppStack.Screen
      name="ChooseCustomer"
      component={ChooseCustomer}
      options={{ headerShown: false, title: "Tela de clientes" }}
    />
  </>
);

export default TradeManagerRoute;
