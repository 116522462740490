import { useAuth } from '@contexts/Auth';
import { Factory, HStack, VStack, View, useMediaQuery } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { SearchBar } from "@components/SearchBar";
import { Text } from 'native-base';
import { TouchableOpacity } from "react-native";
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { CustomerByUserTypeEdited, customersList } from '@hooks/useCustomersById';
import { useReactiveVar } from '@apollo/client';
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { Config } from '@configs/Config';
import { ManagerCustomersList } from '@components/ManagerCustomersList';
import { useShoppingCart } from '@hooks/useShoppingCart';
import { AdditionalCustomersType } from '@types/AdditionalCustomersType';

export const ManageCustomers = () => {
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 640 }]);
  const [searchText, setSearchText] = useState("");
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const FactoryOpacity = Factory(TouchableOpacity);
  const navigation = useNavigation();
  let customers: CustomerByUserTypeEdited[] = useReactiveVar(customersList);
  const { getAdditionalCustomers: getSelectedCustomers, includeAdditionalCustomers, removeAdditionalCustomers } = useAdditionalCustomers();
  const { cart } = useShoppingCart();
  const isFocused = useIsFocused();

  const getAdditionalCustomers = () => {
    const data = customers.filter((item) => {
      return item.isChecked == true
    }).map((item: CustomerByUserTypeEdited) => {
      return {
        name: item.businessName || '', code: item.code || '', status: undefined
      }
    });

    return data;
  }

  const getAdditionalCustomersToRemove = async () => {
    const selectedCustomers: AdditionalCustomersType[] = await getSelectedCustomers(cart?.id);

    const data: AdditionalCustomersType[] = customers.filter(
      item => selectedCustomers?.map(item => item.code).includes(item.code) && !item.isChecked
    ).map(item => {
      return {
        name: item.businessName || '', code: item.code || '', status: undefined
      }
    });

    return data;
  }

  const handlePressComplete = async () => {
    const dataToAdd = getAdditionalCustomers();
    const dataToRemove = await getAdditionalCustomersToRemove();

    await includeAdditionalCustomers(cart?.id || '', dataToAdd);
    await removeAdditionalCustomers(cart?.id || '', dataToRemove);

    navigation.navigate('FinishOrder', { industry: industry });
  }

  const handlePressCancel = () => {
    navigation.navigate('FinishOrder', { industry: industry });
  }

  useEffect(() => {
    if (!Config.FT_ADDITIONAL_CUSTOMERS || !isSmallScreen) {
      navigation.navigate('SummaryOrder', { industry: industry });
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      setSearchText("");
    }, [])
  );

  return (
    <View>
      <HStack
        space={4}
        pb={4}
        px={["3", "3", 0]}
        direction={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={["center", "baseline"]}
        bgColor={["white", ""]}
        _dark={{
          bgColor: ["muted.800", ""],
        }}
        borderBottomWidth={[2, 0]}
        borderColor="coolGray.200"
        borderWidth="0"
      >
        <View width={'full'} pt={4}>
          <SearchBar
            variant={'outline'}
            size={['md', 'xs']}
            placeholder={"Pesquise pelo nome do cliente ou CNPJ"}
            value={searchText}
            onChangeText={(text: string) => setSearchText(text)}
          />
        </View>
      </HStack>

      <VStack px={5} pt={5} space={2}>
        <View>
          <Text fontWeight={'semibold'} fontSize={'sm'} lineHeight={18}>
            Adicione mais clientes ao seu pedido
          </Text>

          <Text fontSize={'xs'} lineHeight={16}>
            Clique nas opções abaixo:
          </Text>
        </View>

        <ManagerCustomersList
          key={cart?.id || '' + isFocused}
          searchText={searchText}
          isMobile
          refreshData={isFocused}
          cartId={cart?.id || ''}
        />
      </VStack>

      <VStack
        backgroundColor={'white'}
        alignItems={'center'}
        borderTopRadius={10}
        borderLeftColor={'white'}
        borderRightColor={'white'}
        borderBottomColor={'white'}
        borderTopColor={'gray.200'}
        borderWidth={1}
        p={5}
        h={32}
      >
        <FactoryOpacity
          onPress={handlePressComplete}
          justifyContent={"center"}
          alignItems={'center'}
          py={2}
          pr={2}
          backgroundColor={'primary.600'}
          borderRadius={2}
          width={80}
          height={12}
          testID='ManageCustomers-Touchable-Complete'
        >
          <Text fontSize={'sm'} color={'white'}>
            Concluir
          </Text>
        </FactoryOpacity>

        <FactoryOpacity
          onPress={handlePressCancel}
          justifyContent={"center"}
          py={2}
          pr={2}
          testID="ManageCustomers-Touchable-Cancel"
        >
          <Text fontSize={'sm'} fontWeight={'bold'} color={'primary.600'}>
            Cancelar
          </Text>
        </FactoryOpacity>
      </VStack>
    </View>
  )
}