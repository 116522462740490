import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';

type ScheduleResponsiveOverlayErrorProps = {
  type: 'WEB' | 'MOBILE'
}

export function ScheduleResponsiveOverlayError({
  type
}: ScheduleResponsiveOverlayErrorProps) {
  return (
    type === 'WEB' ? (
      <Web />
    ) : (
      <Mobile />
    )
  );
}