import React, { useEffect, useMemo, useState } from 'react';
import { Center, Flex, IconButton, View, Text, useMediaQuery, VStack } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FontAwesome, Foundation } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';

import { ComboHeadlightCardList } from '../ComboHeadlightCardList';
import { ProductCard } from '../../ProductCard';
import { useProductTabNavigation } from '../../ProductsTabView';
import * as TabNavigationConfig from '../../../configs/TabNavigationConfig';

import { styles } from './styles';

import {
  useCommercialPolicy,
  refactorRequirementsResult,
  useComboCommercialPolicy,
} from '@hooks/useCommercialPolicy';
import {
  refactorResult,
  useCommercialPolicyProductsLazy,
} from "@hooks/useCommercialPolicyProducts";
import { List } from "@components/List";
import { useAuth } from "@contexts/Auth";
import { AssortmentInputType } from "@types/AssortmentInputType";
import { ProductInputType } from "@types/ProductInputType";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import { RequiredTypeEnum } from "@enums/RequiredTypeEnum";
import { CommercialConditionRequirementsType } from "@types/CommercialConditionRequirementsType";
import { MinimumAmount, MinimumAmountProps } from "@components/MinimumAmount";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { searchFilterVar } from "@components/FastAccessCard/FastAccessCard";
import { useReactiveVar } from "@apollo/client";
import { useFilter } from "@contexts/FilterContext";
import {
  FilterIconMobile,
  fastAccessModalVar,
} from "@components/FastAccessCard/FilterIconMobile";
import { TagAlertOutdatedPrice } from "@components/TagAlertOutdatedPrice";

let totalOffersPerPage = 20;
const totalOffersPerPageMobile = 4;

export type RouteParams = {
  commercialPolicyId: number;
};

export const ComboProducts = () => {
  const { user } = useAuth();
  const nameIndustry = user?.getNameIndustryStore();
  const [products, setProducts] = useState<CommercialPolicyProductsType[]>([]);
  const [first, setFirst] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [isComboHeadlightCardListActive, setComboHeadlightCardListActive] =
    useState<boolean>(false);
  const [textFilter, setTextFilter] = useState('');
  const fastAccessFilter = useReactiveVar(searchFilterVar);
  const { setCurrentTabView } = useFilter();
  const [isReloading, setIsReloading] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const route = useRoute();
  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 768,
    },
  ]);
  const numColumns = isSmallScreen ? 2 : 3;

  let { tabParams: tabParams, tabNavigation, setTabNavigation } = useProductTabNavigation();
  const params = route.params as RouteParams;

  if (params?.commercialPolicyId) {
    tabParams = params;
  }

  if (isSmallScreen) {
    totalOffersPerPage = totalOffersPerPageMobile;
  }

  const [query, { loading: loadingProducts, error, refetch }] = useCommercialPolicyProductsLazy({
    limit: totalOffersPerPage,
    offset: 0,
    commercialPolicyId: tabParams?.commercialPolicyId || 0,
    requiredType: [
      RequiredTypeEnum.NON_REQUIRED,
      RequiredTypeEnum.REQUIRED,
      RequiredTypeEnum.REQUIRED_BASKET,
    ],
    fastAccessFilter,
  });

  useEffect(() => {
    updateData(0, textFilter);
  }, [fastAccessFilter]);

  useEffect(() => {
    (async () => {
      setFirst(false);
      const { data } = await query();
      setTotal(data.commercialPolicyProducts.pageInfo.totalCount);
      result = refactorResult(data.commercialPolicyProducts, nameIndustry);
      setProducts(result);
      setIsListEnd(!data.commercialPolicyProducts.pageInfo.hasNextPage);
    })();
  }, [fastAccessFilter, first]);

  const { data: commercialPolicyData, loading } = useComboCommercialPolicy({
    id: tabParams?.commercialPolicyId || 0,
  });

  let result: CommercialPolicyProductsType[] = [];

  async function updateData(newOffset: number = 0, searchText: string) {
    setIsReloading(true);

    let assortment: AssortmentInputType = {
      ids: fastAccessFilter?.assortment,
    };

    if (assortment.ids?.length === 0) {
      assortment = {
        ids: user.getAssortments().map((assortment) => Number(assortment.id)),
      };
    }

    let productDivision: AssortmentInputType = {
      ids: fastAccessFilter?.division_product,
    };

    const brand = {
      ids: fastAccessFilter?.family,
    };

    const filter: ProductInputType = {
      filter: searchText,
      assortment,
      productDivision,
      brand,
    };

    const { data: updatedProductsData } = await refetch({
      product: filter,
      offset: newOffset,
      limit: totalOffersPerPage,
    });

    const resultRefactor = refactorResult(
      updatedProductsData.commercialPolicyProducts,
      nameIndustry,
    );

    setIsListEnd(!updatedProductsData.commercialPolicyProducts.pageInfo.hasNextPage);
    if (isSmallScreen && newOffset != 0) {
      setProducts((prev) => [...prev, ...resultRefactor]);
    } else {
      setProducts(resultRefactor);
    }
    setIsReloading(false);
    return resultRefactor;
  }

  let minimumAmountProps: MinimumAmountProps[] = [];

  const headLightCards = refactorRequirementsResult(
    commercialPolicyData?.commercialPolicy?.minimumAmountBrand || [],
  );

  if (commercialPolicyData?.commercialPolicy?.minimumValue > 0) {
    console.log(commercialPolicyData);
    minimumAmountProps.push({
      label: 'Valor mínimo:',
      currentAmount: 0,
      minimumAmount: commercialPolicyData?.commercialPolicy?.minimumValue || 0,
      commercialPolicyId: commercialPolicyData?.commercialPolicy?.id,
      invoicedAmount: commercialPolicyData?.commercialPolicy?.invoicedProducts?.total?.value || 0,
    });

    if (commercialPolicyData?.commercialPolicy?.minimumUnits > 0) {
      const minimumQuantityOrder: CommercialConditionRequirementsType = {
        id: 0,
        amount: commercialPolicyData?.commercialPolicy?.minimumUnits,
        brand: {
          id: 0,
          description: 'Quantidade mínima de itens da tabela',
          title: 'Mínimo de Itens',
        },
        icon: <Foundation name="clipboard-pencil" size={38} color="gray" />,
        isMinimumAmountByOrder: true,
      };
      headLightCards.unshift(minimumQuantityOrder);
    }

    if (commercialPolicyData?.commercialPolicy?.numberItemsRequiredBasket || 0 > 0) {
      const basketCard: CommercialConditionRequirementsType = {
        id: 0,
        amount: commercialPolicyData?.commercialPolicy?.numberItemsRequiredBasket || 0,
        brand: {
          id: 0,
          description: 'Quantidade de itens obrigatórios da cesta',
          image: undefined,
          title: 'Cesta',
        },
        icon: <FontAwesome name="shopping-basket" size={30} color="gray" />,
        isBasketRequired: true,
      };
      headLightCards.unshift(basketCard);
    }
  }

  fastAccessModalVar(false);

  useEffect(() => {
    if (headLightCards?.length > 0) {
      setComboHeadlightCardListActive(true);
    }
  }, commercialPolicyData);

  function setTabViewInFocus(tabView: string) {
    setCurrentTabView(tabView);
  }

  setTabViewInFocus('comboProducts');

  const renderContainerTitle = () => {
    if (isSmallScreen) {
      return <></>;
    }

    return (
      <Flex direction={'column'}>
        <Flex direction={'row'}>
          <TouchableOpacity>
            <IconButton
              style={{ paddingLeft: 0 }}
              icon={
                <Center borderRadius={100} size={8} bgColor={'gray.300'}>
                  <FontAwesome name="angle-left" size={24} color="black" />
                </Center>
              }
              onPress={() => {
                const index = tabNavigation.index;
                const routes = TabNavigationConfig.activeRoutes;
                setTabViewInFocus('combos');
                setTabNavigation({ routes, index });
              }}
            />
          </TouchableOpacity>
          <Text
            style={styles.title}
            color={'muted.900'}
            _dark={{
              color: 'muted.400',
            }}
          >
            {commercialPolicyData?.commercialPolicy?.name}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const renderBannner = () => {
    if (isSmallScreen) {
      return (
        <VStack pl={4} pt={2} space={4} flex={1}>
          <TagAlertOutdatedPrice pl={0} />
          <View>
            <Text
              color={'muted.800'}
              _dark={{
                color: 'muted.400',
              }}
              fontWeight={'700'}
              fontSize={'lg'}
            >
              {commercialPolicyData?.commercialPolicy?.name}
            </Text>
            <Text
              color={'muted.800'}
              _dark={{
                color: 'muted.400',
              }}
              fontWeight={'400'}
              fontSize={'sm'}
            >
              Atenda à quantidade mínima sugerida ou leve mais produtos
            </Text>
          </View>
          {isComboHeadlightCardListActive && (
            <View flex={1} height={['full', '56']}>
              <ComboHeadlightCardList
                minimumAmountBrands={headLightCards}
                commercialPolicyId={tabParams?.commercialPolicyId || 0}
                invoicedData={commercialPolicyData?.commercialPolicy?.invoicedProducts}
              />
              <MinimumAmount list={minimumAmountProps} />
            </View>
          )}
        </VStack>
      );
    }

    if (!isComboHeadlightCardListActive) {
      return <></>;
    }

    return (
      <View>
        <Text
          style={styles.subTitle}
          color={'muted.900'}
          _dark={{
            color: 'muted.400',
          }}
        >
          Quantidades mínimas para serem atingidas:
        </Text>
        <ComboHeadlightCardList
          minimumAmountBrands={headLightCards}
          commercialPolicyId={tabParams?.commercialPolicyId || 0}
          invoicedData={commercialPolicyData?.commercialPolicy?.invoicedProducts}
        />
        <MinimumAmount list={minimumAmountProps} />
      </View>
    );
  };

  return (
    <List
      data={products}
      loading={loading || loadingProducts}
      infinitePagination={isSmallScreen}
      showPagination={!isSmallScreen}
      error={error}
      numColumns={numColumns}
      total={total}
      isListEnd={isListEnd}
      isDefault={false}
      pageCount={totalOffersPerPage}
      loadingRefetch={isReloading}
      showsHorizontalScrollIndicator={false}
      containerTitle={renderContainerTitle()}
      banner={renderBannner()}
      _searchBar={{
        variant: isSmallScreen ? 'outline' : '',
      }}
      keyExtractor={(item) => item.product.code}
      columnWrapperStyle={{
        justifyContent: isSmallScreen ? 'space-between' : 'flex-start',
        marginBottom: 20,
      }}
      contentContainerStyle={{
        justifyContent: 'space-around',
      }}
      renderItem={({ item }) => (
        <View
          flex={1}
          maxW={['48%', '46%', '46%', '33%']}
          alignItems={{ base: 'center', xl: 'baseline' }}
        >
          <ProductCard w="full" product={item} tab={CartProductTypeEnum.COMBO} />
        </View>
      )}
      refetch={(offset, text) => {
        setTextFilter(text);
        return updateData(offset, text);
      }}
    />
  );
};
