import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

export type removeCouponInput = {
	cartId: string,
	coupon: {
		name: string,
	},
}

const REMOVE_COUPON = gql`
  mutation CartRemoveCoupon($input: Cart_AddCouponInput!) {
    Cart_removeCoupon(input: $input) {
      id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
    }
  }
`;

export const useRemoveCoupon = () => {
	return useMutation(REMOVE_COUPON, {
		fetchPolicy: 'no-cache',
		refetchQueries: [
		],
		onCompleted(data) {
			if (data.Cart_removeCoupon) {
				cartVar(data.Cart_removeCoupon)
			}
		},
	});
};
