import { ITextProps, Pressable, Text } from "native-base";
import React, { memo, useState } from "react";

export type ProductNameProps = ITextProps & {
  description: string
  press: () => void
}

export const ProductName: React.FC<ProductNameProps> = memo(({
  description,
  press,
  testID,
  ...restProps
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <Pressable
      testID={`product-name-component-${testID}`}
      onHoverIn={() => setMouseOver(true)}
      onHoverOut={() => setMouseOver(false)}
      onPress={() => press()}
    >
      <Text
        w='full'
        isTruncated
        noOfLines={2}
        fontWeight="500"
        fontSize={["xs", "xs", "xs", "sm"]}
        color={mouseOver ? "primary.600" : "muted.800"}
        _dark={{
          color: mouseOver ? "primary.600" : "muted.400"
        }}
        lineHeight="sm"
        selectable
        {...restProps}
      >
        {description}
      </Text>
    </Pressable>
  )
})