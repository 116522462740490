import { useAuth } from "@contexts/Auth";
import { Box, Button, Stack, Text, VStack, View } from "native-base";
import React from "react";

export const SignInSso = () => {
  const { signIn, loginOut } = useAuth();

  const handleBox = () => {
    return (
      <VStack space={4} w={"full"} textAlign={"center"} justifyContent={"flex-center"}>
        <Stack w="100%" space={1} justifyContent={"flex-center"}>
          <Text fontFamily={"body"} fontSize={"lg"} color={"muted.600"} lineHeight={"md"}
            fontWeight={600}
            _android={{
              fontWeight: 800
            }}
          >
            Você desconectou do sistema
          </Text>
        </Stack>
        <Stack w="100%" space={1} justifyContent={"flex-center"}>
          <Text fontFamily={"body"} fontSize={"lg"} color={"muted.600"} lineHeight={"md"}
            fontWeight={600}
            _android={{
              fontWeight: 800
            }}
          >
            Faça login novamente clicando no botão abaixo:
          </Text>
        </Stack>
        <Stack w="100%" space={1} alignItems="center">
          <Button
            bg={"primary.600"}
            w={"50%"}
            h={"12"}
            mt={4}
            alignItems={"center"}
            justifyContent={"center"}
            _hover={{
              bg: "primary.500"
            }}
            onPress={signIn}
          >
            <Text color={"white"}>Acessar conta</Text>
          </Button>
        </Stack>
      </VStack>
    );
  }

  const handleRedirectingBox = () => {
    return (
      <VStack space={4} w={"full"} textAlign={"center"} justifyContent={"flex-center"}>
        <Stack w="100%" space={1} justifyContent={"flex-center"}>
          <Text fontFamily={"body"} fontSize={"lg"} color={"muted.600"} lineHeight={"md"}
            fontWeight={600}
            _android={{
              fontWeight: 800
            }}
          >
            Redirecionando...
          </Text>
        </Stack>
      </VStack>
    )
  }

  const handleSignIn = () => {
    if (!loginOut) signIn();
  }

  handleSignIn();

  return (
    <Box flex={1}
      _dark={{
        bgColor: "muted.900"
      }}
      _light={{
        bgColor: "white"
      }}
      safeArea
    >
      <View alignItems={"flex-end"}>
        {/* <ColorMode /> */}
      </View>
      <View justifyContent={{ xl: "center", lg: "center", md: "center" }} flex={3}>
        <Box px={[4, 10]} pt={"5"} justifyContent={"center"} alignItems={{ xl: "center", lg: "center" }} >
          <Stack space={["24", "16"]} alignItems="center" justifyContent={"center"}>
            { loginOut ? handleBox() : handleRedirectingBox() }
          </Stack>
        </Box>
      </View>
    </Box>
  );
}