import React, { useEffect, useState } from "react";
import { Text, View, Flex, IconButton, Center } from "native-base";

import * as TabNavigationConfig from "@configs/TabNavigationConfig";
import { List } from "@components/List";
import { useProductTabNavigation } from "@components/ProductsTabView";
import { TouchableOpacity } from "react-native-gesture-handler";
import { SuggestionProductCard } from "@components/SuggestionProductCard";
import { useAuth } from "@contexts/Auth";

import { styles } from "@components/SuggestedProducts/styles";
import { FontAwesome } from "@expo/vector-icons";
import { Summary } from "@components/SuggestedProducts/SuggestedProduct/Summary";
import { CommercialPolicyProductsType } from "../../../types/CommercialPolicyProductsType";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import {
  useSuggestionListProductsLazy,
  refactorResult,
} from "@hooks/useSuggestionListProducts";
import { useCustomer } from "../../../contexts/CustomerContext";
import { useAddSuggestedListCart } from "@hooks/useAddSuggestedListCart";
import { useWholesalers } from "@hooks/useWholesalers";
import { useWholesalersStorage } from "@hooks/useWholesalersStorage";

export const SuggestedProduct = () => {
  const { user } = useAuth();
  const nameIndustry = user?.getNameIndustryStore();
  const [first, setFirst] = useState(true);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<CommercialPolicyProductsType[]>([]);
  const [suggestedProductsPerPage, setSuggestedProductsPerPage] = useState<
    CommercialPolicyProductsType[]
  >([]);

  const addItems = useAddSuggestedListCart();

  const {
    tabParams: tabParams,
    tabNavigation,
    setTabNavigation,
  } = useProductTabNavigation();

  const { wholesalers, loading } = useWholesalersStorage();
  const { customerCode } = useCustomer();

  const [
    query,
    {
      data: suggestionData,
      loading: suggestionLoading,
      error: suggestionError,
    },
  ] = useSuggestionListProductsLazy({
    listId: tabParams?.suggestedListId || 0,
    limit: 100,
    offset: 0,
    commercialPolicyId: 0,
    wholesalerBranchCode: wholesalers[0]?.code ?? "",
    customerCode: customerCode,
  });

  async function getData() {
    const { data } = await query();
    const suggestionProducts =
      data?.Msbit_suggestedListProducts?.suggestionListProducts;
      console.log(data?.Msbit_suggestedListProducts?.suggestionListProducts);
    const productsInCommercialCondition = suggestionProducts.filter(
      (p: any) => {
        return p.commercialPolicyProduct != null;
      }
    );

    if (productsInCommercialCondition.length == 0) {
      setTotal(0);
      setProducts([]);
    }

    const result = refactorResult(
      productsInCommercialCondition,
      tabParams?.suggestedListId || 0,
      tabParams?.name || "",
      nameIndustry
    );

    setTotal(data?.Msbit_suggestedListProducts.pagination.total);
    setProducts(result);
    setIsLoading(false);
  }

  const [filteredList, setFilteredList] = useState<
    CommercialPolicyProductsType[]
  >([]);

  const IconCenterProps = {
    borderRadius: styles.iconListTitle.borderRadius,
    size: styles.iconListTitle.size,
    bgColor: styles.iconListTitle.backgroundColor,
  };

  const subTitleStyleProps = {
    color: styles.font.color,
    _dark: { color: styles.fontDark.color },
    alignSelf: "center",
  };

  function renderItem({ item }: { item: CommercialPolicyProductsType }) {
    return (
      <View
        flex={1}
        maxW={"33%"}
        alignItems={["center", "center", "center", "baseline"]}
      >
        <SuggestionProductCard
          product={item}
          tab={CartProductTypeEnum.SUGGESTED}
          showShopping={false}
        />
      </View>
    );
  }

  const containerTitle = (name: string) => (
    <Flex direction={"column"}>
      <Flex direction="row">
        <TouchableOpacity>
          <IconButton
            style={{ paddingLeft: 0 }}
            icon={
              <Center {...IconCenterProps}>
                <FontAwesome name="angle-left" size={24} color="black" />
              </Center>
            }
            onPress={() => {
              const index = tabNavigation.index;
              const routes = TabNavigationConfig.activeRoutes;
              setTabNavigation({ routes, index });
            }}
          ></IconButton>
        </TouchableOpacity>
        <Text fontSize={["sm", "md", "lg"]} {...subTitleStyleProps}>
          {name}
        </Text>
      </Flex>
    </Flex>
  );

  useEffect(() => {
    if (loading || !first) return;

    (async () => {
      setFirst(false);
      getData();
    })();
  }, [loading, wholesalers]);

  const totalSuggestedProductsPerPage = 20;

  const refreshSuggestedProductsList = (offset: number = 1) => {
    offset = offset == 0 ? 1 : offset;
    const start =
      offset * totalSuggestedProductsPerPage - totalSuggestedProductsPerPage;
    const end = offset * totalSuggestedProductsPerPage;

    const suggestedListProductsSliced = products.slice(start, end);
    setSuggestedProductsPerPage(suggestedListProductsSliced);
  };

  return (
    !suggestionLoading && !loading && (
      <List
        data={suggestedProductsPerPage}
        loading={suggestionLoading}
        error={suggestionError}
        numColumns={3}
        total={total}
        pageCount={totalSuggestedProductsPerPage}
        containerTitle={containerTitle(tabParams?.listCode || "")}
        banner={
          <Summary
            products={products}
            listId={tabParams?.suggestedListId || 0}
            addAllAtOnceToCart={() =>
              addItems.addSuggestedList(
                products,
                tabParams?.listName || "",
                tabParams?.listCode || ""
              )
            }
          />
        }
        keyExtractor={(item) => item.productId}
        renderItem={renderItem}
        refetch={(offset, text) => {
          return refreshSuggestedProductsList(offset);
        }}
        showSearch={false}
      />
    )
  );
};
