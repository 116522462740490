import React, { useMemo } from "react";
import { View, useMediaQuery } from 'native-base';
import { DraxView } from "react-native-drax";
import { DoubleClick } from "../DoubleClick";
import { WholesalerBranchesType } from "../../types/WholesalerBranchesType";

import { styles } from "./styles";
import { Pressable } from "react-native";
import { CardWholesaler } from "@components/DragCard/CardWholeSaler";

interface Props {
  item: WholesalerBranchesType;
  onClick: () => void;
  showBillingOptions: boolean;
  setWholesaler:() => void;
  isPreferredWholesalerScreen: boolean
}

export function DragCard({ item, onClick, showBillingOptions = false, setWholesaler, isPreferredWholesalerScreen }: Props) {

  const [isSmallScreen] = useMediaQuery([
    { maxWidth: 480 }
  ]);

  const isLargeScreen = !isSmallScreen
  
  const DraxListStyle = useMemo(() => {
      if(isSmallScreen) return styles.squareShapeViewMobile

      return styles.squareShapeView      
  }, [isSmallScreen]);

  return (
    <DraxView
      style={ DraxListStyle }
      draggable={isLargeScreen}
      draggingStyle={styles.dragging}
      payload={item}
      animateSnapback={false}
    >
      {isLargeScreen ?
        (
          <Pressable>
            <DoubleClick onDoubleClick={onClick}>
              <View>
                <CardWholesaler 
                  item={item} 
                  showBillingOptions={showBillingOptions}
                  isPreferredWholesalerScreen={isPreferredWholesalerScreen}
                />
              </View>
            </DoubleClick>
          </Pressable>
        )
        : (
          <CardWholesaler 
            setWholesaler={setWholesaler} 
            item={item} 
            showBillingOptions={showBillingOptions} 
            isPreferredWholesalerScreen={isPreferredWholesalerScreen}
          />
        )
      }
    </DraxView>
  );
};