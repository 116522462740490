import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@contexts/Auth";
import Cdn from "@helpers/Cdn";
import { ProductType } from "@types/ProductType";

export const PRODUCT = gql`
  query Product(
    $code: String!
   ){
    product(code: $code) {
      id
      code
      description
      monitored
      industryCode
      shippingBox
      assortment {
        id,
        name
        industryCode
      }
      brand {
        id
        description
        industryCode
      }
    }
  }
`;

const useProductQuery = (code: string) => {
  return useQuery(PRODUCT, {
    variables: {
      code,
    },
  });
};

export const useProduct = (code: string) => {
  const { data, loading, error } = useProductQuery(code);
  const { user } = useAuth();
  const nameIndustry = user.getIndustry();

  const product: ProductType | undefined = data ? refactorResult(data.product, nameIndustry) : undefined;

  return { product, error, loading }
};

export const refactorResult = (
  product: any,
  nameIndustry: string
): ProductType => {
    const urlImage = Cdn.getUrl(nameIndustry.toUpperCase(), `${product.code}.jpg`);
    const leafletUrl = Cdn.getUrl(nameIndustry.toUpperCase(), `bulas`, `${product.code}.pdf`);
    return {
      ...product,
      url: urlImage,
      type: null,
      leafletUrl,
    };
};