import React from "react";
import { Center, Image, useColorMode } from "native-base";
import funcionalLogo from "@assets/images/funcional_logo.png";
import funcionalLogoDark from "@assets/images/funcional_logo_dark.png";
import { Asset } from "expo-asset";

const assetLogoFuncional = Asset.fromModule(funcionalLogo);
const logoFuncional = assetLogoFuncional.uri;

const assetLogoFuncionalDark = Asset.fromModule(funcionalLogoDark);
const logoFuncionalDark = assetLogoFuncionalDark.uri;

interface PropsInterface {
  style?: object;
}

export const FuncionalLogo = (props: PropsInterface) => {
  const { colorMode } = useColorMode();

  return (
    <Center>
      <Image
        source={{
          uri: colorMode == "dark" ? logoFuncionalDark : logoFuncional,
        }}
        {...props.style}
        resizeMode="contain"
        testID="logoFuncional"
        alt="logo funcional"
      />
    </Center>
  );
};
