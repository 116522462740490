import { CastProductEnum } from "@helpers/CastProductEnum";
import { useAddItemOverwriteAlert } from "./useAddItemOverwriteAlert";
import { useShoppingCart } from "./useShoppingCart";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { ItemType } from "@types/CartType";
import { useAddItemExistsInListAlert } from "./useAddItemExistsInListAlert";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { CommercialPolicyProductsAnchorType } from "@types/CommercialPolicyProductAnchorType";

export const useAddSuggestedAnchorCart = () => {
  const { addSuggestedAnchor, cart } = useShoppingCart();
  const add = useAddItemOverwriteAlert();
  const listWarning = useAddItemExistsInListAlert();

  const addItem = async (item: CommercialPolicyProductsAnchorType,
    onSucess: () => void = () => { },
    onError: (error: any) => void = () => { },
    onClose: () => void = () => { },
  ) => {
    const itemInCart = cart?.items?.find(cartItem => cartItem.code === item?.product?.code);
    const type = CastProductEnum(item?.product?.tab)
    const existsInList = !!itemInCart && itemInCart?.type !== type && itemInCart?.type == ItemTypeEnum.SUGGESTED_LIST ;
    if(existsInList) {
      listWarning.setWarning();
      onClose();

      add.setOnSuccessCallback(async () => {
        onClose();
      });

      add.setOnFailCallback(async () => {
        onClose()
      });
      
      listWarning.onOpen()
      return;
    }

    if (!!itemInCart && (itemInCart?.type !== type || itemInCart?.commercialPolicyId !== item.commercialPolicyId)) {
      add.setNewProduct({
        description: itemInCart.product.description,
        discount: itemInCart.discount
      })

      add.setOnFailCallback(async () => {
        onClose()
      })

      add.setOnSuccessCallback(async () => {
        try {
          await addSuggestedAnchor({
            amount: item.product.quantity,
            code: item.product.code,
            commercialPolicyId: item.commercialPolicyId ?? 0,
            type: type,
            suggestions: item.suggestions,
          } as ItemType, true)
          onSucess()
        } catch (error) {
          onError(error)
        }
      })

      add.onOpen()
      return;
    }

    try {
      await addSuggestedAnchor({
        amount: item.product.quantity,
        code: item.product.code,
        commercialPolicyId: item.commercialPolicyId ?? 0,
        type: type,
        suggestions: item.suggestions
      } as ItemType)
      onSucess()
    } catch (error) {
      console.log(error);
      onError(error)
    }
  }

  return { addItem }
};
