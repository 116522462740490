import React from 'react';
import { View, Text } from 'native-base';

export const DialogListToProductTemplate = (): React.ReactElement => {
  return (
    <View>
      <Text fontWeight="800" fontSize="sm" color="gray.600">
        Atenção, um ou mais itens dessa lista já foram adicionados ao carrinho.
      </Text>

      <Text fontWeight="700" fontSize="sm" color="gray.600">
        Deseja fazer a troca? Ao aceitar a troca, os descontos de outros
        produtos podem ser impactados. Por favor, verifique o carrinho
        novamente antes de enviar o pedido.
      </Text>
    </View>
  );
};
