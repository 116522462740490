import React from 'react';
import { Center, Text, VStack, View, } from 'native-base';
import { ContainerLayout } from '@components/ContainerLayout';
import { Time } from "@assets/images/svgs/Time"
import { TouchableOpacity } from 'react-native-gesture-handler';
import { weekType } from '@helpers/week';
import { Loader } from '@components/Loader';
import { useTimeBlockModel } from './TimeBlock.Model';

export const TimeBlockView = ({ loading, timeBlock, onLogout }: ReturnType<typeof useTimeBlockModel>) => {

  if (loading) {
    return (
      <ContainerLayout>
        <VStack flex={1} px={[4, 4, 12, 24]} pt={[4, 10]} justifyContent={'center'}>
          <Loader />
        </VStack>
      </ContainerLayout>
    )
  }

  return (
    <ContainerLayout>
      <VStack flex={1} px={[4, 4, 12, 24]} pt={[4, 10]} justifyContent={'center'}>
        <View flexDir={['column', 'row']} alignItems={['center', 'center', 'inherit']}>
          <Time size={20} />
          <View flex={1} alignItems={['center', 'center', 'inherit']}>
            <Text
              fontSize={["lg", "2xl"]}
              fontWeight={'600'}
              color="muted.800"
              mb={['3', '0']}
              lineHeight={["md", "lg"]}
              _dark={{
                color: "muted.400"
              }}
            >
              Horário de operação da plataforma
            </Text>

            <Text
              fontSize={["xs", "sm"]}
              fontFamily="body"
              color="muted.800"
              mb={[8]}
              _dark={{
                color: "muted.400"
              }}
            >
              O acesso à plataforma está disponível apenas <Text
                fontSize={["xs", "sm"]}
                fontFamily="body"
                fontWeight={600}
                color="muted.800"
                _dark={{
                  color: "muted.400"
                }}
              >
                das {timeBlock.time?.start}h às {timeBlock.time?.end}h, de {weekType[timeBlock.week?.start as never]} a {weekType[timeBlock.week?.end as never]}.
              </Text> Por favor, retorne dentro do horário de funcionamento para prosseguir com o registro de seus pedidos.
            </Text>
            <Text
              fontSize={["xs", "sm"]}
              fontFamily="body"
              color="muted.800"
              mb={[8, 16]}
              _dark={{
                color: "muted.400"
              }}
            >
              Agradecemos a compreensão!
            </Text>
          </View>
        </View>
        <View
          justifyContent={'center'}
          alignItems={'center'}
        >
          <TouchableOpacity onPress={onLogout}>
            <Center
              bg='primary.500'
              flex={1}
              p={2}
              w='72'
              rounded='md'
              _text={{
                fontSize: ['sm', 'md'],
                fontFamily: 'heading',
                color: 'white'
              }}
            >
              Sair
            </Center>
          </TouchableOpacity>
        </View>
      </VStack>
    </ContainerLayout>
  );
};