import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "../../Dimension";

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#F37022',
    height: width * 0.03,
    bottom: 0,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  title: {
    color: "#FFFFFF",
    fontSize: RFValue(5.5),
    
    fontWeight: "600",
  },
  button: {
    color: "#FFFFFF",
    fontSize: RFValue(5.5),
    
    fontWeight: "600",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: "#FFFFFF",
    marginLeft: width * 0.0023
  },
  content: {
    position: 'relative',
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: -13
  }
});