import React, { ReactElement, createContext, useMemo } from "react";
import { useReactiveVar } from "@apollo/client"
import { useCustomerCoupons, couponsVar } from "@hooks/useCustomerCoupons";
import { couponsActiveVar, useCouponsDiscountsLazy } from "@hooks/useCouponsDiscounts";
import { useShoppingCart } from '@hooks/useShoppingCart';
import { CouponsType } from "@types/CouponsType";

interface CouponContextDataProps {
  loading: boolean;
  coupons: CouponsType[];
  applyCoupon(coupon: CouponsType): Promise<void>;
  unapplyCoupon(coupon: CouponsType): Promise<void>;
  unapplyAllCoupons(): Promise<void>;
  loadDiscounts: () => Promise<any>
}

export const CouponContext = createContext<CouponContextDataProps>({} as CouponContextDataProps);

interface CouponContextProviderProps {
  children: ReactElement
}

export const CouponContextProvider: React.FC<CouponContextProviderProps> = ({ children }) => {
  const { loading } = useCustomerCoupons();
  const coupons = useReactiveVar(couponsVar);
  const couponsActive = useReactiveVar(couponsActiveVar);

  const { cart, loading: loadingCart, removeCoupon, addCoupon, clearCoupons, empty } = useShoppingCart()
  const skipCouponsDiscounts = loadingCart || loading || empty || coupons.length == 0;

  const [loadDiscounts, { loading: loadingDiscount }] = useCouponsDiscountsLazy(
    cart,
    skipCouponsDiscounts
  )

  const couponsAppliedName = cart?.coupons ?? []

  const applyCoupon = async (coupon: CouponsType) => {
    await addCoupon(coupon)
  }

  const unapplyCoupon = async (coupon: CouponsType) => {
    await removeCoupon(coupon)
  }

  const unapplyAllCoupons = async () => {
    await clearCoupons()
  }

  const couponMarkApplied = coupons.map(coupon => {

    coupon.active = false;
    if (couponsActive.includes(coupon.coupon.name)) {
      coupon.active = true;
    }

    if (couponsAppliedName.includes(coupon.coupon.name)) {
      coupon.applied = true;
    } else {
      coupon.applied = false;

    }
    return coupon
  })

  const value = useMemo(() => ({
    coupons: couponMarkApplied,
    applyCoupon,
    unapplyCoupon,
    unapplyAllCoupons,
    loadDiscounts,
    loading: loading || loadingDiscount || loadingCart,
  }), [
    loading,
    coupons,
    loadingDiscount,
    loadingCart,
    couponMarkApplied,
    couponsAppliedName
  ]);

  return (
    <CouponContext.Provider value={value}>
      {children}
    </CouponContext.Provider>
  );
};
