import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { THEME } from "../../../src/theme";

export const styles = StyleSheet.create({
  popover: {
    borderRadius: 4,
    border: "1px" + THEME.colors.green[300] + "solid",
    boxShadow: "0px 14px 14px rgba(0, 0, 0, 0.25)",
    backgroundColor: THEME.colors.green[50],
  },

  cardContentText: {
    marginTop: 5,
    fontWeight: "medium",
    fontSize: RFValue(6),
  },

  cardContentTitle: {
    marginTop: 20,
    fontWeight: "bold",
    fontSize: RFValue(7),
  },

  btnFinish: {
    backgroundColor: THEME.colors.green[400],
    color: THEME.colors.white,
    marginBottom: 20,
  },

  btnContainer: {
    marginTop: 20,
  },

  btnFinishText: {
    color: THEME.colors.white,
    fontSize: RFValue(6),
    fontWeight: "600",
  },

  btnContinue: {
    backgroundColor: THEME.colors.white,
    border: "1px " + THEME.colors.green[400] + " solid",
    marginTop: 20,
    marginBottom: 10,
    width: "100%",
  },

  btnContinueText: {
    color: THEME.colors.green[400],
  },
});
