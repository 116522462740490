import React, { useState } from "react";
import { View, Text } from 'native-base';
import { useShoppingCart } from '@hooks/useShoppingCart';
import Alert, { Props as AlertProps } from "@components/Alert";
import { Loader } from "@components/Loader";
import { finishPromotionalActionSession } from "@components/PromotionalAction";

export const ConfirmationOrderModal = ({
  onClose,
  alertDialog,
}: {
  onClose: any;
  alertDialog: any;
}) => {
  const [loading, setLoading] = useState(false);
  const { checkout } = useShoppingCart();

  function closeModal(swal: boolean = false, error: boolean = false, id?: string) {
    onClose(swal, error, id);
  }

  const handleActionButton = async () => {
    setLoading(true);

    try {
      await checkout();
      closeModal(true, false);
    } catch (e) {
      console.log(e);
      closeModal(false, true);
    } finally {
      setLoading(false);
      finishPromotionalActionSession();
    }
  };

  const alertParams: AlertProps = {
    type: "ALERT",
    headerText: "Enviar pedido",
    bodyContent: (
      <View>
        <Text
          selectable
          fontSize="sm"
          lineHeight={20}
          fontWeight="500"
          color="muted.800"
          _dark={{
            color: "muted.400",
          }}
        >
          Deseja enviar o pedido?
        </Text>
        <Text
          selectable
          fontSize="sm"
          lineHeight={16}
          fontWeight="400"
          marginTop={1}
          color="muted.800"
          _dark={{
            color: "muted.400",
          }}
        >
          Após envio, seu pedido não poderá ser mais alterado. Deseja
          prosseguir?
        </Text>
      </View>
    ),
    btnTextConfirm: "Sim, enviar agora",
    btnTextCancel: "Cancelar",
    onConfirmCallback: () => {
      handleActionButton();
    },
  };

  return (
    <>
      <Alert ref={alertDialog} {...alertParams} />
      <Loader visible={loading} />
    </>
  );
};
