import React from 'react';

import { Separator } from "@components/FinishOrder/Separator";
import { Box, IBoxProps } from "native-base";
import { StyleSheet } from 'react-native';

type DottedLineProps = {

} & IBoxProps
export const DottedLine = (props: DottedLineProps) => {
  return (
    <Box flex={1} justifyContent={'center'} alignItems={'center'} {...props}>
      <Separator style={styles.separator} borderBottomColor='primary.600' bgColor={'none'} />
    </Box>
  );
};

const styles = StyleSheet.create({
  separator: {
    width: '100%',
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
});
