import {
  IStackProps,
  ITextProps,
  Text,
  Tooltip,
} from "native-base";
import React from "react";
import helpers from "@helpers/NumberHelper";
import { WholesalerPriceProps } from "@components/WholesalerPriceCard";
import { OutdatedPrice, OutdatedPriceProps } from "@components/ProductPrice";
import { Price } from "@components/Price";

export type PriceProps = {
  price: number;
} & ITextProps;


export type ProductPriceProps = IStackProps & {
  data: WholesalerPriceProps;
};

export const WholesalerProductPrice: React.FC<ProductPriceProps> =
  ({ data }) => {
    
    const wholesalerData: OutdatedPriceProps = {
      wholesaler:{
        price: {
          netPrice: data.price,
          fullPrice: data.price
        },
        fractionation: data.fractionation,
        outdated: data.outdated,
        updatedAt: data?.updatedAt && new Date(data?.updatedAt).toISOString().substr(0, 10).split('-').reverse().join('/').split('T')[0]
      }
    }

    return (data.outdated ? <OutdatedPrice wholesaler={wholesalerData.wholesaler} />
      : <Price price={data.price} />)
  };
