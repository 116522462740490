import { useAuth } from "../contexts/Auth";
import { IntegrationEnum } from "../enums/IntegrationEnum";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useCustomer } from "../contexts/CustomerContext";
import { AllCommercialPoliciesInputType } from "@types/AllCommercialPoliciesInputType";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";

const ALL_COMMERCIAL_POLICIES_DISCOUNT_RULES = gql`
  query AllCommercialPolicies(
    $customerCode: String!
    $integrations: IntegrationEnum!
    $prevailing: Boolean
    $industry: IndustryInputType
    $aggregator: Boolean = true
    $policyRules: CommercialPolicyInputType
    $availability: AvailabilityConsolidateEnum
  ) {
    allCommercialPolicies(
      customerCode: $customerCode
      integrations: [$integrations]
      prevailing: $prevailing
      industry: $industry
      aggregator: $aggregator
      filter: $policyRules
      availability: $availability
    ) {
      id
      endValidityDate
      name
      maxDiscount
      numberItemsRequiredBasket
    }
  }
`;

export const useAllCommercialPoliciesRules = (
  input: AllCommercialPoliciesInputType
) => {
  const { customerCode, impersonate } = useCustomer();
  const { user } = useAuth();

  const integrations = IntegrationEnum.POINT_OF_SALES;
  const availability = impersonate && user.isUserAllowedToImpersonate() ? AvailabilityConsolidateEnum.CUSTOMER : user.getAvailability();

  return useQuery(ALL_COMMERCIAL_POLICIES_DISCOUNT_RULES, {
    variables: {
      customerCode: customerCode,
      integrations,
      availability,
      ...input,
    },
    fetchPolicy: "no-cache",
  });
};

export const useAllCommercialPoliciesDiscountRuleslazy = (
  discountRules: string[] = []
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const integrations = IntegrationEnum.POINT_OF_SALES;

  return useLazyQuery(ALL_COMMERCIAL_POLICIES_DISCOUNT_RULES, {
    variables: {
      customerCode: customerCode,
      prevailing: true,
      integrations,
      industry: { isEnabled: true },
      aggregator: false,
      discountRules,
    },
    fetchPolicy: "no-cache",
  });
};
