import React, { useMemo } from "react";
import {
  Center,
  Flex,
  Image,
  View,
  Text,
  Tooltip,
  useMediaQuery,
} from "native-base";
import { AnimatedCircularProgress } from "react-native-circular-progress";

import Label from "@components/Label";
import { Loading } from "@components/Loading";

import { CommercialConditionRequirementsType } from "@types/CommercialConditionRequirementsType";
import { FindProductsPropsType } from "@types/FindProductsPropsType";
import { RequiredTypeEnum } from "@enums/RequiredTypeEnum";
import { useShoppingCart } from "@hooks/useShoppingCart";
import {
  refactorResult,
  useCommercialPolicyProducts,
} from "@hooks/useCommercialPolicyProducts";
import { ItemType } from "@types/CartType";
import { useAuth } from "@contexts/Auth";
import { THEME } from "@theme/index";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";

interface ComboHeadlightCardProps {
  item: CommercialConditionRequirementsType;
  commercialPolicyId: number;
}

export function ComboHeadlightCard({
  item,
  commercialPolicyId,
}: ComboHeadlightCardProps) {
  const { cart, loading, empty } = useShoppingCart();
  const { user } = useAuth();

  const productSamePolicy: ItemType[] =
    cart?.items.filter(
      (item) =>
        item.commercialPolicyId == commercialPolicyId &&
        item.type == ItemTypeEnum.COMBO
    ) ?? [];

  const countProduct: number =
    cart?.items.reduce((previous, current) => previous + current.amount, 0) ??
    0;

  const productCodes: string[] =
    productSamePolicy.map((item) => item.code) ?? [];

  const skip = loading || empty;
  //TODO: estudar se é melhor usar fields adcionais
  const { loading: loadingProducts, data } = useCommercialPolicyProducts(
    {
      commercialPolicyId,
      requiredType: [
        RequiredTypeEnum.NON_REQUIRED,
        RequiredTypeEnum.REQUIRED,
        RequiredTypeEnum.REQUIRED_BASKET,
      ],
      product: {
        codes: productCodes,
      },
      limit: 30,
      offset: 0,
    },
    skip
  );

  let findProductProps: FindProductsPropsType = {
    commercialPolicyId: commercialPolicyId,
    brandId: item?.brand?.id,
  };

  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 768,
    },
  ]);

  const currentAmount = useMemo(() => {
    const commercialPolicyProducts = refactorResult(
      data?.commercialPolicyProducts,
      user?.getIndustry()
    );

    if (item?.isBasketRequired) {
      const sameBasket = commercialPolicyProducts
        .filter((item) => item.commercialPolicyId === commercialPolicyId)
        .filter((p) => productCodes.includes(p.product.code))
        .filter((p) => !p.hasNotConsideredMinimum)
        .filter((p) => p.requiredType == RequiredTypeEnum.REQUIRED_BASKET);

      return sameBasket.length + (item?.acummulativeData || 0);
    }

    if (item?.isMinimumAmountByOrder) {
      const sameAmount = commercialPolicyProducts
        .filter((item) => item.commercialPolicyId === commercialPolicyId)
        .filter((p) => productCodes.includes(p.product.code))
        .filter((p) => !p.hasNotConsideredMinimum)
        .map((p) => p.product.code);

      const same = productSamePolicy.filter((p) => sameAmount.includes(p.code));

      const amount: number = same.reduce(
        (previous, current) => previous + current.amount,
        0
      );

      return amount + (item?.acummulativeData || 0);
    }

    const sameBrand = commercialPolicyProducts
      .filter((item) => item.commercialPolicyId === commercialPolicyId)
      .filter((p) => productCodes.includes(p.product.code))
      .filter((p) => !p.hasNotConsideredMinimum)
      .filter((p) => p.product.brand.id === findProductProps.brandId)
      .map((p) => p.product.code);
    const itensSameBrand = productSamePolicy.filter((p) =>
      sameBrand.includes(p.product.code)
    );

    const amount: number = itensSameBrand.reduce(
      (previous, current) => previous + current.amount,
      0
    );

    return amount + (item?.acummulativeData || 0);
  }, [cart?.items, countProduct, data]);

  const currentProgress = (currentAmount / item.amount) * 100;
  const isComplete = currentProgress >= 100;

  if (loadingProducts) {
    <Loading />;
  }

  return (
    <Center
      bgColor="white"
      _dark={{
        bgColor: "muted.700",
      }}
      borderRadius={4}
      flex="1"
      shadow={"5"}
      h={[40, 48, "56"]}
      w={[24, 32]}
    >
      {!isSmallScreen && (
        <Text
          color={"muted.800"}
          _dark={{
            color: "muted.400",
          }}
          fontWeight={"500"}
          fontSize={"sm"}
          mb="4"
        >
          {item?.brand?.title}
        </Text>
      )}
      <Tooltip
        label={item?.brand?.description}
        bg={"gray.600"}
        placement={"top"}
        openDelay={500}
        flex={1}
      >
        <View>
          <AnimatedCircularProgress
            size={84}
            width={5}
            fill={currentProgress}
            tintColor={
              isComplete ? THEME.colors.success[600] : THEME.colors.danger[600]
            }
            backgroundColor={THEME.colors.gray[300]}
            duration={1500}
            children={() => {
              if (item.icon) {
                return <View>{item.icon}</View>;
              }
              return (
                <View textAlign={"center"} alignContent={"center"}>
                  <Image
                    size={65}
                    resizeMethod={"auto"}
                    resizeMode={"contain"}
                    src={item.brand?.image}
                    alt={item?.brand?.label}
                    fallbackElement={
                      <Text
                        color={"muted.800"}
                        _dark={{
                          color: "muted.400",
                        }}
                        fontWeight={"500"}
                        fontSize={"sm"}
                        lineHeight={"sm"}
                      >
                        {item?.brand?.label}
                      </Text>
                    }
                  />
                </View>
              );
            }}
          />
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
          >
            <Text
              color={"muted.800"}
              _dark={{
                color: "muted.400",
              }}
              fontWeight={"400"}
              fontSize={"sm"}
              lineHeight={"sm"}
              style={[
                isComplete
                  ? {
                      color: THEME.colors.success[600],
                      fontWeight: "900",
                    }
                  : currentProgress > 1
                  ? {
                      color: THEME.colors.danger[600],
                      fontWeight: "900",
                    }
                  : {},
              ]}
            >
              {currentAmount || 0}/{item.amount} un
            </Text>
          </Flex>
        </View>
      </Tooltip>
      {item.obligatoriness && (
        <Label
          text="Obrigatório"
          bg="danger.600"
          w="100%"
          _text={{
            fontSize: "xs",
          }}
          borderBottomRadius={5}
        />
      )}
    </Center>
  );
}
