import {
  IStackProps,
  ITextProps,
  Text,
  Tooltip,
} from "native-base";
import React from "react";
import helpers from "@helpers/NumberHelper";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { WholesalerType } from "@types/WholesalerType ";
import { Price } from "@components/Price";

export type PriceProps = {
  price: number;
} & ITextProps;

export type OutdatedPriceProps = {
  wholesaler: WholesalerType;
};

export const OutdatedPrice: React.FC<OutdatedPriceProps> =
  ({ wholesaler }) => {
    return (
      <Tooltip
        label={wholesaler.updatedAt ? 'Última atualização ' + wholesaler.updatedAt : 'Preço não informado'}
        bgColor={"gray.450"}
      >
        <Text
          fontWeight={700}
          color={"warning.500"}
          _dark={{
            color: "muted.300",
          }}
          fontSize={["xs", "xs", "xs", "sm"]}
        >
          R${" "}
          {helpers.currency(wholesaler.price.netPrice, 2)}
        </Text>
      </Tooltip>
    )
  };


export type ProductPriceProps = IStackProps & {
  product: CommercialPolicyProductsType;
};

export const ProductPrice: React.FC<ProductPriceProps> =
  ({ product }) => {

    const renderPrice = (product: CommercialPolicyProductsType) => {
      if (product.wholesaler.outdated) {
        return <OutdatedPrice wholesaler={product.wholesaler} />
      }
      return <Price price={product.wholesaler.price.netPrice} />
    }

    if (product.discount.base > 0) {
      return (
        <>
          <Price price={product.wholesaler.price.fullPrice} textDecorationLine="line-through" />
          {renderPrice(product)}
        </>
      )
    }
    return renderPrice(product);
  };
