import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";


export type RemoveItemInput = {
	cartId: string,
	productCode: string
}

const REMOVE_ITEM = gql`
  mutation CartRemoveItem($input: Cart_RemoveItemInput!) {
    Cart_removeItem(input: $input) {
      id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
					list{
						name
						code
						amount
					}
					anchor {
						anchorAmount
					}
				}
				}
    }
  }
`;

export const useRemoveItem = () => {
	return useMutation(REMOVE_ITEM, {
		refetchQueries: [
		],
		onCompleted(data) {
			if (data.Cart_removeItem) {
				cartVar(data.Cart_removeItem)
			}
		},
	});
};
