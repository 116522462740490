import { extendTheme } from "native-base";

export const THEME = extendTheme({
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    primary: {
      50: "#FAFFF5",
      100: "#F0FFDD",
      200: "#C8F495",
      300: "#AFEA6C",
      400: "#97DA4B",
      500: "#81C532",
      600: "#69A920",
      700: "#518613",
      800: "#375D0B",
      900: "#1E3305"
    },
    muted: {
      100: "#FAFBFB",
      200: "#F3F3F4",
      300: "#E7E8E8",
      400: "#D7D7D8",
      500: "#C1C1C2",
      600: "#A3A4A5",
      700: "#7F7F80",
      800: "#555555",
      900: "#282929"
    },
    purple: {
      50: "#FCF5FF",
      100: "#F9E8FF",
      200: "#DD9FF4",
      300: "#CC79EA",
      400: "#B85BDA",
      500: "#A242C5",
      600: "#892FA9",
      700: "#6B2086",
      800: "#4A145D",
      900: "#280A33"
    },
    orange: {
      50: "#FFF8F5",
      100: "#FFEFE7",
      200: "#F4AE8B",
      300: "#EA8D5E",
      400: "#DA703C",
      // 500: "#C55822", // TODO: REMOVE CURRENT 500 AND USE THIS ONE (NEEDS TO TALK WITH UX)
      500: "#EF5A10",
      600: "#A94411",
      700: "#863107",
      800: "#5D2002",
      900: "#331100"
    },
    orangeCharts: { // no theme eles estão disposto do orange99 ao orange20
      50: "##FFFAF5",
      100: "#FFF6ED",
      200: "#FFEAD5",
      300: "#FDDCAB",
      400: "#FEB273",
      500: "#FD853A",
      600: "#FB6514",
      700: "#EC4A0A",
      800: "#C4320A",
      900: "#9C2A10"
    },
    blue: {
      50: "#F5FEFF",
      100: "#E1FBFE",
      200: "#8BEAF4",
      300: "#5EDCEA",
      400: "#3CCADA",
      500: "#22B5C5",
      600: "#119AA9",
      700: "#077986",
      800: "#02545D",
      900: "#002E33"
    },
    info: {
      50: "#F5FAFF",
      100: "#EFF8FF",
      200: "#D1E9FF",
      300: "#B2DDFF",
      400: "#84CAFF",
      500: "#53B1FD",
      600: "#2E90FA",
      700: "#1570EF",
      800: "#175CD3",
      900: "#1849A9"
    },
    success: {
      50: "#F2FFF6",
      100: "#E6FAEF",
      200: "#B6F3C6",
      300: "#92F2AB",
      400: "#2DD07A",
      500: "#00A74E",
      600: "#00853C",
      700: "#006D31",
      800: "#005718",
      900: "#07461F"
    },
    warning: {
      50: "#FFFCF0",
      100: "#FEF7E5",
      200: "#FDEA98",
      300: "#FFCD5E",
      400: "#F3AF00",
      500: "#D09000",
      600: "#AE7300",
      700: "#8C5900",
      800: "#744600",
      900: "#473101"
    },
    danger: {
      50: "#FFFBFF",
      100: "#FDEEF0",
      200: "#FFDADA",
      300: "#FFB3B5",
      400: "#FF888E",
      500: "#FF5165",
      600: "#E3294A",
      700: "#BE0044",
      800: "#92003D",
      900: "#6C073F"
    },
    gray: {
      200: "#EDEDED",
      300: "#DADADA",
      400: "#AAAAAA",
      450: "#383838",
      500: "#686868",
      600: "#484848",
      800: "#1C1C1C",
    },
    green: {
      300: "#74BF48",
      400: "#5E9022",
    },
    red: {
      key: "#FF0000",
      500: "#D10000",
    },
    yellow: {
      400: "#FFE247",
    },
  },
  fontConfig: {
    Roboto: {
      100: {
        normal: "Roboto_400Regular",
      },
      200: {
        normal: "Roboto_400Regular",
      },
      300: {
        normal: "Roboto_400Regular",
      },
      400: {
        normal: "Roboto_400Regular",
      },
      500: {
        normal: "Roboto_500Medium",
      },
      600: {
        normal: "Roboto_700Bold",
      },
      700: {
        normal: 'Roboto_700Bold',
      },
      800: {
        normal: 'Roboto_700Bold',
      },
    }
  },
  fonts: {
    heading: "Roboto_700Bold",
    body: "Roboto_400Regular",
    subheading: "Roboto_500Medium"
  },
  fontWeights: {
    emptyDataWarning: "500",
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
    "3xl": 30,
    "4xl": 36,
  },
  sizes: {
    26: 104,
    28: 112,
    35: 140,
    42: 168,
    43: 172,
    59: 236,
    75: 300,
    90: 360,
  },
  components: {
    Progress: {
      baseStyle: {
        _filledTrack: {
          rounded: 'none',
          bg: 'success.500'
        }
      },
      defaultProps: {
        rounded: 'none'
      }
    },
    Button: {
      baseStyle: {
        rounded: 'sm'
      }
    },
    Modal: {
      sizes: {
        xs: {
          contentSize: {
            width: "60%",
            maxWidth: "280",
          },
        },
        sm: {
          contentSize: {
            width: "65%",
            maxWidth: "320",
          },
        },
        md: {
          contentSize: {
            width: "75%",
            maxWidth: "380",
          },
        },
        lg: {
          contentSize: {
            width: "80%",
            maxWidth: "520",
          },
        },
        xl: {
          contentSize: {
            width: "90%",
            maxWidth: "580",
          },
        },
        full: {
          contentSize: {
            maxWidth: 1200,
            width: "100%",
          },
        },
      },
    },
  },
});
