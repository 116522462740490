import React from 'react';
import { TouchableOpacity } from 'react-native';
import { HStack, Heading, Text, VStack, Factory } from 'native-base';

import { OrderStatusTag } from './OrderStatusTag';
import { TextHelper } from '@helpers/TextHelper';
import { DateConvert } from '@helpers/Date';

type Props = {
  status: string;
  id: string;
  customerCode: string;
  createdAt: string;
  onPress?: () => void;
}

const Button = Factory(TouchableOpacity);

export function OrderCard(props: Props) {
  const { status, id, customerCode, createdAt, onPress } = props;

  const date = new DateConvert(createdAt).formatDate();
  const hour = new DateConvert(createdAt).getHour();

  return (
    <Button
      testID={`order-card-button_${status}`}
      disabled={!!!onPress}
      onPress={onPress}
    >
      <VStack
        p={4}
        rounded='md'
        bg='white'
        shadow='3'
        mb={6}
      >
        <OrderStatusTag status={status} />

        <HStack mt={4} mb={2}>
          <HStack flex={1} alignItems='center'>
            <Heading
              fontSize='xs'
              fontFamily='heading'
              color='muted.800'
              mr={4}
            >
              ID
            </Heading>

            <Text fontSize='xs' color='muted.700' selectable>
              {id}
            </Text>
          </HStack>

          <HStack alignItems='center'>
            <Heading
              fontSize='xs'
              fontFamily='heading'
              color='muted.800'
              mr={4}
            >
              CNPJ
            </Heading>

            <Text fontSize='xs' color='muted.700' selectable>
              {new TextHelper(customerCode).formatCNPJ()}
            </Text>
          </HStack>
        </HStack>

        <HStack alignItems='center'>
          <Heading
            flex={1}
            fontSize='xs'
            fontFamily='heading'
            color='muted.800'
          >
            Data e horário da criação
          </Heading>

          <Text fontSize='xs' color='muted.700' selectable>
            {date} às {hour}
          </Text>
        </HStack>
      </VStack>
    </Button>
  );
}