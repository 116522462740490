import React, { useEffect, useState } from 'react';
import { Text, VStack, useMediaQuery, View, Button } from 'native-base';
import { useReactiveVar } from '@apollo/client';

import { ContainerLayout } from './ContainerLayout';
import { IndustryList } from './IndustryList';
import { UnavailableIndustries } from './UnavailableIndustries';
import { Loading } from '@components/Loading';
import { UnexpectedError } from '@components/UnexpectedError';
import { IndustryDataType } from '../../types/IndustryDataType';
import { useAvailableIndustriesByUser, industryItemsVar } from '@hooks/useAvailableIndustriesByUser';
import { filterIndustryPromotionalAction, finishPromotionalActionSession, redirectPromotionalAction } from '@components/PromotionalAction';
import { useAuth } from '@contexts/Auth';
import { useCustomer } from '@contexts/CustomerContext';
import { getSessionStorage, setSessionStorage } from '@helpers/SessionStorage';
import { promotionalActionEansVar, usePromotionalAction } from '@hooks/usePromotionalAction';

const MOBILE_PAGE_SIZE = 10;
const WEB_PAGE_SIZE = 20;

export const ChooseIndustry = () => {
  const { setDataStore, user } = useAuth();
  const { saveCustomer } = useCustomer();
  const isPointOfSales = user.isPointOfSales();
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 620 }]);
  const ITEMS_PER_PAGE = isSmallScreen ? MOBILE_PAGE_SIZE : WEB_PAGE_SIZE;
  const [offset, setOffset] = useState(0);
  const customerCode = user?.getUserCode() || "";
  const [promotional, setPromotional] = useState<any>();
  const [promotionalIndustrySelected, setPromotionalIndustrySelected] = useState<boolean>(false);

  const { data, loading, error, fetchMore } = useAvailableIndustriesByUser({
    limit: ITEMS_PER_PAGE,
    offset
  });

  usePromotionalAction({
    name:  promotional?.name,
    skip: promotional?.name ? false : true
  })
  const promotionalActionVar = useReactiveVar(promotionalActionEansVar);

  const industries: IndustryDataType[] = useReactiveVar(industryItemsVar);

  const handleLoadMoreData = () => {
    if (
      !loading
      && data?.availableIndustriesByUser?.pageInfo?.hasNextPage
    ) {
      fetchMore({
        variables: {
          offset: offset + ITEMS_PER_PAGE,
        },
      });
      setOffset(offset + ITEMS_PER_PAGE);
    }
  };

  useEffect(() => {
    if(isPointOfSales && industries.length > 0){
      (async function(){
        const promo = await getSessionStorage('promotionalAction');
        if(promo?.active){
          setPromotional(promo);
          const selectedIndustry: IndustryDataType | undefined = await filterIndustryPromotionalAction(industries);
          if(selectedIndustry){
            setPromotionalIndustrySelected(true)
            await setDataStore(selectedIndustry, user);
            await saveCustomer({ code: customerCode });
          }
        }
      })()
    }
  }, [industries])

  useEffect(() => {
    if(isPointOfSales && promotionalIndustrySelected){

      (async function(){
        const origins = promotionalActionVar?.origins?.map((origin) => {
          return origin.toLowerCase()
        });
        const originName = promotional?.origin.toLowerCase()

        if(!origins?.includes(originName)){
          const promo = await getSessionStorage('promotionalAction');
          promo.error = true;
          await setSessionStorage('promotionalAction', promo);
          await finishPromotionalActionSession()
        }

        redirectPromotionalAction();
      })()
    }
  },[promotionalActionVar])

  return (
    <ContainerLayout>
      {loading ? <Loading /> : (
        <VStack flex={1} px={[4, 4, 12, 24]} pt={[4, 10]}>
          {
            error ? <UnexpectedError consoleError={error.message} />
              : industries.length === 0 ? <UnavailableIndustries />
                : (
                  <>
                    <Text
                      fontSize={["lg", "2xl"]}
                      fontFamily="heading"
                      color="muted.800"
                      lineHeight={["md", "lg"]}
                      _dark={{
                        color: "muted.400"
                      }}
                    >
                      Qual portal você deseja acessar?
                    </Text>

                    <Text
                      fontSize={["xs", "sm"]}
                      fontFamily="body"
                      color="muted.800"
                      mb={[8, 16]}
                      _dark={{
                        color: "muted.400"
                      }}
                    >
                      Clique na opção desejada abaixo:
                    </Text>

                    <View flex={1}>
                      <IndustryList
                        industries={industries}
                        onLoadMore={handleLoadMoreData}
                      />
                    </View>
                  </>
                )
          }
        </VStack>
      )}
    </ContainerLayout>
  );
};