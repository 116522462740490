import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';

type FractionationAlertProps = {
  type?: 'WEB' | 'MOBILE'
}

export function FractionationAlert({
  type = 'WEB'
}: FractionationAlertProps) {
  return (
    type === 'WEB' ? (
      <Web />
    ) : (
      <Mobile />
    )
  );
}