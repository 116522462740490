import React from "react";
import { View } from "react-native";
import { Card } from "react-native-elements";
import { DataTableHeader } from "../../contracts/DataTableHeader";
import { EnhancedDataTable } from "../../components/EnhancedDataTable";
import { Config } from "@configs/Config";
import { CouponsList } from "../../components/CouponsList";

export const ProductsPerIndustry = (productProps: any) => {
  const productRows = productProps.products;
  const industry = productProps.industry;
  const isKuponoEnabled = Config.FT_KUPONO_ENABLED;

  function setProductFields() {
    return productRows.map(function (product: any) {
      if (product.industryCode === industry) {
        return {
          ean: [product.ean || "", 15],
          name: [product.name || "", 35],
          quantity: [product.quantity || 0, 10],
          invoiceQuantity: [product.invoiceQuantity || 0, 10],
          coupons: [product?.extraDiscounts ? <CouponsList coupons={product?.extraDiscounts} /> : null, 30]
        };
      }
      return [];
    });
  }

  const productHeader: DataTableHeader[] = [
    {
      name: "ean",
      value: "EAN",
      visibility: true,
      size: 15
    },
    {
      name: "name",
      value: "Descrição",
      visibility: true,
      size: 35
    },
    {
      name: "quantity",
      value: "Qtd. Pedida",
      visibility: true,
      size: 10
    },
    {
      name: "invoiceQuantity",
      value: "Qtd. Faturada",
      visibility: true,
      size: 10
    },
    {
      name: "coupons",
      value: "Cupons aplicados",
      visibility: isKuponoEnabled,
      size: 30
    },
  ];

  const formatedRows = setProductFields();

  return (
    <View key={Math.random()}>
      <Card key={Math.random()}>
        {EnhancedDataTable(productHeader, formatedRows, 7)}
      </Card>
    </View>
  );
};
