

import React from "react";
import { Text } from "native-base"

import { SearchBar } from "@components/SearchBar"

type CustomerSearchBarProps = {
  testID?: string
  label?: string,
  value: string,
  onChange: any,
  searchByFilters: any
}

export const CustomerSearchBar = ({ label, value, onChange, searchByFilters, testID }: CustomerSearchBarProps) => {
  return (
    <>
      {label && <Text
        color="muted.800"
        _dark={{
          color: "muted.400",
        }}
        mt={4}
        mb="2"
      >
        {label}
      </Text>}
      <SearchBar
        testID={testID}
        value={value}
        onChangeText={onChange}
        onKeyPress={(event) => {
          if (event.nativeEvent.key !== "Enter") {
            return;
          }

          searchByFilters(value);
        }}
        onPress={() => searchByFilters(value)}
      />
    </>
  )
}