import React from 'react';
import { Platform } from 'react-native';
import { FlatList, useMediaQuery } from 'native-base';

import { IndustryButton } from './IndustryButton';
import { IndustryDataType } from '@types/IndustryDataType';

type Props = {
  industries: IndustryDataType[];
  onLoadMore: () => void;
}


export function IndustryList({ industries, onLoadMore }: Props) {
  const [isLargeScreen] = useMediaQuery([{ minWidth: 700 }]);
  const numColumns = Platform.OS === "web" && isLargeScreen ? 2 : 1;

  return (
    <FlatList
      testID='industries-list'
      data={industries}
      renderItem={({ item }) => (
        <IndustryButton industry={item} />
      )}
      numColumns={numColumns}
      key={numColumns}
      scrollEnabled
      showsVerticalScrollIndicator={isLargeScreen}
      onEndReached={onLoadMore}
      onEndReachedThreshold={0.2}
      contentContainerStyle={{
        alignItems: numColumns === 1 ? 'center' : 'flex-start',
        marginBottom: 80
      }}
    />
  );
}