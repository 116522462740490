import React, { useCallback } from 'react';
import { Text, View } from 'native-base';
import { useAuth } from '@contexts/Auth';

interface ProfileIconInterface {
  w: number;
  h: number;
  fontSize: string | string[];
  rounded: string | string[];
  innerProps?: object;
  containerProps?: object;
}

export const ProfileIcon = (props: ProfileIconInterface) => {
  const { w, h, fontSize, rounded, innerProps, containerProps } = props;

  const { user } = useAuth();

  const nameAcronym = useCallback((): string => {
    const name = user.getName();
    if (name.trim() === "") {
      return "";
    }

    const arrayNames = name.split(" ");

    if (arrayNames.length < 1) {
      return "";
    }

    const firstName = arrayNames[0];
    return firstName.slice(0, 2).toUpperCase();
  }, [user]);

  return (
    <View
      bgColor={"primary.600"}
      alignItems={"center"}
      justifyContent={"center"}
      w={w}
      h={h}
      rounded={rounded}
      {...containerProps}
    >
      <Text
        fontWeight={"medium"}
        fontSize={fontSize}
        color={"white"}
        textAlign={"center"}
        {...innerProps}
      >
        {nameAcronym()}
      </Text>
    </View>
  );
};
