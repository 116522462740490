import React from 'react';
import { TouchableOpacity } from 'react-native';
import { CloseIcon, Factory, HStack, Icon, Modal, Text, VStack } from 'native-base';
import { useSendOrderResponsiveOverlay } from '@hooks/useSendOrderResponsiveOverlay';
import { CartIcon } from '@assets/images/svgs/CartIcon';

const TouchableOpacityFactory = Factory(TouchableOpacity);

type DateSchedule = {
  date: Date | string,
  period: string
}

type Props = {
  handleSendOrder: () => Promise<void>;
  dateSchedule?: DateSchedule;
  cartCode: string;
}

export function Web({ cartCode, handleSendOrder, dateSchedule }: Props) {
  const { isOpen, onClose } = useSendOrderResponsiveOverlay();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="504px" px={6} pb={6}>
        <Modal.Header pl={0}>
          <HStack
            w='100%'
            alignItems='center'
          >
            <CartIcon size={6} />

            <Text
              ml={4}
              flex={1}
              fontSize='md'
              fontFamily='subheading'
              color='success.700'
            >
              Enviar pedido
            </Text>

            <TouchableOpacityFactory alignSelf='start' onPress={onClose}>
              <CloseIcon size={3} color='primary.600' />
            </TouchableOpacityFactory>
          </HStack>
        </Modal.Header>

        <Modal.Body px={0} pb={0} mb={12}>
          <VStack mt={4} w='full'>
            <Text fontSize='sm' color='muted.800'>
              Após o envio, seu pedido não poderá mais ser{"\n"}
              alterado.
            </Text>

            <HStack mt={5}>
              <Text fontSize='sm' color='muted.800'>
                ID da solicitação:
              </Text>

              <Text fontSize='sm' color='muted.700'>
                {" "}{cartCode}
              </Text>
            </HStack>

            {dateSchedule &&
              <HStack mt={5} mb={12}>
                <Text fontSize='sm' color='muted.800'>
                  Período de envio:
                </Text>
                <Text fontSize='sm' color='muted.700'>
                  {" "}{`${dateSchedule.date} às ${dateSchedule.period} hr`}
                </Text>
              </HStack>}
          </VStack>
        </Modal.Body>

        <TouchableOpacityFactory
          testID='send-order-overlay-mobile_send'
          bg='primary.600'
          rounded='sm'
          py={4}
          px={8}
          mb={3}
          alignItems='center'
          justifyContent='center'
          _dark={{
            opacity: 0.8,
          }}
          onPress={handleSendOrder}
        >
          <Text fontSize='sm' fontFamily='subheading' color='muted.100'>
            Enviar
          </Text>
        </TouchableOpacityFactory>

        <TouchableOpacityFactory
          testID='send-order-overlay-mobile_cancel'
          rounded='sm'
          py={4}
          px={8}
          alignItems='center'
          justifyContent='center'
          _dark={{
            opacity: 0.7,
          }}
          onPress={onClose}
        >
          <Text fontSize='sm' fontFamily='subheading' color='primary.600'>
            Cancelar
          </Text>
        </TouchableOpacityFactory>
      </Modal.Content>
    </Modal>
  );
}