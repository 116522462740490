import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { View, Text, FlatList } from 'native-base';
import { width } from "../components/Dimension";
import { RFValue } from "react-native-responsive-fontsize";
import { CartType, ItemType } from "@types/CartType";
import { ProductCart } from "@components/ProductCart";
import { useAuth } from "@contexts/Auth";
import { Config } from "@configs/Config";

interface Props {
  cart: CartType | undefined;
}

export const ShoppingCartProductList = ({cart}: Props) => {

  const nonSuggestedProducts = cart?.items.filter((item: any) => {
    return (item?.suggestions?.list?.length == 0 || !item?.suggestions?.list)
  });

  return (
    <FlatList
        data={nonSuggestedProducts}
        keyExtractor={(item) => item.code}
        renderItem={({ item }: { item: any }) => (
        <ProductCart
        product={item}
        key={item.product.code + item.amount}
    />
    )}
    numColumns={1}
    ItemSeparatorComponent={() => (
    <View style={{ height: width * 0.01 }} />
    )}
    scrollEnabled={true}
    showsVerticalScrollIndicator={true}
    style={{
    width: width * 0.26,
    }}
    />
  );
};