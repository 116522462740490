import {
  Box,
  IBoxProps,
  ITextProps,
  InfoIcon,
  Text,
} from "native-base";
import React from "react";

export type TagAlertProps = { _text?: ITextProps } & IBoxProps

export const TagAlert: React.FC<TagAlertProps> =
  ({ children, _text, ...restProps }) => {
    return (
      <Box alignContent={'center'} flexDir={'row'} p={2} {...restProps}
      >
        <InfoIcon color={'gray.600'} />
        <Text
          pl={1}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
          color={'gray.600'}
          lineHeight={'sm'}
          fontSize={'xs'}
          fontWeight='400'
          {..._text}
        >
          {children}
        </Text>
      </Box>
    );
  };
