import { Box, Button, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useAuth } from "@contexts/Auth";
import { CloseButtonCircle } from "@components/CloseButtonCircle";
import { useSideMenu } from "@hooks/useSideMenu";
import { SideModal } from "@components/SideModal";
import { THEME } from '@theme/index';
import { useNavigation } from "@react-navigation/native";
import { ProfileCard } from "@components/More/ProfileCard";
import { TargetSwitcher } from "@components/More/TargetSwitcher";
import { LinkTradeTools } from "@components/LinkTradeTools";
import { finishPromotionalActionSession } from "@components/PromotionalAction";

interface Props {
  open: boolean;
  onClose: (status: boolean) => void;
}

export const SideMenu = (props: Props) => {
  const { open, onClose } = props;
  const { signOut, user } = useAuth();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const sideMenuModal = useSideMenu();
  const navigation = useNavigation();

  useEffect(() => {
    setModalVisible(open);
  }, [open]);

  function closeModal() {
    onClose(false);
    setModalVisible(false);
  }

  const onToPrivacyPolicy = () => {
    navigation.navigate("PrivacyPolicy");
  };

  return (
    <SideModal
      isVisible={modalVisible}
      close={closeModal}
      side="right"
    >
      <View flex={1} width={"full"} height={"50%"} px={3} mt={6} mb={6}>
        <Box w={"full"} h={"auto"} alignItems={"end"}>
          <CloseButtonCircle
            size={24}
            color={THEME.colors.green[300]}
            close={() => closeModal()}
          />
        </Box>
        <ProfileCard />
        <Box
          padding={4}
          borderColor="coolGray.200"
          borderWidth="0"
          borderBottomWidth={2}
        >
          <TargetSwitcher navigation={navigation} profile={user.getProfile()}
            testID="sideMenu:alter"
            _text={{
              color: "primary.600",
              fontWeight: "500",
              fontSize: ["sm", "md"],
            }}
          />
          <LinkTradeTools
            user={user}
            _text={{
              color: 'primary.600',
              fontWeight: '500',
              fontSize: ['sm', 'md'],
              textDecorationLine: 'none'
            }}
          />
        </Box>
        <Box padding={4}>
          <Button
            testID="menuProfile:term"
            onPress={onToPrivacyPolicy}
            variant={"unstyled"}
            justifyContent="flex-start"
            w="100%"
            p={2}
            mb={3}
            _hover={{
              opacity: 0.8,
            }}
            _pressed={{
              bgColor: "muted.300",
            }}
            _text={{
              color: "primary.600",
              fontWeight: "500",
              fontSize: ["sm", "md"]
            }}
            _dark={{
              opacity: 0.7,
              _pressed: {
                bgColor: "muted.700",
              },
            }}
          >
            Termos de uso e Política de privacidade
          </Button>
          <Button
            testID="menuProfile:logout"
            onPress={async () => {
              await finishPromotionalActionSession();
              sideMenuModal.close(), signOut();
            }}
            variant={"unstyled"}
            justifyContent="flex-start"
            w="100%"
            p={2}
            mb={3}
            _hover={{
              opacity: 0.8,
            }}
            _pressed={{
              bgColor: "muted.300",
            }}
            _text={{
              color: "primary.600",
              fontWeight: "500",
              fontSize: ["sm", "md"],
            }}
            _dark={{
              opacity: 0.7,
              _pressed: {
                bgColor: "muted.700",
              },
            }}
          >
            Sair
          </Button>
        </Box>
      </View>
    </SideModal>
  );
};
