import { WholesalerBranchesType } from "@types/WholesalerBranchesType";
import { useWholesalerBranchCart } from "./useWholesalerBranchCart";
import { useWholesalersStorage } from "./useWholesalersStorage";

interface WholesalerBranchCart {
  loading: boolean,
  wholesalers: WholesalerBranchesType[],
}

export const useWholesalers = (): WholesalerBranchCart => {
  const {
    wholesalers: storageValueWholesalers,
    loading: loadingWholesalersStorage
  } = useWholesalersStorage()

  const { wholesalers, loading: loadingWholesalersBranchCart } =
    useWholesalerBranchCart();

  const getWholesalers = (): WholesalerBranchesType[] => {
    if (wholesalers.length > 0) {
      return wholesalers;
    }

    return storageValueWholesalers;
  };

  return {
    loading: loadingWholesalersStorage || loadingWholesalersBranchCart,
    wholesalers: getWholesalers()
  }
};
