import React from "react";
import { View } from 'native-base';
import { ProductList } from "@components/ProductList";

export const Product = () => {

  return (
    <View flex={1} >
      <ProductList flex={1} paginationInfinite />
    </View>
  );
};
