import React from "react";
import { View } from 'native-base';
import { ComboProducts } from "@components/ComboTab/ComboProducts";

export const Combo = () => {
  return (
    <View flex={1} mt={6}>
      <ComboProducts />
    </View>
  );
};
