import React, { memo } from "react";

import { Actionsheet, Modal } from "native-base";
import { FastAccessCard } from "./index";

export type FastAccessActionsheetProps = {
    closeModal: () => void
    visible: boolean
}

export const FastAccessActionsheet: React.FC<FastAccessActionsheetProps> = memo(({ closeModal, visible }) => (
    <Actionsheet
        isOpen={visible}
        onClose={() => closeModal()}
    >
        <Actionsheet.Content
            bgColor='white'
            _dark={{
                bgColor: 'muted.800'
            }}
            borderWidth={0}
        >
            <Modal.CloseButton
                _icon={{
                    color: 'primary.600'
                }}
            />
            <FastAccessCard
                title="Filtros rápidos"
                subTitle=""
                filterIsClick={true}
                borderWidth={0}
                shadow='none'
            >
                <FastAccessCard.Buttons pt="4" mt="8" borderTopWidth={1} borderTopColor={'muted.600'} />
            </FastAccessCard>
        </Actionsheet.Content>
    </Actionsheet>
)
)