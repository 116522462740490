import React from "react";
import { TouchableOpacity } from "react-native";
import { View, Text } from 'native-base';
import { IconButton } from "react-native-paper";
import { RFValue } from "react-native-responsive-fontsize";

import { styles } from "./styles";

type AlertCouponProps = {
  onPressModal?: () => void;
  onPressModalClose?: () => void;
  activeAlertModal?: boolean;
  quantityCoupons?: number;
}

export const AlertCoupon: React.FC<AlertCouponProps> = ({
  onPressModal = () => { },
  onPressModalClose = () => { },
  activeAlertModal,
  quantityCoupons,
}) => {

  return (
    <>
      {activeAlertModal &&
        <View style={styles.container}>
          <View style={styles.content}>
            <Text style={styles.title}>
              Você tem {quantityCoupons} cupons disponíveis! Aproveite seus descontos.

              <TouchableOpacity onPress={onPressModal} style={styles.button}>
                <Text>
                  Clique aqui e confira.
                </Text>
              </TouchableOpacity>
            </Text>
            <IconButton
              icon={"close"}
              color="#FFFFFF"
              centered={true}
              size={RFValue(9)}
              onPress={() => {
                onPressModalClose();
              }}
              style={styles.closeButton}
            />
          </View>
        </View>
      }
    </>
  );
};