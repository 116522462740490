import React, { memo } from "react";
import { Actionsheet, Button, Icon, Modal, Text, View } from "native-base";
import Feather from 'react-native-vector-icons/Feather';
import { finishPromotionalActionSession } from "@components/PromotionalAction";
import { setSessionStorage } from "@helpers/SessionStorage";


export type LogoutActionsheetProps = {
  onClose: () => void
  onLogout: () => void
  visible: boolean
}

export const LogoutActionsheet: React.FC<LogoutActionsheetProps> = memo(({ onClose, onLogout, visible }) => (
  <Actionsheet
    isOpen={visible}
    onClose={onClose}
  >
    <Actionsheet.Content
      bgColor='white'
      _dark={{
        bgColor: 'muted.800'
      }}
      borderWidth={0}
    >
      <Modal.CloseButton
        _icon={{
          color: 'primary.600'
        }}
      />
      <View
        position={'absolute'}
        flex={1}
        flexDirection='row'
        alignItems='center'
        left={0}
        ml="6"
        top={"4"}
        w={'full'}
        pb={3}
        borderBottomColor={'muted.400'}
        borderBottomWidth={1}
      >
        <Icon
          as={Feather}
          name={'alert-triangle'}
          size={[5, 6]}
          mr={2}
          color={'red.500'}
          _dark={{
            color: 'red.400'
          }}
        />
        <Text color="red.500" textAlign={'initial'} fontSize={'xl'} fontWeight="medium"
          _dark={{
            color: 'red.400'
          }}
        >
          Sair da conta
        </Text>
      </View>
      <View ml="6" flex="1" justifyContent={"flex-start"} mt="10" mb="6" w={'full'}>
        <Text color="muted.800" textAlign={'initial'} fontSize={'md'} fontWeight="medium"
          _dark={{
            color: 'muted.400'
          }}
        >
          Já vai embora?
        </Text>
        <Text color="muted.800" textAlign={'initial'} fontSize={'sm'} fontWeight="400"
          _dark={{
            color: 'muted.400'
          }}
        >
          Tem certeza de que deseja sair da sua conta?
        </Text>
      </View>
      <Button
        w={'80%'}
        testID='more-logout:close'
        _text={{
          fontWeight: '700',
          fontSize: ['md', 'lg']
        }}
        _hover={{
          bg: 'primary.500',
        }}
        _pressed={{
          bg: 'primary.400',
        }}
        _dark={{
          opacity: 0.8,
        }}
        bg='primary.600'
        mb={3}
        rounded='sm'
        onPress={onClose}
      >
        Continuar conectado
      </Button>
      <Button
        variant={'unstyled'}
        testID='more-logout:logout-button'
        w='100%'
        p={2}
        mb={6}
        onPress={async () => {
          onLogout()
          await finishPromotionalActionSession(true);
        }}
        alignItems='center'
        justifyContent='center'
        _hover={{
          opacity: 0.8,
        }}
        _pressed={{
          bgColor: "muted.300",
        }}
        _text={{
          color: 'primary.600',
          fontWeight: '700',
          fontSize: ['md', 'lg']
        }}
        _dark={{
          opacity: 0.7,
          _pressed: {
            bgColor: "muted.700"
          }
        }}
      >
        Sair
      </Button>
    </Actionsheet.Content>
  </Actionsheet>
)
)