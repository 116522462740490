import { TextHelper } from "@helpers/TextHelper"
import { HStack, VStack, View, Text, Spinner, CheckCircleIcon, WarningIcon } from "native-base"
import React from "react"
import { AdditionalCustomersType } from "@types/AdditionalCustomersType"

export const Mobile = ({item}: {item: AdditionalCustomersType}) => {

  return (
    <View 
      borderColor={'gray.300'} 
      width={80} 
      height={16}
      borderWidth={1}
      borderRadius={8}
      shadow={'6'}
      alignSelf={'center'}
      justifyItems={'center'}
      alignItems={'center'}
    >
        <HStack alignSelf={'center'} alignItems={'center'} justifyItems={'center'} h={'full'} space={5}>
          {item.status == undefined && <Spinner color="emerald.500" />}
          {item.status == true && <CheckCircleIcon size={4} color={'success.500'} />}
          {item.status == false && <WarningIcon size={4} color={'red.500'} />}
          <VStack alignSelf={'center'} space={1} >
            <Text 
              fontSize={'xs'} 
              color={'black'} 
              numberOfLines={2} 
              w={59} 
              fontFamily={'body'} 
              lineHeight={16}
            >
              {item?.name}
            </Text>
            <Text 
              fontSize={'xs'} 
              color={'black'} 
              fontFamily={'body'} 
              lineHeight={14}
            >
              {new TextHelper(item?.code).formatCNPJ()}
            </Text>
          </VStack>
        </HStack>
    </View>
  )
}