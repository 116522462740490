import { TextHelper } from "@helpers/TextHelper"
import { HStack, View, Text, Spinner, CheckCircleIcon, WarningIcon } from "native-base"
import React from "react"
import { AditionalCustomersType } from "@types/AditionalCustomersType"

type ItemListValidateCustomersWebProps = {
  item: AditionalCustomersType;
  index: number;
}

export const Web = ({item, index}: ItemListValidateCustomersWebProps) => {
  return (
    <View 
      width={'full'} 
      height={12}
      alignSelf={'center'}
      justifyItems={'center'}
      alignItems={'center'}
      backgroundColor={index % 2 == 0 ? 'muted.200' : ''}
    >
        <HStack alignSelf={'center'} alignItems={'center'} justifyItems={'center'} h={'full'} space={5} w={'full'}>
          <View w={'10%'} alignItems={'center'}>
            {item.status == undefined && <Spinner color="emerald.500" />}
            {item.status == true && <CheckCircleIcon size={4} color={'success.500'} />}
            {item.status == false && <WarningIcon size={4} color={'red.500'} />}
          </View>
          <View w={'60%'}>
            <Text 
              fontSize={'sm'} 
              color={'black'} 
              numberOfLines={2} 
              fontFamily={'body'} 
              lineHeight={18}
            >
              {item?.name}
            </Text>
          </View>
          <View w={'30%'}>
            <Text 
              fontSize={'sm'} 
              color={'black'} 
              fontFamily={'body'} 
              lineHeight={18}
            >
              {new TextHelper(item?.code).formatCNPJ()}
            </Text>
          </View>
        </HStack>
    </View>
  )
}