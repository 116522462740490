import React, { memo } from "react";
import { Text, WarningTwoIcon, Flex } from 'native-base';


import { CommercialPolicyProductsType } from "../../types/CommercialPolicyProductsType";

interface HasNotConsideredMinimumProps {
  product: CommercialPolicyProductsType
}

export const HasNotConsideredMinimum: React.FC<HasNotConsideredMinimumProps> = memo((props) => {
  const { product } = props;

  return product.hasNotConsideredMinimum ? <HasNotConsideredMinimumContent /> : null
})

const HasNotConsideredMinimumContent = () => (
  <Flex direction="row">
    <WarningTwoIcon color='orange.500' mr="1" />
    <Text fontSize='xs' color='orange.500' >
      Não considera para o mínimo
    </Text>
  </Flex>
)