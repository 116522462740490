import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View, Text } from 'native-base';
import { width } from "../components/Dimension";
import { RFValue } from "react-native-responsive-fontsize";
import { IconButton } from "react-native-paper";
import { Card } from "react-native-elements";
import { NavigationContainer } from "@react-navigation/native";
import { SubOrder } from "./SubOrder";
import { ProductsPerIndustry } from "./ProductsPerIndustry";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

export const SubOrdersPerIndustry = (data: {
  industry: any;
  subOrderRows: any;
  products: any;
}) => {
  const Tab = createMaterialTopTabNavigator();
  const [isExpanded, setIsExpanded] = useState(false);

  const orderRows = data.subOrderRows;
  const toggleIsExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const expandButton = (
    <IconButton
      icon={"arrow-down"}
      color="#74BF48"
      size={RFValue(9)}
      rippleColor="rgba(229, 229, 229, 0.5)"
      onPress={toggleIsExpanded}
    />
  );

  const unexpandButton = (
    <IconButton
      icon={"arrow-up"}
      color="#74BF48"
      size={RFValue(9)}
      rippleColor="rgba(229, 229, 229, 0.5)"
      onPress={toggleIsExpanded}
    />
  );

  const button = isExpanded ? unexpandButton : expandButton;

  const productProps = {
    industry: data.industry,
    products: data.products,
  };

  const SubOrderComponent = () => {
    return <SubOrder {...orderRows.industryWholesalerOrders}></SubOrder>;
  };

  const ProductsPerIndustryComponent = () => {
    return <ProductsPerIndustry {...productProps}></ProductsPerIndustry>;
  };

  return (
    <Card key={String(Math.random())}>
      <View>
        <Text style={styles.textTitleContentCard}>
          {data.industry}
          {button}
        </Text>
      </View>
      {!!isExpanded && (
        <NavigationContainer independent={true}>
          <Tab.Navigator>
            <Tab.Screen name="Pedidos" component={SubOrderComponent} />
            <Tab.Screen
              name="Produtos"
              component={ProductsPerIndustryComponent}
            />
          </Tab.Navigator>
        </NavigationContainer>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  textTitleContentCard: {

    fontWeight: "200",
    fontSize: RFValue(8),
    color: "#1C1C1C",
    marginLeft: width * 0.007, // 10,
  },
  cardContainer: {
    marginTop: width * 0.015, // 50,
    flex: 1,
  },
  screen: {
    height: "2px",
    flex: 1,
  },
});

