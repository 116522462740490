import { useQuery, gql, useLazyQuery, makeVar } from "@apollo/client";
import { OrderSourceEnum } from "../enums/OrderSourceEnum";
import { useCustomer } from "../contexts/CustomerContext";
import { OrderType } from "@types/OrderType";

const RETAIL_ORDERS = gql`
  query ($page: Int, $limit: Int, $filter: Retail_RetailOrderFilter) {
    Retail_retailOrders(currentPage: $page, perPage: $limit, filter: $filter) {
      total
      per_page
      current_page
      has_more_pages
      data {
        customerCode
        id
        createdAt
        customerOrderCode
        status
        wholesalerBranchesPayment {
          code
          paymentTerm {
            code
            description
          }
        }
      }
    }
  }
`;

export const orderItemsVar = makeVar<OrderType[]>([]);

export const useRetailOrders = (limit: number, page: number) => {
  const { customerCode } = useCustomer();

  return useQuery(RETAIL_ORDERS, {
    variables: {
      filter: {
        customerCode,
        source: [OrderSourceEnum.EXP_LOJISTA],
      },
      limit,
      page,
    },
    onCompleted: (data) => {
      const newOrders: OrderType[] = data.Retail_retailOrders.data;

      const parsedData = [
        ...orderItemsVar(),
        ...newOrders
      ];

      orderItemsVar(parsedData);
    }
  });
};

export const useRetailOrdersLazy = (limit: number, page: number) => {
  const { customerCode } = useCustomer();

  return useLazyQuery(RETAIL_ORDERS, {
    variables: {
      filter: {
        customerCode,
        source: [OrderSourceEnum.EXP_LOJISTA],
      },
      limit,
      page,
    },
  });  
}