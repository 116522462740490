import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { filter } from "@components/FastAccessCard/DynamicFilter";
import { useAuth } from "@contexts/Auth";
import { useCustomer } from "@contexts/CustomerContext";
import Cdn from "@helpers/Cdn";
import { CommercialConditionRequirementsType } from "@types/CommercialConditionRequirementsType";

interface CommercialPolicyArgs {
  id: number;
  brandId?: number;
}

const COMMERCIAL_POLICY = gql`
  query commercialPolicy($id: ID!, $industryCode: String!, $brandId: Float) {
    commercialPolicy(id: $id, industryCode: $industryCode) {
      id
      minimumUnits
      minimumValue
      endValidityDate
      numberItemsRequiredBasket
      name
      discountsBrand (
        filter: {
          brandId: $brandId
        }
      ) {
        id
        industryCode
        value
        amount
        brand {
          description
        }
      }
      minimumAmountBrand {
        id
        amount,
        obligatoriness
        brand {
          id
          description
        }
      }
    }
  }
`;

const COMBO_COMMERCIAL_POLICY = gql`
  query commercialPolicy($id: ID!, $industryCode: String!, $brandId: Float, $customerCode: String!) {
    commercialPolicy(id: $id, industryCode: $industryCode) {
      id
      minimumUnits
      minimumValue
      endValidityDate
      numberItemsRequiredBasket
      name
      discountsBrand (
        filter: {
          brandId: $brandId
        }
      ) {
        id
        industryCode
        value
        amount
        brand {
          description
        }
      }
      minimumAmountBrand {
        id
        amount,
        obligatoriness
        brand {
          id
          description
        }
      }
      invoicedProducts(
        filter: {
          customerCode: $customerCode
        }
      ){
        brands{
          id
          amount
          value
        }
        basket{
          ean
          amount
          value
        }
        total{
          amount
          value
        }
      }
    }
  }
`;

export const useCommercialPolicy = (args: CommercialPolicyArgs) => {
  const { user } = useAuth();
  return useQuery(COMMERCIAL_POLICY, {
    variables: { ...args, industryCode: user.getIndustry()},
    fetchPolicy: "no-cache",
  });
};

export const useComboCommercialPolicy = (args: CommercialPolicyArgs) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  return useQuery(COMBO_COMMERCIAL_POLICY, {
    variables: { ...args, industryCode: user.getIndustry(), customerCode },
    fetchPolicy: "no-cache",
  });
};

export const useCommercialPolicyLazy = (args: CommercialPolicyArgs) => {
  const { user } = useAuth();
  return useLazyQuery(COMMERCIAL_POLICY, {
    variables: { ...args, industryCode: user.getIndustry() },
    fetchPolicy: "no-cache",
  });
};

export const refactorRequirementsResult = (requirements: CommercialConditionRequirementsType[]) => {
  const { user } = useAuth();
  return requirements.map((requirement: CommercialConditionRequirementsType): CommercialConditionRequirementsType => {

    requirement.brand.title = 'Marca'
    if (typeof requirement.brand.label === typeof undefined) {
      requirement.brand.label = requirement.brand.description
    }
    requirement.brand.description = 'Quantidade mínima por marca'

    return {
      ...requirement,
      id: requirement.id,
      brand: {
        ...requirement.brand,
        image: Cdn.getUrl(user?.getNameIndustryStore(), `brand`, `${requirement?.brand?.id}.png`)
      },
      isBasketRequired: false,
    }
  })
}
