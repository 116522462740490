import React, { useState } from 'react';
import { Box, Button, ChevronDownIcon, ChevronUpIcon, HStack, Heading, IBoxProps, Text, VStack, View } from 'native-base';
import { OrderProductType } from '@types/OrderProductType';

type OrderProductCardProps = IBoxProps & {
  product: OrderProductType;
}

export const OrderProductCard: React.FC<OrderProductCardProps> = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      flex={1}
      justifyContent={"flex-start"}
      bgColor={"white"}
      _dark={{
        bgColor: "muted.800",
        borderWidth: 0,
      }}
      shadow='3'
      rounded='md'
      p="2"
      mb={3}
    >
      <HStack flex={1} alignItems={'center'} p={2}>
        <View flex={1}>
          <Text
            fontSize={'xs'}
            fontWeight={600}
            lineHeight={"md"}
            color={'muted.800'}
            _dark={{
              color: 'muted.400'
            }}
            isTruncated
            selectable
          >
            {product.name}
          </Text>

          <Text
            fontSize={'xs'}
            fontWeight={400}
            lineHeight={"md"}
            color={'muted.800'}
            _dark={{
              color: 'muted.400'
            }}
            selectable
          >
            {product.ean}
          </Text>
        </View>
        <Button
          testID={`product-order-chevron-${product.ean}`}
          p={0}
          onPress={() => setIsOpen(!isOpen)}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          variant={'unstyled'}
          pb={3}
          leftIcon={isOpen ? <ChevronUpIcon color='primary.600' /> : <ChevronDownIcon color='primary.600' />}
        />
      </HStack>

      {isOpen && <VStack pt={4} px={2} borderTopWidth={1} borderTopColor={'muted.200'}>
        <HStack>
          <HStack flex={1} alignItems='center' w='50%'>
            <Heading
              fontSize='xs'
              fontFamily='heading'
              color='muted.700'
              mr={4}
            >
              Qtd. pedida:
            </Heading>
            <Text fontSize='xs' color='muted.700'>
              {product.quantity}
            </Text>
          </HStack>
          <HStack alignItems='center' w='50%'>
            <Heading
              fontSize='xs'
              fontFamily='heading'
              color='muted.700'
              mr={4}
            >
              Qtd. faturada:
            </Heading>
            <Text fontSize='xs' color='muted.700'>
              {product.invoiceQuantity}
            </Text>
          </HStack>
        </HStack>
        <HStack>
          <HStack alignItems='center' w='50%'>
            <Heading
              fontSize='xs'
              fontFamily='heading'
              color='muted.700'
              mr={4}
            >
              Desconto (%):
            </Heading>

            <Text fontSize='xs' color='muted.700'>
              {product.discountPercentage}
            </Text>
          </HStack>
        </HStack>
      </VStack>
      }
    </Box >
  );
}
