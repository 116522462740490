import React from "react";
import {
  Divider,
  Text,
  VStack,
  Center,
  Button,
  Pressable,
  Box,
} from "native-base";
import { cloneDeep } from "@apollo/client/utilities";
import { useProductTabNavigation } from "@components/ProductsTabView";
import { SuggestedProductListType } from "../../../types/SuggestedProductListType";

interface Props {
  suggestedList: SuggestedProductListType;
}

export function SuggestedListProductCard({ suggestedList }: Props) {
  const {
    tabNavigation,
    setTabParams,
    setTabNavigation,
    getNavigationChildren,
    setTabNavigationLoading,
  } = useProductTabNavigation();

  const goToRoute = () => {
    setTabParams({
      listName: suggestedList?.description || "",
      listCode: suggestedList?.name || "",
      suggestedListId: suggestedList?.id,
    });
    const newTabNavigation = cloneDeep(tabNavigation);
    const index = newTabNavigation.index;
    const route = getNavigationChildren("suggestedProduct", index);
    if (route?.length) {
      setTabNavigationLoading(true);
      newTabNavigation.routes[index] = route[0];
      setTabNavigation({ ...newTabNavigation, index });
    }
  };

  return (
    <Box
      mx={3}
      borderWidth={1}
      borderColor={"gray.300"}
      borderRadius={5}
      marginBottom={5}
      bgColor={"white"}
      width={["90%", "45%", "45%", "28%", "19%"]}
    >
      <Pressable onPress={goToRoute}>
        <Center>
          <Box bg={"primary.600"} h={"2px"} w={"100%"}></Box>
        </Center>
        <VStack>
          <Center flex={1} my={2}>
            <Text color={"muted.800"} fontSize={["xs", "sm", "sm", "sm"]}>
              {suggestedList.description}
            </Text>
          </Center>
          <Divider marginBottom={5} alignSelf={"center"} w={"90%"} />
          <Center>
            <Button
              onPress={goToRoute}
              bg={"primary.600"}
              _hover={{ background: "primary.700" }}
              mb={5}
            >
              <Text color={"white"} fontSize={["xs", "sm"]}>
                Consulte os produtos
              </Text>
            </Button>
          </Center>
        </VStack>
      </Pressable>
    </Box>
  );
}
