

import React from "react";
import { Factory } from "native-base";
import { Card } from "@components/Card";

import { TouchableOpacity } from "react-native";

const TouchableOpacityNative = Factory(TouchableOpacity);

type ButtonProps = {
  onPress: () => void,
  text: string
}

export const Button: React.FC<ButtonProps> = ({ onPress, text }) => (
  <TouchableOpacityNative
    mt={2}
    _web={{
      cursor: "pointer"
    }}
    onPress={onPress}
  >
    <Card.Text
      color={"primary.600"}
      fontSize={'sm'}
      _dark={{
        color: 'primary.400'
      }}
    >
      {text}
    </Card.Text>
  </TouchableOpacityNative>
)