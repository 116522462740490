import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const HeaderBarStyle = StyleSheet.create({
  iconStyleWeb: {
    marginTop: 5,
    flexDirection: "row",
    flex: 1,
  },
  viewIconStyleWeb: {
    marginTop: 5,
    marginHorizontal: 64,
    flexDirection: "row",
    flex: 1,
    alignItems:"center"
  },
  iconStyleMobile: {
    marginTop: "auto",
    marginRight: "auto",
    flexDirection: "row",
    width: "auto",
    height: "auto",
  },
  viewStyle: {
    flexDirection: "row",
    width: "auto",
    height: "auto",
  },
  containerStyleWeb: {
    width: "auto",
    height: 64,
  },
  containerStyleMobile: {
    width: "auto",
  },
});

const WebSearchBarStyle = StyleSheet.create({
  customBarStyle: {
    flexDirection: "row",
    flex: 1,
    height: 30,
    marginRight: 100,
    marginTop: 5,
  },
  inputStyle: {
    backgroundColor: "#F3EFEF",
    borderRadius: 40,
    marginTop: 5,
    height: 35,
    fontSize: 14,
  },
  subMenuStyle: {
    fontSize: 14,
    flex: 1,
  },
  textStyle: {
    fontSize: RFValue(7.5),
    marginRight: 30,
  },
  viewTextStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});

const MobileSearchBarStyle = StyleSheet.create({
  viewStyle: {
    width: "auto",
    height: "auto",
    alignItems: "center",
    marginTop: 15,
    flex: 1,
  },
  menu: {
    marginLeft: "auto",
    marginTop: "auto",
    flexDirection: "row",
    flex: 1,
    width: "auto",
    height: "auto",
  },
});

export { HeaderBarStyle, WebSearchBarStyle, MobileSearchBarStyle };
