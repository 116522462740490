import React from "react";
import { Box } from "native-base";
import { cloneDeep } from "@apollo/client/utilities";

import { Accordion } from "@components/Accordion";
import { ProductSummary } from "@components/ProductSummary";

import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { ItemType } from "@types/CartType";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";
import { ListType } from "@types/ListType";

interface Props {
  products: ItemType[];
}

interface SectionedListInterface {
  [key: string]: CommercialPolicyProductsType;
}

interface ConvertedListInterface {
  listId: string;
  value: CommercialPolicyProductsType;
}

export const SummarySuggestionListOrder = (props: Props) => {
  const { products } = props;
  const sectionedList: SectionedListInterface[] = [];

  const createSectionedList = (index: number, value: ItemType) => {
    if (sectionedList.hasOwnProperty(index)) {
      const itemInList = value?.suggestions?.list?.find((list: ListType) => {
        return list.code == index;
      });

      const newValue = cloneDeep(value);
      newValue.amount = itemInList?.amount;
      sectionedList[index].push(newValue);
      return;
    }

    const itemInList = value?.suggestions?.list?.find((list: ListType) => {
      return list.code == index;
    });

    const newValue = cloneDeep(value);
    newValue.amount = itemInList?.amount;

    sectionedList[index] = [newValue];
  };

  for (let product of products) {
    product?.suggestions?.list?.map((list: ListType) => {
      if (list?.code) {
        createSectionedList(list?.code, product);
      }
    });
  }

  let i = 0;

  const { clearList, clearByType } = useShoppingCart();
  const convertedList: ConvertedListInterface[] = [];

  Object.keys(sectionedList).map((listas: string) => {
    convertedList.push({
      listId: Object.keys(sectionedList)[i],
      value: Object.values(sectionedList)[i],
    });
    i++;
  });

  return convertedList.length > 0 ? (
    <Accordion
      title="Listas sugeridas"
      callbackText={"Limpar listas sugeridas"}
      callback={() => clearByType([ItemTypeEnum.SUGGESTED_LIST])}
    >
      {convertedList?.map((item: ConvertedListInterface) => {
        return (
          <Accordion
            key={item.listId}
            title={item.listId || "Lista"}
            callbackText={"Limpar lista"}
            callback={() => clearList(item.listId)}
          >
            {item?.value?.map((item: ItemType) => {
              return (
                <Box key={item.product.code} mb={[4, 6]}>
                  <ProductSummary product={item} />
                </Box>
              );
            })}
          </Accordion>
        );
      })}
    </Accordion>
  ) : (
    <></>
  );
};
