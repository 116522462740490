import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

export type CartInputType = {
	cratId: String,
}

const VALIDATE_CART = gql`
  mutation ValidateCart($input: Cart_ValidateCartInput!) {
    Cart_validateCart(input: $input) {
      id
        customerCode
        industryCode
        status
        code
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
    }
  }
`;

export const useValidateCart = () => {
	return useMutation(VALIDATE_CART, {
		refetchQueries: [
			'Cart'
		],
		onCompleted(data) {
			if (data.Cart_validateCart) {
				cartVar(data.Cart_validateCart)
			}
		},
	});
};