import { useShoppingCart } from "./useShoppingCart";
import { useAddListItemExistsAlert } from "./useAddListItemExistsAlert";
import { useAddItemAlert } from "@hooks/useAddItemAlert";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";
import { ItemType } from "@types/CartType";
import { CommercialPolicyProductsType } from "@types/CommercialPolicyProductsType";

export const useAddSuggestedListCart = () => {
  const { addSuggestedList: addItemsInCart, cart } = useShoppingCart()
  const add = useAddListItemExistsAlert();
  const alert = useAddItemAlert();

  const addSuggestedList = async (
    items: CommercialPolicyProductsType[],
    listName: string,
    listCode: string,
    onSuccess: () => void = () => { },
    onError: (error: any) => void = () => { },
    onClose: () => void = () => { },
  ) => {
    const itemType = ItemTypeEnum.SUGGESTED_LIST;
    let itemInCart: ItemType | null = null;

    items.map((item: CommercialPolicyProductsType) => {
      const foundItem = cart?.items?.find(
        cartItem => cartItem.code === item?.product?.code &&
        (cartItem?.type !== itemType || cartItem?.commercialPolicyId !== item.commercialPolicyId)
        );

      if(foundItem) {
        itemInCart = foundItem
        return;
      }
    })

    if (!!itemInCart) {
    add.setWarning()

      add.setOnFailCallback(async () => {
        onClose()
      })

      add.setOnSuccessCallback(async () => {
        try {
          await addItemsInCart(
            listName,
            listCode,
            items.map((item: CommercialPolicyProductsType) => {
              return {
                amount: item.product.quantity,
                code: item.product.code,
                commercialPolicyId: item.commercialPolicyId ?? 0,
                type: ItemTypeEnum.SUGGESTED_LIST,
              }}),
            true
          )

          onSuccess()
          alert.onOpen(true);
        } catch (error) {
          onError(error)
        }
      })

      add.onOpen()
      return;
    }

    try {
      await addItemsInCart(
        listName,
        listCode,
        items.map((item: CommercialPolicyProductsType) => {
          return {
            amount: item.product.quantity,
            code: item.product.code,
            commercialPolicyId: item.commercialPolicyId ?? 0,
            type: ItemTypeEnum.SUGGESTED_LIST,
          }}),
        )

      onSuccess()
      alert.onOpen(true);
    } catch (error) {
      onError(error)
    }
  }

  return { addSuggestedList }
};
