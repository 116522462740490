import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@contexts/Auth";
import { AssortmentType } from "@types/AssortmentType";

export interface PricesByShippingOrderProps {
  customerCode: string;
  productCode: string | undefined;
  fractionation: number
}

export const PRICES_BY_SHIPPING_ORDER = gql`
  query PricesByShippingOrder(
    $input: ShippingOrderPriceInputType!
   ){
    pricesByShippingOrder(input: $input){
      code
      description
      price
      fractionation
      stock
      outdated
      updatedAt
    }
  }
`;

export const usePricesByShippingOrder = (props: PricesByShippingOrderProps) => {
  const { user } = useAuth();
  const names: string[] = user
    .getAssortments()
    .map((assortment: AssortmentType) => String(assortment.name));
  return useQuery(PRICES_BY_SHIPPING_ORDER, {
    variables: {
      input: {
        customerCode: props.customerCode,
        productCode: props.productCode,
        assortment: {
          names
        },
      }
    },
  });
};
