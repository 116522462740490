import React, { useEffect } from "react";
import { SceneMap } from "react-native-tab-view";

import { ComboTab } from "@components/ComboTab";
import { ComboProducts } from "@components/ComboTab/ComboProducts";
import { OffersByBrandTab } from '@components/OffersByBrandTab';
import { OffersByBrandProducts } from '@components/OffersByBrandTab/OffersByBrandProducts';
import { ProductList } from '@components/ProductList';
import { SuggestedProductsTab } from "@components/SuggestedProducts";
import { SuggestedProduct } from "@components/SuggestedProducts/SuggestedProduct";
import { Config } from '@configs/Config';
import { ProductPromotionalAction } from "@components/PromotionalAction/ProductList";

const routes = [
  {
    key: "productList",
    title: "Produtos",
    active: true,
    category: "products",
  },
  {
    key: 'offersByBrands',
    title: 'Ofertas por marcas',
    active: Config.FT_OFFERS_BY_BRAND_TAB_ENABLED,
    category: "brand",
    children: [
      {
        key: 'offersByBrandProducts',
        title: 'Ofertas por marcas',
        category: 'brand',
        active: Config.FT_OFFERS_BY_BRAND_TAB_ENABLED
      },
    ],
  },
  {
    key: "combos",
    title: "Combos",
    active: Config.FT_COMBO_TAB_ENABLED,
    category: "combo",
    children: [
      {
        key: "comboProducts",
        title: "Combos",
        category: "combo",
        active: Config.FT_COMBO_TAB_ENABLED,
      },
    ],
  },
  {
    key: 'suggestedProductList',
    title: 'Listas Sugeridas',
    active: Config.FT_SUGGESTED_PRODUCTS_TAB_ENABLED,
    category: "suggested_product_list",
    children: [
      {
        key: "suggestedProduct",
        title: "Listas Sugeridas",
        category: "suggested_product",
        active: Config.FT_SUGGESTED_PRODUCTS_TAB_ENABLED
      }
    ]
  }  
];

export const initialIndex = 0;

export const activeRoutes = routes.filter((route) => route.active);

export const sceneMap = SceneMap({
  productList: () => <ProductList />,
  offersByBrands: () => <OffersByBrandTab />,
  offersByBrandProducts: () => <OffersByBrandProducts />,
  combos: () => <ComboTab />,
  comboProducts: () => <ComboProducts />,
  suggestedProductList: () => <SuggestedProductsTab />,
  suggestedProduct: () => <SuggestedProduct />,
  promotionalAction: () => <ProductPromotionalAction />
});