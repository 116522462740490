import React, { memo, useEffect, useMemo, useState } from "react";
import { View, Text, Stack, Button, Box, IBoxProps, VStack } from "native-base";
import { useLazyQuery } from "@apollo/client";

import { PRODUCT } from "@hooks/useProduct";
import { MinimumAmount } from "@components/MinimunAmount";
import { ProductAmountCounter } from "@components/ProductAmountCounter";
import { ProductCardImage } from "@components/ProductCardImage";
import { ProductCardSkeleton } from "@components/ProductCardSkeleton";
import { ProgressiveProductDiscount } from "@components/ProgressiveProductDiscount";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import helpers from "@helpers/NumberHelper";
import { CommercialPolicyProductsType } from "../../types/CommercialPolicyProductsType";
import { useAddSuggestedAnchorCart } from "@hooks/useAddSuggestedAnchorCart";
import { CommercialPolicyProductsAnchorType } from "@types/CommercialPolicyProductAnchorType";
import { ProductFractionation } from "@components/ProductFractionation";
import { useAddToCartAlert } from "@hooks/useAddToCartAlert";

export type ProductCardProps = IBoxProps & {
  product: CommercialPolicyProductsType;
  tab: CartProductTypeEnum;
  isLoading?: boolean;
};

export const ProductCardAnchor: React.FC<ProductCardProps> = memo(
  ({
    product,
    tab = CartProductTypeEnum.DEFAULT,
    isLoading = false,
    ...restProps
  }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isMessageErrorShown, setIsMessageErrorShown] = useState(false);
    const [isCounterVisible, setIsCounterVisible] = useState(false);
    const addItemHook = useAddSuggestedAnchorCart();
    const { showToast } = useAddToCartAlert();

    const { cart, loading } = useShoppingCart();
    const products = cart?.items || [];

    const [productAmount, setProductAmount] = useState(
      product.product.recommendedQuantity || 0
    );

    const isStoreProductLoading = useMemo(
      () => (loading && isClicked) || isClicked,
      [isClicked, loading]
    );

    const [queryToGetProducts] = useLazyQuery(PRODUCT);

    const whenNotValid = isClicked;
    if (whenNotValid) {
      setIsMessageErrorShown(true);
      setIsClicked(false);
    }

    async function addCountProduct() {
      setProductAmount(productAmount + 1);
    }

    async function removeCountProduct() {
      setProductAmount(productAmount - 1);
    }

    async function addToCart(
      item: CommercialPolicyProductsAnchorType,
      quantity: number
    ) {
      const anchorProduct = products.find((x: any) => {
        return x.product.code === product?.suggestions?.anchor[0].code;
      });

      item.suggestions = {
        anchor: [
          {
            ...item.suggestions.anchor[0],
            anchorAmount: anchorProduct?.amount || 0,
          },
        ],
      };
      item.product.quantity = quantity;
      item.product.tab = tab;
      await addItemHook.addItem(
        item,
        () => {
          setIsClicked(false);
          setProductAmount(quantity);
          showToast(true);
        },
        (error) => {
          setIsMessageErrorShown(true);
          setIsClicked(false);
          setProductAmount(quantity);
        },
        () => {
          setIsClicked(false);
          setProductAmount(quantity);
        }
      );
    }

    function handleInputCount(item: any) {
      if (!isNaN(item)) {
        let newCount = item;

        if (newCount === "" || isNaN(parseInt(newCount))) {
          newCount = "0";
        }

        setProductAmount(parseInt(newCount));
      }
    }

    function handleInputBlur() {
      return productAmount < 0
        ? setProductAmount(0)
        : setProductAmount(productAmount);
    }

    function showChangeQuantity(): void {
      setIsCounterVisible(true);
    }

    useEffect(() => {
      setIsClicked(false);
    }, [isClicked]);


    if (isLoading) {
      return <ProductCardSkeleton />;
    }

    return (
      <Box
        width={["98%", "96%", "98%", "96%", "95%"]}
        rounded="lg"
        height={["350", "360", "380", "420", "430"]}
        margin={[0.5, 0.5, 1, 1]}
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        shadow="2"
        _dark={{
          borderColor: "coolGray.600",
          backgroundColor: "gray.700",
        }}
        _web={{
          shadow: 2,
          borderWidth: 0,
        }}
        _light={{
          backgroundColor: "gray.50",
        }}
        {...restProps}
      >
        <Box>
          <ProductCardImage commercialPolicyProduct={product} />
        </Box>

        <View
          background="white"
          flex={2}
          _dark={{
            backgroundColor: "muted.800",
          }}
          height={194}
          borderBottomRadius={10}
        >
          <Stack m={3} space={1} flex={1}>
            <View>
              <Text
                w="full"
                isTruncated
                maxW="300"
                noOfLines={2}
                fontWeight="500"
                fontSize={["xs", "xs", "xs", "sm"]}
                lineHeight="sm"
                selectable
              >
                {product.product.description}
              </Text>

              <Text
                lineHeight={"md"}
                my={1}
                fontSize={["xs", "xs", "xs", "sm"]}
                color={"muted.800"}
                _dark={{
                  color: "muted.400",
                }}
                fontStyle={"normal"}
                fontWeight={"normal"}
                selectable
              >
                {product.product.code}
              </Text>
            </View>

            {product?.wholesaler.fractionation > 1 && <ProductFractionation
              fractionationAmount={product.wholesaler.fractionation}
            />}

            <View
              flex={1}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <View alignSelf={"end"}>
                {product.discount.base > 0 ? (
                  <>
                    <Text
                      color={"muted.700"}
                      _dark={{
                        color: "muted.400",
                      }}
                      fontSize={["md", "md", "xs", "xs", "sm"]}
                      fontWeight="400"
                      textDecorationLine="line-through"
                    >
                      R$ {helpers.currency(product.wholesaler.price.fullPrice, 2)}
                    </Text>

                    <Text
                      fontWeight={700}
                      color={"muted.900"}
                      _dark={{
                        color: "muted.300",
                      }}
                      fontSize={["xs", "xs", "xs", "xs", "sm"]}
                    >
                      R$ {helpers.currency(product.wholesaler.price.netPrice, 2)}
                    </Text>
                  </>
                ) : (
                  <Text
                    fontWeight={700}
                    color={"muted.900"}
                    _dark={{
                      color: "muted.300",
                    }}
                    fontSize={["xs", "xs", "xs", "xs", "sm"]}
                  >
                    R$ {helpers.currency(product.wholesaler.price.netPrice, 2)}
                  </Text>
                )}

              </View>

              <View alignSelf={"end"}>
                <View>
                  <Text
                    fontSize={["xs", "xs", "xs"]}
                    fontWeight="400"
                    color={"muted.900"}
                    _dark={{ color: "muted.400" }}
                  >
                    {" "}
                    Qtd. sugerida
                  </Text>
                  <Text
                    fontSize={["xs", "xs", "xs", "xs", "sm"]}
                    fontWeight={700}
                    color={"muted.900"}
                    _dark={{
                      color: "muted.300",
                    }}
                  >
                    {product?.product?.recommendedQuantity} unidades
                  </Text>
                </View>
              </View>
            </View>

            <View justifyContent={"flex-end"}>
              <ProgressiveProductDiscount product={product} />
              <MinimumAmount product={product} />
            </View>

            <VStack space={2} w="full">
              <Button
                flex={1}
                bg="primary.600"
                isLoading={isStoreProductLoading}
                _pressed={{
                  bg: "primary.400",
                }}
                _hover={{
                  bg: "primary.500",
                }}
                size="xs"
                alignItems={"center"}
                justifyContent={"center"}
                onPress={() => {
                  addToCart(product, productAmount);
                }}
                testID="resumo-pedido:btn-adicionar-produto-ancora"
              >
                <Text
                  fontSize={["xs", "sm", "2xs", "xs", "sm"]}
                  fontWeight="500"
                  color="white"
                >
                  Adicionar ao carrinho
                </Text>
              </Button>

              {isCounterVisible ? (
                <ProductAmountCounter
                  isLoading={isStoreProductLoading}
                  isMessageErrorShown={isMessageErrorShown}
                  onMessageErrorClose={() => {
                    setIsMessageErrorShown(false);
                    //setValidationProductCart(null);
                  }}
                  _pressable={{
                    onAddClick: addCountProduct,
                    onRemoveClick: removeCountProduct,
                  }}
                  _input={{
                    value: productAmount.toString(),
                    onChangeText: handleInputCount,
                    onBlur: handleInputBlur,
                  }}
                  w={["full"]}
                  mr={[0, 4]}
                  mb={[1, 1, 1, 0]}
                />
              ) : (
                <Button
                  h={[8, 8, 8, 9]}
                  w="100%"
                  p={2}
                  bg={"white"}
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    bg: "white",
                    opacity: 0.8,
                  }}
                  _pressed={{
                    bg: "muted.300",
                  }}
                  onPress={showChangeQuantity}
                >
                  <Text
                    fontSize={["xs", "sm", "2xs", "xs", "sm"]}
                    fontWeight="500"
                    color={"primary.600"}
                  >
                    Alterar quantidade
                  </Text>
                </Button>
              )}
            </VStack>
          </Stack>
        </View>
      </Box>
    );
  }
);
