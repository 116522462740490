import React from 'react';
import { Box, FlatList, IBoxProps, Text, View } from 'native-base';
import { ProductRuleItem } from '../ProductRuleItem';
import { CouponsType } from '@types/CouponsType';
import { width } from '@components/Dimension';

type CouponProps = {
  item: CouponsType;
  _header?: IBoxProps
}

export const Coupon: React.FC<CouponProps> = ({ item, _header }) => {

  return (
    <View key={item.id} flex={1}>
      <Box
        justifyContent={'center'}
        alignItems={'center'}
        {..._header}
      >
        <Text
          lineHeight={24}
          fontSize={['md']}
          fontWeight='700'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}

        >
          {item.coupon.name}
        </Text>
        <Text
          lineHeight={24}
          fontSize={['sm']}
          fontWeight='400'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}
        >
          {item.coupon.description}
        </Text>
      </Box>

      <View style={{
        borderBottomWidth: 1,
        borderColor: "#C4C4C4",
        opacity: 0.3,
        marginVertical: width * 0.013
      }} />

      {item.rules.minCheckoutVolume && (
        <Text
          lineHeight={24}
          fontSize={['sm', 'xs']}
          fontWeight='400'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}
        >
          {item.rules.minCheckoutVolume}.
        </Text>
      )}
      {item.rules.discountPercentage && (
        <Text
          lineHeight={24}
          fontSize={['sm', 'xs']}
          fontWeight='400'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}
        >
          {item.rules.discountPercentage}.
        </Text>
      )}
      {item.rules.maxValue && (
        <Text
          lineHeight={24}
          fontSize={['sm', 'xs']}
          fontWeight='400'
          color='muted.800'
          _dark={{
            color: 'muted.300'
          }}
        >
          {item.rules.maxValue}.
        </Text>
      )}
      <Text
        lineHeight={24}
        fontSize={['sm', 'xs']}
        fontWeight='400'
        color='muted.800'
        _dark={{
          color: 'muted.300'
        }}
      >
        {item.rules.endDate}.
      </Text>

      <View mt='4'>
        {item.rules.products.length > 0 ? (
          <View>
            <Text
              lineHeight={24}
              fontSize={['sm', 'sm']}
              fontWeight='500'
              color='muted.800'
              _dark={{
                color: 'muted.300'
              }}
            >
              Desconto exclusivo para os produtos:
            </Text>

            <FlatList
              scrollEnabled
              ml='4'
              maxH={['48', '50%']}
              data={item.rules.products}
              keyExtractor={item => item.ean}
              renderItem={({ item }) => <ProductRuleItem productRule={item} />}
              showsHorizontalScrollIndicator={false}
            />
          </View>
        ) : (
          <Text
            lineHeight={24}
            fontSize={['sm', 'sm']}
            fontWeight='500'
            color='muted.800'
            _dark={{
              color: 'muted.300'
            }}
          >
            Desconto válido para todos os produtos.
          </Text>
        )}
      </View>
    </View>
  );
};