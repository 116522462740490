import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  View,
  Button,
  Center,
  useBreakpointValue,
} from 'native-base';

import { useProductTabNavigation } from '@components/ProductsTabView';
import { styles } from '@components/SuggestedProducts/styles';

import { ItemTypeEnum } from '@enums/ItemTypeEnum';
import helpers from '@helpers/NumberHelper';
import { useShoppingCart } from '@hooks/useShoppingCart';
import { ItemType } from '@types/CartType';
import { CommercialPolicyProductsType } from '@types/CommercialPolicyProductsType';
import { ListType } from '@types/ListType';

export interface SummaryPropsInterface {
  listId: number;
  products: CommercialPolicyProductsType[];
  addAllAtOnceToCart: () => void;
}

export const Summary = (props: SummaryPropsInterface) => {
  const { cart } = useShoppingCart();
  const [disabled, setDisabled] = useState(false);

  let { tabParams: tabParams } = useProductTabNavigation();

  function checkListSuggestionInCart() {
    let found = false;
    cart?.items.map((item: ItemType) => {
      item?.suggestions?.list?.map((list: ListType) => {
        if (list?.code == tabParams?.listCode) {
          found = true;
          return;
        }
      })
    });
    return found;
  }

  async function getLengthListSuggestionInCart() {
    const cartStorage = cart?.items || [];

    const list = cartStorage.filter((item: ItemType) => {
      return item.type == ItemTypeEnum.SUGGESTED_LIST;
    });

    return list.length;
  }

  const [length, setLength] = useState(0);

  const summaryStyleLabels = {
    color: styles.font.color,
    fontSize: ["xs", "sm"],
  };

  const summaryStyleTotal = {
    fontSize: ["xl"],
    fontWeight: styles.summaryTotal.fontWeight,
    color: styles.summaryTotal.color,
  };

  const btnProps = {
    bg: styles.btn.backgroundColor,
    _hover: { background: styles.btn_hover.backgroundColor },
    isDisabled: disabled,
  };

  const btnStyleProps = {
    color: styles.btn.color,
    fontSize: ["xs", "sm"],
  };

  const { listId, products, addAllAtOnceToCart } = props;

  const flexDir = useBreakpointValue({
    base: "column",
    sm: "column",
    md: "column",
    lg: "row",
    xl: "row",
  });

  const init = async () => {
    const numberOfRegistersInList = await getLengthListSuggestionInCart();
    if (length != numberOfRegistersInList) {
      setLength(numberOfRegistersInList);
    }
  };

  init();

  useEffect(() => {
    if (checkListSuggestionInCart()) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  });

  let total = 0;

  const calcTotal = () => {
    products.forEach((item: CommercialPolicyProductsType) => {
      let totalItem = item.product.quantity * item.wholesaler.price.netPrice;
      total += totalItem;
    });

    return helpers.currency(total, 2);
  };

  const BoxWidth = ["100%", "100%", "100%", "33%", "33%"];

  return (
    <Center>
      <Box
        mb={5}
        w={"full"}
        h={150}
        justifyContent="center"
        borderWidth={1}
        borderColor={"coolGray.200"}
        shadow={3}
      >
        <View style={{ flexDirection: flexDir }} m={5}>
          <Box w={BoxWidth}>
            <Text {...summaryStyleLabels}>Quantidades:</Text>
            <Text {...summaryStyleLabels}>
              {products.length || "Nenhum"} produto(s) selecionado(s)
            </Text>
          </Box>

          <Box w={BoxWidth}>
            <Text {...summaryStyleLabels}>Valor Total:</Text>
            <Text {...summaryStyleTotal}>R$ {calcTotal()}</Text>
          </Box>

          <Box w={BoxWidth} alignItems={"center"}>
            <Button
              disabled={products.length == 0}
              opacity={products.length == 0 ? 0.5 : 1}
              w={["100%", "100%", "80%", "80%"]}
              {...btnProps}
              mt={2}
              mb={[1, 1, 0, 0]}
              onPress={async () => {
                if (!disabled && products.length > 0) {
                  await addAllAtOnceToCart();
                }
              }}
              testID="btnAddList"
            >
              <Text {...btnStyleProps}>Adicionar ao carrinho</Text>
            </Button>
          </Box>
        </View>
      </Box>
    </Center>
  );
};
