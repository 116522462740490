import { create } from 'zustand';

import { useRefAlert } from "@components/Alert";
import { MutableRefObject } from 'react';
import { DialogProductToListTemplate } from '@components/Alert/templates/DialogProductToList';



interface AddItemExistsInListAlert {
  ref: MutableRefObject<useRefAlert | undefined> | undefined,
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSuccessCallback: () => void;
  onFailCallback: () => void;
  setOnSuccessCallback: (on: () => {}) => void;
  setOnFailCallback: (on: () => {}) => void;
  text: string | React.ReactElement,
  setWarning: () => void,
  setRef: (ref: MutableRefObject<useRefAlert | undefined>) => void,
}

export const useAddItemExistsInListAlert = create<AddItemExistsInListAlert>((set, get) => ({
  isOpen: false,
  text: '',
  ref: undefined,
  onSuccessCallback: () => { },
  onFailCallback: () => { },
  setOnSuccessCallback: (onSuccessCallback) => set({ onSuccessCallback }),
  setOnFailCallback: (onFailCallback) => set({ onFailCallback }),
  setRef: (ref) => set({ ref }),
  onOpen: () => {
    set({ isOpen: true })
    get().ref?.current?.show()
  },
  onClose: () => {
    set({ isOpen: false })
    get().onFailCallback()
  },
  setWarning: () => {
    const text = DialogProductToListTemplate();
    set({ text })
  },
}));
