import React, { useCallback, useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import {
  Factory,
  HStack,
  Progress,
  Text,
  VStack,
  View,
  useMediaQuery,
} from "native-base";
import { useFocusEffect, useNavigation } from "@react-navigation/native";

import { SearchBar } from "@components/SearchBar";
import { useAuth } from "@contexts/Auth";
import { TextHelper } from "@helpers/TextHelper";
import { useAdditionalCustomers } from "@hooks/useAdditionalCustomers";
import { useShoppingCart } from "@hooks/useShoppingCart";
import { AdditionalCustomersType } from "@types/AdditionalCustomersType";
import { useValidateCommercialPoliciesByCustomer } from "@hooks/useValidateCommercialPoliciesByCustomer";
import { IntegrationEnum } from "@enums/IntegrationEnum";
import { SendOrderResponsiveOverlay } from "@components/SendOrderResponsiveOverlay";
import { useSendOrderResponsiveOverlay } from "@hooks/useSendOrderResponsiveOverlay";
import { AdditionalCustomersInputType } from "@hooks/useCheckout";
import { Loading } from "@components/Loading";
import { ResultSendOrderResponsiveOverlay } from "@components/ResultSendOrderResponsiveOverlay";
import { useResultSendOrderResponsiveOverlay } from "@hooks/useResultSendOrderResponsiveOverlay";
import { ValidateCustomersList } from "@components/ValidateCustomersList";

export const ValidateCustomers = () => {
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 640 }]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const FactoryOpacity = Factory(TouchableOpacity);
  const navigation = useNavigation();
  const { getAdditionalCustomers } = useAdditionalCustomers();
  const [customers, setCustomers] = useState<AdditionalCustomersType[]>([]);
  const [data, setData] = useState<AdditionalCustomersType[]>([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const { cart } = useShoppingCart();
  const [successAmount, setSuccessAmount] = useState(0);
  const [failAmount, setFailAmount] = useState(0);
  const [query, { loading: loadingValidateCustomers }] =
    useValidateCommercialPoliciesByCustomer();
  const industryCode = user.getNameIndustryStore();
  const sendOrderDialog = useSendOrderResponsiveOverlay();
  const resultSendOrderDialog = useResultSendOrderResponsiveOverlay();
  const { checkout } = useShoppingCart();

  const args = {
    industry: {
      code: industryCode,
    },
    integrations: [IntegrationEnum.POINT_OF_SALES],
    availability: user.isIndustryEmployee() ? "INDUSTRY" : "CUSTOMER",
  };

  const includesStatusCustomers = (
    customersToEdit: AdditionalCustomersType[]
  ) =>
    customersToEdit?.map((item: AdditionalCustomersType) => {
      return { ...item, status: undefined };
    });

  const fillAdditionalCustomers = async () => {
    try {
      const finalCustomers: AdditionalCustomersType[] = [];
      const asyncCustomers = await getAdditionalCustomers(cart?.id);
      setData(() => includesStatusCustomers(asyncCustomers));
      setCustomers(() => includesStatusCustomers(asyncCustomers));

      for (const element of asyncCustomers) {
        const { data } = await query({
          variables: {
            customer: { code: element.code },
            commercialPolicy: { ids: getCommercialPoliciesIds() },
            ...args,
          },
        });
        finalCustomers.push({
          ...element,
          status: data?.validateCommercialPoliciesByCustomer?.valid,
        });
        setProgressValue((value) => value + 1);
      }

      setCustomers(finalCustomers);
      setData(finalCustomers);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = (value: string) => {
    const formattedQuery = value.toLowerCase();

    const filteredData = getFilteredDataByFilterButton().filter(
      (item: AdditionalCustomersType) => {
        const formattedCNPJ = new TextHelper(item?.code).formatCNPJ();

        return (
          item?.name?.toLowerCase().includes(formattedQuery) ||
          item?.code.includes(formattedQuery) ||
          formattedCNPJ.includes(formattedQuery)
        );
      }
    );

    setData(filteredData);
    setSearchText(value);
  };

  const handleOpenDialog = () => {
    sendOrderDialog.onOpen();
  };

  function getCommercialPoliciesIds() {
    return [...new Set(cart?.items.map((item) => item.commercialPolicyId))];
  }

  useEffect(() => {
    setData(getFilteredDataByFilterButton());
  }, [selectedFilter]);

  const getAmountStatusByFilter = (filter: number) => {
    const value = customers?.filter((item) => {
      if (filter == 1 && item.status == true) return true;
      if (filter == 2 && item.status == false) return true;
      if (filter == 0) return true;
    });

    return value?.length;
  };

  useEffect(() => {
    setSuccessAmount(getAmountStatusByFilter(1));
    setFailAmount(getAmountStatusByFilter(2));
  }, [customers]);

  useFocusEffect(
    useCallback(() => {
      if (!isSmallScreen)
        navigation.navigate("SummaryOrder", { industry: industry });
      setProgressValue(0);
      fillAdditionalCustomers();
    }, [cart])
  );

  const getFilteredDataByFilterButton = () => {
    const filteredData = customers.filter((item: AdditionalCustomersType) => {
      if (selectedFilter == 1 && item.status == true) return true;
      if (selectedFilter == 2 && item.status == false) return true;
      if (selectedFilter == 0) return true;
    });

    return filteredData;
  };

  const handleSendOrder = async () => {
    setLoading(true);

    const additionalCustomers: AdditionalCustomersInputType[] = customers
      .filter((customer) => customer.status === true)
      .map((item) => {
        return {
          code: item.code,
        };
      });

    sendOrderDialog.onClose();

    try {
      await checkout(additionalCustomers);
      resultSendOrderDialog.onOpen("SUCCESS");
    } catch (error) {
      resultSendOrderDialog.onOpen("ERROR");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <View>
      <HStack
        space={4}
        pb={4}
        px={["3", "3", 0]}
        direction={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={["center", "baseline"]}
        bgColor={["white", ""]}
        _dark={{
          bgColor: ["muted.800", ""],
        }}
        borderBottomWidth={[2, 0]}
        borderColor="coolGray.200"
        borderWidth="0"
      >
        <View width={"full"} pt={4}>
          <SearchBar
            variant={"outline"}
            size={["md", "xs"]}
            placeholder={"Pesquise pelo nome do cliente ou CNPJ"}
            value={searchText}
            onChangeText={(e: string) => handleSearch(e)}
          />
        </View>
      </HStack>

      <Progress
        colorScheme={"success"}
        value={progressValue}
        height={2}
        _filledTrack={{ rounded: "none", bg: "success.500" }}
        max={customers?.length}
        width={"full"}
      />

      <Text
        alignSelf={"flex-end"}
        mr={4}
        fontFamily={"body"}
        color="gray.400"
        fontSize={"xs"}
      >
        Progresso ({progressValue}/{customers?.length})
      </Text>

      <VStack px={5} pt={2} space={2}>
        <HStack space={2}>
          <FactoryOpacity
            onPress={() => setSelectedFilter(0)}
            width={"84px"}
            height={"22px"}
            borderColor={"gray.500"}
            borderRadius={10}
            borderWidth={1}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={selectedFilter == 0 ? "gray.500" : "white"}
            testID="ValidateCustomers-Filter-Touchable-All"
          >
            <Text
              fontFamily={"subheading"}
              color={selectedFilter == 0 ? "white" : "gray.500"}
              fontSize={"xs"}
            >
              Todos ({customers?.length})
            </Text>
          </FactoryOpacity>

          <FactoryOpacity
            onPress={() => setSelectedFilter(1)}
            width={"96px"}
            height={"22px"}
            borderColor={selectedFilter == 1 ? "success.500" : "gray.500"}
            borderRadius={10}
            borderWidth={1}
            alignItems={"center"}
            justifyContent={"center"}
            disabled={loadingValidateCustomers}
            backgroundColor={selectedFilter == 1 ? "success.500" : ""}
            testID="ValidateCustomers-Filter-Touchable-Success"
          >
            <Text
              fontFamily={"subheading"}
              color={selectedFilter == 1 ? "white" : "gray.500"}
              fontSize={"xs"}
            >
              Sucesso ({successAmount})
            </Text>
          </FactoryOpacity>

          <FactoryOpacity
            onPress={() => setSelectedFilter(2)}
            width={"73px"}
            height={"22px"}
            borderColor={selectedFilter == 2 ? "danger.700" : "gray.500"}
            borderRadius={10}
            borderWidth={1}
            alignItems={"center"}
            justifyContent={"center"}
            disabled={loadingValidateCustomers}
            backgroundColor={selectedFilter == 2 ? "danger.700" : ""}
            testID="ValidateCustomers-Filter-Touchable-Error"
          >
            <Text
              fontFamily={"subheading"}
              color={selectedFilter == 2 ? "white" : "gray.500"}
              fontSize={"xs"}
            >
              Erro ({failAmount})
            </Text>
          </FactoryOpacity>
        </HStack>

        <ValidateCustomersList customers={customers} data={data} isMobile />
      </VStack>

      <VStack
        backgroundColor={"white"}
        alignItems={"center"}
        borderTopRadius={10}
        borderLeftColor={"white"}
        borderRightColor={"white"}
        borderBottomColor={"white"}
        borderTopColor={"gray.200"}
        borderWidth={1}
        p={5}
        h={32}
      >
        <FactoryOpacity
          onPress={() => handleOpenDialog()}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
          pr={2}
          backgroundColor={"primary.600"}
          borderRadius={2}
          width={80}
          height={12}
          disabled={loadingValidateCustomers}
          testID="ValidateCustomers-SendOrder-Touchable"
        >
          <Text fontSize={"sm"} color={"white"}>
            Enviar Pedido
          </Text>
        </FactoryOpacity>

        <FactoryOpacity
          onPress={() =>
            navigation.navigate("FinishOrder", { industry: industry })
          }
          justifyContent={"center"}
          py={2}
          pr={2}
        >
          <Text fontSize={"sm"} fontWeight={"bold"} color={"primary.600"}>
            Cancelar
          </Text>
        </FactoryOpacity>
      </VStack>

      <SendOrderResponsiveOverlay
        type="MOBILE"
        handleSendOrder={handleSendOrder}
      />

      <ResultSendOrderResponsiveOverlay type="MOBILE" />
    </View>
  );
};
