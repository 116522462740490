import React from "react";
import { FlatList, View, Text } from "native-base";

import { GenerateKey } from "../../helpers/GenerateKey";
import { ExtraDiscountType } from "../../types/ExtraDiscountType";


export const CouponsList = ({ coupons }: { coupons: ExtraDiscountType[] }) => {
  return (
    <FlatList
      data={coupons}
      renderItem={({ item }: { item: any }) => (
        <Item coupon={item} />
      )}
      key={GenerateKey()}
      style={{ borderWidth: 0 }}
    />
  );
}

const Item = ({ coupon }: { coupon: ExtraDiscountType }) => (
  <View>
    <Text>{`${coupon.couponName}: ${coupon.discount}%`}</Text>
  </View>
);