import React from "react";
import { Box, IBoxProps, Skeleton, Text } from "native-base";

type InfoOrderProps = IBoxProps & {
  label: string;
  value: string,
  color?: string
  isLoaded?: boolean
}

export const InfoOrder = ({ label, value, color, isLoaded = true, ...rest }: InfoOrderProps) => {
  return (
    <Box
      flexDirection='row'
      justifyContent='space-between'
      {...rest}
    >
      <Text
        fontSize={16}
        fontWeight={500}
        lineHeight={"md"}
        color={color ?? 'muted.800'}
        _dark={{
          color: color ?? 'muted.400'
        }}
      >
        {label}
      </Text>
      <Skeleton.Text lines={1} mb="3" px="1" size={3}
        w={16}
        startColor={color ?? 'muted.400'}
        isLoaded={isLoaded}>
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={"md"}
          color={color ?? 'muted.700'}
          _dark={{
            color: color ?? 'muted.400'
          }}
          selectable
        >
          {value}
        </Text>
      </Skeleton.Text>

    </Box>
  );
};
