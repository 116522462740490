import React, { useState, useEffect } from 'react';
import { DraxProvider, DraxList } from 'react-native-drax';
import { FlatList } from 'react-native-gesture-handler';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  View,
  Text,
  Box,
  Hidden,
  CheckCircleIcon,
  ScrollView,
  Pressable,
  VStack,
  HStack,
  useMediaQuery,
  Button,
  Checkbox,
  useToast,
  useTheme,
  Center
} from 'native-base';
import Spinner from 'react-native-loading-spinner-overlay';

import { width, height } from '@components/Dimension';
import { DragCard } from '@components/DragCard';
import { SearchBar } from '@components/SearchBar';
import { SelectedDragCard } from '@components/SelectedDragCard';

import { useAuth } from '@contexts/Auth';
import { containerDealersStyleProps, btnCancelProps } from '@screens/ChooseWholesaler/styleProps';
import { PaginationType } from '@types/PaginationType';
import { SearchWholesalersType } from '@types/SearchWholesalersType';
import { WholesalerBranchesType } from '@types/WholesalerBranchesType';

import { Config } from '@configs/Config';
import { GenerateKey } from '@helpers/GenerateKey';
import { styles } from './styles';
import { ToastAlert } from '@components/ToastAlert';

let possibleWholesalerAvailable: number = Config.NUMBER_POSSIBLES_WHOLESALER_AVAILABLE
  ? Number(Config.NUMBER_POSSIBLES_WHOLESALER_AVAILABLE)
  : 12;

const numberOfSelectableWholesalers: number = Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED
  ? Number(Config.NUMBER_POSSIBLES_WHOLESALER_SELECTED)
  : 6;

type Props = {
  data: any;
  searchWholesalers: SearchWholesalersType;
  storageValue?: any;
  handleButton: (onPress: () => void, disabled: boolean) => React.ReactNode;
  actionButton: any;
  clearEnabled?: boolean;
  context: any;
  contextFunction: any;
  orderWholesalers?: boolean;
  isPreferredWholesalerScreen: boolean;
  applyLastUsedOrder?: (check: boolean) => void;
}

export const ChooseWholesaler = ({
  data,
  storageValue = [],
  searchWholesalers,
  handleButton,
  actionButton,
  clearEnabled = false,
  context,
  contextFunction,
  orderWholesalers = false,
  isPreferredWholesalerScreen,
  applyLastUsedOrder = (check: boolean) => { }
}: Props) => {
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();
  const navigation = useNavigation();
  const initialArray = generateClearArray();
  const [isSmallScreen] = useMediaQuery([{ maxWidth: 480 }]);
  const toast = useToast();
  const { colors } = useTheme();
  const { name: routeName } = useRoute();

  const [receivingItemList, setReceivedItemList] =
    useState<WholesalerBranchesType[]>(initialArray);
  const [dragItemList, setDragItemList] = useState<WholesalerBranchesType[]>(
    []
  );
  const [focusDragItemList, setFocusDragItemList] = useState<
    WholesalerBranchesType[]
  >([]);

  const [previousOrder, setPreviousOrder] = useState<WholesalerBranchesType[]>(
    []
  );

  const [isSelectWholesalerLoading, setIsSelectWholesalerLoading] = useState(false);
  const [paginationList, setPaginationList] = useState<PaginationType[]>([]);
  const [paginationFocus, setPaginationFocus] = useState(0);
  const [paginationCount, setPaginationCount] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [loadedStorage, setLoadedStorage] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [index, setIndex] = useState(0);
  const [wholesalerEdit, setWholesalerEdit] = useState(-1)
  const [searchText, setSearchText] = useState("");
  const [isMobileCurrentColumnActive, setIsMobileCurrentColumnActive] = useState(true);
  let possibleWholesalers: WholesalerBranchesType[] = [];
  const largeDevices = ["xs", "sm", "md", "lg", "xl", "2xl"]
  const smallDevices = ["base"]
  const showWholesalersColumn = false
  const showSelectedWholeSalersColumn = true
  const checkboxStyleProps = isPreferredWholesalerScreen ? { display: "none" } : {}

  if (isSmallScreen) {
    possibleWholesalerAvailable = data.length
  }

  useEffect(() => {
    const start = paginationFocus * possibleWholesalerAvailable;

    const filteredList = clearSelectedFromAvailable(dragItemList);

    setFocusDragItemList(
      filteredList.slice(start, start + possibleWholesalerAvailable)
    );
    createPaginationData(filteredList.length);
  }, [paginationFocus]);

  useEffect(() => {
    let start = paginationFocus * possibleWholesalerAvailable;

    let newDragItemList = context.slice(
      start,
      start + possibleWholesalerAvailable
    );

    if (newDragItemList.length === 0 && paginationFocus !== 0) {
      start = start - 1;
      newDragItemList = context.slice(
        start,
        start + possibleWholesalerAvailable
      );
      setPaginationFocus(paginationFocus - 1);
    }

    const filteredList = clearSelectedFromAvailable(dragItemList);

    setFocusDragItemList(
      filteredList.slice(start, start + possibleWholesalerAvailable)
    );

    createPaginationData(filteredList.length);
    handleDisabledButton();
  }, [dragItemList, context]);

  useEffect(() => {
    const filteredList = clearSelectedFromAvailable(dragItemList);
    createPaginationData(filteredList.length);
    handleDisabledButton();
  }, [receivingItemList]);

  useEffect(() => {
    setLoadedData(false);
  }, [data, storageValue]);

  if (data && !loadedData) {
    let result = [];
    result = data;

    const {
      wholesalersWithPriority,
      filteredPossibleWholesalers
    } = extractWholesalersWithPriority(result);

    if (wholesalersWithPriority.length !== 0 && storageValue.length === 0) {
      result = filteredPossibleWholesalers;
      handleInitialWholesalersSelected(wholesalersWithPriority);
    }

    if(!applyLastUsedOrder) {
      const previous = previousOrder.length > 0 ? previousOrder : result
      setDragItemList(previous);
      createPaginationData(previous.length);
      setLoadedData(true);
      return;
    }

    possibleWholesalers = result.length === 0
      ? []
      : result.map((item: WholesalerBranchesType) => {
        return {
          code: item.code,
          description: item.description,
          priority: 0,
          wholesaler: item.wholesaler,
        };
      });

    possibleWholesalers = sortWholesalerList(possibleWholesalers);

    setDragItemList(possibleWholesalers);
    createPaginationData(possibleWholesalers.length);
    setLoadedData(true);
  }

  if (storageValue.length > 0 && !loadedStorage) {
    addItemFromStorageOnReceivingList();

    const filteredPossibleWholesalers = filterWholesalerFromStorage();

    setDragItemList(filteredPossibleWholesalers);
    createPaginationData(filteredPossibleWholesalers.length);
    setLoadedStorage(true);
  }

  function extractWholesalersWithPriority(list: WholesalerBranchesType[]) {
    const filtered = list.filter(
      (x: WholesalerBranchesType) =>
        x.priority !== 0 && x.priority <= numberOfSelectableWholesalers
    );

    if (filtered.length === 0) {
      return {
        wholesalersWithPriority: [],
        filteredPossibleWholesalers: list,
      };
    }

    const filteredPossibleWholesalers = list.filter(
      (x: WholesalerBranchesType) =>
        x.priority === 0 || x.priority > numberOfSelectableWholesalers
    );

    return {
      wholesalersWithPriority: filtered,
      filteredPossibleWholesalers,
    };
  }

  function clearSelectedFromAvailable(list: WholesalerBranchesType[]) {
    const available = list.filter(function (item) {
      return (
        context.findIndex((x: WholesalerBranchesType) => x.code === item.code) <
        0
      );
    });

    const result = available.reduce((unique: any, o: any) => {
      if (!unique.some((obj: { code: any }) => obj.code === o.code)) {
        unique.push(o);
      }
      return unique;
    }, []);

    return result;
  }

  function handleInitialWholesalersSelected(
    wholesalersWithPriority: WholesalerBranchesType[]
  ) {
    if (storageValue.length > 0) {
      return;
    }

    const initialWholesalersSelected = initialArray.map((item) => {
      const wholesaler = wholesalersWithPriority.filter(
        (x) => x.priority === item.priority
      );

      if (wholesaler.length > 0) {
        return {
          code: wholesaler[0].code,
          description: wholesaler[0].description,
          priority: wholesaler[0].priority,
          wholesaler: wholesaler[0].wholesaler,
        };
      }

      return item;
    });

    setReceivedItemList(initialWholesalersSelected);
  }

  function addItemFromStorageOnReceivingList() {
    storageValue.map((item: WholesalerBranchesType, index: number) => {
      return (receivingItemList[index] = item);
    });
  }

  function filterWholesalerFromStorage() {
    return possibleWholesalers.filter(
      (x: WholesalerBranchesType) =>
        !storageValue.find((o: WholesalerBranchesType) => o.code === x.code)
    );
  }

  function sortWholesalerList(list: WholesalerBranchesType[]) {
    return list.length === 0 ? [] : list.sort((a: WholesalerBranchesType, b: WholesalerBranchesType) =>
      a.description > b.description ? 1 : b.description > a.description ? -1 : 0
    );
  }

  function handleDisabledButton() {
    if (filterOnlyWholesalerSelect().length > 0 && disabledButton) {
      setDisabledButton(false);
    }

    if (filterOnlyWholesalerSelect().length === 0) {
      setDisabledButton(true);
    }
  }

  function createPaginationData(length: number) {
    const paginationCount = possibleWholesalerAvailable !== 0
      ? Math.ceil(length / possibleWholesalerAvailable) : 0;

    const arrayPagination = Array.from(Array(paginationCount).keys());

    setPaginationCount(paginationCount);
    const objectPagination = arrayPagination.map((item) => {
      return {
        index: item,
        focus: item === paginationFocus,
      };
    });
    setPaginationList(objectPagination);
  }

  function handlePressPagination(index: number) {
    if (index < 0 || index > paginationCount - 1) return;
    setIndex(index);
    setPaginationFocus(index);
  }

  async function handleWholesalerPriority(): Promise<void> {
    const filtered = filterOnlyWholesalerSelect();

    const newPriority = filtered.map((item, index) => {
      item.priority = index + 1;
      return item;
    });

    const wholesalersBranch = newPriority
      .concat(dragItemList)
      .map((item: WholesalerBranchesType, index: number) => {
        return {
          code: item.code,
          priority: index + 1,
          wholesaler: item.wholesaler
        };
      });

    await actionButton(newPriority, wholesalersBranch);
  }

  function filterOnlyWholesalerSelect() {
    const filtered = receivingItemList.filter((item) => {
      return item.code !== "";
    });

    return filtered;
  }

  function generateClearArray(): WholesalerBranchesType[] {
    return Array.from({ length: 6 }, (_, k) => {
      return {
        code: "",
        description: "",
        priority: k + 1,
        wholesaler: { id: 0 },
      };
    });
  }

  async function clearSelectedCards() {
    let wholesalersSelected = filterOnlyWholesalerSelect();
    const clearArr = generateClearArray();
    setReceivedItemList(clearArr);

    wholesalersSelected = wholesalersSelected.map((item) => {
      item.priority = item.priority;
      return item;
    });

    const arrNewAvailable = [...dragItemList, ...wholesalersSelected];

    setDragItemList(sortWholesalerList(arrNewAvailable));
  }

  function renderDragCard(item: WholesalerBranchesType) {
    return (
      <DragCard
        item={item}
        showBillingOptions={orderWholesalers}
        onClick={() => addToSelectedWholesalerList(item)}
        setWholesaler={() => addToSelectedWholesalerList(item)}
        isPreferredWholesalerScreen={isPreferredWholesalerScreen}
      />
    );
  }

  function showSlotsColumnIfAllHasBeenTaken() {
    const wholesalersSelected = filterOnlyWholesalerSelect();
    const numberOfWholesalersSelected: number = wholesalersSelected.length + 1

    if (numberOfWholesalersSelected === numberOfSelectableWholesalers) {
      swapBetweenColumns(showSelectedWholeSalersColumn)
    }
  }

  function getCurrentWholesaler(): string {
    const isEditing: boolean = wholesalerEdit !== -1 ? true : false

    if (isEditing) {
      return `Editar ${(wholesalerEdit + 1)}º Distribuidor`
    }

    const firstAvailableIndex = receivingItemList.findIndex((item) => {
      return item?.code?.length === 0;
    });

    if (firstAvailableIndex === -1) {
      return 'Todos os distribuidores foram selecionados';
    }

    return `${(firstAvailableIndex + 1)}º Distribuidor`
  }

  function addToSelectedWholesalerList(item: WholesalerBranchesType) {
    setIsSelectWholesalerLoading(true);

    setTimeout(() => {
      const isEditing: boolean = wholesalerEdit !== -1 ? true : false;
      let firstAvailableIndex: number = 0;
      const newSelectedCards = [...receivingItemList];

      if (!isEditing) {
        firstAvailableIndex = receivingItemList.findIndex((item) => {
          return item.code.length === 0;
        });

        if (firstAvailableIndex === -1) {
          maxWholsalersToast();
          setIsSelectWholesalerLoading(false);
          return;
        }
      }

      const newAvailableCards = dragItemList.filter((it) => {
        return it.code !== item.code;
      });

      if (isEditing) {
        newAvailableCards.push(receivingItemList[wholesalerEdit]);
      }

      const selectedIndex = isEditing ? wholesalerEdit : firstAvailableIndex;

      item.priority = receivingItemList[selectedIndex].priority;
      newSelectedCards[selectedIndex] = item;

      setReceivedItemList(newSelectedCards);
      setDragItemList(newAvailableCards);

      if (isSmallScreen) {
        if (isEditing) {
          setWholesalerEdit(-1);
          swapBetweenColumns(showSelectedWholeSalersColumn);
        } else {
          showSlotsColumnIfAllHasBeenTaken();
        }
      }

      //renderToast(item.priority); // TODO: VALIDAR COM PRODUTOS A NECESSIDADE DO FEEDBACK

      setIsSelectWholesalerLoading(false);
    }, 300);
  }

  function maxWholsalersToast() {
    toast.show(
      {
        placement: 'top-right',
        rounded: 'lg',
        render: ({
          id,
        }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={'Já foram selecionados 6 distribuidores'}
            variant='subtle'
            status="warning"
            isClosable
            _title={{
              color: 'warning.800'
            }}
            rounded='lg'
            right={[1, 24]}
            top={[1, 1, 6]}
          />
        )
      }
    )
  }

  function renderToast(priority: number) {
    const toastMessage = priority !== numberOfSelectableWholesalers
      ? `${priority}° distribuidor selecionado, escolha o próximo distribuidor, se desejar!`
      : `${priority}° distribuidor selecionado!`;

    toast.show({
      duration: 4000,
      render: () => {
        return (
          <HStack
            bg='success.600'
            alignItems='center'
            justifyContent='space-between'
            rounded='2xl'
            mx={4}
            py={3}
            px={4}
            flex={1}
          >
            <CheckCircleIcon color='white' size={[12, 8]} mr={4} />

            <Text
              width='full'
              color='white'
              fontSize={['sm', 'md']}
              fontWeight='500'
            >
              {toastMessage}
            </Text>
          </HStack>
        )
      }
    });
  }

  const receivingItemListColumns = []

  receivingItemListColumns.push(receivingItemList.slice(0, 3))
  receivingItemListColumns.push(receivingItemList.slice(3, 6))

  const createSlots = (slots: WholesalerBranchesType[]) => {
    return (
      <Box
        key={GenerateKey()}
        flexDirection={["column", "row", "row", "row"]}
        alignItems={["center", "normal"]}
        mb={!isSmallScreen ? 6 : 0}
      >
        <>
          {slots.map((item: WholesalerBranchesType) => (
            <SelectedDragCard
              key={GenerateKey()}
              card={item}
              selectedCards={receivingItemList}
              setSelectedCards={setReceivedItemList}
              availableCards={dragItemList}
              setAvailableCards={setDragItemList}
              setContext={contextFunction}
              showBillingOptions={orderWholesalers}
              editWholesaler={() => editWholesaler(item)}
              isPreferredWholesalerScreen={isPreferredWholesalerScreen}
            />
          ))}
        </>
      </Box>
    )
  }

  const getNumberOfColumns = (): number => {
    if (isSmallScreen) {
      return 1;
    }

    return possibleWholesalerAvailable / 2;
  }

  const getMarginLeft = (): string => {
    if (!isSmallScreen) {
      return "0vw"
    }

    if (isMobileCurrentColumnActive) {
      return "0vw"
    }

    return "-100vw"
  }

  function getPropsStyle() {
    if (isSmallScreen && getMarginLeft() === '0vw') {
      return Object.assign({ 'display': 'none' }, containerDealersStyleProps)
    }

    return containerDealersStyleProps
  }

  function getBtnCancelProps() {
    if (isPreferredWholesalerScreen) {
      return Object.assign({ 'display': 'none' }, btnCancelProps)
    }

    return btnCancelProps
  }

  const swapBetweenColumns = (status: boolean) => {
    if (status) {
      setWholesalerEdit(-1)
    }
    setIsMobileCurrentColumnActive(status)
  }

  function editWholesaler(item: WholesalerBranchesType): void {
    if (!item) return;

    const index = receivingItemList.findIndex((itemList) => {
      return itemList.code === item.code;
    });
    setWholesalerEdit(index)
    swapBetweenColumns(showWholesalersColumn)
  }

  function backToScreen(screen: string) {
    navigation.navigate(screen as any, { industry: industry })
  }

  return (
    <DraxProvider>
      <Box
        flexDirection={["row", "column", "column", "column"]}
        width="full"
        h={"auto"}
        px={4}
      >

        <Spinner visible={isSelectWholesalerLoading} color={colors.primary[600]} />

        <Box
          flexDirection={["column", "column", "column", "column", "row"]}
          h={"auto"}
          width="full"
          ml={getMarginLeft()}
        >
          <Hidden only={largeDevices}>
            <>
              <HStack>
                <HStack flex={1}>
                  <VStack>
                    <Text fontSize='sm' fontFamily='heading' color={styles.fontColorDefault.color}>
                      Ordem dos distribuidores
                    </Text>

                    <Text fontSize='xs' color={styles.fontColorDefault.color}>
                      {routeName === 'PreferredWholesaler' ? (
                        "Selecione a ordem."
                      ) : (
                        "Selecione a ordem e o prazo de envio."
                      )}
                    </Text>
                  </VStack>
                </HStack>

                {clearEnabled && (
                  <Pressable onPress={() => clearSelectedCards()}>
                    <Text
                      color={styles.linkColor.color}
                      fontSize='xs'
                      fontFamily='heading'
                    >
                      Limpar ordem
                    </Text>
                  </Pressable>
                )}
              </HStack>

                <Pressable
                  disabled={filterOnlyWholesalerSelect().length === numberOfSelectableWholesalers}
                  onPress={() => swapBetweenColumns(showWholesalersColumn)}
                >
                  <Text
                    color={
                      filterOnlyWholesalerSelect().length === numberOfSelectableWholesalers
                        ? 'muted.400'
                        : 'primary.600'
                    }
                    fontSize={["xs"]}
                    fontFamily='heading'
                    mt={2}
                  >
                    Selecione os distribuidores disponíveis
                  </Text>
                </Pressable>

              <VStack my={2}>
                <Checkbox
                  {...checkboxStyleProps}
                  value=""
                  testID="btn-apply-last-order-mobile"
                  color={'muted.800'}
                  borderRadius={50}
                  onChange={(check) => {
                    setLoadedData(false);
                    setLoadedStorage(false);
                    applyLastUsedOrder(check);
                  }}
                >
                  <Text
                    color={'muted.800'}
                    fontWeight={'400'}
                    fontSize={["xs", "sm"]}
                    _dark={{ color: 'muted.400' }}
                  >
                    aplicar ordem utilizada no último pedido
                  </Text>
                </Checkbox>
              </VStack>
            </>
          </Hidden>

          {
            isSmallScreen
              ? createSlots(receivingItemList)
              : receivingItemListColumns.map((item) => createSlots(item))
          }

          <Hidden only={largeDevices}>
            <VStack
              width='80%'
              alignSelf={"center"}
              mt={4}
            >
              {handleButton(handleWholesalerPriority, disabledButton)}

              <Button
                {...getBtnCancelProps()}
                testID='resumo-pedido-distribuidores:voltar'
                onPress={() => backToScreen('SummaryOrder')}
              >
                <Text fontWeight='700' color={styles.linkColor.color}>
                  Voltar
                </Text>
              </Button>
            </VStack>
          </Hidden>
        </Box>

        <Hidden only={smallDevices}>
          <Box
            width='full'
            alignItems="end"
            mt={6}
            mb={2}
          >
            {clearEnabled && (
              <Pressable onPress={() => clearSelectedCards()}>
                <Text
                  color={styles.linkColor.color}
                  fontSize='sm'
                  fontFamily='heading'
                  pr={1}
                >
                  Limpar ordem
                </Text>
              </Pressable>
            )}
          </Box>
        </Hidden>

        <Hidden only={smallDevices}>
          <Box alignItems={"start"} w={"auto"} my={2}>
            <Checkbox
              {...checkboxStyleProps}
              value=""
              testID="btn-apply-last-order"
              color={'muted.800'}
              borderRadius={50}
              onChange={(check) => {
                setLoadedData(false);
                setLoadedStorage(false)
                applyLastUsedOrder(check)
              }}
            >
              <Text
                color={'muted.800'}
                _dark={{ color: 'muted.400' }}
                fontWeight={'400'}
                fontSize={["xs", "sm"]}
              >
                aplicar ordem utilizada no último pedido
              </Text>
            </Checkbox>
          </Box>
        </Hidden>

        <VStack
          {...getPropsStyle()}
          w='full'
          pl={routeName === 'PreferredWholesaler' ? 16 : 12}
          mr={[-4, 0]}
        >
          <Hidden only={largeDevices}>
            <VStack>
              <Text fontSize="sm" fontWeight="bold" color={styles.fontColorDefault.color}>
                {getCurrentWholesaler()}
              </Text>

              <Text fontSize="xs" color={styles.fontColorDefault.color}>
                Selecione a ordem e o prazo dos distribuidores
              </Text>
            </VStack>
          </Hidden>

          <View
            w={['full', 56]}
            alignSelf={['center', 'start']}
            my={4}
            mr={[-4, 0]}
          >
            <SearchBar
              size={['md', 'xs']}
              placeholder="Nome ou CNPJ"
              value={searchText}
              onChangeText={setSearchText}
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  searchWholesalers(searchText);
                  return;
                }

                if ((event.nativeEvent.key === 'Backspace' || event.nativeEvent.key === 'Delete') && searchText.length == 1) {
                  searchWholesalers("");
                  return;
                }
              }}
              onPress={() => searchWholesalers(searchText)}
            />
          </View>

          <Box flexDirection={["column", "row", "row", "row"]} minH={"20vmin"} mr={[-4, 0]}>
            <Hidden only={largeDevices}>
              <Pressable
                width='full'
                mb={2}
                onPress={() => swapBetweenColumns(showSelectedWholeSalersColumn)}
              >
                <Text color={styles.linkColor.color} fontSize='xs' fontFamily='heading'>
                  Consultar distribuidores selecionados
                </Text>
              </Pressable>
            </Hidden>

            <View
              flex={1}
              width={"100%"}
              minH={["auto", "25vmin"]}
              mb={[5, 0]}
              mr={[-4, 0]}
            >
              {focusDragItemList.length === 0 ? (
                <Center flex={1} mt={[24, 0]}>
                  <Text
                    fontSize={['xs', 'sm']}
                    fontWeight='500'
                    color='muted.800'
                    _dark={{
                      color: 'muted.400'
                    }}
                  >
                    Nenhum distribuidor encontrado!
                  </Text>
                </Center>
              ) : (
                <ScrollView width='full' horizontal={!isSmallScreen}>
                  <DraxList
                    data={focusDragItemList}
                    renderItemContent={({ item }) => renderDragCard(item)}
                    keyExtractor={(item) => item.code}
                    horizontal={false}
                    numColumns={getNumberOfColumns()}
                    key={getNumberOfColumns()}
                    scrollToOverflowEnabled={false}
                    ItemSeparatorComponent={() => (
                      <View mb={2} />
                    )}
                    style={{
                      width: isSmallScreen ? "100%" : "auto",
                      alignItems: 'center'
                    }}
                    flatListStyle={{
                      width: '100%',
                    }}
                  />
                </ScrollView>
              )}
            </View>
          </Box >

          <Hidden only={smallDevices}>
            <HStack alignSelf={"center"}>
              <FlatList
                data={paginationList}
                horizontal={true}
                keyExtractor={(item) => String(item.index)}
                renderItem={({ item }) => (
                  <Pressable
                    key={item.index}
                    onPress={() => handlePressPagination(item.index)}
                  >
                    <View
                      alignSelf={"center"}
                      style={{
                        borderRadius: 2,
                        width: width * 0.024,
                        height: height * 0.008,
                        marginLeft: 0,
                        marginRight: width * 0.006,
                        backgroundColor: item.focus ? styles.paginationBtnFocus.color : styles.paginationBtnColor.color
                      }}
                    />
                  </Pressable>
                )}
              />
            </HStack>
          </Hidden>
        </VStack>

        <Hidden only={smallDevices}>
          <Box
            alignItems={"end"}
            flexDirection={"column"}
            w={"auto"}
            my={5}
          >
            <Box flexDirection={"row"}>
              <Button
                testID="btn-back"
                onPress={() => { backToScreen('SummaryOrder') }}
                minWidth={120}
                {...getBtnCancelProps()}
                mr={2}
              >
                <Text fontWeight='700' color={styles.linkColor.color}>
                  Voltar
                </Text>
              </Button>
              {handleButton(handleWholesalerPriority, disabledButton)}
            </Box >
          </Box >
        </Hidden >
      </Box>
    </DraxProvider>
  );
};
