import { create } from 'zustand';

type ResultSendOrderType = 'SUCCESS' | 'ERROR';

interface ResultSendOrderResponsiveOverlayStore {
  isOpen: boolean;
  type: ResultSendOrderType;
  onOpen: (type?: ResultSendOrderType) => void;
  onClose: () => void;
}

export const useResultSendOrderResponsiveOverlay = create<ResultSendOrderResponsiveOverlayStore>((set) => ({
  isOpen: false,
  type: 'SUCCESS',
  onOpen: (type: ResultSendOrderType = 'SUCCESS') => set({
    isOpen: true,
    type
  }),
  onClose: () => set({ isOpen: false }),
}));
