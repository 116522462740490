import React, { useEffect } from "react";
import {
  Image,
  TouchableOpacity,
} from "react-native";
import {
  View,
  Text,
} from 'native-base';
import { styles } from "./styles";
import { useAddItemAlert } from "@hooks/useAddItemAlert";
import { useCartModal } from "@hooks/useCartModal";

export const AlertAddToCart = () => {
  const alert = useAddItemAlert()
  const cartModal = useCartModal()

  useEffect(() => {
    const delay = 3;
    const timer = setTimeout(() => alert.onClose(), delay * 1000);

    return () => {
      clearTimeout(timer);
    };

  }, [alert.isOpen]);

  const onPress = () => {
    alert.onClose()
    cartModal.onOpen()
  }

  if (!alert.isOpen) {
    return <></>
  }

  return (
    <View style={styles.container}>
      <View style={styles.iconTop}></View>
      <Image source={require('./checked.gif')} style={styles.gif} />
      <Text style={styles.title}>{alert.isAdd ? 'Produto Adicionado no Carrinho' : 'Produto removido do carrinho'}</Text>
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={0.5}
        style={styles.btn}
      >
        <Text style={styles.btnText}>Ver carrinho</Text>
      </TouchableOpacity>
    </View>
  );
};