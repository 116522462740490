import { useEffect, useState } from "react";
import Storage from "../components/StorageTTL";


export const useAsyncStorage = <S = any>(key: string, defaultValue: any): {
  storageValue: S, updateStorage: (newValue: any, expiredTime?: number | null) => Promise<void>, loadingStorage: boolean

} => {
  const [storageValue, updateStorageValue] = useState(defaultValue);
  const [updated, setUpdated] = useState(false);
  const [loadingStorage, setLoadingStorage] = useState(true);

  async function getStorageValue() {
    let value = defaultValue;
    try {
      value = (await Storage.getItem(key)) || defaultValue;
    } catch (e) {
    } finally {
      updateStorageValue(value);
      setLoadingStorage(false);
    }
  }

  async function updateStorage(
    newValue: any,
    expiredTime: number | null = null
  ) {
    try {
      if (newValue === null) {
        await Storage.removeItem(key);
        return;
      }

      const value = JSON.stringify(newValue);
      await Storage.setItem(key, value, expiredTime);
    } catch (e) {
    } finally {
      updateStorageValue(newValue);
    }
  }

  useEffect(() => {
    getStorageValue();
  }, [updated]);

  useEffect(() => {
    getStorageValue();
  }, [key]);

  return { storageValue, updateStorage, loadingStorage };
};
