import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Text, View } from 'native-base';
import { RFValue } from 'react-native-responsive-fontsize';
import { IconButton } from 'react-native-paper';
import Modal from 'react-native-modal';
import { CouponsType } from '@types/CouponsType';

import { styles } from './styles';
import { Coupon } from '../Coupon';

type ModalRulesProps = {
  open: boolean;
  onClose: any;
  item?: CouponsType;
}

export const ModalRules: React.FC<ModalRulesProps> = ({
  open,
  onClose,
  item
}) => {
  const [modalVisible, setModalVisible] = useState(open);

  function closeModal() {
    onClose(false);
    setModalVisible(false);
  }

  useEffect(() => {
    setModalVisible(open);
  }, [open]);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        closeModal();
      }
    }
    if (Platform.OS == "web") {
      document.addEventListener('keydown', handleEscapeKey);
      return () => document.removeEventListener('keydown', handleEscapeKey);
    }

  }, []);

  return (
    <>
      <Modal
        animationIn="slideInRight"
        animationOut="slideOutRight"
        onBackdropPress={closeModal}
        backdropOpacity={0.4}
        coverScreen={true}
        isVisible={modalVisible}
        style={styles.modalStyle}
      >
        <View style={styles.container}>
          <View position={'relative'}>
            <View style={styles.containerHeader}>
              <IconButton
                icon={"arrow-left"}
                color="#74BF48"
                centered={true}
                size={RFValue(7)}
                onPress={() => {
                  closeModal();
                }}
              />
              <Text style={styles.title}>Regras do cupom</Text>
            </View>
            <View style={styles.closeButtonCircle}>
              <IconButton
                icon={"close"}
                color="#74BF48"
                centered={true}
                size={RFValue(7)}
                onPress={() => {
                  closeModal();
                }}
              />
            </View>
            {item && <Coupon item={item} />}
          </View>
        </View>
      </Modal>
    </>
  );
};