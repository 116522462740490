import * as React from "react";
import { NativeBaseProvider } from "native-base";

import Routes from "../routes";
import { AuthProvider } from "../contexts/Auth";
import { THEME } from "../theme";
import { CustomerProvider } from "../contexts/CustomerContext";
import { FilterProvider } from "../contexts/FilterContext";
import { colorModeManager } from "@helpers/colorModeManager";

const AppScreen = () => {

  return (
    <AuthProvider>
      <FilterProvider>
        <CustomerProvider>
          <NativeBaseProvider theme={THEME} colorModeManager={colorModeManager}>
            <Routes />
          </NativeBaseProvider>
        </CustomerProvider>
      </FilterProvider>
    </AuthProvider>
  );
};

export default AppScreen;
