import React from 'react';
import { FontAwesome } from '@expo/vector-icons';

import Label from '@components/Label';
import { RequiredTypeEnum } from '@enums/RequiredTypeEnum';
import { CommercialPolicyProductsType } from '@types/CommercialPolicyProductsType';

type Props = {
  commercialPolicyProduct: CommercialPolicyProductsType;
}

export function ProductRequirementLabel({ commercialPolicyProduct }: Props) {
  if (commercialPolicyProduct.requiredType == RequiredTypeEnum.REQUIRED) {
    return (
      <Label
        text='Item obrigatório'
        bg='red.500'
        borderTopRadius={5}
      />
    );
  }

  if (commercialPolicyProduct.requiredType == RequiredTypeEnum.REQUIRED_BASKET) {
    return (
      <Label
        icon={<FontAwesome name='shopping-basket' size={12} color='white' />}
        text='Item da cesta'
        borderTopRadius={5}
      />
    );
  }

  return <></>;
};