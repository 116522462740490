import { gql, makeVar, useQuery } from "@apollo/client";
import { CustomersByUserFilterType } from "../types/CustomersByUserFilterType";
import { SortEnum } from "@enums/SortEnum";
import { CustomerByUserType } from "@types/CustomerByUserType";
import { useAdditionalCustomers } from "./useAdditionalCustomers";

export type CustomerByUserTypeEdited = {
  isChecked: boolean
} & CustomerByUserType

const LOAD_USER_DATA = gql`
  query (
    $activeOnly: Boolean
    $filter: Retail_CustomersByUserIdFilter
    $offset: Int
    $sort: Retail_CustomersByUserIdOrderBy
  ) {
    Retail_customersByUser(
      activeOnly: $activeOnly
      filter: $filter
      first: 20
      offset: $offset
      sort: $sort
    ) {
      code
      companyName
      phoneNumber
      businessName
      address {
          street
          complement
          zipCode
          latitude
          longitude
          city {
            name
            state {
              name
              abbreviation
            }
          }
          neighborhood
      }
      group {
        description
      }
    }
  }
`;

export const customersList = makeVar<CustomerByUserTypeEdited[]>([]);

const useCustomersByUser = (
  activeOnly: boolean = true,
  filter: CustomersByUserFilterType,
  offset = 0,
  cartId?: string,
  skip: boolean = false,
) => {
  const { generateCheckedCustomers } = useAdditionalCustomers();
  const sort = {
    companyName: SortEnum.ASC
  }

  return useQuery(LOAD_USER_DATA, {
    variables: {
      activeOnly,
      filter,
      offset,
      sort,
    },
    fetchPolicy: "cache-first",
    skip: skip,
    onCompleted: async (data) => {
      const customers: CustomerByUserTypeEdited[] = await generateCheckedCustomers(cartId || '', data.Retail_customersByUser);
      customersList(customers);
    }
  });
};

export const useCustomersById = () => {
  return { useCustomersByUser };
};
