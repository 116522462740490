import { PriceProps } from "@components/ProductPrice";
import helpers from "@helpers/NumberHelper";
import { Text } from "native-base";

export const Price: React.FC<PriceProps> =
  ({ price, ...restProps }) => {
    return (
      <Text
        fontWeight={700}
        color={"muted.700"}
        _dark={{
          color: "muted.300",
        }}
        fontSize={["xs", "xs", "xs", "sm"]}
        {...restProps}
      >
        R${" "}
        {helpers.currency(price, 2)}
      </Text>
    )
  };