import React from "react";
import {
  createNativeStackNavigator
} from "@react-navigation/native-stack";
import { HeaderBar } from "@components/HeaderBar";
import LoggedRoute from "./Logged.routes";

const AppStack = createNativeStackNavigator();

const AppRoutes = () => {
  return (
    <AppStack.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <HeaderBar navigation={props.navigation} />,
      }}
    >
      {LoggedRoute()}
    </AppStack.Navigator>
  );
};

export default AppRoutes;
