import React from "react";
import { Stack, CloseIcon, Heading } from 'native-base';

type Props = {
  consoleError?: string;
}

export function UnexpectedError({ consoleError }: Props) {
  if (consoleError) {
    console.log(consoleError);
  }

  return (
    <Stack
      testID="unexpected-error"
      flex={1}
      direction={["column", "row"]}
      alignItems="center"
      justifyContent="center"
    >
      <CloseIcon size={[8, 10]} color="red.500" />

      <Heading
        ml={[0, 4]}
        mt={[3, 0]}
        fontSize={["sm", "md"]}
        fontFamily="heading"
        color="muted.800"
      >
        {"Ocorreu um erro inesperado.\nPor favor, tente novamente."}
      </Heading>
    </Stack>
  );
};