
import React, { useEffect, useState } from "react";
import { ScrollView, Skeleton, View } from "native-base";
import { useCoupon } from "@hooks/useCoupon";
import { ItemCoupon } from "@components/ModalCoupon/ItemCoupon";
import { CouponActionsheet } from "@components/ModalCoupon/CouponActionsheet";
import { CouponsType } from "@types/CouponsType";

export const Coupons = () => {
  const { coupons, loading } = useCoupon();
  const [activeModalRules, setActiveModalRules] = useState(false);
  const [coupon, setCoupon] = useState<CouponsType>();

  const onClickRules = (item: CouponsType) => {
    setCoupon(item);
    setActiveModalRules(true);
  }
  const onPressCloseModalRules = () => {
    setCoupon(undefined);
    setActiveModalRules(false);
  }


  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      px={3}
      mt={6}
      mb={6}
    >
      <View
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Skeleton px="4" mb="4" rounded="md" h={"16"} startColor="muted.400" isLoaded={!loading}>
          <ItemCoupon coupons={coupons} onClickRules={onClickRules} activeRedeemCoupon={false} />
        </Skeleton>
        {[0, 1, 2, 4, 5, 6, 7].map((id) => <Skeleton key={id} px="4" mb="4" h={"16"} rounded="md" startColor="muted.400" isLoaded={!loading} />)}
      </View>
      {coupon && <CouponActionsheet
        visible={activeModalRules}
        coupon={coupon}
        onClose={onPressCloseModalRules}
      />}
    </ScrollView>
  );
};
