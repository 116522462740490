import React, { useCallback, useState } from "react";
import { useNavigation } from "@react-navigation/native"
import { useReactiveVar } from "@apollo/client";

import { Card } from "@components/Card";
import { industryVar, useIndustry } from "@hooks/useIndustry";
import { Loading } from "@components/Loading";
import { Button } from "../Button";
import { User } from "@contexts/Auth";
import { AspectRatio, Box, Center, IBoxProps, Image } from "native-base";
import { Config } from "@configs/Config";

type IndustryCardProps = IBoxProps & {
  user: User
}

export const IndustryCard: React.FC<IndustryCardProps> = ({ user, ...restProps }) => {
  const navigation = useNavigation()

  const onRedirectTo = useCallback((): void => {
    navigation.navigate('ChooseIndustry')
  }, [user])

  const name = user.getNameIndustryStore();

  const [isImageFound, setIsImageFound] = useState(true);
  const imageUrl = `${Config.URL_CDN_BASE}/${name.toUpperCase()}/logo.jpg`;

  const renderIndustryImage = () => {
    return (
      <Image
        testID={`${name}-image`}
        source={{ uri: imageUrl }}
        defaultSource={{ uri: imageUrl }}
        alt={`Imagem da indústria ${name}`}
        resizeMode='contain'
        onError={() => {
          setIsImageFound(false);
        }}
      />
    );
  }

  const renderDefaultImage = () => {
    return (
      <Center
        testID={`${name}-default-image`}
        bg='primary.600'
        rounded='md'
        _text={{
          fontSize: 'sm',
          fontFamily: 'heading',
          color: 'white'
        }}
      >
        {name}
      </Center>
    )
  }

  return <Box {...restProps}>
    <AspectRatio
      w='full'
      maxH={8}
      ratio={16 / 9}
      mt={2}
    >
      {isImageFound ? renderIndustryImage() : renderDefaultImage()}
    </AspectRatio>
    <Button onPress={onRedirectTo} text={'Alterar indústria'} />
  </Box >
}