import { StyleSheet } from 'react-native';
import { width, height } from "../Dimension";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    paddingTop: height * 0.0008,
  },
  imageBkg: {
    width: width * 1,
    position: "absolute",
    height: width * 0.125,
    top: 0,
    left: 0,
  },
  carouselContainer: {
    maxWidth: "100%",
    height: width * 0.24,
    minHeight: width * 0.24,
  },
  pagination: {
    borderRadius: 2,
    width: width * 0.032,
    height: height * 0.008,
    marginTop: width * 0.024,
    marginLeft: 0,
    marginRight: width * 0.006,
  },
  image: {
    height: width * 0.24,
    width,
  },
});