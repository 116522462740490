import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Actionsheet, CloseIcon, Factory, HStack, Text } from 'native-base';

import {
  useScheduleModal
} from '@hooks/useScheduleModal';
import { CartIcon } from '@assets/images/svgs/CartIcon';

const TouchableOpacityFactory = Factory(TouchableOpacity);

type MobileProps = {
  children: React.ReactNode,
}

export function Mobile({ children }: MobileProps) {
  const scheduleModal = useScheduleModal();
  return (
    <>
      <Actionsheet isOpen={scheduleModal.isOpen} onClose={scheduleModal.onClose} hideDragIndicator testID='schedule-mobile'>
        <Actionsheet.Content
          p={4}
          borderTopRadius='xl'
          _dark={{
            bg: 'muted.800'
          }}
        >
          <TouchableOpacityFactory alignSelf='end' onPress={scheduleModal.onClose}>
            <CloseIcon size={4} color='primary.600' />
          </TouchableOpacityFactory>
          <HStack
            w='100%'
            alignItems='center'
            pb={4}
            borderBottomWidth={1}
            borderBottomColor='muted.300'
          >
            <CartIcon size={6} />
            <Text
              ml={4}
              flex={1}
              fontSize='md'
              fontFamily='subheading'
              color='success.700'
            >
              Data de envio do pedido
            </Text>
          </HStack>
          {children}
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
}