import React, { useCallback, useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";
import { View, FlatList, Factory, Text } from "native-base";
import { Loader } from "@components/Loader";
import { CustomerByUserTypeEdited, customersList, useCustomersById } from "@hooks/useCustomersById";
import { useReactiveVar } from "@apollo/client";
import { useAuth } from "@contexts/Auth";
import { ItemListManageCustomers } from "@components/ItemListManageCustomers";
import { useAdditionalCustomers } from '@hooks/useAdditionalCustomers';
import { useCustomer as useCustomerContext } from '@contexts/CustomerContext';
import { useShoppingCart } from "@hooks/useShoppingCart";
import { useFilterCustomer } from "@hooks/useFilterCustomer";
import { cloneDeep } from "@apollo/client/utilities";

const ITEMS_PER_PAGE = 20;

type ManageCustomersListProps = {
  cartId: string;
  searchText: string;
  isMobile: boolean;
  refreshData: boolean;
}

export const ManagerCustomersList = ({ cartId, searchText, isMobile, refreshData }: ManageCustomersListProps) => {
  const { user } = useAuth();
  const nameIndustry = user.getNameIndustryStore();
  let customers: CustomerByUserTypeEdited[] = useReactiveVar(customersList);
  const [data, setData] = useState<CustomerByUserTypeEdited[]>([]);
  const [canUpdateList, setCanUpdateList] = useState(true);
  const [offset, setOffset] = useState(0);
  const { height } = useWindowDimensions();
  const { useCustomersByUser } = useCustomersById();
  const { customerCode } = useCustomerContext();
  const { loading: loadingCart } = useShoppingCart();
  const { customerFilter } = useFilterCustomer();
  const { generateCheckedCustomers } = useAdditionalCustomers();

  let heightScrollView = (height - 328).toString() + 'px';
  const marked = customers.filter(cus => cus.isChecked);
  const {
    data: customersData,
    refetch: customersRefetch,
    loading: customersLoading,
    fetchMore,
  } = useCustomersByUser(true, {
    industry: {
      name: nameIndustry,
    },
  },
    0,
    cartId,
    loadingCart,
  );

  const press = (item: CustomerByUserTypeEdited) => {
    data.find((itemList) => {
      if (itemList.code === item.code) {
        itemList.isChecked = item.isChecked;
      }
    })
    const finalData = data.filter(item => item.code != customerCode);
    customersList(finalData);

    setData(finalData)
  }

  const renderItem = ({ item, index }: { item: CustomerByUserTypeEdited, index: number }) => {
    return (
      <ItemListManageCustomers type={isMobile ? "MOBILE" : "WEB"} item={item} press={press} index={index} />
    )
  }

  const handleEndList = async () => {
    if (!customersLoading && canUpdateList && !searchText) {
      fetchMore({
        variables: {
          offset: offset + ITEMS_PER_PAGE,
        },
      }).then(async result => {
        if (result.data.Retail_customersByUser.length == 0) setCanUpdateList(false);

        setOffset(offset + ITEMS_PER_PAGE);

        let incomingData = await generateCheckedCustomers(
          cartId,
          [
            ...result.data.Retail_customersByUser as CustomerByUserTypeEdited[]
          ]
        );

        let finalData = [...data, ...incomingData];

        finalData = finalData.filter(item => item.code != customerCode);
        setData(finalData);
      });
    }
  }

  const handleSearch = async (value: string) => {
    const searchFilter = value.trim().toLowerCase();
    customerFilter.search = searchFilter;

    const { data: dataRefetch } = await customersRefetch({
      activeOnly: true,
      filter: {
        ...customerFilter,
        industry: {
          name: nameIndustry,
        },
      },
      offset: 0,
    });

    const filteredCustomers = cloneDeep(dataRefetch?.Retail_customersByUser);
    const formattedData = await formatCustomer(filteredCustomers);
    const finalData = formattedData.filter(item => item.code != customerCode);

    setData(finalData);
  };

  const formatCustomer = async (customers: CustomerByUserTypeEdited[]) => {
    if (!customers) {
      return [];
    }

    const formattedCustomers: CustomerByUserTypeEdited[] = await generateCheckedCustomers(
      cartId,
      customers
    );

    return formattedCustomers;
  }

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    const customersWithoutTarget = customers.filter(item => item.code != customerCode);
    setData(customersWithoutTarget);

    if (refreshData) {
      customersRefetch()
    }
  }, [refreshData, loadingCart])

  useEffect(() => {
    generateCheckedCustomers(cartId, customers);
    const customersWithoutTarget = customers.filter(item => item.code != customerCode);
    setData(customersWithoutTarget);
  }, [cartId]);


  if (loadingCart || !cartId) {
    return (
      <View h={isMobile ? heightScrollView : '476px'}>
        <Loader />
      </View>
    )
  }

  return (
    <>
      {isMobile && (
        <FlatList
          key={user.getUuid() + marked.length}
          mt={3}
          extraData={customers}
          data={data}
          renderItem={renderItem}
          onEndReached={handleEndList}
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
          h={heightScrollView}
        />
      )}

      {!isMobile && (
        <FlatList
          key={user.getUuid()}
          mt={3}
          borderColor={'muted.300'}
          borderRadius={10}
          borderWidth={1}
          rounded={10}
          extraData={customers}
          data={data}
          renderItem={renderItem}
          onEndReached={handleEndList}
          showsVerticalScrollIndicator={true}
          onEndReachedThreshold={1}
        />
      )
      }
    </>
  )
}