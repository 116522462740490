import { Dimensions, StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  textProduct2: {
    marginLeft: width * 0.01, // 20,
  },
  title: {
    fontWeight: "400",
    fontSize: RFValue(6),
    color: "#FFFFFF",
    cursor: "pointer",
    marginRight: "auto",
    flex: 5.6,
  },
});
