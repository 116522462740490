import React from "react";
import { Center, Flex, View, Text } from "native-base";
import { Asset } from "expo-asset";
import { DiscountBrandType } from "@types/BrandType";
import { DateConvert } from "@helpers/Date";
import { Card } from "@components/Card";

type DiscountRateSmallBannerProps = {
  discountsRange: DiscountBrandType[];
  endValidityDate: Date | string;
}

export const DiscountRateSmallBanner = ({ discountsRange, endValidityDate }: DiscountRateSmallBannerProps) => {
  const asset = Asset.fromURI(
    require("../../../../assets/images/progressiveBannerDefault.png")
  );

  const renderDiscountTag = (discount: DiscountBrandType) => {
    return (
      <Center key={discount.value} flexDir="row">
        <Text
          isTruncated
          fontSize={'xs'}
          color={'white'}
        >
          {discount.amount} produtos:   {discount?.value}% de desconto
        </Text>
      </Center >
    );
  };

  return (
    <Center
      backgroundColor={'orangeCharts.500'}
      flex={1}
      justifyContent={'space-between'}
      flexDir={'row'}
      borderBottomRadius={3}
      alignItems={'flex-end'}
    >
      <Flex flex={1.2} w={'60%'} direction="column" py={2} pl={2} h={'full'} justifyContent={'space-between'}>
        <View flex={0.5} flexDir={'column'} alignSelf={'flex-start'} >
          <Text
            color={'orange.600'}
            fontSize={'md'}
          >
            quanto mais você compra,
          </Text>
          <Text
            fontSize={'md'}
            color={'white'}
          >
            mais você economiza
          </Text>
        </View>
        <View flex={1} justifyContent={'center'} alignItems={'flex-start'}>
          {discountsRange.map((discount: DiscountBrandType) => {
            return renderDiscountTag(discount);
          })}
        </View>
        <Text
          color={'white'}
          fontSize={'2xs'}
        >
          {" "}
          *Válido até {new DateConvert(endValidityDate).getExpirationDate()}
        </Text>
      </Flex>
      <View flex={1} w={'30%'}>
        <Card.Image source={asset.uri} p={0} h={"32"} alt="banner-discount-brand" />
      </View>
    </Center >
  );
};