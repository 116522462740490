import React from 'react';

import { Web } from './Web';
import { Mobile } from './Mobile';

type ResultSendOrderResponsiveOverlayProps = {
  type: 'WEB' | 'MOBILE';
}

export function ResultSendOrderResponsiveOverlay({
  type
}: ResultSendOrderResponsiveOverlayProps) {
  return (
    type === 'WEB' ? (
      <Web />
    ) : (
      <Mobile />
    )
  );
}