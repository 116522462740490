import React from "react";
import { IButtonProps } from "native-base";
import { PointOfSales } from "./Profile/PointOfSales";
import { IndustryEmployee } from "./Profile/IndustryEmployee";
import { Retailer } from "./Profile/Retailer";

const ProfileView = {
  "point_of_sales_buyer": PointOfSales,
  "industry_employee": IndustryEmployee,
  "retailer": Retailer,
}

type TargetSwitcherProps = IButtonProps & {
  profile: string,
  navigation: any
}

export const TargetSwitcher: React.FC<TargetSwitcherProps> = ({ profile, navigation, ...restPros }) => {
  const CurrentView = ProfileView[profile] ?? <></>;
  return (<CurrentView {...restPros} navigation={navigation} />)
};
