import React, { useState } from "react";
import { Box, Button, ChevronDownIcon, ChevronUpIcon, HStack, IBoxProps, PresenceTransition, View } from "native-base";
import { Title, TitleProps } from "./Title";


type ContainerContentProps = IBoxProps & {
  startOpened?: boolean,
  title: string;
  collapsable?: boolean
  _title?: TitleProps
  sideTitle?: string | React.ReactElement | false;
}


export const ContainerContentChevron = (props: ContainerContentProps) => {
  const { startOpened = true, title, collapsable, children, _title, sideTitle, ...rest } = props;
  const [isOpen, setIsOpen] = useState(startOpened);

  const titleContent = <Title {..._title}>{title}</Title>
  let content = (
    <>
      <View
        mt={2}
        mb={6}
      >
        {titleContent}
      </View>
      {children}
    </>
  )

  if (collapsable) {
    content = (
      <>
        <HStack justifyContent={'space-between'} my={2}
        >
          <Button
            p={0}
            onPress={() => setIsOpen(!isOpen)}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            variant={'unstyled'}
            leftIcon={isOpen ? <ChevronUpIcon color='primary.600' /> : <ChevronDownIcon color='primary.600' />}
          >
            {titleContent}
          </Button>

          {sideTitle && sideTitle}
        </HStack>
        <PresenceTransition
          visible={isOpen}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 250
            }
          }}>
          {isOpen && children}
        </PresenceTransition>
      </>
    )
  }

  return (
    <Box flex={1} flexDirection={'column'} {...rest}>
      {content}
    </Box>
  );
};