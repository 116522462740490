
import React, { useCallback } from 'react';
import { Text, Box, IBoxProps, View } from 'native-base';
import { useAuth } from '@contexts/Auth';
import { ProfileIcon } from '@components/More/ProfileIcon';

type ProfileCard = IBoxProps & {}

export const ProfileCard: React.FC<ProfileCard> = ({ ...restPros }) => {
  const { user } = useAuth()

  const name = useCallback((): string => {
    const arrayNames = user.getName()
      .split(' ')
      .map((string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      });

    if (arrayNames.length === 1) {
      return arrayNames[0];
    }

    return `${arrayNames[0]} ${arrayNames[arrayNames.length - 1]}`
  }, [user])

  const nameRole = useCallback((): string => {

    if (user.isIndustryEmployee()) {
      return 'Representante'
    }

    if (user.isRetailer()) {
      return 'Grupo'
    }

    if (user.isTradeManager()) {
      return 'Trade Manager'
    }

    return 'Lojista'
  }, [user])

  return (
    <Box
      flex={1}
      w={'full'}
      borderColor="coolGray.200"
      borderWidth="0"
      borderBottomWidth={2}
      _dark={{
        borderColor: "coolGray.600",
        backgroundColor: "gray.700"
      }}
      px={3}
      py={3}
      justifyContent={"flex-start"}
      alignItems={"center"}
      justifyItems={"flex-start"}
      flexDir={'row'}
      maxH={"32"}
      {...restPros}
    >
      <ProfileIcon
        w={24}
        h={24}
        fontSize={['6xl']}
        rounded={['lg']}
      />
      <View
        ml={4}
      >
        <Text
          fontWeight={'medium'}
          fontSize={'2xl'}
          color={'muted.800'}
          _dark={{
            color: "muted.400"
          }}
        >
          {name()}
        </Text>
        <Text
          fontWeight={'400'}
          fontSize={'md'}
          color={'muted.800'}
          _dark={{
            color: "muted.400"
          }}
        >
          {nameRole()}
        </Text>
      </View>
    </Box>
  );
};
