import React from "react";
import Svg, { Path, Rect } from 'react-native-svg';

export const CheckboxIcon = ({checked}: {checked: boolean}) => {
  return (
    <Svg
      height={20}
      viewBox="0 0 20 20"
      width={20}
    >
      <Rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill={checked? '#69A920' : '#FFFFFF'} stroke={checked ? "#69A920" : "#555555"} />
      {checked && (<Path
          d="M7.40625 15.75L2.21875 10.5625C1.90625 10.25 1.90625 9.71875 2.21875 9.40625L3.34375 8.28125C3.65625 7.96875 4.15625 7.96875 4.46875 8.28125L8 11.7812L15.5 4.28125C15.8125 3.96875 16.3125 3.96875 16.625 4.28125L17.75 5.40625C18.0625 5.71875 18.0625 6.25 17.75 6.5625L8.5625 15.75C8.25 16.0625 7.71875 16.0625 7.40625 15.75Z"
          fill="white"
      />)}
      
    </Svg>
);
}