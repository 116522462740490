import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { THEME } from "../../../theme";

const { width, height } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  containerTitle: {
    flexDirection: "row",
  },
  containerFilter: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  searchBar: {
    width: width * 0.22,
    height: width * 0.03,
    fontSize: RFValue(8),
  },
  title: {
    fontFamily: THEME.fonts["body"],
    fontWeight: "600",
    fontSize: RFValue(8),
    alignSelf: "center",
  },
  subTitle: {
    fontFamily: THEME.fonts["body"],
    fontSize: RFValue(7),
    fontWeight: "600",
    marginBottom: width * 0.01,
  },
  paginationButton: {
    borderRadius: Math.round(width + height) / 2,
    backgroundColor: THEME.colors.gray[300],
    width: width * 0.01,
    height: width * 0.01,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationButtonActive: {
    backgroundColor: THEME.colors.green[300],
  },
  textDescription: {
    fontFamily: THEME.fonts["body"],
    fontWeight: "400",
    fontSize: RFValue(8),
    color: THEME.colors.gray[500],
    marginBottom: width * 0.007, // 10,
  },
});
