import React from "react";
import { TouchableOpacity } from "react-native";
import {
  Actionsheet,
  CloseIcon,
  Factory,
  HStack,
  Text,
  VStack,
} from "native-base";

import { CartIcon } from "@assets/images/svgs/CartIcon";

import { useSendOrderResponsiveOverlay } from "@hooks/useSendOrderResponsiveOverlay";
import { useShoppingCart } from "@hooks/useShoppingCart";

const TouchableOpacityFactory = Factory(TouchableOpacity);

type DateSchedule = {
  date: Date | string;
  period: string;
};

type Props = {
  handleSendOrder: () => Promise<void>;
  dateSchedule?: DateSchedule;
};

export function Mobile({ handleSendOrder, dateSchedule }: Props) {
  const { isOpen, onClose } = useSendOrderResponsiveOverlay();
  const { cart } = useShoppingCart();

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} hideDragIndicator>
      <Actionsheet.Content
        p={4}
        borderTopRadius="xl"
        _dark={{
          bg: "muted.800",
        }}
      >
        <HStack
          w="100%"
          alignItems="center"
          pb={4}
          borderBottomWidth={1}
          borderBottomColor="muted.300"
        >
          <CartIcon size={6} />

          <Text
            ml={4}
            flex={1}
            fontSize="md"
            fontFamily="subheading"
            color="success.700"
          >
            Enviar pedido
          </Text>

          <TouchableOpacityFactory alignSelf="start" onPress={onClose}>
            <CloseIcon size={3} color="primary.600" />
          </TouchableOpacityFactory>
        </HStack>

        <VStack mt={4} w="full">
          <Text fontSize="sm" color="muted.800">
            Após o envio, seu pedido não poderá mais ser{"\n"}
            alterado.
          </Text>

          <HStack mt={5}>
            <Text fontSize="sm" color="muted.800">
              ID da solicitação:
            </Text>

            <Text fontSize="sm" color="muted.700">
              {" "}
              {cart?.code}
            </Text>
          </HStack>

          {dateSchedule && (
            <HStack mt={5} mb={12}>
              <Text fontSize="sm" color="muted.800">
                Período de envio:
              </Text>
              <Text fontSize="sm" color="muted.700">
                {" "}
                {`${dateSchedule.date} às ${dateSchedule.period} hr`}
              </Text>
            </HStack>
          )}
          <TouchableOpacityFactory
            testID="send-order-overlay-mobile_send"
            bg="primary.600"
            rounded="sm"
            py={4}
            px={8}
            mb={3}
            alignItems="center"
            justifyContent="center"
            _dark={{
              opacity: 0.8,
            }}
            onPress={handleSendOrder}
          >
            <Text fontSize="sm" fontFamily="subheading" color="muted.100">
              Enviar
            </Text>
          </TouchableOpacityFactory>

          <TouchableOpacityFactory
            testID="send-order-overlay-mobile_cancel"
            rounded="sm"
            py={4}
            px={8}
            alignItems="center"
            justifyContent="center"
            _dark={{
              opacity: 0.7,
            }}
            onPress={onClose}
          >
            <Text fontSize="sm" fontFamily="subheading" color="primary.600">
              Cancelar
            </Text>
          </TouchableOpacityFactory>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
}
