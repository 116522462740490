import {
  SSO_URL,
  SSO_OAUTH_SCOPES,
  SSO_OAUTH_CLIENT_ID,
  GATEWAY_URL,
  DELAY_BANNER_CAROUSEL,
  URL_CDN_BASE,
  NUMBER_POSSIBLES_WHOLESALER_SELECTED,
  NUMBER_POSSIBLES_WHOLESALER_AVAILABLE,
  TOTAL_PRODUCTS_EXHIBITED,
  CART_STORAGE_EXPIRATION,
  PREFERRED_WHOLESALER_STORAGE_EXPIRATION,
  TOTAL_ORDER_EXHIBITED,
  GOOGLE_TAG_MANAGER_CODE,
  FT_KUPONO_ENABLED,
  FT_OFFERS_BY_BRAND_TAB_ENABLED,
  FT_COMBO_TAB_ENABLED,
  FT_SUGGESTED_PRODUCTS_TAB_ENABLED,
  COUPONS_STORAGE_EXPIRATION,
  RENDER_MOBILE_ENABLED,
  FT_ADDITIONAL_CUSTOMERS,
  SSO_REDIRECT_URI,
} from "react-native-dotenv";

export const Config = {
  SSO_URL: SSO_URL || window?.environment?.SSO_URL || "",
  SSO_OAUTH_SCOPES:
    SSO_OAUTH_SCOPES || window?.environment?.SSO_OAUTH_SCOPES || "",
  SSO_OAUTH_CLIENT_ID:
    SSO_OAUTH_CLIENT_ID || window?.environment?.SSO_OAUTH_CLIENT_ID || "",
  GATEWAY_URL: GATEWAY_URL || window?.environment?.GATEWAY_URL || "",
  URL_CDN_BASE: URL_CDN_BASE || window?.environment?.URL_CDN_BASE || "",
  DELAY_BANNER_CAROUSEL:
    DELAY_BANNER_CAROUSEL || window?.environment?.DELAY_BANNER_CAROUSEL || "",
  NUMBER_POSSIBLES_WHOLESALER_SELECTED:
    NUMBER_POSSIBLES_WHOLESALER_SELECTED ||
    window?.environment?.NUMBER_POSSIBLES_WHOLESALER_SELECTED ||
    "",
  NUMBER_POSSIBLES_WHOLESALER_AVAILABLE:
    NUMBER_POSSIBLES_WHOLESALER_AVAILABLE ||
    window?.environment?.NUMBER_POSSIBLES_WHOLESALER_AVAILABLE ||
    "",
  TOTAL_PRODUCTS_EXHIBITED:
    TOTAL_PRODUCTS_EXHIBITED ||
    window?.environment?.TOTAL_PRODUCTS_EXHIBITED ||
    "",
  CART_STORAGE_EXPIRATION:
    CART_STORAGE_EXPIRATION ||
    window?.environment?.CART_STORAGE_EXPIRATION ||
    60 * 60,
  PREFERRED_WHOLESALER_STORAGE_EXPIRATION:
    PREFERRED_WHOLESALER_STORAGE_EXPIRATION ||
    window?.environment?.PREFERRED_WHOLESALER_STORAGE_EXPIRATION ||
    60 * 60,
  TOTAL_ORDER_EXHIBITED:
    TOTAL_ORDER_EXHIBITED || window?.environment?.TOTAL_ORDER_EXHIBITED || "20",
  GOOGLE_TAG_MANAGER_CODE:
    GOOGLE_TAG_MANAGER_CODE ||
    window?.environment?.GOOGLE_TAG_MANAGER_CODE ||
    "",
  FT_KUPONO_ENABLED:
    FT_KUPONO_ENABLED == 1 ||
    window?.environment?.FT_KUPONO_ENABLED == 1 ||
    false,
  FT_OFFERS_BY_BRAND_TAB_ENABLED:
    FT_OFFERS_BY_BRAND_TAB_ENABLED == 1 ||
    window?.environment?.FT_OFFERS_BY_BRAND_TAB_ENABLED == 1 ||
    false,
  FT_COMBO_TAB_ENABLED:
    FT_COMBO_TAB_ENABLED == 1 ||
    window?.environment?.FT_COMBO_TAB_ENABLED == 1 ||
    false,
  FT_SUGGESTED_PRODUCTS_TAB_ENABLED:
    FT_SUGGESTED_PRODUCTS_TAB_ENABLED == 1 ||
    window?.environment?.FT_SUGGESTED_PRODUCTS_TAB_ENABLED == 1 ||
    false,
  COUPONS_STORAGE_EXPIRATION:
    COUPONS_STORAGE_EXPIRATION ||
    window?.environment?.COUPONS_STORAGE_EXPIRATION ||
    60 * 60,
  RENDER_MOBILE_ENABLED:
    RENDER_MOBILE_ENABLED == 1 ||
    window?.environment?.RENDER_MOBILE_ENABLED == 1 ||
    false,
  FT_ADDITIONAL_CUSTOMERS:
    FT_ADDITIONAL_CUSTOMERS == 1 ||
    window?.environment?.FT_ADDITIONAL_CUSTOMERS == 1 ||
    false,
  SSO_REDIRECT_URI: SSO_REDIRECT_URI || window?.environment?.SSO_REDIRECT_URI || "",
};
