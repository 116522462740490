import React, { useState } from "react";
import { View, Text, } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";
import { DataTable, IconButton } from "react-native-paper";
import { DataTableHeader } from "../../contracts/DataTableHeader";
import { styles } from "./styles";

export const EnhancedDataTable = (
  headers: DataTableHeader[] = [],
  rows: any[] = [],
  fontSize: number = 6
) => {
  return (
    <DataTable key={String(Math.random())}>
      {headers.length > 0 && (
        <DataTable.Header style={styles.headerColor}>
          {headers
            .filter((header) => {
              return header.visibility !== false;
            })
            .map((header) => {
              return (
                <DataTable.Title key={header.name} style={[{ maxWidth: header.size + "%" }]}>
                  <Text style={styles.headerText}>{header.value}</Text>
                </DataTable.Title>
              );
            })}
        </DataTable.Header>
      )}

      {rows.length > 0 &&
        rows.map((row) => {
          const columnSize =
            100 /
            headers.filter((header) => {
              return header.visibility !== false;
            }).length;
          return (
            <DataTable.Row key={String(Object.values(row)[0]) + Math.random()}>
              {Object.entries(row).filter((entry) => {
                const [key, value] = entry;
                const header = headers.find(header => header.name == key);
                return header?.visibility;
              }).map((entry: any) => {
                const [key, value] = entry;
                return (
                  <View
                    key={String(value) + Math.random()}
                    style={[{ width: value[1] + "%" }]}
                  >
                    <Text style={[styles.dataText, { fontSize: RFValue(fontSize) }]}> {value[0]} </Text>
                  </View>
                );
              })}
            </DataTable.Row>
          );
        })}
    </DataTable>
  );
};