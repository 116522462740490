import { gql, useQuery } from "@apollo/client";
import { filter } from "@components/FastAccessCard/DynamicFilter";
import { User, useAuth } from "@contexts/Auth";
import { TablesFilterEnum } from "@enums/TablesFilterEnum";

interface userFilterProductsInterface  {
    industryCode: string,
    tablesFilter :TablesFilterEnum[]
}

export const FILTER_PRODUCTS = gql`
    query GetProductsFilter(
        $industryCode: String!,
        $tablesFilter: [TablesFilterEnum!]!,
    ){
        getProductsFilter(filter:{
            industryCode: $industryCode,
            tablesFilter: $tablesFilter
        }){
          type
          label
          value
        }
    }
    `;
export const useFilterProducts = ({ industryCode, tablesFilter }: userFilterProductsInterface) => {
    const { user } = useAuth();
    const assortmentsUser = user.getAssortments().map((assortment) => Number(assortment.id))

    const { data, loading, error } = useQuery(FILTER_PRODUCTS, {
        variables: {
            industryCode,
            tablesFilter
        }
    })

    let assortments: filter[] = []
    let division_products: filter[] = []
    let families: filter[] = []

    if(!loading && typeof error === 'undefined') {
        assortments = data?.getProductsFilter.filter((item:filter) => item.type == 'assortment')
        division_products = data?.getProductsFilter.filter((item:filter) => item.type == 'product_division')
        families = data?.getProductsFilter.filter((item:filter) => item.type == 'family')
    }

    if(assortmentsUser.length > 0) {
        assortments = assortments.filter( (assortment:filter) => assortmentsUser.includes(Number(assortment.value)));
    }

    return {
        error: error,
        loading: loading,
        data: {
            assortments,
            division_products,
            families 
        }
    }
}