import React from 'react';
import { useTheme } from 'native-base';
import Svg, { Path, Rect } from 'react-native-svg';

export const Time = ({ size }: { size: number }) => {
  const { colors } = useTheme();

  return (
    <Svg
      height={size * 4}
      width={size * 4}
      viewBox="0 0 80 80"
    >
      <Rect width="80" height="80" rx="32" fill={'#FFFFFF'} />
      <Path opacity="0.4" d="M59.5001 40.0001C59.5001 50.7697 50.7697 59.5001 40.0001 59.5001C29.2306 59.5001 20.5001 50.7697 20.5001 40.0001C20.5001 29.2305 29.2306 20.5001 40.0001 20.5001C50.7697 20.5001 59.5001 29.2305 59.5001 40.0001Z" fill="#AE7300" stroke="#AE7300" />
      <Path d="M46.9375 47.2241L46.9271 47.2171L46.9164 47.2107L40.7166 43.5109C40.7165 43.5108 40.7164 43.5108 40.7164 43.5107C39.3279 42.6811 38.2802 40.8244 38.2802 39.2201V31.0201C38.2802 30.4762 38.7363 30.0201 39.2802 30.0201C39.824 30.0201 40.2802 30.4762 40.2802 31.0201V39.2201C40.2802 39.6985 40.4717 40.2304 40.723 40.6723C40.9735 41.1126 41.332 41.5493 41.7463 41.7909L47.9439 45.4895L47.9439 45.4895L47.9482 45.492C48.4163 45.7651 48.5744 46.3699 48.2921 46.8616C48.0827 47.1918 47.7449 47.3601 47.4202 47.3601C47.2399 47.3601 47.0809 47.3197 46.9375 47.2241Z" fill="#AE7300" stroke="#AE7300" />
    </Svg>
  );
}