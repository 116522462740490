import React from "react";
import { useWindowDimensions } from "react-native";
import { View, FlatList} from "native-base";
import { useAuth } from "@contexts/Auth";
import { ItemListValidateCustomers } from "@components/ItemListValidateCustomers";
import { AdditionalCustomersType } from "@types/AdditionalCustomersType";

type ValidateCustomersListProps = {
  customers: AdditionalCustomersType[];
  data: AdditionalCustomersType[];
  isMobile: boolean
}

export const ValidateCustomersList = ({customers, data, isMobile}: ValidateCustomersListProps) => {
  const { user } = useAuth();
  const { height } = useWindowDimensions();
  let heightScrollView = (height-328).toString() + 'px';

  const renderItem = ({item, index}: {item: AdditionalCustomersType, index: number}) => { 
    return (
      isMobile ? 
      <ItemListValidateCustomers type={'MOBILE'} item={item} /> :
      <ItemListValidateCustomers type={'WEB'} item={item} index={index}/>
    )
  }

  return (
    <>
      {isMobile && (
        <FlatList 
          key={user.getUuid()}
          mt={3}
          extraData={customers}
          data={data}
          renderItem={renderItem} 
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => <View style={{height: 20 }} />}
          h={heightScrollView}
        />
      )}
      
      {!isMobile && (
        <FlatList 
          key={user.getUuid()}
          mt={3}
          borderColor={'muted.300'}
          borderRadius={10}
          borderWidth={1}
          rounded={10}
          extraData={customers}
          data={data}
          renderItem={renderItem} 
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.5}
          h={'476px'}
        />
      )}
    </>
  )
}