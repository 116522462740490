import React, { useEffect, useState } from "react";
import { Modal, Text, Flex, View, Button, Icon } from "native-base";
import { Image } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { ProductType } from "@types/ProductType";

import { styles } from "./styles";
import Ionicons from "react-native-vector-icons/Ionicons";
import {
  WholesalerPriceCard,
  WholesalerPriceProps,
} from "@components/WholesalerPriceCard";
import { height, width } from "@components/Dimension";
import { THEME } from "@theme/index";
import { usePricesByShippingOrder } from "@hooks/usePricesByShippingOrder";
import { List } from "../List";
import { useCustomer } from "@contexts/CustomerContext";
import { TagAlert } from "@components/TagAlert";

interface Props {
  isOpen: boolean;
  item: ProductType | undefined;
  onClose: () => void;
}

export function SingleProductModal({ isOpen, item, onClose }: Props) {
  const [modalVisible, setModalVisible] = useState(isOpen);
  const [hasLeaflet, setHasLeaflet] = useState(false);
  const { customerCode } = useCustomer();

  const { data, refetch, error, loading } = usePricesByShippingOrder({
    customerCode,
    productCode: item?.code,
  });

  useEffect(() => {
    setModalVisible(isOpen);
    if (isOpen) {
      async function getLeaflet() {
        let status = await exists(item?.leafletUrl || "");
        setHasLeaflet(status);
      }
      getLeaflet();
    }
  }, [isOpen]);

  function closeModal() {
    onClose();
    setModalVisible(false);
  }

  async function exists(url: string) {
    const result = await fetch(url, { method: "HEAD" });
    return result.status == 200;
  }

  return (
    <Modal isOpen={modalVisible} onClose={() => closeModal()} size="full">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Body>
          <Flex direction="row">
            <View width="40%">
              <Image
                style={styles.image}
                source={{
                  uri: item?.url,
                }}
                resizeMode='contain'
                resizeMethod='scale'
                defaultSource={require("@assets/images/product_not_found.png")}
                alt={item?.description}
              />
            </View>
            <Flex direction="row" flex={1}>
              <View width="100%" height={"100%"}>
                <View maxWidth={"100%"}>
                  <Text style={styles.title}>{item?.description}</Text>
                </View>
                <Text style={styles.ean}>EAN: {item?.code}</Text>
                {item?.shippingBox && (
                  <Flex direction="row">
                    <Ionicons
                      name="alert-circle"
                      size={13}
                      color={THEME.colors.gray["800"]}
                      style={{ marginTop: 3 }}
                    />
                    {"  "}
                    <Text style={styles.shippingBox}>
                      Caixa de embarque (cx.) {item?.shippingBox} unidades
                    </Text>
                  </Flex>
                )}
                {hasLeaflet && (
                  <Flex direction="row" marginTop={"37%"}>
                    <Button
                      backgroundColor={"white"}
                      borderWidth={1}
                      width={"80%"}
                      borderColor={THEME.colors.green[400]}
                      rightIcon={
                        <Ionicons
                          name="md-download"
                          style={{ marginLeft: "70%" }}
                          size={32}
                          color={THEME.colors.green[400]}
                        />
                      }
                      onPress={() => {
                        window.open(item?.leafletUrl, "_blank");
                      }}
                    >
                      <Text style={styles.textBtn}>Baixar Bula</Text>
                    </Button>
                  </Flex>
                )}
              </View>
            </Flex>
            <Text></Text>
          </Flex>
          <Flex>
            <List
              data={data?.pricesByShippingOrder || []}
              error={error}
              loading={loading}
              refetch={() => { }}
              total={data?.pricesByShippingOrder?.length || 0}
              showSearch={false}
              showPagination={false}
              containerTitle={
                <View marginTop={7} marginBottom={3} marginLeft={3}>
                  <Text style={styles.text} fontSize={20}>
                    Preço do distribuidor
                  </Text>
                  <TagAlert>
                    O preço do produto é determinado com base no preço do
                    distribuidor
                  </TagAlert>
                </View>
              }
              renderItem={({ item }: { item: WholesalerPriceProps }) => (
                <View marginRight={2}>
                  <WholesalerPriceCard {...item} />
                </View>
              )}
              keyExtractor={(item) => item.code}
              numColumns={4}
            />
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export default SingleProductModal;