import React from 'react';
import { Text, View, useColorMode } from 'native-base';

import { LinkButton } from '@components/LinkButton';
import { useAuth } from '@contexts/Auth';

type Props = {
  active: number;
  isMobile?: boolean;
}

export function ShopTabs({ active, isMobile = false }: Props) {
  const { colorMode } = useColorMode();
  const { user } = useAuth();
  const industry = user?.getNameIndustryStore();

  const arrayTabs = [
    {
      tab: "Carrinho",
      action: "SummaryOrder",
      index: 1,
    },
    {
      tab: "Distribuidores",
      action: "WholesalerOrder",
      index: 2,
    },
    {
      tab: "Finalização",
      action: "Industry",
      index: 3,
    },
  ];

  return (
    <View
      w={isMobile ? '100%' : 320}
      h={20}
      px={8}
      bg={!isMobile ? 'transparent' : (colorMode === 'dark' ? 'muted.800' : 'white')}
      borderBottomWidth={[2, 0]}
      borderBottomColor={['muted.400', 'none']}
      _dark={{
        borderBottomColor: ['muted.700', 'none']
      }}
    >
      <View>
        <View h={0.5} mt={8} bg="muted.600" />

        <View
          position='absolute'
          w='100%'
          flexDir='row'
          justifyContent='space-between'
          mt={4}
        >
          {arrayTabs.map((item) => {
            const isActiveTab = active === item.index;
            const isClickDisabled = (
              (!isMobile && item.index >= active) ||
              (isMobile && item.index === active + 2)
            );

            return (
              <LinkButton
                testID={item.tab}
                key={item.tab}
                to={{
                  screen: `${item.action}`,
                  params: { industry: industry },
                }}
                w={8}
                h={8}
                bg={isMobile ? 'white' : 'muted.100'}
                borderWidth={2}
                borderColor={isActiveTab ? 'green.300' : 'muted.600'}
                borderRadius='50%'
                alignItems='center'
                justifyContent='center'
                _dark={{
                  bg: isMobile ? 'muted.800' : 'muted.900'
                }}
                _hover={{
                  opacity: 1
                }}
                disabled={isClickDisabled}
              >
                <Text
                  fontSize='md'
                  fontWeight={isActiveTab ? '500' : '400'}
                  color={isActiveTab ? 'green.300' : 'muted.600'}
                >
                  {item.index}
                </Text>

                <Text
                  fontSize='xs'
                  fontWeight={isActiveTab ? '500' : '400'}
                  color={isActiveTab ? 'green.300' : 'muted.600'}
                  position='absolute'
                  bottom={-24}
                >
                  {item.tab}
                </Text>
              </LinkButton>
            )
          })}
        </View>
      </View>
    </View>
  );
};