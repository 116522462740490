import { StyleSheet } from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { width } from "../Dimension";

export const styles = StyleSheet.create({
  containerContentHeader: {
    marginTop: width * 0.035, // 50,
  },

  headerColor: {
    backgroundColor: "#E8E8E8",
  },

  dataText: {

    color: "#1C1C1C",
    textAlign: "left",
    marginTop: width * 0.004,
    marginRight: width * 0.007, // 10,
  },

  maxWidth: {
    fontSize: RFValue(7),
    flexDirection: "row",
    justifyContent: "space-between",
  },

  headerText: {
    fontWeight:"bold", 
    color: "black",

    fontSize: RFValue(6),
  }
});
