import React, { useEffect, useState } from "react";
import {
  createNativeStackNavigator
} from "@react-navigation/native-stack";
import { useAuth, User } from "../contexts/Auth";
import PointOfSalesRoute from "./PointOfSales.routes";
import IndustryEmployeeRoute from "./IndustryEmployee.routes";
import OrderRoute from "./Order.routes";
import { useMediaQuery, } from "native-base";
import { Industry } from "@screens/Industry";
import { HomeBottomScreen } from "./home-bottom.routes";
import { Config } from "@configs/Config";
import { Coupons } from "@screens/Coupons";
import { HeaderBar } from "@components/HeaderBar";
import { CouponsApply } from "@screens/CouponsApply";
import { PrivacyPolicy } from "@screens/PrivacyPolicy";
import RetailerRoute from "./Retailer.routes";
import { TimeBlock } from "@screens/TimeBlock";
import TradeManagerRoute from "./TradeManager.routes";

const AppStack = createNativeStackNavigator();

const LoggedRoute = () => {
  const { user } = useAuth();
  const [isSmallScreen] = useMediaQuery([
    {
      maxWidth: 768,
    },
  ]);
  const [small, setSmall] = useState(false)

  useEffect(() => {
    if (small !== isSmallScreen && Config.RENDER_MOBILE_ENABLED) {
      setSmall(isSmallScreen)

    }
  }, [isSmallScreen])

  return (
    <>
      {renderScreensByUser(user)}
      {small ? MobileStackScreen() : WebStackScreen()}
      {OrderRoute()}
      <AppStack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicy}
        initialParams={{ name: "Política de Privacidade" }}
        options={{
          title: "Política de Privacidade",
          headerShown: true,
          header: (props) => <HeaderBar isStackHeader {...props} />
        }}
      />
      <AppStack.Screen
        name="TimeBlock"
        component={TimeBlock}
        initialParams={{ name: "Tela de bloqueio" }}
        options={{
          title: "Tela de bloqueio",
          headerShown: false,
        }}
      />
    </>
  );
};


function WebStackScreen() {
  return (
    <AppStack.Group>
      <AppStack.Screen
        name="Industry"
        component={Industry}
        options={{ title: "Meu perfil" }}
      />
    </AppStack.Group>
  )
}

function MobileStackScreen() {
  return (
    <AppStack.Group>
      <AppStack.Screen name="Industry" options={{ headerShown: false }} component={HomeBottomScreen} />
      <AppStack.Screen name="Coupons"
        initialParams={{ name: "Cupons Disponíveis" }}
        options={{
          headerShown: true,
          title: "Cupons Disponíveis",
          header: (props) => <HeaderBar isStackHeader hasRightIcon={false} {...props} />
        }}
        component={Coupons}
      />
      <AppStack.Screen name="CouponsApply"
        initialParams={{ name: "Cupons Disponíveis" }}
        options={{
          headerShown: true,
          title: "Cupons Disponíveis",
          header: (props) => <HeaderBar isStackHeader hasRightIcon={false} {...props} />
        }}
        component={CouponsApply}
      />
    </AppStack.Group>
  )
}



const renderScreensByUser = (user: User) => {
  if (user.isPointOfSales()) {
    return PointOfSalesRoute();
  }

  if (user.isIndustryEmployee()) {
    return IndustryEmployeeRoute();
  }

  if (user.isRetailer()) {
    return RetailerRoute();
  }

  if( user.isTradeManager()) {
    return TradeManagerRoute();
  }
};

export default LoggedRoute;
