import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { THEME } from "../../../theme";

export const styles = StyleSheet.create({
  discountText: {
    color: THEME.colors.white,
    fontSize: RFValue(6),
    fontStyle: THEME.fonts["body"],
  },
  bannerHeaderText: {
    textAlign: "center",
    fontStyle: THEME.fonts["body"],
    fontSize: RFValue(8),
    color: THEME.colors.gray[600],
  },
  bannerFooterText: {
    textAlign: "center",
    fontStyle: THEME.fonts["body"],
    fontSize: RFValue(6),
    color: THEME.colors.gray[500],
  },
});
