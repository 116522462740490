import { useAuth } from "../contexts/Auth";
import { CommercialPolicyProductsType } from "../types/CommercialPolicyProductsType";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Config } from "@configs/Config";
import { useCustomer } from "../contexts/CustomerContext";
import { AssortmentInputType } from "../types/AssortmentInputType";
import { CartProductTypeEnum } from "@enums/CartProductTypeEnum";
import { EanType } from "../types/EanType";
import { useWholesalers } from "./useWholesalers";
import { AvailabilityConsolidateEnum } from "@enums/AvailabilityConsolidateEnum";
import Cdn from "@helpers/Cdn";

export const SUGGESTED_ANCHOR_PRODUCTS = gql`
  query Msbit_suggestedAnchorProducts(
    $anchorEans: [Msbit_anchorProductInput]!
    $wholesalerBranchCode: String!
    $customerCode: String!
    $industryCode: String!
    $limit: Int!
    $offset: Int!
    $assortmentIds: [Int!]
    $availability: AvailabilityConsolidateEnum
  ) {
    Msbit_suggestedAnchorProducts(
      filter: {
        productsAnchor: $anchorEans
        industry: { code: $industryCode }
        customer: { code: $customerCode }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      product {
        anchor
        code
      }
      amount
      commercialPolicyProduct(
        industryCode: $industryCode
        customerCode: $customerCode
        availability: $availability
        product: { assortment: { ids: $assortmentIds } }
      ) {
        commercialPolicyId
        maxAmount
        discount {
          base
          progressive {
            products {
              amount
              value
            }
          }
        }
        product {
          id
          code
          description
          monitored
          shippingBox
          brand {
            id
          }
        }
        minimumAmount
        wholesaler(
          wholesalerBranch: { code: $wholesalerBranchCode }
          customer: { code: $customerCode }
        ) {
          price
          fractionation
        }
        requiredType
        hasNotConsideredMinimum
      }
    }
  }
`;

interface SuggestedAnchorProductsProps {
  anchorEans: EanType[];
  limit: number;
  offset: number;
  commercialPolicyId?: number | undefined;
  industryCode?: string;
  customerCode?: string;
  wholesalerBranchCode?: string;
  assortmentIds?: number[];
  availability?: AvailabilityConsolidateEnum;
}

export const useSuggestionAnchorProducts = (
  props: SuggestedAnchorProductsProps
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryName = user?.getNameIndustryStore();
  const wholesalers = useWholesalers();

  const assortment: AssortmentInputType = {
    ids: user.getAssortments().map((assortment) => Number(assortment.id)),
  };

  props.wholesalerBranchCode =
    props?.wholesalerBranchCode ?? wholesalers.wholesalers[0]?.code ?? "";
  props.industryCode = industryName;
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.assortmentIds = assortment.ids || [];
  props.availability = user.getAvailability();

  return useQuery(SUGGESTED_ANCHOR_PRODUCTS, {
    variables: props,
    fetchPolicy: "no-cache",
  });
};

export const useSuggestionAnchorProductsLazy = (
  props: SuggestedAnchorProductsProps
) => {
  const { user } = useAuth();
  const { customerCode } = useCustomer();
  const industryName = user?.getNameIndustryStore();
  const wholesalers = useWholesalers();

  const assortment: AssortmentInputType = {
    ids: user.getAssortments().map((assortment) => Number(assortment.id)),
  };

  props.wholesalerBranchCode =
    props?.wholesalerBranchCode ?? wholesalers.wholesalers[0]?.code ?? "";
  props.industryCode = industryName;
  props.customerCode = customerCode != "" ? customerCode : user.getUserCode();
  props.assortmentIds = assortment.ids || [];
  props.availability = user.getAvailability();

  return useLazyQuery(SUGGESTED_ANCHOR_PRODUCTS, {
    variables: props,
    fetchPolicy: "no-cache",
  });
};

export const refactorResult = (
  products: any,
  nameIndustry?: string | undefined,
  commercialPolicyId?: number | undefined
): CommercialPolicyProductsType[] => {
  if (!nameIndustry) {
    const { user } = useAuth();
    nameIndustry = user?.getIndustry();
  }

  const parsedProducts = products.map((product: any) => {
    const item = product.commercialPolicyProduct;
    const urlImage = Cdn.getProductImageUrl(item.code, nameIndustry || "");
    return {
      ...item,
      discount: {
        ...item.discount,
        total: item.discount.base,
      },
      product: {
        ...item.product,
        url: urlImage,
        type: null,
        recommendedQuantity: product.amount,
        quantity: product.amount,
      },
      tab: CartProductTypeEnum.DEFAULT,
      commercialPolicyId: commercialPolicyId || Number(item.commercialPolicyId),
      minimumAmount: item.minimumAmount,
      maxAmount: item.maxAmount,
      extraDiscounts: [],
      wholesaler: {
        price: {
          fullPrice: item.wholesaler.price,
          netPrice:
            Number(item.wholesaler.price) -
            (Number(item.wholesaler.price) * item.discount.base) / 100,
        },
        fractionation: item.wholesaler.fractionation,
      },
      suggestions: {
        anchor: [{
          code: product.product.anchor,
          suggestedAmount: product.amount,
        }]
      }
    };
  });
  return parsedProducts;
};
