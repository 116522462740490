export class TextHelper {
  text: string;

  constructor(text: string) {
    this.text = text;
  }

  static create(text:string) {
   return new TextHelper(text);
  }

  formatZipCode() {
    const zipCode = this.text.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');

    return zipCode;
  }

  formatCNPJ() {
    const cnpj = this.text.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    return cnpj;
  }

  upperCase() {
    if(this.text.length == 0 ) return '';

    return this.text.toUpperCase()
  }

}