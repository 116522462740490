import { StyleSheet } from 'react-native';
import { THEME } from "../../theme";

import { width } from '../Dimension';

export const styles = StyleSheet.create({
  numberOrderCircle: {
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  numberOrderText: {
    color: THEME.colors.muted[600],
  },
  icon: {
    margin: width * 0.0035, // 5,
  },
  deleteIconColor: {
    color: THEME.colors.red[400]
  },
  deleteIconMobile: {
    margin: 0,
    marginTop: 8,
    marginRight: 1
  },
  deleteIconRippleColor: {
    color: THEME.colors.white
  },
  linkPaymentTerm: {
    color: THEME.colors.primary[600]
  },
  iconGrabber: {
    color: THEME.colors.gray[400]
  },

  iconPopover: {
    color: THEME.colors.primary[600] 
  },
  DropDownPaymentTerms: {
    borderWidth: 0, 
    height: "auto", 
    paddingTop: 5, 
    paddingBottom: 0, 
    marginBottom: 0 
  },

  fontColorDefault: {
    color: THEME.colors.muted[800]
  },

  fontColorDefaultDark: {
    color: THEME.colors.muted[400]
  },

  cardsBackgroundDark: {
    color: THEME.colors.muted[800]
  }
});