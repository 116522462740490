import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { Config } from "@configs/Config";
import { getTokenFromStorage } from "@helpers/getTokenFromStorage";

const endpoint = Config.GATEWAY_URL + `/graphql`;

const httpLink = createHttpLink({
  uri: endpoint,
});

export const gatewayClient = (token: any) => {

  const authLink = setContext(async (_, { headers }) => {
    const authToken = await getTokenFromStorage();

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            customerAvailableIndustries: {
              ...offsetLimitPagination(),
              keyArgs: false,
              read(existing, { args: { offset, limit } }) {
                return existing && existing?.slice(offset, offset + limit);
              },
              merge(existing, incoming, { args: { offset = 0 } }) {
                const merged = existing ? existing.slice(0) : [];

                for (let i = 0; i < incoming?.edges?.length; ++i) {
                  merged[offset + i] = incoming.edges[i];
                }
                return merged;
              },
            },
            availableIndustriesByUser: {
              ...offsetLimitPagination(),
              keyArgs: false,
              read(existing, { args: { offset, limit } }) {
                return existing && existing?.slice(offset, offset + limit);
              },
              merge(existing, incoming, { args: { offset = 0 } }) {
                const merged = existing ? existing.slice(0) : [];

                for (let i = 0; i < incoming?.edges?.length; ++i) {
                  merged[offset + i] = incoming.edges[i];
                }
                return merged;
              },
            }
          },
        },
      },
    }),
  });
};