import { gql, useMutation } from "@apollo/client";

export type SchedulingInputType = {
  date: Date;
  period: string;
}

export type ScheduleInputType = {
  cartId: string;
  schedules: SchedulingInputType[];
}

const CART = gql`
  mutation Schedule($input: Cart_ScheduleInputType!) {
    Cart_schedule(input: $input) {
      id
      customerCode
      userId
      status
      createdAt
      updatedAt
      notifications {
        key
        message
      }
      items {
        code
        amount
        discount
        type
        coupons {
          name
          percentage
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const useSchedule = () => {
  return useMutation(CART, {
    refetchQueries: [
      'Cart'
    ]
  });
};
