import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";

export type CustomerInputType = {
	code: string,
}

export type IndustryInputType = {
	code: string,
}

type ShippingOrderInputType = {
	code: string,
	paymentTerm?: string | null,
}

export type CartInputType = {
	customer: CustomerInputType,
	industry: IndustryInputType
	shippingOrder: ShippingOrderInputType[]
}

const CREATE_CART = gql`
  mutation FindOrCreate($input: Cart_CartInputType!) {
    Cart_findOrCreateCart(input: $input) {
      id
        customerCode
        industryCode
        status
        code
		customCode
        userId
				grossPrice
				netPrice
				valid
				discountValue
				coupons
				shippingOrders {
					code
					paymentTerm
				}
				items {
					code
					amount
					totalDiscount
					commercialPolicyId
					type
					valid
					unitGrossPrice
					unitNetPrice
					grossPrice
					netPrice
					discountValue
					discount
					errors {
						type
						message
					}
					discountsTrace
					coupons {
						name
						percentage
					}
          product {
						code
						description
						brand {
							id
							description
						}
						assortment {
							name
						}
					}
					suggestions{
						list{
							name
							code
							amount
						}
						anchor {
							anchorAmount
						}
					}
				}
    }
  }
`;

export const useFindOrCreateCart = () => {
	return useMutation(CREATE_CART, {
		refetchQueries: [
			'Cart'
		],
		onCompleted(data) {
			if (data.Cart_findOrCreateCart) {
				cartVar(data.Cart_findOrCreateCart)
			}
		},
	});
};