import { useAuth } from "@contexts/Auth";
import { RedirectHome } from "@helpers/RedirectHome";
import { useTimeBlock } from "@hooks/useTimeBlock";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useCallback, useState } from "react";

export const useTimeBlockModel = () => {
  const timeBlock = useTimeBlock()
  const { navigate } = useNavigation()
  const redirect = RedirectHome();
  const { user, signOut } = useAuth()

  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(true);

  const onLogout = (useCallback(() => {
    if (isClicked) {
      return;
    }
    setIsClicked(true)
    setTimeout(async () => {
      await signOut()
      setTimeout(() => {
      }, 300)
    }, 1000)

  }, [user, isClicked]))

  useFocusEffect(
    useCallback(() => {
      const intervalId = setTimeout(() => {
        setLoading(false)
        if (!timeBlock.block) {
          navigate(redirect.screen, redirect.params)
        }
      }, 2000);

      return () => {
        clearTimeout(intervalId);
      };
    }, [timeBlock.block])
  );

  return {
    timeBlock,
    onLogout,
    loading,
  }
}