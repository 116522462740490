import { gql, useMutation } from "@apollo/client";
import { cartVar } from "./useCart";
import { ItemTypeEnum } from "@enums/ItemTypeEnum";

export type AddItemsInput = {
	cartId: string,
	listName: string,
	listCode: string,
	products: [{
		code: string,
		type: ItemTypeEnum,
		commercialPolicyId: number,
		amount: number,
	}],
	overwrite: boolean
};

const CART = gql`
  mutation CartAddSuggestedList($input: Cart_AddItemsInput!) {
    Cart_addSuggestedList(input: $input) {
      	id
        customerCode
        industryCode
        status
        code
        userId
		grossPrice
		netPrice
		valid
		discountValue
		coupons
		shippingOrders {
			code
			paymentTerm
		}
		items {
			code
			amount
			totalDiscount
			commercialPolicyId
			type
			valid
			unitGrossPrice
			unitNetPrice
			grossPrice
			netPrice
			discountValue
			discount
			errors {
				type
				message
			}
			discountsTrace
			coupons {
				name
				percentage
			}
			product {
				code
				description
				brand {
					id
					description
				}
				assortment {
					name
				}
			}
			suggestions{
				list{
					name
					code
					amount
				}
				anchor {
					anchorAmount
				}
			}
		}
    }
  }
`;

export const useAddSuggestedList = () => {
	return useMutation(CART, {
		onCompleted(data) {
			if (data.Cart_addSuggestedList) {
				cartVar(data.Cart_addSuggestedList)
			}
		},
	});
};
